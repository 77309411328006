import { Box, Typography } from "@mui/material";

import CommonButton from "../CommonButton/CommonButton";
import { useGetConnectionUrlMutation, useGetPlatformConfigurationPollingQuery } from "../../services/platformQuery";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMyContext } from "../CommanToasterContext/toast";

export function ButtonComponent({ label, required, color, name, Platform, platformType, readonly, configured = false }) {
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [skip, setSkip] = useState(true)
    const dataTypeId = searchParams.get('dataTypeId');
    const dataType  = searchParams.get('dataType');
    const { setOpen2, setMessage2 } = useMyContext();
    const [connectionUrl, { data: UrlData, isLoading: UrlLoading, error: UrlError },] = useGetConnectionUrlMutation();

    const { data, isLoading, error, refetch } = useGetPlatformConfigurationPollingQuery({
        platformId: id,
        dataTypeId: dataTypeId
    }, {
        pollingInterval: 5000,
        skip,
        // skipPollingIfUnfocused: true
    });
    const handleClick = () => {
        connectionUrl({ connectorId: dataTypeId, platformName: Platform?.name, platformType: platformType })
    }
    useEffect(() => {
        if (UrlData !== undefined) {
            if (UrlData.statusCode === 200 && UrlData.response) {
                window.open(UrlData.response, '_blank');
                setSkip(false)
            } else if (UrlData.statusCode !== 200) {
                // Handle other scenarios (show message, etc.)
                setMessage2(UrlData?.statusMessage || "Unknown error occurred");
                setOpen2(true);
            }
        }


        if (UrlError !== undefined) { 
            console.log("UrlError", UrlError);
        }
    }, [UrlData, UrlError]);


    useEffect(() => { 
        if (data !== undefined) {
            if (data.statusCode === 200 && data.response) {
                const platformConfiguration = data.response.platformConfigurations[dataType]
                if (platformType === "src" ? platformConfiguration?.srcConfigured === true : platformConfiguration?.destConfigured === true) {
                    setSkip(true)
                }
            } else if (data.statusCode !== 200) {
                // Handle other scenarios (show message, etc.)
                setMessage2(data?.statusMessage || "Unknown error occurred");
                setOpen2(true);
            }
        }

        if (error !== undefined) {
            console.log("error", error);
        }
    }, [data, error, dataType]);

    return (
        <Box>
            <Typography
                sx={{
                    fontFamily: "Helvetica Neue",
                    fontWeight: 700,
                    fontSize: "0.75rem",
                    color: color.themeBlack,
                    mb: 0.5,
                }}
            >
                {label}
                {required && "*"}
            </Typography>
            <Box sx={{ mb: "1rem", width: "100%" }}>
                <CommonButton
                    width={"25rem"}
                    textStyle={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        fontFamily: "Helvetica Neue",
                        color: color.whiteColor,
                    }}
                    isLoading={!skip || UrlLoading}
                    title={configured ? "Connected - Click to Reconnect" : label}
                    onClick={handleClick}
                    color={color.buttonColor}
                    disabled={!skip || UrlLoading}
                ></CommonButton>
            </Box>
        </Box>
    );
}
