import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import NaLayout from "../../components/NavLayout/NaLayout";
import { setImageLoaded } from "../../features/madTechSlice";
import useDocumentTitle from "../../hook/useDocumentTitle";
import { color } from "../../utils/Colors";
import HomeFooter from "../Home/HomeFooter";
import LetsConnect from "../Home/LetsConnect";
import pdfWhiteImg from "../../assets/pdf_white.svg";
import { HOST } from "../../utils/Baseurl";
import {
  DownloadBtn,
  DownloadBtnWrapper,
  Heading,
  HeadingWrapper,
  LastUpdatedAt,
} from "./TermsCondition.styles";
import { fiveSixSevenArr } from "./constant";

const TremsCondition = () => {
  useDocumentTitle("Madconnect Terms of Service");
  const matcheslg = useMediaQuery("(min-width:1100px)");
  const matchesmd = useMediaQuery("(min-width:980px)");
  const matchessm = useMediaQuery("(min-width:880px)");
  const matchesxs = useMediaQuery("(min-width:760px)");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setImageLoaded(true));
  }, []);

  return (
    <NaLayout>
      <Box sx={{ mx: "auto" }}>
        <HeadingWrapper>
          <Box sx={{ width: "91%", maxWidth: 1350 }}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Heading
                fontSize={
                  matcheslg
                    ? "2.5rem"
                    : matchesmd
                    ? "2.3rem"
                    : matchessm
                    ? "2rem"
                    : matchesxs
                    ? "1.8rem"
                    : "1.6rem"
                }
              >
                MADCONNECT TERMS OF SERVICE
              </Heading>
              <DownloadBtnWrapper>
                <a
                  style={{ color: color.whiteColor, textDecoration: "none" }}
                  download={"MadConnect_terms_of_service_07242023.pdf"}
                  target="_blank"
                  href={`${HOST}/platforms/MadConnect_terms_of_service_07242023.pdf`}
                >
                  <Box
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                    }}
                  >
                    <Typography>
                      <img
                        src={pdfWhiteImg}
                        style={{ maxWidth: "24px", maxHeight: "24px" }}
                      />
                    </Typography>
                    <DownloadBtn>DOWNLOAD</DownloadBtn>
                  </Box>
                </a>
              </DownloadBtnWrapper>
            </Box>
            <LastUpdatedAt
              fontSize={
                matcheslg
                  ? "1rem"
                  : matchesmd
                  ? "0.9rem"
                  : matchessm
                  ? "0.8rem"
                  : matchesxs
                  ? "0.7rem"
                  : "0.7rem"
              }
            >
              Last Updated: 24 July, 2023
            </LastUpdatedAt>
          </Box>
        </HeadingWrapper>
        <Box
          sx={{
            py: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eeeeee",
          }}
        >
          <Box
            sx={{
              width: "91%",
              maxWidth: 1350,
              backgroundColor: "#fff",
              p: 3,
              borderRadius: 2,
              border: "1px solid rgba(54, 70, 172, 0.24)",
            }}
          >
            <div>
              <meta
                httpEquiv="Content-Type"
                content="text/html; charset=utf-8"
              />
              <meta name="Generator" content="Microsoft Word 15 (filtered)" />
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n@font-face\n\t{font-family:"Noto Sans Symbols";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:170%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpFirst\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpMiddle\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1.CxSpLast\n\t{mso-style-link:"Heading 1 Char";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nspan.Heading1Char\n\t{mso-style-name:"Heading 1 Char";\n\tmso-style-link:"Heading 1";\n\tfont-family:"Arial",sans-serif;\n\tcolor:windowtext;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:6.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:.5in 1.0in 1.25in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in; padding-left: 1rem;}\nul\n\t{margin-bottom:0in;}\n-->\n',
                }}
              />
              <div className="WordSection1">
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={1} type={1}>
                  <li className="MsoNormal">
                    <b>ACKNOWLEDGEMENT AND ACCEPTANCE OF TERMS OF SERVICE</b>
                  </li>
                </ol>
                <p className="MsoNormal">
                  MADTECH, LLC ("
                  <span style={{ "text-transform": "uppercase" }}>MadTech</span>
                  ") MAINTAINS THIS SERVICE FOR ITS CLIENTS, PARTNERS, AND OTHER
                  AUTHORIZED USERS. PLEASE READ THESE TERMS OF SERVICE (
                  <span style={{ "text-transform": "uppercase" }}>
                    the "Terms")
                  </span>{" "}
                  CAREFULLY. BY ACCESSING OR USING ALL OR ANY PORTION OF THE
                  SERVICE AS CONTEMPLATED BY YOUR LICENSE AGREEMENT WITH MADTECH
                  (THE "LICENSE AGREEMENT"), BY CLICKING "I ACCEPT", OR "I
                  AGREE", OR BY PAYING FOR THE SERVICES OFFERED BY MADTECH OR
                  ITS AFFILIATES OR PARTNERS, INCLUDING THOSE SERVICES OR
                  PLATFORMS LICENSED BY MADTECH (COLLECTIVELY, THE "
                  <span style={{ "text-transform": "uppercase" }}>Service</span>
                  "), YOU ACCEPT THE TERMS AND CONDITIONS OF THE LICENSE
                  AGREEMENT AND THESE TERMS (COLLECTIVELY, THE "AGREEMENT"), AS
                  THE SAME MAY BE MODIFIED FROM TIME TO TIME. YOU AGREE THAT THE
                  AGREEMENT IS ENFORCEABLE LIKE ANY WRITTEN NEGOTIATED AGREEMENT
                  SIGNED BY YOU. YOU REPRESENT AND WARRANT THAT ANY INDIVIDUAL
                  CLICKING THROUGH AND ACCEPTING THE AGREEMENT ON YOUR BEHALF
                  HAS THE FULL AND LAWFUL AUTHORITY TO DO SO. IF YOU DO NOT
                  AGREE WITH THE AGREEMENT, DO NOT USE THE SERVICE. THE TERMS
                  AND CONDITIONS OF THE AGREEMENT APPLY TO ANY AND ALL USE OF
                  THE SERVICE BY YOU OR YOUR AUTHORIZED USERS, INCLUDING IF YOU
                  ARE USING THE SERVICE PURSUANT TO ANY DEMO OR TRIAL PERIOD,
                  FOR THE TERM OF THE AGREEMENT AND YOU, ON YOUR OWN BEHALF AND
                  ON BEHALF OF YOUR USERS, AGREE AND CONSENT TO BE BOUND BY THE
                  TERMS AND CONDITIONS OF THE AGREEMENT REGARDLESS OF THE TYPE
                  OF USE OF THE SERVICE BY YOU.
                </p>
                <p className="MsoNormal">
                  The Agreement, as amended from time to time, is between you
                  ("Client", "you", "your") and MadTech and shall apply to all
                  dealings between Client and MadTech and all use of the
                  Services by Client. You agree that by using the Service, you
                  shall (i) be responsible for your compliance with the
                  Agreement and all activities occurring within or from your
                  account, (ii) be solely responsible for the accuracy, quality,
                  integrity and legality of, and for the means by which you
                  acquired content or data, and your use of the Service, (iii)
                  use reasonable efforts to prevent unauthorized access to or
                  use of the Service, and notify us promptly of any such
                  unauthorized access or use, and (iv) use the Service only in
                  accordance with the Terms and applicable laws and government
                  regulations. You shall not (a) interfere with or disrupt the
                  integrity or performance of the Service or any third-party
                  data accessible thereby, or (d) attempt to gain unauthorized
                  access to the Service or MadTech’s systems or networks. In
                  addition, in using the Service, Client shall not and shall not
                  permit or authorize any third party to:
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (i) <span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  copy, rent, sell, lease, sublicense, distribute, assign, or
                  otherwise transfer or encumber rights to the Service;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (ii)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  except as expressly permitted by the License Agreement, use
                  the Service for the benefit of any third party, or make the
                  Service available to anyone other than its authorized users;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (iii)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  circumvent or disable any digital rights management, usage
                  rules, or other security features of the Service, or otherwise
                  attempt to gain unauthorized access to, or disrupt the
                  integrity or performance of, the Service or the data contained
                  therein;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (iv)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  modify, copy, translate, enhance, decompile, disassemble,
                  reverse engineer or create derivative works based on the
                  Service, or any portion thereof;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (v)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  access or use the Service for the purpose of building a
                  competitive product or service or copying its features or user
                  interface;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (vi)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  remove, alter, or obscure any copyright, trademark or other
                  proprietary notices appearing in or on the Service; or
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (vii)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  use the Service in a manner that overburdens, or that
                  threatens the integrity, performance, or availability of, the
                  Service.
                </p>
                <p className="MsoNormal">
                  ANY VIOLATION OF THESE TERMS MAY RESULT IN THE SUSPENSION OR
                  TERMINATION OF CLIENT’S ACCOUNT, CLIENT’S ACCESS AND USE OF
                  THE SERVICE OR SUCH OTHER ACTION PERMITTED IN ACCORDANCE WITH
                  THE AGREEMENT, LAW, OR REGULATION.
                </p>
                <p className="MsoNormal">
                  Capitalized terms used and not defined in these Terms have the
                  meanings given such terms in the License Agreement.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={2} type={1}>
                  <li
                    className="MsoNormal"
                    style={{ "margin-bottom": "6.0pt" }}
                  >
                    <b>THE SERVICE</b>
                  </li>
                </ol>
                <p className="MsoNormal" style={{ "margin-bottom": "12.0pt" }}>
                  The Service is a self-service platform that enables Client to
                  establish Connections through the Service with the Third Party
                  Platforms as designated from time to time by Client in the
                  Client Account Dashboard. In order to establish each
                  Connection through the Service, Client will be required to
                  enter the unique API key provided to Client by the applicable
                  Third Party Platform under its Third Party Platform Agreement
                  (each a "Client API Key" and collectively "Client API Keys").
                  Client shall be solely responsible for protecting and
                  maintaining the security of the Client API Keys.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={3} type={1}>
                  <li
                    className="MsoNormal"
                    style={{ "margin-bottom": "6.0pt" }}
                  >
                    <b>CLIENT ACCOUNT</b>
                  </li>
                </ol>
                <p className="MsoNormal" style={{ "margin-bottom": "12.0pt" }}>
                  Client must create an account to access and use the Service
                  and in connection therewith, Client agrees to provide accurate
                  and complete information including Client name and address,
                  names of Client Users, Client email address, telephone number,
                  and contact information for administrative and accounting
                  matters. Client is solely responsible for all activities that
                  take place on or through its account, including the acts and
                  omissions of any of its employees, contractors, agents or
                  representatives (collectively, "Client Users"). Client shall,
                  and shall require all Client Users to, (a) establish, maintain
                  and utilize appropriate security measures with respect to
                  Client’s or Client Users’ access to and use of (i) the
                  Service, including using appropriately complex passwords,
                  keeping passwords confidential, and regularly changing
                  passwords, and (ii) any Client API Keys, and (b) comply with
                  MadTech’s security guidelines and procedures applicable to the
                  Service and the use thereof. MadTech shall not be responsible
                  for any unauthorized access to Client’s account, including any
                  unauthorized use of the Service by Client or Client Users or
                  any unauthorized access to or use of any Client API Keys by
                  any third party, and Client shall indemnify, defend and hold
                  MadTech harmless from and against any and all losses, costs
                  and expenses arising out of or resulting from any such
                  unauthorized access or use.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={4} type={1}>
                  <li
                    className="MsoNormal"
                    style={{ "margin-bottom": "6.0pt" }}
                  >
                    <b>CLIENT CONTENT</b>
                  </li>
                </ol>
                <p className="MsoNormal">
                  The Service is designed to allow Client to access the Service
                  via API(s) from Client or Client’s applications, web connected
                  devices or web properties. MadTech collects, stores and uses
                  information regarding Client’s use of the Service, including
                  record of acceptance of Terms, IP address, time of access,
                  usernames, number of connections, number of transactions, and
                  volume of data transmitted ("Client Usage Information"), to
                  maintain, improve and support the Service. MadTech may also
                  use Client Usage Information in an anonymized and aggregated
                  form ("Aggregated Data") for MadTech’s own business purposes,
                  including use, duplication, modification and creation of
                  derivative works regarding usage and performance of the
                  Service.&nbsp; MadTech shall own all right, title and interest
                  to the Aggregated Data and any derivative works thereof or
                  therefrom. MadTech will only share Client Usage Information
                  with others under special circumstances as follows:
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (i)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  with third parties who work on MadTech’s behalf to provide the
                  Service;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (ii)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  to the extent needed to comply with laws or to respond to
                  lawful requests and legal process (provided that MadTech will
                  endeavor to notify Client if MadTech has received a lawful
                  request for Client’s information);
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (iii)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  to protect the rights and property of MadTech, its agents,
                  Clients, and others including to enforce MadTech’s agreements,
                  policies, and terms of use;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (iv)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  in an emergency, including to protect the personal safety of
                  any person;
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (v)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  in connection with a sale or transfer of all or a part of
                  MadTech’s business or assets (business deals may include, for
                  example, any merger, financing, acquisition, divestiture, or
                  bankruptcy transaction or proceeding); or
                </p>
                <p
                  className="MsoNormal"
                  style={{ "margin-left": "0.5in", "text-indent": "-.25in" }}
                >
                  <span
                    style={{ "font-size": "10.0pt", "line-height": "107%" }}
                  >
                    (vi)<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;</span>
                  </span>
                  as directed by Client, including through its use of the
                  Service.
                </p>
                <p className="MsoNormal">
                  MadTech will delete Client Usage Information upon request once
                  a business need for such information no longer exists.
                  &nbsp;Upon termination of MadTech’s relationship with Client,
                  MadTech’s business need, or in accordance with MadTech’s data
                  retention policy, MadTech may delete Client Usage Information
                  no longer in active use. MadTech takes no responsibility and
                  assumes no liability for any Client Usage Information.
                </p>
                {fiveSixSevenArr?.map((data) => {
                  return (
                    <>
                      <p className="MsoNormal">&nbsp;</p>
                      <ol
                        style={{ "margin-top": "0in" }}
                        start={data.start}
                        type={1}
                      >
                        <li className="MsoNormal">
                          <b>{data.question}</b>
                        </li>
                      </ol>
                      <p className="MsoNormal">{data?.answer}</p>
                      {data?.isAlpha &&
                        data?.alphaArr?.map((alData) => {
                          return (
                            <p
                              className="MsoNormal"
                              style={{
                                "text-indent": "-.25in",
                                padding: "0px 45px",
                              }}
                            >
                              {alData.key}<span style={{ font: "7.0pt" }}>&nbsp;&nbsp;{(alData.key ==="C." || alData.key ==="D." || alData.key ==="E.") && " "}</span>
                              {alData.value}
                            </p>
                          );
                        })}
                    </>
                  );
                })}
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={10} type={1}>
                  <li className="MsoNormal">
                    <b>WARRANTIES; DISCLAIMER OF WARRANTIES</b>
                  </li>
                </ol>
                <p className="MsoNormal">
                  MadTech warrants to Client that the Service will perform
                  materially in accordance with the provisions of the License
                  Agreement and any documentation provided by MadTech.&nbsp;
                  MadTech’s sole liability and Client’s exclusive right and
                  remedy for a breach of the foregoing warranty is for MadTech
                  to correct or re-perform the nonconforming Service.
                </p>
                <p className="MsoNormal">
                  Client warrants that Client owns or has obtained all necessary
                  rights, title and interest, and obtained all necessary
                  consents, to transfer any Client data to the Third Party
                  Platforms via the Service.
                </p>
                <p className="MsoNormal">
                  EXCEPT AS SPECIFICALLY PROVIDED HEREIN, USE OF ANY SERVICE
                  PROVIDED BY MADTECH IS AT CLIENT’S SOLE RISK. THE SERVICE IS
                  PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. MADTECH AND
                  ITS SUPPLIERS AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES
                  OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                  LIMITED TO THE IMPLIED INDEMNITIES AND WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                  NON-INFRINGEMENT. MADTECH DOES NOT GUARANTEE THE ACCURACY,
                  COMPLETENESS, OR USEFULNESS OF THE SERVICE, AND CLIENT RELIES
                  ON SUCH SERVICE AT CLIENT’S OWN RISK.&nbsp; MADTECH DOES NOT
                  GUARANTEE THE ACCURACY, USABILITY, COMPLETENESS, OR USEFULNESS
                  OF ANY DATA OBTAINED BY CLIENT FROM ANY THIRD PARTY PLATFORM.
                  ANY MATERIAL OR DATA THAT CLIENT ACCESSES, TRANSMITS OR
                  OBTAINS THROUGH THE SERVICE IS ACCESSED AND USED AT CLIENT’S
                  OWN DISCRETION AND RISK AND CLIENT WILL BE SOLELY RESPONSIBLE
                  FOR ANY DAMAGE TO CLIENT’S COMPUTER SYSTEMS OR LOSS OF DATA
                  THAT RESULTS FROM THE DOWNLOAD OR TRANSFER OF ANY MATERIAL
                  THROUGH THE SERVICE. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                  WRITTEN, OBTAINED BY CLIENT FROM MADTECH OR THROUGH OR FROM
                  THE SERVICE WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN
                  THE AGREEMENT.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={11} type={1}>
                  <li className="MsoNormal">
                    <b>LIMITATION OF LIABILITY</b>
                  </li>
                </ol>
                <p className="MsoNormal">
                  MADTECH AND ITS AFFILIATES WILL NOT BE LIABLE UNDER THE
                  AGREEMENT FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                  CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
                  TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER
                  INTANGIBLE LOSSES (EVEN IF MADTECH HAS BEEN ADVISED OF THE
                  POSSIBILITY OF THESE DAMAGES), INCLUDING AS A RESULT OF
                  CLIENT’S USE OF THE SERVICE. UNDER NO CIRCUMSTANCES WILL THE
                  TOTAL LIABILITY OF MADTECH AND ITS AFFILIATES ARISING OUT OF
                  OR RELATED TO THE AGREEMENT AND/OR CLIENT’S USE OF THE SERVICE
                  (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), REGARDLESS OF
                  WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT,
                  NEGLIGENCE OR OTHERWISE, EXCEED THE AGGREGATE AMOUNT, IF ANY,
                  THAT CLIENT HAS PAID TO MADTECH FOR THE SERVICE DURING THE
                  TWELVE (12) MONTH PERIOD PRIOR TO THE DATE THE CLAIM FIRST
                  AROSE.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={12} type={1}>
                  <li className="MsoNormal">
                    <b>GOVERNING LAW &amp; JURISDICTION</b>
                  </li>
                </ol>
                <p className="MsoNormal">
                  Client and MadTech agree that all matters arising from the
                  Agreement or relating to the use and operation of the Service
                  will be governed by the laws of the State of Connecticut,
                  without regard to its conflicts of laws principles.{" "}
                  <a name="_Hlk132790860">
                    Client agrees that all such matters will litigated in the
                    state courts of Connecticut or the United States District
                    Court of the District of Connecticut (and appellate courts
                    therefrom). Client consents to the personal jurisdiction of
                    such courts, stipulates to the fairness and convenience of
                    proceeding in such courts, and covenants not to assert any
                    objections to proceeding in such courts
                  </a>
                  .
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <ol style={{ "margin-top": "0in" }} start={13} type={1}>
                  <li className="MsoNormal">
                    <b>GENERAL</b>
                  </li>
                </ol>
                <p className="MsoNormal">
                  In the event that any provision of the Agreement shall, in
                  whole or in part, be determined to be invalid, unenforceable
                  or void for any reason, such determination shall affect only
                  the portion of such provision determined to be invalid,
                  unenforceable or void, and shall not affect in any way the
                  remainder of such provision or any other provision of the
                  Agreement. MadTech’s failure to act with respect to a breach
                  by Client or others does not waive its right to act with
                  respect to subsequent or similar breaches.&nbsp;
                  <b>
                    In the event of a direct conflict between the Terms and the
                    terms of the License Agreement, the License Agreement shall
                    govern.
                  </b>
                  &nbsp;
                </p>
                <p className="MsoNormal">&nbsp;</p>
              </div>
            </div>
          </Box>
        </Box>
        <LetsConnect />
        <Box sx={{ maxWidth: 1550, mx: "auto" }}>
          <HomeFooter />
        </Box>
      </Box>
    </NaLayout>
  );
};

export default TremsCondition;
