import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "1rem !important",
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  borderRadius: "8px !important",
});

export const PrimaryContDetailsBox = styled(Box)({
  width: "100%",
  marginTop: "1.5rem",
  border: "1px solid #E4E4E4",
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  paddingBottom: "1.5rem",
  display: "flex",
  flexDirection: "column",
});

export const SelfBox = styled(Box)({
  marginTop: "0.5rem",
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  height: "1.25rem",
});

export const Heading = styled(Typography)({
  fontSize: "1.125rem !important",
  fontFamily: "Helvetica Neue !important",
  fontWeight: "500 !important",
  letterSpacing: "0.2px !important",
});

export const CountrySelectWrapper = styled(Box)({
  display: "flex",
  marginTop: "1.5rem",
  width: "100%",
  height: "2.75rem",
  fontSize: "1rem",
  borderRadius: "4px",
  alignItems: "center",
  justifyContent: "center",
});

export const BillContactWrapper = styled(Box)({
  display: "flex",
  marginTop: "1rem",
  width: "100%",
  justifyContent: "space-between",
})

export const SubmitBtnWrapper = styled(Box)({
  width: "100%",
  marginTop: "1.5rem",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
})