import React, { useState } from 'react'
import { Box } from "@mui/material";
import { Controller } from 'react-hook-form';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import dayjs from 'dayjs';

import './Plans.css'

const CommanDatePicker = ({
    name,
    control,
    value
}) => {
    const [open, setOpen] = useState(false);

    const defaultEndTime = new Date();   

    defaultEndTime.setHours(23, 59, 0, 0);
    const formattedDefaultValue = value !== null ? dayjs(value) : null

    return (
        <Box
            sx={{
                marginTop: "15px",
                width: "100%"
            }}
            className='changeDate'
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimeRangePicker']}>
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={formattedDefaultValue ? formattedDefaultValue : null}
                        render={({ field }) => (
                            <DateTimePicker
                                {...field}
                                label="Initial Sync - Start Time"
                                className='dateranges'
                                sx={{
                                    "& input": {
                                        color: "#1C2B47", fontSize: "13px", fontFamily: "Helvetica Neue",
                                    }
                                }}
                                open={open}
                                onClose={() => setOpen(false)}
                                slotProps={{
                                    textField: {
                                        onClick: () => setOpen(true),
                                    },
                                }}

                            />
                        )}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </Box>
    )
}

export default CommanDatePicker