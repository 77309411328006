import React from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import aboutMadconnectImg from '../../assets/AbotMadconnect.webp'
import { AboutSuperContainer, ConnectDescription, ConnectSubHeading, ConnectContainer, ConnectMainContainer, Heading } from './AboutMad.styles';
import { getFontSize } from '../../utils/utils';


const AbouMadconnect = () => {
    
    const matches = {
        lg: useMediaQuery('(min-width: 1400px)'),
        md: useMediaQuery('(min-width: 1280px)'),
        sm: useMediaQuery('(min-width: 1200px)'),
        xs: useMediaQuery('(min-width: 1060px)'),
        xss: useMediaQuery('(min-width: 810px)'),
      };

    return (
        <AboutSuperContainer isConnect={true} >
            <ConnectMainContainer matchesxss={matches.xss}>
                <Box>
                    <LazyLoadImage
                        alt={'ABOUT MADCONNECT'}
                        // effect="blur"
                        width={'100%'}
                        height={'100%'}
                        src={aboutMadconnectImg} />
                </Box>
                <ConnectContainer matchesxss={matches.xss}>
                    <Heading  isConnect={true} fontSize={getFontSize(matches , { lg: '1.2rem', md: '1.1rem', sm: '1rem', xs: '1rem', xss: '1rem' })} >
                        ABOUT MADCONNECT
                   </Heading>   
                   <ConnectSubHeading fontSize={getFontSize(matches ,{ lg: '2.5rem', md: '2.2rem', sm: '1.9rem', xs: '1.7rem', xss: '1.7rem' })} >
                       Solving for Data Interoperability
                   </ConnectSubHeading>
                   <ConnectDescription  isDescription1={true} fontSize={getFontSize(matches ,{ lg: '1rem', md: '1rem', sm: '1rem', xs: '1rem', xss: '1rem' })}  >
                   With the industry shift towards consumer privacy and first-party data,
                   communication and interoperability between platforms such as CDPs, data clean rooms, DSPs, and enrichment providers is essential. However, building and maintaining direct integrations between platforms is a strain on engineering and product resources.
                   </ConnectDescription>
                   <ConnectDescription fontSize={getFontSize(matches, { lg: '1rem', md: '1rem', sm: '1rem', xs: '1rem', xss: '1rem' })}>
                    MadConnect is a connector hub that solves for platform-to-platform interoperability at scale. MadConnect provides the integrations necessary for the privacy-safe transfer, normalization, and bi-directional communication between data platforms across AdTech and MarTech.
                    </ConnectDescription>
                    <ConnectDescription fontSize={getFontSize(matches, {lg : '1rem' ,md: '1rem' , sm : '1rem' , xs : '01rem' , xss: '1rem'})} />
                </ConnectContainer>
            </ConnectMainContainer>
        </AboutSuperContainer>

    )
}

export default AbouMadconnect