import React, { useEffect } from "react";
import { Box, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";

import leftArrow from "../../assets/left_arrow.svg";
import searchImg from "../../assets/search.svg";
import {
  CommingSoonText,
  DetailContainer,
  FilterSearch,
  ListHeader,
  ListHeader2,
  ListHeaderImg,
  ListHeaderSpan,
  ListHeading,
  LoaderContainer,
  LoaderWrapper,
  ToolTipComingSoonContainer,
  ToolTipContainer,
  ToolTipTitle,
  TooltipSpan,
} from "./Connectors.styles";
import { Item } from "./ConnectorsList";

const ConnectorHeading = ({
  matchesxss,
  handleBack,
  showDoc,
  handleSearch,
  searchValue,
  id
}) => {
  return (
    <ListHeading matchesxss={matchesxss}>
      {showDoc && id !== undefined ? (
        <ListHeader className="heading" onClick={handleBack}>
          <ListHeaderImg alt="<" src={leftArrow}></ListHeaderImg>
          <ListHeaderSpan className="heading">Back</ListHeaderSpan>
        </ListHeader>
      ) : (
        <ListHeader2 className="heading">Connectors</ListHeader2>
      )}

      {!showDoc && (
        <FilterSearch
          value={searchValue}
          onChange={(e) => handleSearch(e)}
          placeholder="Search Here"
          inputProps={{
            style: { paddingLeft: 15 },
          }}
          matchesxss={matchesxss}
          startAdornment={
            <img
              alt=""
              src={searchImg}
              style={{ width: "1.25rem", height: "1.25rem" }}
            ></img>
          }
        />
      )}
    </ListHeading>
  );
};

const ConnectorData = (props) => {
  const {
    handleBack,
    matchesxss,
    showDoc,
    handleSearch,
    searchValue,
    loader,
    selectedItem,
    selectedDoc,
    data,
    handleShowConnectoreData,
    allplatfomrs,
    ref,
    isLoading,
    id
  } = props;

  const showLoader = () => {
    return (
      <LoaderContainer p={2}>
        <LoaderWrapper>
          <CircularProgress
            size={45}
            thickness={8}
            sx={{ color: "#3646AC" }}
          ></CircularProgress>
        </LoaderWrapper>
      </LoaderContainer>
    );
  };



  return (
    <>
      {<ConnectorHeading
        handleBack={handleBack}
        matchesxss={matchesxss}
        showDoc={showDoc}
        handleSearch={handleSearch}
        searchValue={searchValue}
        id={id}
      />}

      <Box display="flex" flexDirection="column" my="10px">

        <>
          {loader && showLoader()}
          {showDoc && id !== undefined && (
            <>
              <DetailContainer id="logo-container">
                <img
                  alt="logo"
                  src={selectedItem?.logo}
                  style={{ height: "70px", maxWidth: "300px" }}
                />
              </DetailContainer>
              <iframe
                src={selectedDoc}
                id="doc-src"
                style={{ opacity: 0 }}
                title="description"
                className="frame"
                width={"100%"}
                ref={ref}
                height={800}
              ></iframe>
            </>
          )}
        </>
      </Box>

      <Box my={2} display={showDoc && id !== undefined ? "none" : ""}>
        {allplatfomrs.length >= 0 ? (
          <Grid container spacing={{ xs: 4, sm: 4, md: 4 }}>
            {allplatfomrs.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Item
                  cursor={item.status == 2 ? "default" : "pointer"}
                  onClick={() => handleShowConnectoreData(item)}
                >
                  <img
                    alt=""
                    src={item.logo}
                    style={{ maxWidth: "100%", maxHeight: "65%" }}
                  ></img>
                  <ToolTipContainer>
                    <Tooltip
                      title={
                        <TooltipSpan>
                          {item.dataType ? item.dataType : ""}
                        </TooltipSpan>
                      }
                      style={{ textTransform: "capitalize" }}
                      placeholder="top"
                    >
                      <ToolTipTitle>
                        {item.dataType ? item.dataType : ""}
                      </ToolTipTitle>
                    </Tooltip>
                  </ToolTipContainer>
                  {item.status === 2 && (
                    <ToolTipComingSoonContainer>
                      <CommingSoonText>Coming Soon</CommingSoonText>
                    </ToolTipComingSoonContainer>
                  )}
                </Item>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={45} thickness={8}></CircularProgress>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ConnectorData;
