import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import CommonButton from "../CommonButton/CommonButton";
import { Strings } from "../../utils/Strings";
import { color } from "../../utils/Colors";
import connectorImg from "../../assets/ConnectorWhite.svg";
import connectorImg1 from "../../assets/Connector.svg";
import createConnection from "../../assets/CreatedCOnnection.svg";
import lineImg from "../../assets/Line_18.svg";
import CommonSelect from "../CommonSelect/CommonSelect";
import {
  useAddConnectionsMutation,
  useDeleteConnectionMutation,
  useGetConfigurationByIdMutation,
  useGetConfigurationPlatformDestMutation,
  useGetConfigurationPlatformMutation,
  useUpdateConnectionsMutation,
  useUpdateConnectionsStatusMutation,
} from "../../services/connectionsQuery";
import { madTechState } from "../../features/madTechSlice";
import { useGetSelectedPlatformsQuery } from "../../services/platformQuery";
import trash from "../../assets/trash-2.svg";
import cancel from "../../assets/cancel.svg";
import AppLayout from "../AppLayout/AppLayout";
import SourceForm from "./SourceForm";
import DestinationForm from "./DestinationForm";
import { useMyContext } from "../CommanToasterContext/toast";

function CreateConnections({ setStep }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const connectionId = searchParams.get("connectionId");

  const { selectedAccount, allSelectedPlatforms } = useSelector(madTechState);
  const { control, handleSubmit, reset, setValue } = useForm();
  const {
    control: Destcontrol,
    reset: destReset,
    getValues: destgetValues,
  } = useForm();

  const [connectors, setConnectors] = useState([]);
  const { setOpen2, setMessage2 } = useMyContext();
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [createModal, setCreateModal] = useState(false);

  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const [selectedSources, setSelectedSources] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");
  const [inputText, setInputText] = useState("");
  const [showSources, setShowSources] = useState(true);
  const [showDestination, setShowDestination] = useState(true);

  const [sourceConfiguration, setSourceConfiguration] = useState([]);
  const [sourceConfig, setSourceConfig] = useState({});
  const [destinationConfiguration, setDestinationConfiguration] = useState([]);
  const [destinationConfig, setDestinationConfig] = useState({});
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [allDropdownData, setAllDropdownData] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoader(false);
    }, 2000);
  }, []);

  function getMatchingSourceDataById(idToMatch) {
    if (!idToMatch && options2 && options2.length > 0) {
      idToMatch = options2[0].dataTypeId;
    }

    const matchingData = allDropdownData.filter((item) => {
      return item.dataTypeId === idToMatch;
    });
    return matchingData.length > 0 ? matchingData[0] : options2[0];
  }

  function getMatchingDestDataById(idToMatch) {
    if (!idToMatch && options && options.length > 0) {
      idToMatch = options[0].dataTypeId;
    }
    const matchingData = allDropdownData.filter((item) => {
      return item.dataTypeId === idToMatch;
    });
    return matchingData.length > 0 ? matchingData[0] : options[0];
  }

  const {
    data: partnerData,
    error: partnerError,
    refetch,
  } = useGetSelectedPlatformsQuery();
  
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (partnerData !== undefined) {
      if (partnerData.response) {
        partnerData.response.selectedPlatforms.map((item) => {
          const option = {
            value: item.name,
            label: item.name,
            img: item.logo,
            id: item.id,
            description: item.description,
            dataTypes: item.dataTypes,
            dataTypeId: item.dataTypeId,
          };
          setAllDropdownData((prevOptions) => [...prevOptions, option]);
          if (item.supportedType === "SOURCE") {
            return setOptions2((prevOptions) => [...prevOptions, option]);
          } else if (item.supportedType === "BOTH") {
            setOptions2((prevOptions) => [...prevOptions, option]);
            setOptions((prevOptions) => [...prevOptions, option]);
            return;
          } else if (item.supportedType === "DESTINATION") {
            setOptions((prevOptions) => [...prevOptions, option]);
            return;
          }
        });
      }
    }
    if (partnerError !== undefined) {
      console.log("partnerError", partnerError);
      if (partnerError.statusCode === 403) {
        navigate("signin");
      }
      setMessage2(partnerError?.data?.error);
      setOpen2(true);
    }
  }, [partnerData, partnerError]);

  const [anchorEl, setAnchorEl] = useState(null);

  // const getByIdConnectionData = {
  //     "statusCode": 200,
  //     "statusMessage": "Connection configurations information get successfully",
  //     "response": {
  //         "destination": {
  //             "dataTypeId": "a981f9e00a348eda",
  //             "dataConfig": [],
  //             "platformId": "a7442ba436c87857",
  //             "config": null
  //         },
  //         "name": "Yahoo Default Name",
  //         "source": {
  //             "dataTypeId": "ea314bf3b7476682",
  //             "dataConfig": [],
  //             "platformId": "69b7318a943118d3",
  //             "config": null
  //         }
  //     }
  // }

  const [
    getByIdConnectionStatus,
    {
      data: getByIdConnectionData,
      isLoading: getByIdConnectionLoading,
      error: getByIdConnectionError,
    },
  ] = useGetConfigurationByIdMutation();

  useEffect(() => {
    if (connectionId === null || connectionId === undefined) {
      setSource(null);
      setDestination(null);
      setSourceConfiguration([]);
      setSourceConfig({});
      setDestinationConfiguration([]);
      setDestinationConfig({});
      reset();
      destReset();
      setValue("name", "");
      return;
    } else if (connectionId !== undefined && connectionId !== null) {
      getByIdConnectionStatus({ connectionId: connectionId });
    }
  }, [connectionId]);

  const convertStringToJSON = (obj) => {
    const result = {};
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        try {
          result[key] = JSON.parse(obj[key]);
        } catch (error) {
          // If parsing fails, keep the original string value
          result[key] = obj[key];
        }
      } else {
        result[key] = obj[key];
      }
    }
    return result;
  };

  useEffect(() => {
    if (getByIdConnectionData !== undefined && allDropdownData?.length  ) {
      if (getByIdConnectionData.statusCode === 200) {
        setValue(
          "name",
          getByIdConnectionData.response.name
            ? getByIdConnectionData.response.name
            : "Yahoo Default Name"
        );
        const dataTypeSrc = getByIdConnectionData?.response?.source?.dataTypeId;
        const dataTypeDest = getByIdConnectionData?.response?.destination?.dataTypeId;

        const SelectedSrcData = getMatchingSourceDataById(dataTypeSrc);
        const SelectedDestData = getMatchingDestDataById(dataTypeDest);

        setSource(SelectedSrcData);
        setDestination(SelectedDestData);
        const src = getByIdConnectionData?.response?.source;
        const dest = getByIdConnectionData?.response?.destination;
        if (src) {
          setSourceConfiguration(src.dataConfig);
          const formData = convertStringToJSON(src.config);
          setSourceConfig(formData);
        }
        if (dest) {
          setDestinationConfiguration(dest.dataConfig);
          const formData = convertStringToJSON(dest.config);
          setDestinationConfig(formData);
        }
      } else {
        setMessage2(getByIdConnectionData?.statusMessage);
        setOpen2(true);
      }
    }

    if (getByIdConnectionError !== undefined) {
      console.log("GetById Connection Error", getByIdConnectionError);
      setMessage2(getByIdConnectionError?.data?.error);
      setOpen2(true);
    }
  }, [getByIdConnectionData, getByIdConnectionError , allDropdownData]);

  const handleSourcesSelect = (event) => {
    setSelectedSources(event.target.value);
    setShowSources(false);
  };
  const handleDestinationSelect = (event) => {
    setSelectedDestination(event.target.value);
    setShowDestination(false);
  };

  const open = Boolean(anchorEl);
  const handleSourceClick = (event) => {
    setAnchorEl(event.currentTarget);
    setConnectorError(false);
    setConnectionErrorMsg("");
  };
  const handleSourceClose = () => {
    setAnchorEl(null);
    setInputValue2("");
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const handleDestinationClick = (event) => {
    setAnchorEl2(event.currentTarget);
    setConnectorError(false);
    setConnectionErrorMsg("");
  };
  const handleDestinationClose = () => {
    setAnchorEl2(null);
    setInputValue("");
  };
  // const handleCreateCancel = () => {
  //     reset()
  // };

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setCreateModal(false);
  const handleOpen = () => setCreateModal(true);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleDeleteClose = () => {
    setSelectedItem(null);
    setDeleteIds([]);
    setIsDeleteOpen(false);
  };

  const [isForDisable, setIsForDisable] = useState(false);

  useEffect(() => {
    if (!isDeleteOpen) {
      setSelectedItem(null);
      setIsForDisable(false);
    }
  }, [isDeleteOpen]);
  const handleDeleteOpen = () => setIsDeleteOpen(true);

  const handleSetSelectedItem = (data) => {
    setSelectedItem(data);
  };

  const [connectorError, setConnectorError] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");

  const isSmallForTableHeader = useMediaQuery("(max-width:1390px)");
  const isSmallCreateConnection = useMediaQuery("(max-width:850px)");
  const firstButtonRef = useRef(null);

  const style = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 8px 8px",
      bgcolor: "#F8F8FE",
      width: "31.25rem",
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&:focus": {
        outline: "none",
      },
    }),
    []
  );

  const addDuration = (id, timeDuration) => {
    let newConnectors = [...connectors];
    newConnectors[id].duration = timeDuration;
    setConnectors(newConnectors);
  };

  useEffect(() => {
    if ((inputValue === "" || inputValue2 === "") && allSelectedPlatforms) {
      const sourceOptions = [];
      const destinationOptions = [];

      allSelectedPlatforms.forEach((item) => {
        const option = {
          value: item?.name,
          label: item?.name,
          img: item?.logo,
          id: item?.id,
          description: item?.description,
          dataTypes: item?.dataTypes,
          dataTypeId: item?.dataTypeId,
        };

        if (item.supportedType === "SOURCE") {
          return sourceOptions.push(option);
        } else if (item.supportedType === "DESTINATION") {
          return destinationOptions.push(option);
        } else if (item.supportedType === "BOTH") {
          destinationOptions.push(option);
          sourceOptions.push(option);
          return;
        }
      });

      setOptions2(sourceOptions);
      setOptions(destinationOptions);
    }
  }, [inputValue, inputValue2, allSelectedPlatforms]);

  const [
    addConnections,
    {
      data: addConnectionData,
      error: addConnectionError,
      isLoading: addconnectionLoading,
    },
  ] = useAddConnectionsMutation();
  const [
    updateConnections,
    {
      data: updateConnectionData,
      error: updateConnectionError,
      isLoading: updateconnectionLoading,
    },
  ] = useUpdateConnectionsMutation();
  const [
    deleteConnection,
    {
      data: deleteConnectionData,
      isLoading: deleteConnectionLoading,
      error: deleteConnectionError,
    },
  ] = useDeleteConnectionMutation();

  const [
    updateConnectionStatus,
    {
      data: updateConnectionStatusData,
      isLoading: updateConnectionStatusLoading,
      error: updateConnectionStatusError,
    },
  ] = useUpdateConnectionsStatusMutation();

  useEffect(() => {
    if (addConnectionData !== undefined) {
      if (addConnectionData.statusCode === 200) {
        handleOpen();
      } else {
        setMessage2(addConnectionData.statusMessage);
        setOpen2(true);
      }
    }

    if (addConnectionError !== undefined) {
      console.log("add Connection Error", addConnectionError);
      setMessage2(addConnectionError.data.error);
      setOpen2(true);
    }
  }, [addConnectionData, addConnectionError]);

  useEffect(() => {
    if (updateConnectionData !== undefined) {
      if (updateConnectionData.statusCode === 200) {
        handleOpen();
      } else {
        setMessage2(updateConnectionData.statusMessage);
        setOpen2(true);
      }
    }

    if (updateConnectionError !== undefined) {
      console.log("update Connection Error", updateConnectionError);
      setMessage2(updateConnectionError.data.error);
      setOpen2(true);
    }
  }, [updateConnectionData, updateConnectionError]);

  const [deleteIds, setDeleteIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (deleteIds.length > 0) {
      handleDeleteOpen();
    }
  }, [deleteIds]);

  const deleteConnector = useCallback(() => {
    deleteConnection({
      id: deleteIds[0],
    });
  }, [useDeleteConnectionMutation, connectors, deleteIds]);

  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  useEffect(() => {
    if (deleteConnectionData !== undefined) {
      if (deleteConnectionData.statusCode === 200) {
        // refetch();
        handleDeleteClose();
        let newConnectors = [...connectors];
        newConnectors.splice(deleteIds[1], 1);
        setConnectors(newConnectors);
        setSelectedItem(null);
        if (setStep === undefined || setStep === "undefined") {
          setIsDeleteSuccess(true);
        }
      }
      // handleDeleteClose()
      // setIsDeleteSuccess(true)
    }

    if (deleteConnectionError !== undefined) {
      console.log("deleteConnectionError", deleteConnectionError);
      setMessage2(deleteConnectionError.data.error);
      setOpen2(true);
    }
  }, [deleteConnectionData, deleteConnectionError]);

  const [
    getConfigurations,
    {
      data: connectionConfigurations,
      error: configurationsError,
      isLoading: configurationLoading,
    },
  ] = useGetConfigurationPlatformMutation();
  const [
    getConfigurationsdest,
    {
      data: connectionConfigurationsdest,
      isLoading: configurationLoadingdest,
      error: configurationsErrordest,
      refetch: configurationsFetchdest,
    },
  ] = useGetConfigurationPlatformDestMutation();

  const [fromWhere, setFromWhere] = useState("");

  useEffect(() => {
    if (source && (connectionId === null || connectionId === undefined)) {
      getConfigurations({
        connectorId: source?.dataTypeId,
      });
      setFromWhere("src");
    }
  }, [useGetConfigurationPlatformMutation, source]);

  useEffect(() => {
    if (
      connectionConfigurations?.response ||
      connectionConfigurationsdest?.response
    ) {
      if (
        connectionConfigurations?.statusCode === 200 ||
        connectionConfigurationsdest?.statusCode === 200
      ) {
        if (fromWhere === "src") {
          const response = connectionConfigurations?.response?.srcDataConfig;
          setSourceConfiguration(response);
          // if (response?.length > 0) {
          //     setSelectedSources(response[0].id.toString());
          // }
        } else if (fromWhere === "dest") {
          const response =
            connectionConfigurationsdest?.response?.destDataConfig;
          setDestinationConfiguration(response);
          // if (response?.length > 0) {
          //     setSelectedDestination(response[0].id.toString());
          // }
        }
      }
    }
  }, [
    connectionConfigurations,
    connectionConfigurationsdest,
    configurationsError,
    configurationsErrordest,
    fromWhere,
    setSelectedSources,
  ]);

  useEffect(() => {
    if (destination && (connectionId === null || connectionId === undefined)) {
      getConfigurationsdest({
        connectorId: destination?.dataTypeId,
      });
      setFromWhere("dest");
    }
  }, [useGetConfigurationPlatformMutation, destination]);

  useEffect(() => {
    setInputText("");
  }, [isDeleteOpen]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };
  useEffect(() => {
    if (inputText === "DELETE" || isForDisable) {
      setIsDeleteButtonEnabled(true);
    } else {
      setIsDeleteButtonEnabled(false);
    }
  }, [inputText, isForDisable]);

  useEffect(() => {
    if (updateConnectionStatusData !== undefined) {
      // refetch();
      handleDeleteClose();
    }

    if (updateConnectionStatusError !== undefined) {
      console.log("updateConnectionStatusError", updateConnectionStatusError);
    }
  }, [updateConnectionStatusData, updateConnectionStatusError]);

  const _handleUpdateConnectionStatus = useCallback(() => {
    updateConnectionStatus({
      id: selectedItem.id,
      enabled: !selectedItem.enabled,
    });
  }, [useUpdateConnectionsStatusMutation, selectedItem, connectors]);

  function stringifySourceData(obj) {
    if (!obj || typeof obj !== "object" || obj instanceof Date) {
      return obj;
    }

    if (obj.$isDayjsObject) {
      return obj.toJSON();
    }

    const result = {};

    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        result[key] = JSON.stringify(value);
      } else if (typeof value === "object") {
        result[key] = stringifySourceData(value);
      } else {
        result[key] = value;
      }
    }

    return result;
  }

  const onSubmit = (data) => {
    const payload = {
      DestData: destgetValues(),
      sourceData: data,
    };
    const name = data?.name;
    const srcdatas = stringifySourceData(payload.sourceData);
    const destdatas = stringifySourceData(payload.DestData);
    const sourceDatawithremoveName = (({ name, ...rest }) => rest)(srcdatas);
    if (name === undefined || name === "") {
      setMessage2("Connection name is required.");
      setOpen2(true);
      return;
    } else if (source && destination) {
      if (source.value === destination.value) {
        setMessage2("Source and destination must be different.");
        setOpen2(true);
      } else {
        if (connectionId === null || connectionId === undefined) {
          addConnections({
            destPlatformId: destination.id.toString(),
            srcPlatformId: source.id.toString(),
            srcDataTypeId: source?.dataTypeId,
            destDataTypeId: destination?.dataTypeId,
            srcConfigurationId: selectedSources || null,
            destConfigurationId: selectedDestination || null,
            srcConnectionConfig: sourceDatawithremoveName,
            destConnectionConfig: destdatas,
            name: name,
          });
        } else {
          updateConnections({
            id: connectionId,
            srcConnectionConfig: sourceDatawithremoveName,
            destConnectionConfig: destdatas,
            name: name,
          });
        }
        setSelectedSources(null); // or setSelectedSources("");
        setSelectedDestination(null);
        // setConnectors([...connectors, { source, destination }])
      }
    } else {
      setConnectorError(true);
      setOpen2(true);
      setMessage2("Please select a source and destination.");
    }
  };

  const handlereset = () => {
    if (connectionId === null || connectionId === undefined) {
      setSource(null);
      setDestination(null);
    }
    reset();
    destReset();
    setValue(
      "name",
      connectionId === null
        ? ""
        : getByIdConnectionData.response.name
        ? getByIdConnectionData.response.name
        : "Yahoo Default Name"
    );
    navigate(
      selectedAccount !== null && selectedAccount !== undefined
        ? `/connections?accountId=${selectedAccount.id}`
        : "/connections"
    );
  };

  return (
    <AppLayout>
      {loader ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "75vh",
            }}
          >
            <CircularProgress
              size={45}
              thickness={8}
              sx={{ color: "#3646AC" }}
            ></CircularProgress>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            px: "1.5rem",
            backgroundColor: color.layoutBgColor,
            mt: 2,
          }}
        >
          <Box
            sx={{
              maxWidth: setStep === undefined ? "100%" : 1500,
              margin: "auto",
              backgroundColor: "#fff",
            }}
          >
            {setStep !== undefined && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  width: "100%",
                  alignItems: "center",
                  // border: '1px solid black'
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "46%", mt: "1rem" }}>
                    <p
                      className="text-center"
                      style={{
                        fontSize: "2rem",
                        fontWeight: 700,
                        fontFamily: "Helvetica Neue",
                      }}
                    >
                      {Strings.subscriptionPlanHeading}
                    </p>
                    <p
                      className="text-center"
                      style={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "#999999",
                        fontFamily: "Helvetica Neue",
                      }}
                    >
                      {Strings.subscriptionPlanSubtitle}
                    </p>
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                background:
                  setStep !== undefined ? "#fff" : color.layoutBgColor,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 2,
                  alignItems: "center",
                  width: setStep !== undefined ? "85%" : "100%",
                  mx: "auto",
                  justifyContent: "center",
                  boxShadow:
                    setStep !== undefined
                      ? "none"
                      : "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
                  px: setStep !== undefined ? 0 : 2,
                }}
              >
                {setStep === undefined && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "3.75rem",
                      mb: 3,
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          mt: "1rem",
                          fontSize: "1.25rem",
                          fontWeight: 700,
                          fontFamily: "Helvetica Neue",
                        }}
                      >
                        {connectionId !== undefined && connectionId !== null
                          ? "Update Connection"
                          : "Create Connection"}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Controller
                    name={"name"}
                    defaultValue={""}
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        sx={{
                          width: "40%",
                          height: "2.75rem",
                          fontSize: "1rem",
                          color: "#666666",
                          backgroundColor: "#FFFFFF",
                          fontFamily: "Helvetica Neue",
                        }}
                        size="small"
                      >
                        <InputLabel
                          sx={{
                            fontSize: "0.875rem",
                            marginTop: "0.25rem",
                            opacity: 0.5,
                            backgroundColor: "#FFFFFF",
                            fontFamily: "Helvetica Neue",
                          }}
                          id="name"
                        >
                          Connection Name
                        </InputLabel>
                        <OutlinedInput
                          {...field}
                          placeholder={"Connection Name"}
                          sx={{
                            width: "100%",
                            height: "2.75rem",
                            fontSize: "1rem",
                            color: "#1C2B47",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "Helvetica Neue",
                            borderRadius: "10px",
                            "& fieldset": {
                              borderColor: "#EAEDF6 !important",
                            },
                          }}
                          labelid="ConnectionName"
                          id="name"
                          label="Connection Name"
                          // value={sourceDetails?.title}
                          // onChange={(e) => {
                          //     setSourceDetails({ ...sourceDetails, title: e.target.value });
                          // }}
                        />
                      </FormControl>
                    )}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid black",
                      color: "black",
                      textTransform: "capitalize",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                        borderColor: "black",
                        boxShadow:
                          "rgba(37, 38, 45, 0.40) 0px 0px 1px, rgba(37, 38, 45, 0.25) 0px 5px 4px",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        selectedAccount !== null &&
                          selectedAccount !== undefined
                          ? `/platform?accountId=${selectedAccount.id}`
                          : "/platform"
                      );
                    }}
                  >
                    Connectors Catalog
                  </Button>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                      height: "3.5rem",
                    }}
                  >
                    <Box
                      sx={{ width: "42%", display: "flex", alignItems: "end" }}
                    >
                      <Typography
                        sx={{
                          color: "#66696A",
                          fontSize: setStep !== undefined ? 20 : 16,
                          fontWeight: 400,
                          fontFamily: "Helvetica Neue",
                        }}
                      >
                        Source
                      </Typography>
                    </Box>
                    <Box sx={{ width: "16%", height: 2 }}></Box>
                    <Box sx={{ width: "8.75rem", height: 2 }}></Box>
                    <Box sx={{ width: "8%", height: 2 }}></Box>
                    <Box
                      sx={{ width: "50%", display: "flex", alignItems: "end" }}
                    >
                      <Typography
                        sx={{
                          color: "#66696A",
                          fontSize: setStep !== undefined ? 20 : 16,
                          fontWeight: 400,
                          fontFamily: "Helvetica Neue",
                        }}
                      >
                        Destination
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "40%" }}>
                      <CommonSelect
                        handleClick={handleSourceClick}
                        title={"Select Source"}
                        data={source}
                        setData={setSource}
                        anchorEl={anchorEl}
                        open={open}
                        handleClose={handleSourceClose}
                        inputValue={inputValue2}
                        setInputValue={setInputValue2}
                        setOptions={setOptions2}
                        options={options2}
                        setShowSources={setShowSources}
                        setShowDestination={setShowDestination}
                        isSmallCreateConnection={isSmallCreateConnection}
                        source={source}
                        configurationLoading={configurationLoading}
                        connectionId={connectionId}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        width: "20%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          height: 0,
                          width: "40%",
                          borderTop: "2px dashed #538BF4",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: isSmallCreateConnection ? "80px" : 80,
                          height: isSmallCreateConnection ? "80px" : 80,
                          px: 1,
                          borderRadius: 20,
                          backgroundColor: "#EBEDF7",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: isSmallCreateConnection ? "74px" : 65,
                            height: isSmallCreateConnection ? "64px" : 65,
                            borderRadius: 20,
                            backgroundColor: " #538BF4",
                          }}
                        >
                          <img
                            alt=""
                            src={connectorImg}
                            style={{ width: 43, height: 43 }}
                          ></img>
                        </Box>
                      </Box>
                      <div
                        style={{
                          height: 0,
                          width: "40%",
                          borderTop: "2px dashed #538BF4",
                        }}
                      />
                    </Box>

                    <Box sx={{ width: "40%" }}>
                      <CommonSelect
                        handleClick={handleDestinationClick}
                        title={"Select Destination"}
                        data={destination}
                        setData={setDestination}
                        anchorEl={anchorEl2}
                        open={open2}
                        handleClose={handleDestinationClose}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        setOptions={setOptions}
                        options={options}
                        setShowDestination={setShowDestination}
                        isSmallCreateConnection={isSmallCreateConnection}
                        destination={destination}
                        configurationLoading={configurationLoadingdest}
                        connectionId={connectionId}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mx: "auto",
                      alignItems: "flex-start",
                      width: "100%",
                      justifyContent: source ? "start" : "end",
                    }}
                  >
                    <Box
                      sx={{
                        display:
                          source ||
                          connectionId !== undefined ||
                          connectionId !== null
                            ? ""
                            : "none",
                        padding: "5px",
                        marginTop: "20px",
                        width: "40%",
                      }}
                    >
                      <SourceForm
                        control={control}
                        sourceConfiguration={sourceConfiguration}
                        sourceConfig={sourceConfig}
                        connectionId={connectionId}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "20%",
                        alignItems: "center",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display:
                          destination ||
                          connectionId !== undefined ||
                          connectionId !== null
                            ? ""
                            : "none",
                        padding: "5px",
                        marginTop: "20px",
                        width: "40%",
                      }}
                    >
                      <DestinationForm
                        control={Destcontrol}
                        destinationConfiguration={destinationConfiguration}
                        destinationConfig={destinationConfig}
                        connectionId={connectionId}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      // mt: 3,
                    }}
                  >
                    <Box sx={{ width: "36%" }}></Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%" }}>
                      <Box>
                        {false && (
                          <>
                            <FormControl
                              sx={{
                                width: "100%",
                                height: "2.30rem",
                                fontSize: "1rem",
                                color: "#666666",
                                backgroundColor: "#FFFFFF",
                                fontFamily: "Helvetica Neue",
                              }}
                              size="small"
                            >
                              <InputLabel
                                sx={{
                                  fontSize: "0.875rem",
                                  marginTop: "0.25rem",
                                  opacity: 0.5,
                                  backgroundColor: "#FFFFFF",
                                  fontFamily: "Helvetica Neue",
                                }}
                                id="configuration"
                              >
                                Configuration
                              </InputLabel>
                              <Select
                                sx={{
                                  height: "2.75rem",
                                  fontSize: "1rem",
                                  color: "#666666",
                                  backgroundColor: "#FFFFFF",
                                  fontFamily: "Helvetica Neue",
                                }}
                                labelid="configuration"
                                id="configuration"
                                label="Configuration"
                                value={selectedSources}
                                onChange={handleSourcesSelect}
                              >
                                {sourceConfiguration?.map((srcConfig, id) => {
                                  return (
                                    <MenuItem
                                      sx={{
                                        fontSize: "0.875rem",
                                        backgroundColor: "#FFFFFF",
                                        fontFamily: "Helvetica Neue",
                                        padding: "0.8rem",
                                      }}
                                      value={srcConfig?.id.toString()}
                                      key={srcConfig.id}
                                    >
                                      {srcConfig.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "40%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          height: 0,
                          width: "40%",
                        }}
                      />
                      <div
                        style={{
                          height: 0,
                          borderTop: "2px dashed #3646AC",
                        }}
                      />
                    </Box>
                    <Box sx={{ width: "40%" }}>
                      {/* {destination && destinationConfiguration?.length > 0 && ( */}
                      {false && (
                        <>
                          <FormControl
                            sx={{
                              width: "100%",
                              height: "2.30rem",
                              fontSize: "1rem",
                              color: "#666666",
                              backgroundColor: "#FFFFFF",
                              fontFamily: "Helvetica Neue",
                            }}
                            size="small"
                          >
                            <InputLabel
                              sx={{
                                fontSize: "0.875rem",
                                marginTop: "0.25rem",
                                opacity: 0.5,
                                backgroundColor: "#FFFFFF",
                                fontFamily: "Helvetica Neue",
                              }}
                              id="configuration"
                            >
                              Configuration
                            </InputLabel>
                            <Select
                              sx={{
                                height: "2.75rem",
                                fontSize: "1rem",
                                color: "#666666",
                                backgroundColor: "#FFFFFF",
                                fontFamily: "Helvetica Neue",
                              }}
                              labelid="configuration"
                              id="configuration"
                              label="Configuration"
                              value={selectedDestination}
                              onChange={handleDestinationSelect}
                            >
                              {destinationConfiguration?.map((destConfig) => {
                                return (
                                  <MenuItem
                                    sx={{
                                      fontSize: "0.875rem",
                                      backgroundColor: "#FFFFFF",
                                      fontFamily: "Helvetica Neue",
                                      padding: "0.8rem",
                                    }}
                                    value={destConfig?.id.toString()}
                                    key={destConfig.id}
                                  >
                                    {destConfig.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: setStep !== undefined ? 0 : 2,
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box sx={{ marginTop: "20px", gap: "10px", display: "flex" }}>
                {!addconnectionLoading ? (
                  <Button
                    variant="outlined"
                    onClick={handleSubmit(onSubmit)}
                    ref={firstButtonRef}
                    sx={{
                      border: "1px solid black",
                      color: "#fff",
                      textTransform: "capitalize",
                      background: "#000",
                      borderRadius: "8px",
                      "&:hover": {
                        background: "#000",
                        border: "1px solid black",
                        cursor: "pointers",
                      },
                    }}
                  >
                    {connectionId !== undefined && connectionId !== null
                      ? "Update Connection"
                      : "Create Connection"}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid black",
                      color: "#fff",
                      textTransform: "capitalize",
                      background: "#000",
                      borderRadius: "8px",
                      width: firstButtonRef.current?.offsetWidth || "auto", // Set width
                      height: firstButtonRef.current?.offsetHeight || "auto", // Set height
                      "&:hover": {
                        background: "#000",
                        border: "1px solid black",
                        cursor: "pointers",
                      },
                    }}
                  >
                    <CircularProgress
                      style={{ color: "white" }}
                      size={25}
                      thickness={8}
                    ></CircularProgress>
                  </Button>
                )}
                <Button
                  variant="text"
                  sx={{ color: "black", textTransform: "capitalize" }}
                  onClick={() => handlereset()}
                >
                  Cancel
                </Button>
              </Box>
            </Box>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={isDeleteOpen}
              onClose={handleDeleteClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={isDeleteOpen}>
                <Box sx={{ ...style, pl: 5, pr: 5, width: 600 }}>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontFamily: "Helvetica Neue",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  >
                    {selectedItem !== null && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: 2,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                p: 1,
                                width: "35%",
                                height: 60,
                                borderRadius: 1,
                                // border: "1px solid rgba(54, 70, 172, 0.15)",
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "2.5rem",
                                  borderRadius: 1,
                                  // backgroundColor: "rgba(54, 70, 172, 0.07)",
                                  p: 0.4,
                                }}
                              >
                                <img
                                  alt=""
                                  src={selectedItem?.source.img}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                ></img>
                              </Box>
                            </Box>
                            <div
                              style={{
                                marginLeft: 5,
                                marginRight: 5,
                                height: 0,
                                width: 72,
                                borderTop: "2px solid rgba(25, 50, 65, 0.5)",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 56,
                                height: 56,
                                borderRadius: 8,
                                backgroundColor: "#EDEFF0",
                              }}
                            >
                              <img
                                alt=""
                                src={connectorImg1}
                                style={{
                                  width: 24,
                                  height: 24,
                                  color: "#193241",
                                }}
                              ></img>
                            </Box>
                            <img
                              src={lineImg}
                              style={{
                                width: 55,
                                marginRight: 5,
                                marginLeft: 5,
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                p: 1,
                                width: "35%",
                                height: 60,
                                borderRadius: 1,
                                position: "relative",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "2.5rem",
                                  borderRadius: 1,
                                  p: 0.4,
                                }}
                              >
                                <img
                                  alt=""
                                  src={selectedItem?.destination.img}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                ></img>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Typography>

                  <Typography
                    style={{
                      fontWeight: 400,
                      fontFamily: "Helvetica Neue",
                      fontSize: "1rem",
                      textAlign: "center",
                      color: "#656C7B",
                      width: "70%",
                      marginBottom: 16,
                    }}
                  >
                    Are you sure you want{" "}
                    {isForDisable
                      ? `${!selectedItem?.enabled ? "enable" : "disable"}`
                      : `delete`}{" "}
                    this connection?
                  </Typography>
                  {!isForDisable && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FormControl
                        sx={{
                          width: "70%",
                          height: "2.75rem",
                          fontSize: "1rem",
                          color: "#666666",
                          backgroundColor: "#FFFFFF",
                          fontFamily: "Helvetica Neue",
                          display: "flex",
                          justifyContent: "center",
                          marginRight: "auto",
                          marginLeft: "auto",
                        }}
                        size="small"
                      >
                        <InputLabel
                          sx={{
                            fontSize: "0.875rem",
                            marginTop: "0.25rem",
                            opacity: 0.5,
                            backgroundColor: "#FFFFFF",
                            fontFamily: "Helvetica Neue",
                          }}
                          id="delete"
                        >
                          Type DELETE to confirm
                        </InputLabel>
                        <OutlinedInput
                          placeholder={""}
                          sx={{
                            width: "100%",
                            height: "2.75rem",
                            fontSize: "1rem",
                            color: "#666666",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "Helvetica Neue",
                          }}
                          labelid="delete"
                          id="delete"
                          label="Type DELETE to confirm"
                          onChange={handleInputChange}
                          value={inputText}
                          autoComplete="off"
                        />
                      </FormControl>
                    </Box>
                  )}
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Button
                      onClick={(event) => {
                        if (isForDisable) {
                          _handleUpdateConnectionStatus(event);
                        } else {
                          deleteConnector();
                        }
                      }}
                      disabled={!isDeleteButtonEnabled}
                      style={{
                        cursor: "pointer",
                        backgroundColor: isForDisable
                          ? color.buttonColor
                          : color.whiteColor,
                        width: "6.625rem",
                        height: "2.5rem",
                        color: isForDisable ? color.whiteColor : color.themeRed,
                        textTransform: "none",
                        border: "1.5px solid",
                        borderColor: isForDisable
                          ? color.buttonColor
                          : color.themeRed,
                        cursor: isDeleteButtonEnabled
                          ? "pointer"
                          : "not-allowed",
                      }}
                      variant="contained"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        {!isForDisable && (
                          <img
                            alt="trash"
                            src={trash}
                            style={{
                              width: "1.3rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          ></img>
                        )}

                        <Typography
                          style={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            fontFamily: "Helvetica",
                            display: "flex",
                            alignItems: "center",
                            // marginTop: 2,
                          }}
                        >
                          {isForDisable ? "Yes" : "Delete"}
                        </Typography>
                      </Box>
                    </Button>

                    <Button
                      onClick={handleDeleteClose}
                      isLoading={deleteConnectionLoading}
                      disabled={deleteConnectionLoading}
                      style={{
                        cursor: "pointer",
                        // border: `1px solid ${color.themeDarkGray}`,
                        backgroundColor: color.whiteColor,
                        width: "6.625rem",
                        height: "2.5rem",
                        color: color.themeBlack,
                        textTransform: "none",
                      }}
                      variant="contained"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        {!isForDisable && (
                          <img
                            alt=""
                            src={cancel}
                            style={{
                              width: "0.8rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          ></img>
                        )}
                        <Typography
                          style={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            fontFamily: "Helvetica",
                            display: "flex",
                            alignItems: "center",
                            // marginTop: 2,
                          }}
                        >
                          {isForDisable ? "No" : "Cancel"}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Box>
              </Fade>
            </Modal>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={isDeleteSuccess}
              onClose={() => setIsDeleteSuccess(false)}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={isDeleteSuccess}>
                <Box sx={{ ...style, height: "12.5rem" }}>
                  {/* <Box sx={{ width: 68, height: 68, mb: 3 }}>
              <img src={ModalAlertIcons} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </Box> */}
                  {/* <Typography style={{
              fontWeight: 700, width: '80%',
              fontFamily: 'Helvetica Neue', fontSize: "1.25rem",
              textAlign: 'center', color: '#25262D'
            }}>
              Thank you for the confirmation! Your connection is marked as deleted. It will take 2 working days to reflect the changes.
            </Typography> */}
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontFamily: "Helvetica Neue",
                      fontSize: "1rem",
                      textAlign: "center",
                      color: "#656C7B",
                      width: "70%",
                    }}
                  >
                    Your connection is marked as deleted. It will take up to 2
                    working days to reflect the changes.
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <CommonButton
                      width={"4.375rem"}
                      height={"2.25rem"}
                      textStyle={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        fontFamily: "Helvetica Neue",
                      }}
                      onClick={() => setIsDeleteSuccess(false)}
                      title={"Okay"}
                      color={color.buttonColor}
                    ></CommonButton>
                  </Box>
                </Box>
              </Fade>
            </Modal>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={createModal}
              onClose={() => setCreateModal(false)}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={createModal}>
                <Box
                  sx={{
                    ...style,
                    height: "19.5rem",
                    background: "#fff",
                    borderRadius: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 80,
                      height: 80,
                      borderRadius: 8,
                      mb: 2,
                    }}
                  >
                    <img
                      alt=""
                      src={createConnection}
                      style={{ width: 80, height: 80, color: "#193241" }}
                    ></img>
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontFamily: "Helvetica Neue",
                      fontSize: "25px",
                      textAlign: "center",
                      color: "#1C2B47",
                      width: "70%",
                      mb: 2,
                    }}
                  >
                    {connectionId !== null && connectionId !== undefined
                      ? "Connection Updated"
                      : "Connection Created"}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontFamily: "Helvetica Neue",
                      fontSize: "1rem",
                      textAlign: "center",
                      color: "#616161",
                      width: "90%",
                    }}
                  >
                    Please proceed to complete prerequisites before testing and
                    activating connection.
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <CommonButton
                      width={"8rem"}
                      height={"2.25rem"}
                      borderRadius={"10px"}
                      textStyle={{
                        fontSize: "1rem",
                        fontWeight: "500",
                        fontFamily: "Helvetica Neue",
                      }}
                      onClick={() => {
                        setCreateModal(false);
                        navigate(
                          selectedAccount !== null &&
                            selectedAccount !== undefined
                            ? `/connections?accountId=${selectedAccount.id}`
                            : "/connections"
                        );
                      }}
                      title={"Okay"}
                      color={"black"}
                    ></CommonButton>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </Box>
        </Box>
      )}
    </AppLayout>
  );
}

export default CreateConnections;