import { Box, InputAdornment } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  CreateBtnContainer,
  Heading,
  InputContainer,
} from "./Styles/Header.styles";
import Search from "../../../assets/myConnectionSearch.svg";
import resetClose from "../../../assets/cancel.svg";
import { madTechState } from "../../../features/madTechSlice";

const Header = ({ data, inputValue, setInputValue }) => {
  const navigate = useNavigate();
  const { selectedAccount } = useSelector(madTechState);

  const handleCreateBtn = () => {
    navigate(
      selectedAccount !== null && selectedAccount !== undefined
        ? `/create-connections?accountId=${selectedAccount.id}`
        : "/create-connections"
    );
  };

  const searchIcon = () => {
    return (
      <InputAdornment position="start">
        <img src={Search} alt="Search icon" style={{ width: 22, height: 22 }} />
      </InputAdornment>
    );
  };

  const endIcon = () => {
    return (
      <InputAdornment position="start">
        <img
          src={resetClose}
          alt="Search icon"
          style={{
            width: 14,
            height: 14,
            cursor: "pointer",
            display: !inputValue && "none",
          }}
          onClick={() => setInputValue("")}
        />
      </InputAdornment>
    );
  };

  return (
    <Box width="100%" display="flex" justifyContent="space-between" mt={1}>
      <Box display="flex">
        <Heading>My Connections</Heading>
      </Box>
      {data?.length && (
        <Box display="flex" alignItems="center" gap="10px">
          <CreateBtnContainer variant="outlined" onClick={handleCreateBtn}>
            Create Connection
          </CreateBtnContainer>
          <InputContainer
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            sx={{
              "& fieldset": {
                borderColor: "#EAEDF6",
              },
            }}
            placeholder="Search"
            startAdornment={searchIcon()}
            endAdornment={endIcon()}
          />
        </Box>
      )}
    </Box>
  );
};

export default Header;
