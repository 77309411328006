import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, useMediaQuery, Menu, MenuItem, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import madConnectLogo from '../../assets/madconnectdark.png'
import userLogo from '../../assets/user.svg'
import { color } from '../../utils/Colors';
import { madTechState } from '../../features/madTechSlice';
import './Navbar.css'
import ResponsiveAppBar from './AppBar';
import { ImageDiv, LogoStyled, NameContentBox, NavBox, NavStyled } from './Navbar.styles';

const Navbar = () => {

  // const navContext = useContext(navStore);
  const location = useLocation()
  const navigate = useNavigate()
  const { user, isHomeNav, isGetStarted } = useSelector(madTechState)
  const [selected, setSelected] = useState('Home')

  const matcheslg = useMediaQuery('(min-width:1150px)');
  const matchesxss = useMediaQuery('(min-width:600px)');
  const matchesxs = useMediaQuery('(min-width:1024px)');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const HomeContent = () => {
    return (
      <>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#myNavbar" aria-controls="myNavbar" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
        {matcheslg ?
          <div className="collapse navbar-collapse" id="myNavbar">
            <Box sx={{
              position: "relative",
              mx: 'auto', display: "flex", flexDirection: "row", justifyContent: "space-between",
              width: "50%", px: 2,
              // border: "1px solid black"
            }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  // fontSize: matcheslg ? '1rem' : matchesmd ? '0.9rem' : matchessm ? '0.8rem' : matchesxs ? '0.7rem' : '0.6rem',
                  cursor: "pointer", color: "#193241", fontFamily: 'Helvetica Neue',
                  fontWeight: !isGetStarted && location.pathname === '/' ? 700 : 300
                }}
                onClick={() => {
                  if (!isGetStarted) {
                    setSelected('Home')
                    navigate('/')
                  }
                }}>Home</Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  // fontSize: matcheslg ? '1rem' : matchesmd ? '0.9rem' : matchessm ? '0.8rem' : matchesxs ? '0.7rem' : '0.6rem',
                  cursor: "pointer", color: "#193241", fontFamily: 'Helvetica Neue',
                  fontWeight: !isGetStarted && location.pathname === '/connector' ? 700 : 300
                }}
                onClick={() => {
                  if (!isGetStarted) {
                    setSelected('Connectors')
                    navigate('/connector')
                  }
                }}>Connectors</Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  // fontSize: matcheslg ? '1rem' : matchesmd ? '0.9rem' : matchessm ? '0.8rem' : matchesxs ? '0.7rem' : '0.6rem',
                  cursor: "pointer", color: "#193241", fontFamily: 'Helvetica Neue',
                  fontWeight: !isGetStarted && location.pathname === '/learnmore' ? 700 : 300
                }}
                onClick={() => {
                  if (!isGetStarted) {
                    setSelected('Learn More')
                    navigate('/learnmore')
                  }
                }}>Learn More</Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  // fontSize: matcheslg ? '1rem' : matchesmd ? '0.9rem' : matchessm ? '0.8rem' : matchesxs ? '0.7rem' : '0.6rem',
                  cursor: "pointer", color: "#193241", fontFamily: 'Helvetica Neue',
                  fontWeight: !isGetStarted && location.pathname === '/contact' ? 700 : 300
                }}
                onClick={() => {
                  if (!isGetStarted) {
                    setSelected('Contact Us')
                    navigate('/contact')
                  }
                }}>Contact Us</Typography>
              {!isGetStarted &&
                <Box sx={{
                  position: "absolute", bottom: -28,
                  left: location.pathname === '/' ? '0%' :
                    location.pathname === '/connector' ? '25%' :
                      location.pathname === '/learnmore' ? '54%' :
                        location.pathname === '/contact' && '83%',
                  width: 90, height: 2, backgroundColor: "#3843AC"
                }} />
              }
            </Box>
            {/* <ul className="navbar-nav mx-auto mb-2 mb-lg-0"
            style={{ border: '1px solid black' }}>
            <li className="nav-item" style={{ marginRight: 20 }}>
              <NavLink className="nav-link" to="/home">Home</NavLink>
            </li>
            <li className="nav-item" style={{ marginRight: 20 }}>
              <NavLink className="nav-link" to="/connector">Connectors</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">Contact Us</NavLink>
            </li>
          </ul> */}
            <Box sx={{
              px: 2, display: "flex", flexDirection: "row",
            }}>
              <Box sx={{
                mr: "1rem", width: 145, height: "3rem", border: "1px solid #3843AC",
                display: 'flex', justifyContent: "center", alignItems: "center",
                borderRadius: 1, cursor: "pointer"
              }} onClick={() => {
                if (!isGetStarted) {
                  // dispatch(setIsGetStarted())
                  setSelected('Contact Us')
                  navigate('/contact')
                }
              }}>
                <Typography sx={{ color: '#3843AC', fontFamily: 'Helvetica Neue', }}>
                  Get Started
                </Typography>
              </Box>
              <Box sx={{
                backgroundColor: "#3843AC", cursor: "pointer",
                width: "6.25rem", height: "3rem", borderRadius: 1,
                display: 'flex', justifyContent: "center", alignItems: "center"
              }}
                onClick={() => navigate('/signin')}
              >
                <Typography sx={{ color: color.whiteColor, fontFamily: 'Helvetica Neue', }}>
                  Log in
                </Typography>
              </Box>

            </Box>
            {/* <div className="buttons">
            <NavLink to="/signin" className="btn custom-button">
              <i className="fa fa-sign-in me-1"></i> Log In
            </NavLink>
            <NavLink to="/signup" className="btn custom-button ms-2">
              <i className="fa fa-user-plus me-1"></i> Sign Up
            </NavLink>
          </div> */}
          </div> :
          <Box>
            <IconButton sx={{ cursor: 'pointer' }} onClick={handleClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  if (!isGetStarted) {
                    setSelected('Home')
                    navigate('/')
                    handleClose()
                  }
                }}>Home</MenuItem>
              <MenuItem onClick={() => {
                if (!isGetStarted) {
                  setSelected('Connectors')
                  navigate('/connector')
                  handleClose()
                }
              }}
              >Connectors</MenuItem>
              <MenuItem onClick={() => {
                if (!isGetStarted) {
                  setSelected('Learn More')
                  navigate('/learnmore')
                  handleClose()
                }
              }}>Learn More</MenuItem>
              <MenuItem onClick={() => {
                if (!isGetStarted) {
                  setSelected('Contact Us')
                  navigate('/contact')
                  handleClose()
                }
              }}>Contact Us</MenuItem>
              <MenuItem onClick={() => {
                if (!isGetStarted) {
                  // dispatch(setIsGetStarted())
                  setSelected('Contact Us')
                  navigate('/contact')
                  handleClose()
                }
              }}>Get Started</MenuItem>
              <MenuItem onClick={() => {
                navigate('/signin')
                handleClose()
              }}>Log in</MenuItem>
            </Menu>
          </Box>
        }
      </>
    )
  }

  const NameContent = () => {
    return (
      <NameContentBox>
        <Typography style={{ fontFamily: 'Helvetica Neue', fontWeight: 500, marginRight: 15, fontSize: 18 }}>
          {user ? `Hi, ${user.firstName} ${user.lastName}` : ''}
        </Typography>
        <ImageDiv>
          <img style={{ margin: '5px 5px', width: "1.5rem", height: 24 }} alt={'MADCONNECT'} src={userLogo}></img>
        </ImageDiv>
      </NameContentBox>
    )
  }

  const renderNav = () => {
    return (
      isHomeNav ?
        <Box>
          <ResponsiveAppBar />
          <Toolbar sx={{ height: 2 * matchesxs ? 104 : 60 }} />
        </Box>
        :
        <NavStyled className="navbar navbar-expand-md navbar-light py-3 shadow-sm">
          <div className={matchesxss ? "container-fluid custom-page-margin" : 'smallDiv'}>
            <NavBox>
              <LogoStyled alt={'MADCONNECT'} src={madConnectLogo}></LogoStyled>            
            </NavBox>
            <NameContent />
          </div>
        </NavStyled>
      
    )
  }

  return (
    <div>
      {renderNav()}
    </div>
  );
}

export default Navbar;