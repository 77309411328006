import {
  Backdrop,
  Box,
  InputLabel,
  OutlinedInput,
  Typography,
  Button,
  Fade,
  Modal,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import connectorImg1 from "../../../../assets/Connector.svg";
import lineImg from "../../../../assets/Line_18.svg";
import cancel from "../../../../assets/cancel.svg";
import { color } from "../../../../utils/Colors";
import { useRestoreDeleteListMutation } from "../../../../services/query";
import { useMyContext } from "../../../../components/CommanToasterContext/toast";
import {
  BoxDeleteToConfirm,
  BoxImg,
  BoxImgContainer,
  BoxImgInner,
  BoxMiddelImg,
  ButtonBox,
  ButtonName,
  ButtonWrapper,
  DivMiddelImg,
  FormControlDeleteToConfirm,
  ImgStyled,
  WrapperTypographyImage,
  WrapperTypographyPara,
} from "../Styles/ConnectionList.styles";

const RetriveModel = ({ open, handleClose, selectedItem, refetch, activeTab ,setActiveTab}) => {
  const style = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 8px 8px",
      bgcolor: "#F8F8FE",
      width: "31.25rem",
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&:focus": {
        outline: "none",
      },
    }),
    []
  );

  const { setOpen2, setMessage2, setMessage } = useMyContext();

  const [retryCheck, { data: retryData, isLoading, error: retryError }] =
    useRestoreDeleteListMutation();

  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (retryData !== undefined) {
      if (retryData.statusCode === 200) {
        handleClose()
        setMessage(retryData.statusMessage);
        refetch();
        setActiveTab("In-progress")
      } else {
        setMessage2(retryData.statusMessage);
        setOpen2(true);
      }
    }
    if (retryError !== undefined) {
      console.log("Activate Connection Error", retryError);
    }
  }, [retryData, retryError]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };

  const handleRetry = () => {
    if (inputText === "RESTORE") {
      retryCheck({ connectionId: selectedItem.conId });
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style, pl: 5, pr: 5, width: 600 }}>
            <WrapperTypographyImage>
              <BoxImg>
                <BoxImgContainer>
                  <BoxImgInner>
                    <ImgStyled>
                      <img
                        alt=""
                        src={selectedItem?.src.logo}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      ></img>
                    </ImgStyled>
                  </BoxImgInner>
                  <DivMiddelImg />
                  <BoxMiddelImg>
                    <img
                      alt=""
                      src={connectorImg1}
                      style={{ width: 24, height: 24, color: "#193241" }}
                    ></img>
                  </BoxMiddelImg>
                  <img
                    alt=""
                    src={lineImg}
                    style={{ width: 55, marginRight: 5, marginLeft: 5 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      p: 1,
                      width: "35%",
                      height: 60,
                      borderRadius: 1,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "2.5rem",
                        borderRadius: 1,
                        p: 0.4,
                      }}
                    >
                      <img
                        alt=""
                        src={selectedItem?.dest.logo}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      ></img>
                    </Box>
                  </Box>
                </BoxImgContainer>
              </BoxImg>
            </WrapperTypographyImage>

            <WrapperTypographyPara>
              Are you sure you want to Restore this connection?
            </WrapperTypographyPara>

            <BoxDeleteToConfirm>
              <FormControlDeleteToConfirm size="small">
                <InputLabel
                  sx={{
                    fontSize: "0.875rem",
                    marginTop: "0.25rem",
                    opacity: 0.5,
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Helvetica Neue",
                  }}
                  id="restore"
                >
                  Type RESTORE to confirm
                </InputLabel>
                <OutlinedInput
                  placeholder={""}
                  sx={{
                    width: "100%",
                    height: "2.75rem",
                    fontSize: "1rem",
                    color: "#666666",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Helvetica Neue",
                  }}
                  labelid="restore"
                  id="restore"
                  label="Type RESTORE to confirm"
                  onChange={handleInputChange}
                  value={inputText}
                  autoComplete="off"
                />
              </FormControlDeleteToConfirm>
            </BoxDeleteToConfirm>

            <ButtonWrapper>
              <Button
                onClick={handleRetry}
                disabled={!inputText === "RESTORE"}
                style={{
                  cursor: "pointer",
                  backgroundColor: color.whiteColor,
                  width: "6.625rem",
                  height: "2.5rem",
                  color: color.themeRed,
                  textTransform: "none",
                  border: "1.5px solid",
                  borderColor: color.themeRed,
                  cursor: inputText === "RESTORE" ? "pointer" : "not-allowed",
                }}
                variant="contained"
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >

                  <AutorenewIcon
                    sx={{
                      width: "1.3rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica",
                      display: "flex",
                      alignItems: "center",
                      // marginTop: 2,
                    }}
                  >
                    Restore
                  </Typography>
                </Box>
              </Button>
              <Button
                onClick={handleClose}
                isLoading={isLoading}
                disabled={isLoading}
                style={{
                  cursor: "pointer",
                  backgroundColor: color.whiteColor,
                  width: "6.625rem",
                  height: "2.5rem",
                  color: color.themeBlack,
                  textTransform: "none",
                }}
                variant="contained"
              >
                <ButtonBox>
                  <img
                    alt=""
                    src={cancel}
                    style={{
                      width: "0.8rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />

                  <ButtonName>Cancel</ButtonName>
                </ButtonBox>
              </Button>
            </ButtonWrapper>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RetriveModel;
