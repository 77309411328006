import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { TableHeading } from "../Constant";
import { TableCellStyle } from "../Styles/MyConnection.styles";
import { favIconMapping1 } from "../../../../components/FavIconMaping/favicon";
import editImg from "../../../../assets/edit.svg";
import trash from "../../../../assets/trash-2.svg";
import { madTechState } from "../../../../features/madTechSlice";
import DeleteModal from "../Modal/DeleteModal";
import DeleteSuccessModal from "../Modal/DeleteSuccessModal";
import {
  ActionTabBox,
  ActionTableCell,
  DestinationContainer,
  ImageBox,
  ListLeftWrapper,
  SpanStyled,
  TableCellStyled,
  TableContainerStyled,
  TableHeadStyled,
  TableRowStyled,
} from "../Styles/ConnectionList.styles";
import RetriveModel from "../Modal/RetriveModel";

function getFavIconImagePath(key) {
  if (favIconMapping1.hasOwnProperty(key)) {
    return favIconMapping1[key];
  } else {
    return "platforms2/favicon.png";
  }
}

const ConnectionListLeft = ({
  activeTab,
  tableDetails,
  handleRowChange,
  rowSelectIndex,
  connectors,
  refetch,
  setConnectors,
  connectionLoader,
  setIsStatusChange,
  setActiveTab
}) => {
  const navigate = useNavigate();

  const { selectedAccount } = useSelector(madTechState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRetryModalOpen, setIsRetryModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isForDisable, setIsForDisable] = useState(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  const handleEdit = (editId) => {
    if (editId.length > 0) {
      navigate(
        selectedAccount !== null && selectedAccount !== undefined
          ? `/create-connections?accountId=${selectedAccount.id}&connectionId=${editId}`
          : `/create-connections?connectionId=${editId}`
      );
    }
  };

  const handleClose = () => {
    setSelectedItem(null);
    setIsModalOpen(false);
  };

  const handleOpen = (item, isenable = false) => {
    setIsForDisable(isenable);
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleDeleteCloseSuccess = () => {
    setIsStatusChange(true)
    refetch();
    setIsDeleteSuccess(false);
  };

  const handleRetry = (item) => {
    setIsRetryModalOpen(true);
    setSelectedItem(item);
  };

  const handleRetryClose = () => {
    setSelectedItem(null);
    setIsRetryModalOpen(false);
  };

  const showLoader = () => {
   return  <CircularProgress
    style={{ color: "#3646ac" }}
    size={40}
    thickness={8}
  />
  }

  return (
    <>
      <ListLeftWrapper activeTab={activeTab} tableData={tableDetails} className="mainContent_scroll">
        <TableContainerStyled id="scrolling">
          <Table
            style={{
              border: "none",
            }}
          >
              <TableHeadStyled>
                <TableRow style={{ border: "none" }}>
                  {TableHeading?.map((value, i) => (
                    <TableCellStyle
                      key={i}
                      hidden={value.hidden ? value.hidden(activeTab) : false}
                      center={value.center}
                      headCenter={value.head}
                      label={value.label}
                    >
                      {value.label}
                    </TableCellStyle>
                  ))}
                </TableRow>
              </TableHeadStyled>
              <TableBody>
                {tableDetails?.length > 0 && !connectionLoader ? (
                  tableDetails?.map((item, index) => (
                    <TableRowStyled
                      key={item.id}
                      onClick={() => {
                        rowSelectIndex !== index &&
                          handleRowChange(item, index);
                      }}
                      activeTab={activeTab}
                      rowSelectIndex={rowSelectIndex}
                      index={index}
                    >
                      <TableCellStyled>{item.name}</TableCellStyled>

                      <TableCell
                        style={{
                          width: "23%",
                          border: "none",
                          padding: "10px",
                        }}
                      >
                        <ImageBox>
                          <img
                            alt=""
                            onClick={() => handleRowChange(item, index)}
                            src={getFavIconImagePath(item?.src?.logo)}
                            // src="https://devk0fhuj1.madconnect.io/logos/favicons/salesforce.svg"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              minWidth: "2.5rem",
                              maxWidth: "2.5rem",
                              height: "2.5rem",
                              borderRadius: 2,
                              p: 1,
                            }}
                          />
                          <Tooltip
                            title={
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "1rem",
                                }}
                              >
                                {item.src.name ? item.src.name : ""}
                              </span>
                            }
                            placeholder="top"
                          >
                            <SpanStyled>
                              {item.src.name ? item.src.name : ""}
                            </SpanStyled>
                          </Tooltip>
                        </ImageBox>
                      </TableCell>

                      <TableCell
                        style={{
                          width: "23%",
                          border: "none",
                          padding: "10px",
                        }}
                      >
                        <DestinationContainer>
                          <img
                            alt=""
                            src={getFavIconImagePath(item?.dest?.logo)}
                            // src='http://ec2-44-213-15-199.compute-1.amazonaws.com/logos/favicons/meta%20logo.svg'

                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              minWidth: "2.5rem",
                              maxWidth: "2.5rem",
                              height: "2.5rem",
                              borderRadius: 2,
                              p: 1,
                            }}
                          ></img>
                          <Tooltip
                            title={
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "1rem",
                                }}
                              >
                                {item.dest.name ? item.dest.name : ""}
                              </span>
                            }
                            placeholder="top"
                          >
                            <SpanStyled>
                              {item.dest.name ? item.dest.name : ""}
                            </SpanStyled>
                          </Tooltip>
                        </DestinationContainer>
                      </TableCell>

                      <TableCell
                        style={{
                          width: "19%",
                          border: "none",
                          padding: "10px",
                          color: "rgba(97, 97, 97, 1)",
                          display: activeTab === "In-progress" && "none",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            flexDirection: "row",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <Tooltip
                            title={
                              <span
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                }}
                              >
                                {item?.src?.dataType
                                  ? item?.src?.dataType
                                  : item?.dest?.dataType
                                  ? item?.dest?.dataType
                                  : ""}
                              </span>
                            }
                            placeholder="top"
                          >
                            <span
                              style={{
                                color: "#616161",
                                fontWeight: 500,
                                fontFamily: "Helvetica Neue",
                                textTransform: "capitalize",
                                cursor: "default",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.src?.dataType
                                ? item?.src?.dataType
                                : item?.dest?.dataType
                                ? item?.dest?.dataType
                                : ""}
                            </span>
                          </Tooltip>
                        </Box>
                      </TableCell>

                      <ActionTableCell>
                        <ActionTabBox>
                          {activeTab !== "In-progress" &&
                            activeTab !== "Deleted" && (
                              <Tooltip
                                title={
                                  !connectors.enabled ? "Enable" : "Disable"
                                }
                                placement="top"
                              >
                                <Box
                                  className="action_Connection"
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleOpen(item, true)}
                                >
                                  <Switch size="small" checked={item.enabled} />
                                </Box>
                              </Tooltip>
                            )}
                          {activeTab !== "Deleted" && (
                            <Tooltip title="Edit" placement="top">
                              <Box
                                className="action_Connection2"
                                onClick={() => handleEdit(item.conId)}
                                sx={{ cursor: "pointer" }}
                              >
                                <img
                                  alt=""
                                  src={editImg}
                                  style={{ width: "19px", height: "19px" }}
                                ></img>
                              </Box>
                            </Tooltip>
                          )}
                          {activeTab !== "Deleted" && (
                            <Tooltip title="Delete" placement="top">
                              <Box
                                className="action_Connection2"
                                onClick={() => handleOpen(item)}
                                sx={{ cursor: "pointer" }}
                              >
                                <img
                                  alt=""
                                  src={trash}
                                  style={{ width: "1.5rem", height: "1.5rem" }}
                                ></img>
                              </Box>
                            </Tooltip>
                          )}
                          {activeTab === "Deleted" && (
                            <Tooltip title="Restore" placement="top">
                              <Box
                                className="action_Connection2"
                                onClick={() => handleRetry(item)}
                                sx={{ cursor: "pointer" }}
                              >
                                <AutorenewIcon />
                              </Box>
                            </Tooltip>
                          )}
                        </ActionTabBox>
                      </ActionTableCell>
                    </TableRowStyled>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={TableHeading?.length}
                      align="center"
                      sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                    >
                     {connectionLoader ? showLoader() : <>No connections are {activeTab} </>}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
          </Table>
        </TableContainerStyled>
      </ListLeftWrapper>

      {isModalOpen && (
        <DeleteModal
          handleClose={handleClose}
          open={isModalOpen}
          selectedItem={selectedItem}
          isForDisable={isForDisable}
          connectors={connectors}
          refetch={refetch}
          setConnectors={setConnectors}
          setSelectedItem={setSelectedItem}
          setIsDeleteSuccess={setIsDeleteSuccess}
          setActiveTab={setActiveTab}
        />
      )}
      {isDeleteSuccess && (
        <DeleteSuccessModal
          handleDeleteCloseSuccess={handleDeleteCloseSuccess}
          isDeleteSuccess={isDeleteSuccess}
          setIsDeleteSuccess={setIsDeleteSuccess}
        />
      )}
      {isRetryModalOpen && (
        <RetriveModel
          handleClose={handleRetryClose}
          open={isRetryModalOpen}
          selectedItem={selectedItem}
          refetch={refetch}
          setActiveTab={setActiveTab}
        />
      )}
    </>
  );
};

export default ConnectionListLeft;
