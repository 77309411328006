import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import './signin.css'
import whiteLogo from '../../assets/madconnect-white.svg'
import madConnectLogo from '../../assets/madconnectlight 2 (1).svg'
import { Strings } from '../../utils/Strings';
import CommonButton from '../../components/CommonButton/CommonButton'
import CommonInput from '../../components/CommonInput/CommonInput';
import showPwdImg from '../../assets/show_password.svg';
import hidePwdImg from '../../assets/hide_password.svg';
import { useResendOtpMutation, useSigninUserMutation, useVerifyOtpMutation } from '../../services/query';
import { madTechState, setLoggedin } from '../../features/madTechSlice';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import useDocumentTitle from '../../hook/useDocumentTitle';
import { ContactUsLink, DontHaveAcc, ErrorContainer, FAverificationReceive, FAverificationResend, FAverificationWrapper, ForgetPassword, IsRememberLabel, LeftContainer, LeftImage, LogInTextBox, LoginErrorMsg, LoginHeading, LoginPara, LoginSigninContainer, LoginText1, SignInContainer, WelcomeToHeading } from './CommanSignIn.styles';

function CommanSignIn(props) {

    const { background, ContentFontSize, classname, LoginText, TextFontSize, OAuthType, display } = props

    useDocumentTitle('Sign In')
    const matchesxsss = useMediaQuery('(min-width:495px)');
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('clientId');

    const { isLoggedin, user } = useSelector(madTechState)
    const navigate = useNavigate();

    useEffect(() => {
        if (OAuthType === true && clientId === null) {
            window.close()
        }
    }, [OAuthType])

    const dispatch = useDispatch()

    const [userDetails, setUserDetails] = useState({
        email: "",
        password: ""
    })

    const [isOTPSend, setIsOTPSend] = useState(false);
    const [otp, setOtp] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [loginError, setLoginError] = useState('')
    const [otpErrorMsg, setOtpError] = useState("");
    const [validateForm, setValidateForm] = useState(false)

    const [signinUser, { data, error, isLoading }] = useSigninUserMutation()
    const [verifyUserOtp, { data: otpData, error: otpError, isLoading: otpLoading }] = useVerifyOtpMutation()
    const [resendotp] = useResendOtpMutation()

    useEffect(() => {
        if (isLoggedin === "yes") {
            if (user?.stepPending !== 0) {
                // window.location.href = `/completeProfile?u=${user.userId}`
                navigate(`/completeProfile?u=${user.userId}`)
            } else if (user.userType === "platform" || user.userType === "cloud-provider" || user.userType === "agency" || user.userType === "madconnect") {
                navigate(`/admin/dashboard`)
                // window.location.href = `/admin/dashboard`
            } else {
                navigate('/dashboard');
            }
        }
    }, [isLoggedin])

    // useEffect(() => {
    //     setIsOTPSend(false);
    // }, [])

    useEffect(() => {
        document.getElementById('common').focus()
    }, [isOTPSend])

    const _validateSignInForm = useCallback(() => {
        if (userDetails.email.trim().length === 0 && userDetails.password.trim().length === 0) {
            setValidateForm(true)
            return true
        } else {
            setValidateForm(false)
        }
        return false
    }, [userDetails.email, userDetails.password])

    function _validateOtpVerificationForm() {
        return otp.length > 0;
    }

    const _login = useCallback((event) => {
        event.preventDefault();
        if (_validateSignInForm()) {
            return;
        }
        signinUser({ email: userDetails.email, password: userDetails.password, OAuthType: OAuthType })
    }, [useSigninUserMutation, userDetails.email, userDetails.password, _validateSignInForm])

    const _verifyOTP = useCallback((event) => {
        event.preventDefault();
        verifyUserOtp({ otp })
    }, [useVerifyOtpMutation, otp])

    useEffect(() => {
        if (data !== undefined && data.response) {
            // console.log('data', data);
            setIsOTPSend(true);
        }
        if (data !== undefined && !data.response) {
            setLoginError(data.statusMessage)
        }
        if (error !== undefined) {
            console.log('error', error);
            setIsOTPSend(false);
        }
    }, [data, error])

    useEffect(() => {
        if (otpData !== undefined) {
            if (otpData.statusCode === 200) {
                if (OAuthType === true) {
                    navigate(clientId ? "/oauth/authorize?clientId=" + clientId : "/oauth/authorize")
                } else {
                    localStorage.setItem("USER", JSON.stringify(user));
                    localStorage.setItem("TOKEN", JSON.stringify(user.token));
                    if (user?.stepPending !== 0) {
                        window.location.href = `/completeProfile?u=${user.userId}`
                        navigate(`/completeProfile?u=${user.userId}`)
                    } else if (user.userType === "platform" || user.userType === "cloud-provider" || user.userType === "agency" || user.userType === "madconnect") {
                        window.location.href = `/admin/dashboard`
                        // navigate(`/admin/dashboard`)
                    } else {
                        dispatch(setLoggedin("yes"))
                        navigate('/dashboard');
                    }
                }
            } else {
                setOtpError(otpData.statusMessage)
                setIsOTPSend(true);
            }
        }
        if (otpError !== undefined) {
            console.log('otpError', otpError);
            setOtpError('OTP not matched')
            setIsOTPSend(true);
        }
    }, [otpData, otpError])

    const handleChange = (event) => {
        const { value, placeholder } = event.target
        setValidateForm(false);
        setUserDetails({ ...userDetails, [placeholder.toLowerCase()]: value })
        setLoginError('');
    }


    function SignInFunction() {
        return <Grid item md={5} lg={5} sm={12} xs={12} bgcolor={background}>
            <div className={classname}
                style={{ backgroundColor: background }}>
                <SignInContainer className='signin-container' matchesxsss={matchesxsss}>
                    <ErrorContainer>
                        {validateForm ? <span className='form-error'>{Strings.wrong_credentials}</span> :
                            <LogInTextBox variant='subtitle2'>{loginError}</LogInTextBox>}
                    </ErrorContainer>
                    <LoginHeading className='text-center mb-2' TextFontSize={TextFontSize}>{LoginText}</LoginHeading>
                    <LoginPara className='text-center mb-4 sub-text-color' ContentFontSize={ContentFontSize}>Please fill your details below to Log In!</LoginPara>
                    <div className='emailRide'>
                        <CommonInput
                            isFocused={true}
                            height={"2.75rem"}
                            inputProps={{
                                style: {
                                    padding: "10px 14px",
                                    backgroundColor: 'white',
                                }
                            }}
                            isError={!!validateForm}
                            backgroundColor={'white'}
                            placeholder={Strings.email}
                            inputType='email'
                            value={userDetails.email}
                            onChange={(event) => handleChange(event)}
                        />
                    </div>
                    <div className='mt-3 passwordRide'>
                        <CommonInput
                            height={"2.75rem"}
                            inputProps={{
                                style: {
                                    padding: "10px 14px"
                                }
                            }}
                            isError={!!validateForm}
                            backgroundColor={'white'}
                            placeholder={Strings.password}
                            inputType={isRevealPwd ? "text" : "password"}
                            value={userDetails.password}
                            onChange={(event) => handleChange(event)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    _login(event);
                                }
                            }}
                            endAdornment={<img
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                style={{ width: 18, height: 18, cursor: "pointer" }} />}
                        />
                    </div>
                    <div className='mt-2 combine'>
                        <div style={{ display: display, alignItems: 'center' }}>

                            <input type="checkbox" value="lsRememberMe" id="rememberMe" style={{ marginRight: "0.31rem" }} />
                            <IsRememberLabel htmlFor="rememberMe">Remember me</IsRememberLabel>
                        </div>
                        <div className='forgot-password'>
                            <ForgetPassword
                                onClick={() => {
                                    setLoginError('');
                                    if (OAuthType === true) {
                                        navigate(clientId ? "/oauth/forgotpass?clientId=" + clientId : "/oauth/forgotpass", { state: { data: OAuthType } });
                                    } else {
                                        navigate('/forgotPass', { state: { data: OAuthType } });
                                    }
                                }}
                            >Forgot Password?</ForgetPassword>
                        </div>
                    </div>
                    <div className='mt-2 mb-4'>
                        <CommonButton
                            width={`100%`}
                            height={"2.75rem"}
                            textStyle={{
                                fontSize: '1.125rem',
                                fontWeight: '500'
                            }}
                            onClick={(event) => {
                                _login(event);
                            }}
                            isLoading={isLoading}
                            title={Strings.login}
                            color={'#3843AC'}
                        />
                    </div>

                    <hr className='dashed' />

                    <div className='mt-3 text-center'>
                        <DontHaveAcc className='sub-text-color'>Don’t have an account?
                            <ContactUsLink
                                onClick={() => {
                                    if (!OAuthType) {
                                        navigate("/contact");
                                        // dispatch(setIsGetStarted())
                                        // handleCloseUserMenu()
                                    }
                                    else {
                                        navigate(clientId ? "/oauth/signup?clientId=" + clientId : "/oauth/signup");

                                    }
                                }}
                            >Contact us
                            </ContactUsLink>
                        </DontHaveAcc>
                    </div>
                </SignInContainer>
            </div>
        </Grid>;
    }

    function Verification() {
        return <Grid item md={5} lg={5} sm={12} xs={12} bgcolor={background}>
            <div className={classname}
                style={{ backgroundColor: background }}>
                <FAverificationWrapper className='signin-container'>
                    <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                        <LoginErrorMsg variant='subtitle2'>{otpErrorMsg}</LoginErrorMsg>
                    </Box>
                    <p className='text-center mb-2' style={{ fontSize: TextFontSize, fontWeight: 500, fontFamily: 'Helvetica Neue' }}>{Strings.otpVerification}</p>
                    <p className='text-center mb-4 sub-text-color' style={{
                        fontSize: ContentFontSize,
                        margin: 'Typographyauto', width: '90%',
                        fontWeight: 400, fontFamily: 'Helvetica Neue'
                    }}>Please enter the Pin sent to your registered Email</p>
                    <div className=''>
                        <CommonInput
                            isFocused={true}
                            height={"2.75rem"}
                            backgroundColor={'white'}
                            placeholder={Strings.otp}
                            value={otp}
                            onChange={(event) => {
                                setOtp(event.target.value);
                                setOtpError('');
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    _verifyOTP(event);
                                    setOtpError('');
                                }
                            }}
                        />
                    </div>
                    <div className='mt-3 mb-4'>
                        <CommonButton
                            width={"100%"}
                            height={"2.75rem"}
                            textStyle={{
                                fontSize: '14px',
                                fontWeight: '500',
                                fontFamily: 'Helvetica'
                            }}
                            onClick={(event) => {
                                _verifyOTP(event);
                            }}
                            isLoading={otpLoading}
                            title={Strings.submit}
                            color={'#3843AC'}
                            disabled={!_validateOtpVerificationForm() || otpLoading}
                        />
                    </div>
                    <hr className='dashed' />
                    <div className='mt-3 text-center'>
                        <FAverificationReceive className='sub-text-color'>Didn’t receive the Pin?
                            <FAverificationResend
                                onClick={() => {
                                    setOtpError('');
                                    resendotp();
                                }}
                            >Resend 2FA Pin
                            </FAverificationResend>
                        </FAverificationReceive>
                    </div>
                </FAverificationWrapper>
            </div>
        </Grid>;
    }


    return (
        <AuthLayout OAuthType={OAuthType}>
            <Grid container={!OAuthType} mx='auto' >
                {!OAuthType && <Grid item md={7} lg={7} sm={12} xs={12} position='relative'>
                    <LeftImage
                        alt={'MADCONNECT'}
                        src={whiteLogo}
                        onClick={() => navigate('/')}
                    />
                    <LoginSigninContainer>
                        <LeftContainer matchesxsss={matchesxsss}>
                            <div className={`${matchesxsss ? 'row' : 'new-row'}`}>

                                <div className='col-12' style={{
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <div className={`${matchesxsss ? 'row' : 'new-row'}`}>
                                        <div className='col-12'>
                                            <WelcomeToHeading>Welcome to</WelcomeToHeading>
                                        </div>
                                    </div>
                                    <div className={`${matchesxsss ? 'row' : 'new-row'}`}>
                                        <div className='col-12'>
                                            <img style={{ height: 'auto', width: "15rem" }} alt={'MADCONNECT'} src={madConnectLogo}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </LeftContainer>
                    </LoginSigninContainer>
                </Grid>}
                {
                    !isOTPSend ?
                        SignInFunction()
                        :
                        Verification()
                }
            </Grid>
        </AuthLayout>
    );


}

export default CommanSignIn;
