import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { teal } from "@mui/material/colors";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useSelector } from "react-redux";

import AppLayout from "../../components/AppLayout/AppLayout";
import CommonButton from "../../components/CommonButton/CommonButton";
import { Strings } from "../../utils/Strings";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdateUserMutation,
} from "../../services/query";
import { madTechState } from "../../features/madTechSlice";
import { color } from "../../utils/Colors";
import "./ProfileDetails.css";
import { useMyContext } from "../../components/CommanToasterContext/toast";
import { useMyContext2 } from "../../components/CommanToasterContext/commanplatformCheck";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import {
  BillContactWrapper,
  CountrySelectWrapper,
  Heading,
  PrimaryContDetailsBox,
  SelfBox,
  SubmitBtnWrapper,
  Wrapper,
} from "./ProfileDetails.styles";
import {
  FormNameInputWrapper,
  FormNameLabelWrapper,
  FormNameWrapper,
  PhoneInputWrapper,
  RightLeftContainer,
} from "../MyProfile/SMyProfile.styles";
import {
  BillContactArr,
  billingDetailsArr,
  nameDetails,
  validateAllFields,
} from "./constant";

const ProfileDetails = () => {
  const navigate = useNavigate();
  const { selectedAccount } = useSelector(madTechState);
  const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

  const [userType, setUserType] = useState("");
  const [countrySelected, setCountrySelected] = useState("");

  const { responseInvalid, setResponseInvalid } = useMyContext2();

  const [authorisedSignatory, setAuthorisedSignatory] = useState({
    email: "",
    name: "",
    title: "",
    phoneNumber: "",
  });
  const [billingContact, setBillingContact] = useState({
    name: "",
    title: "",
    email: "",
    phoneNumber: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    address: "",
    companyName: "",
    country: "",
    postalCode: "",
  });
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    title: "",
  });

  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isBillOwnerChecked, setIsBillOwnerChecked] = useState(false);
  const [isBillAuthChecked, setIsBillAuthChecked] = useState(false);

  const handleAuthSigChange = useCallback(
    (type) => {
      if (type === "Authorised Signatory") {
        if (isAuthChecked) {
          setAuthorisedSignatory({
            email: "",
            name: "",
            title: "",
            phoneNumber: userDetails.phoneNumber,
          });
        } else {
          setAuthorisedSignatory({
            email: userDetails.email,
            name: `${userDetails.firstName} ${userDetails.lastName}`,
            title: userDetails.title,
            phoneNumber: userDetails.phoneNumber,
          });
        }
        setIsAuthChecked(!isAuthChecked);
      } else if (type === "Billing with owner") {
        if (isBillOwnerChecked) {
          setBillingContact({
            name: "",
            title: "",
            email: "",
            phoneNumber: userDetails.phoneNumber,
          });
        } else {
          setBillingContact({
            name: `${userDetails.firstName} ${userDetails.lastName}`,
            title: userDetails.title,
            email: userDetails.email,
            phoneNumber: userDetails.phoneNumber,
          });
        }
        setIsBillOwnerChecked(!isBillOwnerChecked);
        setIsBillAuthChecked(false);
      } else {
        if (isBillAuthChecked) {
          setBillingContact({
            name: "",
            title: "",
            email: "",
            phoneNumber: userDetails.phoneNumber,
          });
        } else {
          setBillingContact({
            name: authorisedSignatory.name,
            title: authorisedSignatory.title,
            email: authorisedSignatory.email,
            phoneNumber: authorisedSignatory.phoneNumber,
          });
        }
        setIsBillOwnerChecked(false);
        setIsBillAuthChecked(!isBillAuthChecked);
      }
    },
    [
      userDetails.email,
      userDetails.firstName,
      userDetails.lastName,
      userDetails.title,
      userDetails.phoneNumber,
      isAuthChecked,
      isBillOwnerChecked,
      isBillAuthChecked,
      authorisedSignatory,
    ]
  );

  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: refetchBusinessDetails,
  } = useGetProfileQuery();

  const [
    updateUserProfile,
    {
      data: updateProfileData,
      isLoading: updateProfileLoading,
      error: updateProfileError,
    },
  ] = useUpdateProfileMutation();

  useEffect(() => {
    refetchBusinessDetails();
  }, []);

  const handleUpdateUser = useCallback(() => {
    if (validateAllFields(userDetails, billingDetails, setOpen2, setMessage2)) {
      return;
    }
    updateUserProfile({
      profileData: {
        primaryContactDetails: {
          companyName: userDetails.companyName,
          email: userDetails.email,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          phoneNumber: userDetails.phoneNumber,
          title: userDetails.title,
          userId:
            selectedAccount !== null && selectedAccount !== undefined
              ? selectedAccount.userId
              : null,
        },
        authorisedSignatory,
        billingContact,
        billingDetails,
      },
      responseInvalid,
    });
  }, [
    useUpdateUserMutation,
    userDetails.companyName,
    userDetails.email,
    userDetails.firstName,
    userDetails.lastName,
    userDetails.phoneNumber,
    userDetails.title,
    authorisedSignatory,
    billingContact,
    billingDetails,
    useUpdateProfileMutation,
    userType,
  ]);

  useEffect(() => {
    if (updateProfileData !== undefined) {
      if (updateProfileData.statusCode === 200) {
        setMessage(updateProfileData?.statusMessage);
        setOpen(true);
        refetchBusinessDetails();
      } else {
        setMessage2(updateProfileData?.statusMessage);
        setOpen2(true);
      }
    }

    if (updateProfileError !== undefined) {
      console.log("updateProfileError", updateProfileError);
    }
  }, [updateProfileData, updateProfileError]);

  useEffect(() => {
    if (profileData?.statusMessage === "Profile not found") {
      setResponseInvalid(true);
    } else if (
      profileData !== undefined &&
      profileData.statusCode === 200 &&
      profileData.response
    ) {
      const primaryContactDetailsRes =
        profileData.response.data?.primaryContactDetails;

      setUserDetails({
        firstName: primaryContactDetailsRes?.firstName,
        email: primaryContactDetailsRes?.email,
        lastName: primaryContactDetailsRes?.lastName,
        phoneNumber: primaryContactDetailsRes?.phoneNumber,
        companyName: primaryContactDetailsRes?.companyName,
        title: primaryContactDetailsRes?.title,
      });

      setUserType(profileData.response.data?.primaryContactDetails?.userType);
      setAuthorisedSignatory(profileData.response.data?.authorisedSignatory);
      setBillingContact(profileData.response.data?.billingContact);
      setBillingDetails(profileData.response.data?.billingDetails);
      setCountrySelected(profileData.response.data?.billingDetails?.country);
    }

    if (profileError !== undefined) {
      console.log("profileError", profileError);
      if (profileError.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [profileData, profileError]);

  const handleSetAllChecked = (name, e) => {
    if (name === "companyName") {
      return;
    }

    console.log(name, e);
    const isSame =
      name === "email" || name === "phoneNumber" || name === "title";
    const key = isSame ? name : "name";

    if (isAuthChecked) {
      setAuthorisedSignatory({
        ...authorisedSignatory,
        [key]: e.target.value,
      });
    }
    if (isBillOwnerChecked || (isAuthChecked && isBillAuthChecked)) {
      setBillingContact({
        ...billingContact,
        [key]: e.target.value,
      });
    }
    // if (isAuthChecked && isBillAuthChecked) {
    //   setBillingContact({
    //     ...billingContact,
    //     [key]: e.target.value,
    //   });
    // }
  };

  const handleChange = (e, name) => {
    setUserDetails({
      ...userDetails,
      [name]: e.target.value,
    });
    handleSetAllChecked(name, e);
  };

  const handleBillingDeatilsChange = (e, name) => {
    setBillingDetails({
      ...billingDetails,
      [name]: e.target.value,
    });
  };

  const handleBillingContact = (e, name) => {
    setIsBillAuthChecked(false);
    setIsBillOwnerChecked(false);
    setBillingContact({
      ...billingContact,
      [name]: e.target.value,
    });
  };

  return (
    <AppLayout>
      <Box width="100%" px="1.5rem" pb="1.5rem">
        <SBreadCrumb path="profileDetails" pathName="Account Profile" />
        <Wrapper>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          />
          <PrimaryContDetailsBox>
            <SelfBox />
            <Heading>Primary contact details</Heading>
            <Box
              sx={{
                display: "flex",
                mt: "1.5rem",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "44%",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {nameDetails?.map((detail, index) => {
                    return (
                      <FormNameWrapper size="small" key={index}>
                        <FormNameLabelWrapper id={detail.id}>
                          {detail.label}
                        </FormNameLabelWrapper>
                        <FormNameInputWrapper
                          placeholder={detail.label}
                          labelid={detail.id}
                          id={detail.id}
                          label={detail.label}
                          value={userDetails[detail.id]}
                          onChange={(e) => handleChange(e, detail.id)}
                        />
                      </FormNameWrapper>
                    );
                  })}
                </Box>
                <FormNameWrapper
                  sx={{
                    width: "100%",
                    mt: 3,
                  }}
                  size="small"
                >
                  <FormNameLabelWrapper id="workEmail">
                    Work email*
                  </FormNameLabelWrapper>
                  <FormNameInputWrapper
                    placeholder={"Work email*"}
                    labelid="workEmail"
                    id="workEmail"
                    label="Work email*"
                    value={userDetails.email}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </FormNameWrapper>
                <Box mt={3} height="2.75rem">
                  <PhoneInputWrapper
                    placeholder={`${Strings.phoneNumber}*`}
                    inputClass="colors"
                    country={"us"}
                    countryCodeEditable={false}
                    enableSearch={true}
                    value={userDetails.phoneNumber}
                    onChange={(phone) => {
                      const e = {
                        target: {
                          value: phone,
                        },
                      };
                      setUserDetails({ ...userDetails, phoneNumber: phone });
                      handleSetAllChecked("phoneNumber", e);
                    }}
                  />
                </Box>
              </Box>

              <RightLeftContainer>
                <FormNameWrapper
                  sx={{
                    width: "100%",
                  }}
                  size="small"
                >
                  <FormNameLabelWrapper id="companyName">
                    Company name
                  </FormNameLabelWrapper>
                  <FormNameInputWrapper
                    placeholder={"Company name*"}
                    labelid="companyName"
                    id="companyName"
                    label="Company name*"
                    value={userDetails.companyName}
                    onChange={(e) => handleChange(e, "companyName")}
                  />
                </FormNameWrapper>
                <FormNameWrapper
                  sx={{
                    width: "100%",
                    mt: 3,
                  }}
                  size="small"
                >
                  <FormNameLabelWrapper id="title">Title</FormNameLabelWrapper>
                  <FormNameInputWrapper
                    placeholder={"Title"}
                    labelid="title"
                    id="title"
                    label="Title"
                    value={userDetails.title}
                    onChange={(e) => handleChange(e, "title")}
                  />
                </FormNameWrapper>
              </RightLeftContainer>
            </Box>
          </PrimaryContDetailsBox>

          {/* authorized section display none condition false  this data is saved in dummy data file*/}

          {userType !== "Platform" && (
            <PrimaryContDetailsBox p="1.5rem">
              <Heading>Business details</Heading>
              <Box
                display="flex"
                mt="1.5rem"
                width="100%"
                justifyContent="space-between"
              >
                <RightLeftContainer>
                  {billingDetailsArr?.map((detail, index) => {
                    return (
                      <FormNameWrapper
                        size="small"
                        sx={{
                          width: "100%",
                          mt: index > 0 && "1.5rem",
                        }}
                        key={index}
                      >
                        <FormNameLabelWrapper id={detail.id}>
                          {detail.label}
                        </FormNameLabelWrapper>
                        <FormNameInputWrapper
                          placeholder={detail.label}
                          labelid={detail.id}
                          id={detail.id}
                          label={detail.label}
                          value={billingDetails[detail.id]}
                          onChange={(e) =>
                            handleBillingDeatilsChange(e, detail.id)
                          }
                        />
                      </FormNameWrapper>
                    );
                  })}
                </RightLeftContainer>

                <RightLeftContainer>
                  <FormNameWrapper
                    sx={{
                      width: "100%",
                    }}
                    size="small"
                  >
                    <FormNameLabelWrapper id="postalCode">
                      Postal code
                    </FormNameLabelWrapper>
                    <FormNameInputWrapper
                      placeholder="Postal Code"
                      labelid="postalCode"
                      id="postalCode"
                      label="Postal Code"
                      value={billingDetails.postalCode}
                      onChange={(e) =>
                        handleBillingDeatilsChange(e, "postalCode")
                      }
                    />
                  </FormNameWrapper>

                  <CountrySelectWrapper>
                    <ReactFlagsSelect
                      selected={countrySelected}
                      onSelect={(code) => {
                        setCountrySelected(code);
                        setBillingDetails({ ...billingDetails, country: code });
                      }}
                      searchable
                      className="countrySelect"
                    />
                  </CountrySelectWrapper>
                </RightLeftContainer>
              </Box>
            </PrimaryContDetailsBox>
          )}
          {userType !== "Platform" && (
            <PrimaryContDetailsBox p="1.5rem">
              <Heading>Billing contact</Heading>
              <Box display="flex">
                <FormControlLabel
                  sx={{
                    textAlign: "left",
                    color: "#818181",
                    fontSize: "0.8rem",
                    fontWeight: 400,
                  }}
                  control={
                    <Checkbox
                      checked={isBillOwnerChecked}
                      sx={{
                        padding: "0.5rem",
                        color: "#818181",
                        "&.Mui-checked": {
                          color: teal[900],
                        },
                      }}
                      onClick={() => handleAuthSigChange("Billing with owner")}
                    />
                  }
                  label="Same as account owner"
                />
              </Box>
              <BillContactWrapper>
                <RightLeftContainer>
                  {BillContactArr?.map((detail, index) => {
                    return (
                      <FormNameWrapper
                        size="small"
                        sx={{
                          width: "100%",
                          mt: index > 0 && "1.5rem",
                        }}
                        key={index}
                      >
                        <FormNameLabelWrapper id={detail.id}>
                          {detail.label}
                        </FormNameLabelWrapper>
                        <FormNameInputWrapper
                          placeholder={detail.label}
                          labelid={detail.id}
                          id={detail.id}
                          label={detail.label}
                          value={billingContact[detail.id]}
                          onChange={(e) => handleBillingContact(e, detail.id)}
                        />
                      </FormNameWrapper>
                    );
                  })}
                </RightLeftContainer>
                <RightLeftContainer>
                  <Box sx={{ height: 44 }}>
                    <PhoneInputWrapper
                      placeholder={`${Strings.phoneNumber}*`}
                      inputClass="colors"
                      country={"us"}
                      countryCodeEditable={false}
                      enableSearch={true}
                      value={billingContact.phoneNumber}
                      onChange={(phoneNumber) => {
                        setIsBillAuthChecked(false);
                        setIsBillOwnerChecked(false);
                        setBillingContact({ ...billingContact, phoneNumber });
                      }}
                      style={{
                        fontSize: "0.875rem",
                      }}
                    />
                  </Box>
                  <FormNameWrapper
                    size="small"
                    sx={{
                      width: "100%",
                      mt: "1.5rem",
                    }}
                  >
                    <FormNameLabelWrapper id="email">
                      Email
                    </FormNameLabelWrapper>
                    <FormNameInputWrapper
                      placeholder="Email"
                      labelid="email"
                      id="email"
                      label="Email"
                      value={billingContact.email}
                      onChange={(e) => handleBillingContact(e, "email")}
                    />
                  </FormNameWrapper>
                </RightLeftContainer>
              </BillContactWrapper>
            </PrimaryContDetailsBox>
          )}
          <SubmitBtnWrapper>
            <CommonButton
              style={{
                display: "flex",
                alignItems: "center",
              }}
              width={106}
              height={40}
              textStyle={{
                fontSize: "1rem",
                fontWeight: 600,
                fontFamily: "Helvetica",
                letterSpacing: "normal",
              }}
              startIcon={
                <BookmarkBorderOutlinedIcon
                  startIconStyle={{ height: 24, width: 24 }}
                  sx={{ marginRight: "-8px", marginLeft: "4px" }}
                />
              }
              onClick={handleUpdateUser}
              title={Strings.submit}
              color={updateProfileLoading ? color.themeLightGray : "#3843AC"}
              // color={'#3843AC'}
              isLoading={updateProfileLoading}
              disabled={updateProfileLoading}
            ></CommonButton>
          </SubmitBtnWrapper>
        </Wrapper>
      </Box>
    </AppLayout>
  );
};

export default ProfileDetails;
