import React from 'react';

const ConsumerRights = () => {
    return (
        <div>
            <h1 style={{ border: "none" }}>
                <a name="_heading=h.48osm77m91nm" />
                1.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>YOUR RIGHTS AS A
                CALIFORNIA CONSUMER
            </h1>
            <p className="MsoNormal">
                At MadTech we respect your privacy rights as a consumer
                (“Consumer”) under the California Consumer Privacy Act of 2018,
                as revised by the California Privacy Rights Act of 2020, and the
                associated regulations (the “CCPA/CPRA”) and other California
                laws. We are committed to protecting your CCPA/CPRA rights to:{" "}
            </p>
            <ol style={{ "margin-top": "0in" }} start={1} type={1}>
                <li className="MsoNormal" style={{ "margin-top": "12.0pt", "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify", }}>
                    Delete personal information collected from or about them
                    (“Right to Delete”).
                </li>
                <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify", }}>
                    Correct inaccurate Personal Information collected and
                    maintained about them (“Right to Correct”).
                </li>
                <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify", }}>
                    Know what personal information is being collected about them,
                    to access that personal information, and to know what personal
                    information about them is being sold or shared and to whom
                    (together called the “Right to Know”).
                </li>
                <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify", }}>
                    Opt-out of the Sale or sharing of their personal information
                    (“Right to Opt-out”).
                </li>
                <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify", }}>
                    Limit use and disclosure of sensitive personal information to
                    what is necessary to perform the services or provide the goods
                    reasonably expected by an average Consumer who requests such
                    goods or services (“Right to Limit”).
                </li>
                <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify", }}>
                    Not be retaliated against for exercising their rights (“Right
                    to No Discrimination”).
                </li>
            </ol>
        </div>
    );
};

export default ConsumerRights;
