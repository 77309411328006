import React from 'react';

const PolicyHeader = () => {
    return (
        <div>
            <p className="MsoNormal">
                <span style={{ fontSize: "14.0pt", lineHeight: "107%" }}>
                    CCPA/CPRA CONSUMER PRIVACY RIGHTS POLICY
                </span>
            </p>
            <p className="MsoNormal">Dated:&nbsp; February 1, 2023</p>
            <p className="MsoNormal">
                <span className="content-styled-span">
                    Madtech, LLC. ("<b>MadTech</b>") respects your right to
                    privacy.&nbsp; This Privacy Notice explains who we are, how we
                    collect, store, share and use personal data about you, and how
                    you can exercise your privacy rights.&nbsp; This Privacy
                    Notice applies to personal data that we collect, including
                    through our websites at{" "}
                </span>
                <a href="http://www.madtech.io">   <span className="content-Link-span">www.madtech.io</span></a>
                <span className="content-styled-span">{" "}and{" "}</span>
                <a href="http://www.madconnect.io"> <span className="content-Link-span" >www.madconnect.io</span></a>
                <span className="content-styled-span">
                    , within our product(s) and on other websites that MadTech
                    operates and that link to this policy ("collectively{" "}
                    <b>Websites</b>”).{" "}
                </span>
            </p>
            <p className="MsoNormal">
                <span className="content-styled-span">
                    If you have any questions or concerns about our use of your
                    personal data, then please contact us using the contact
                    details provided at the bottom of this Privacy Notice.
                </span>
            </p>
        </div>
    );
};

export default PolicyHeader;
