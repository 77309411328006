import { Box, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";

export const BoxWrapper = styled(Box)({
  position: "absolute !important",
  top: "50% !important",
  left: "50% !important",
  transform: "translate(-50%, -50%) !important",
  borderRadius: "8px 8px 8px 8px !important",
  backgroundColor: "#F8F8FE !important",
  padding: "32px !important",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const LoaderWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginBottom: "100px",
  marginTop: "50px",
  flex: 5.6,
});

export const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  backgroundColor: color.layoutBgColor,
  marginTop: "8px",
});

export const SubWrapper = styled(Box)({
  width: "100%",
  marginRight: "1.5rem",
  marginLeft: "1.5rem",
  flex: 5.4,
});

export const HeadingWrapper = styled(Box)({
  position: "sticky",
  top: -10,
  background: "#FAFCFF",
  width: "100%",
  zIndex: 99,
  paddingTop: "10px",
});

export const HeadingSubWrapper = styled(Box)({
  display: "flex",
  flexDirection: (props) => (props.matchesxss ? "row" : "column"),
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#FAFCFF",
  height: "40px",
});

export const Heading = styled(Typography)({
  fontSize: "1.25rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
  letterSpacing: "0.2px !important",
});

export const NoDataWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "800px",
});

export const NoDataHeading = styled(Typography)({
  marginTop: "32px !important",
  color: "#999999 !important",
  textAlign: "center !important",
  fontSize: "1.125rem !important",
  fontWeight: "400 !important",
  fontFamily: "Helvetica Neue !important",
});

export const ColumnHeading = styled(Typography)(
  ({ isSmallForTableHeader, isMyPaltformForTableHeader, colName }) => ({
    width:
      colName === "Platform"
        ? "20% !important"
        : colName === "Data type"
        ? "25% !important"
        : colName === "Configuration"
        ? "17% !important"
        : colName === "Status"
        ? "15% !important"
        : colName === "Action"
        ? "7% !important"
        : "8% !important",
    position: "relative !important",
    paddingLeft: "0.33rem !important",
    display: "flex",
    alignItems: "center",
    fontFamily: "Helvetica Neue !important",
    fontWeight: "500 !important",
    fontSize: isSmallForTableHeader
      ? isMyPaltformForTableHeader
        ? "7.7px !important"
        : "11px !important"
      : "0.875rem !important",
    color: "#666666 !important",
    justifyContent:
      colName === "Platform"
        ? "start !important"
        : colName === "Source connections" ||
          colName === "Destination connections"
        ? "end !important"
        : (colName === "Status" || colName === "Action") && "center !important",

    textAlign:
      (colName === "Source connections" ||
        colName === "Destination connections") &&
      "end !important",
  })
);

export const CardDetailWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "1rem",
});

export const CardDetailSubWrapper = styled(Box)(
  ({ isMyPaltformForTableHeader }) => ({
    minHeight: isMyPaltformForTableHeader ? "83px" : "80px",
    borderRadius: "8px",
    border: "1px solid rgba(25, 50, 65, 0.15)",
    backgroundColor: "#fff",
    marginBottom: "0.75rem",
    padding: "0.5rem",
    display: "flex",
  })
);

export const PlatformWrapper = styled(Box)({
  width: "20%",
  position: "relative",
  paddingLeft: "0.33rem",
  alignItems: "center",
  display: "flex",
});

export const PlatformSubWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  maxWidth: "80%",
  maxHeight: "80%",
  overflow: "hidden",
  cursor: "pointer",
  gap: "7px",
});

export const PlatformText = styled("span")(
  ({ isMyPaltformForTableHeader }) => ({
    color: "#000000c2",
    fontWeight: 500,
    fontFamily: "Helvetica Neue",
    textTransform: "capitalize",
    cursor: "default",
    fontSize: isMyPaltformForTableHeader ? "12px" : "1rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  })
);

export const DataTypeWrapper = styled(Box)({
  width: "25%",
  position: "relative",
  paddingLeft: "0.33rem",
  alignItems: "center",
});

export const DataTypeSubWrapper = styled(Box)({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  flexDirection: "row",
  height: "100%",
  width: "90%",
});

export const ConfigureWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "17%",
  position: "relative",
  paddingLeft: "0.25rem",
});

export const ConfigureText = styled(Typography)(
  ({ isMyPaltformForTableHeader }) => ({
    fontFamily: "Helvetica Neue !important",
    fontWeight: "400 !important",
    fontSize: isMyPaltformForTableHeader
      ? "10px !important"
      : "1rem !important",
    color: color.buttonColor,
    cursor: "pointer",
  })
);

export const ConnectionsWrapper = styled(Box)({
  width: "8%",
  position: "relative",
  paddingLeft: "0.33rem",
  paddingRight: "0.63rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
});

export const ConnectionsText = styled(Typography)(
  ({ isMyPaltformForTableHeader }) => ({
    fontFamily: "Helvetica Neue !important",
    fontWeight: "500 !important",
    fontSize: isMyPaltformForTableHeader ? "12px !important" : "1rem !important",
    color: "#121212 !important",
  })
);

export const StatusWrapper = styled(Box)({
    width: (props) => (props.width ? props.width : "15%"),
    position: "relative",
    paddingLeft: "0.33rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  export const StatusText = styled(Typography)(
    ({ isMyPaltformForTableHeader }) => ({
      fontFamily: "Helvetica Neue !important",
      fontWeight: "400 !important",
      fontSize: isMyPaltformForTableHeader ? "10px !important" : "1rem !important",
    })
  );