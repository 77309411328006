export const Strings = {
  "email": "Email",
  "password": "Password",
  "login": "Log In",
  "signup": "Sign Up",
  "otp": "Enter the Pin",
  "otpVerification": "2FA verification",
  "submit": "Submit",
  "firstName": "First Name",
  "lastName": "Last Name",
  "workEmai": "Work Email",
  "companyName": "Company Name",
  "title": "Title",
  "phoneNumber": "Phone Number",
  "codeText": "Write above text",
  "securityCheck": "Security Check",
  "terms": "terms & conditions & privacy policy",
  "createPassword": "Create password",
  "newPassword": "New Password",
  "confirmNewPassword": "Confirm Password",
  "continue": "Continue",
  "email_error": "Please enter a valid email address",
  "phone_error": "Please enter a valid phone number",
  "wrong_credentials": "Please enter all required fields",
  "check_aggrement": "Please check Terms & Condition",
  "password_error": "Please enter a valid password ",
  "password_rule": "(At least 8 chars, 1 uppercase, 1 lowercase, 1 special case letter, and a number)",
  "confirm_password_error": "New password and confirm password do not match!",
  "subscriptionPlanHeading": "Create Connections",
  "subscriptionPlanSubtitle": "Select your source and destination connectors from the menu below",
  "select": "Select",
  "contract": "Enter Business Details",
  "contract_details": "Please add your authorized signatory and billing contact to complete sign up",
  "signup_success_msg": "Account created successfully!",
  "otp_success_msg": "Otp verified successfully",
  "createpass_success_msg": "Password set successfully",
  "send_email": "Send Email",
  "next": "Next",
  "back": "Back",
  "connector_error": "Please select a different source and destination",
  "user_type1": "I am a Brand",
  "user_type2": "I am a Publisher",
  "user_type3": "I am a Platform",
  "request_platform": "Request new platform",
  "name": "Name",
  "url": "Url",
  "request": "Request",
  "wrong_input": "Please enter required fields",
  "requested_platform": "Request Platform",
  "platform_details": "Platform Details",
  "document-link": "Enter link",
  "description": "Description",
  "not_found": "No data available!",
  "account_error_message": "Please fill all mandatory fields"
}

export const InfoModalPoints = {
  "Fetch Rewards": [" Locate Connection ID in MadConnect connection view" , "Provide Connection ID to Fetch Account Rep"],
  "The Trade Desk": ["Ensure Segment ID(s) exist in TTD account"],
  "Google DV360": ["Ensure Audience(s) exist in DV360 account"],
  "Meta Ads": ["Ensure Audience(s) exist in Meta account"]
}