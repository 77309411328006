import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../utils/Baseurl'
import { setAllPlatfomrs, setPlatformConfigurations, setSelectedPlatforms, setPlatformByIdConfigurations } from "../features/madTechSlice"

// Define a service using a base URL and expected endpoints
export const platformApi = createApi({
    reducerPath: 'platformApi',
    // baseQuery: fetchBaseQuery({
    //     baseUrl: `${BASE_URL}/platform/`,
    //     prepareHeaders: (headers, { getState }) => {
    //         // const token = (getState() as RootState).auth.token;
    //         const userData = JSON.parse(localStorage.getItem('USER'))
    //         if (userData) {
    //             headers.set('Authorization', `${userData.token}`);
    //         }
    //         return headers;
    //     },
    // }),
    baseQuery: async (args, api, extraOptions) => {
        // Prepare headers
        const headers = new Headers();
        const userData = JSON.parse(localStorage.getItem("USER"));
        if (userData) {
            headers.set("Authorization", `${userData.token}`);
        }

        let accountId;
        const queryParams = new URLSearchParams(window.location.search);
        if (
            queryParams !== null &&
            queryParams !== undefined &&
            queryParams.has("accountId")
        ) {
            accountId = queryParams.get("accountId");
        }
        const urlObj = new URL(`${BASE_URL}/platform/${args.url}`);
        if (accountId != null) {
            urlObj.searchParams.append("accountId", accountId);
        }
        const modifiedUrl = urlObj.toString();

        // Perform the actual query
        const modifiedArgs = {
            ...args,
            url: modifiedUrl,
            headers,
        };
        const result = await fetchBaseQuery()(modifiedArgs, api, extraOptions);
        return result;
    },
    tagTypes: ['Platform'],
    endpoints: (builder) => ({
        fetchPlatforms: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () =>
            ({
                url: `connectors`,
                method: 'GET'
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            providesTags: (result, error) =>
                result
                    ?
                    result.response.platforms.map(item => ({ type: 'Platform', id: item.id }))
                    :
                    [{ type: 'Platform', id: 'LIST' }]
            ,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        fetchPartner: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () =>
            ({
                url: ``,
                method: 'GET'
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            providesTags: (result, error) =>
                result
                    ?
                    result.response.platforms.map(item => ({ type: 'Platform', id: item.id }))
                    :
                    [{ type: 'Platform', id: 'LIST' }]
            ,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        selectPlatform: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (platformBody) =>
            ({
                url: `select`,
                method: 'POST',
                body: platformBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            // providesTags: (result, error) =>
            //     result
            //         ?
            //         result.response.platforms.map(item => ({ type: 'Platform', id: item.id }))
            //         :
            //         [{ type: 'Platform', id: 'LIST' }]
            // ,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        getSelectedPlatforms: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () =>
            ({
                url: `select`,
                method: 'GET'
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            // providesTags: (result, error) =>
            //     result
            //         ?
            //         result.response.platforms.map(item => ({ type: 'Platform', id: item.id }))
            //         :
            //         [{ type: 'Platform', id: 'LIST' }]
            // ,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    if (data.response) {
                        dispatch(setSelectedPlatforms([...data.response.selectedPlatforms, ...data.response.requestedPlatforms.map((ele) => ({ ...ele, logo: undefined }))]))
                    }
                    // for local testing
                    // dispatch(setSelectedPlatforms([
                    //     {
                    //         "categories": [5, 7],
                    //         "id": "a04fb499f1acfa2e",
                    //         "name": "Gravy",
                    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
                    //         "description": "Data input platforms"
                    //     },
                    //     {
                    //         "categories": [12, 18],
                    //         "id": "a224e0c684fd4b3e",
                    //         "name": "Google DV360",
                    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
                    //         "description": "Data input platforms",
                    //         "status": 2
                    //     },
                    //     {
                    //         "categories": [16, 19],
                    //         "id": "3f78a3d42bfa5902",
                    //         "name": "Freewheel",
                    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
                    //         "description": "Data input platforms"
                    //     },
                    //     {
                    //         "categories": [8, 13],
                    //         "id": "24abf7f8fc33403f",
                    //         "name": "Comscore",
                    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
                    //         "description": "Data input platforms"
                    //     },
                    //     {
                    //         description: "Data input platforms",
                    //         id: 18,
                    //         // logo: "http://madconnect.io/Partners/Connector.svg",
                    //         name: "Google",
                    //         url: "https://www.google.com",
                    //     }
                    // ]))

                    // {createdTime: 1679983908,
                    //     id:  22,
                    //     name: "Test",
                    //     url: "https://newTest.com",
                    //     userId : "b481ab12428cab45"}
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        requestPlatform: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (platformBody) =>
            ({
                url: `request`,
                method: 'POST',
                body: platformBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        deleteRequestPlatform: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (platformBody) =>
            ({
                url: `request`,
                method: 'DELETE',
                body: platformBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        getPlatformDetails: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () =>
            ({
                url: `details`,
                method: 'GET',
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
        }),
        platformDetails: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (platformDetailsBody) =>
            ({
                url: `details`,
                method: 'POST',
                body: platformDetailsBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
        }),
        updatePlatformDetails: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (platformDetailsBody) =>
            ({
                url: `details`,
                method: 'PUT',
                body: platformDetailsBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
        }),
        deleteSelectedPlatform: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({ id, dataTypeId }) =>
            ({
                url: `select/${id}/${dataTypeId}`,
                method: 'DELETE'
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        getPlatformConfiguration: builder.query({

            // note: an optional `queryFn` may be used in place of `query`
            query: ({ platformId, dataTypeId }) =>

            ({
                url: `configurations/${platformId}?dataTypeId=${dataTypeId}`,
                method: 'GET',
                headers: { "Content-Type": "application/json" }
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    if (data.statusCode === 200 && data.response) {
                        dispatch(setPlatformConfigurations(data.response?.platformConfigurations))
                    } else {
                        dispatch(setPlatformConfigurations(null))
                    }

                    // for local testing
                    // dispatch(setPlatformConfigurations({
                    //     "destConfigured": true,
                    //     "srcConfigured": false,
                    //     "destPlatformConfigurations": [
                    //         {
                    //             "type": "text",
                    //             "id": "apiKey",
                    //             "name": "Api key",
                    //             "description": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet.",
                    //             "label": "Api key",
                    //             "value": "",
                    //             "required": true,
                    //             "readonly": false
                    //         },
                    //         {
                    //             "type": "text",
                    //             "id": "apiKeynew",
                    //             "name": "Api key desc new",
                    //             "description": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet.",
                    //             "label": "Api key desc new",
                    //             "value": "",
                    //             "required": false,
                    //             "readonly": false
                    //         }
                    //     ],
                    //     "srcPlatformConfigurations": [
                    //         {
                    //             "type": "text",
                    //             "id": "apiKey",
                    //             "name": "Api key",
                    //             "description": "",
                    //             "label": "Api key",
                    //             "value": "dffd",
                    //             "required": true,
                    //             "readonly": false
                    //         },
                    //         {
                    //             "type": "text",
                    //             "id": "apiKeynew",
                    //             "name": "Api key src new",
                    //             "description": "",
                    //             "label": "Api key src new",
                    //             "value": "",
                    //             "required": true,
                    //             "readonly": false
                    //         }
                    //     ]
                    // }))

                    // {
                    //     "response": {
                    //         "destPlatformConfigurations": [
                    //           {
                    //             "description": "string",
                    //             "id": "string",
                    //             "label": "string",
                    //             "name": "string",
                    //             "readonly": true,
                    //             "required": true,
                    //             "type": "string",
                    //             "value": "string"
                    //           }
                    //         ],
                    //         "srcPlatformConfigurations": [
                    //           {
                    //             "description": "string",
                    //             "id": "string",
                    //             "label": "string",
                    //             "name": "string",
                    //             "readonly": true,
                    //             "required": true,
                    //             "type": "string",
                    //             "value": "string"
                    //           }
                    //         ]
                    //       },
                    //     "statusCode": 0,
                    //     "statusMessage": "string"
                    //   }

                    //   [
                    //     {
                    //         "type": "text",
                    //         "id": "oauth",
                    //         "name": "OAuth 2.0",
                    //         "description": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet.",
                    //         "label": "Sign in with Google",
                    //         "value": "",
                    //         "required": true,
                    //         "readonly": false,
                    //         "type": "button"
                    //     },
                    //     {
                    //         "type": "text",
                    //         "id": "apiKey",
                    //         "name": "Api key",
                    //         "description": "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet.",
                    //         "label": "Api key",
                    //         "value": "",
                    //         "required": true,
                    //         "readonly": false
                    //     }
                    // ]

                } catch (err) {
                    // `onError` side-effect
                    console.log('invoice error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
        }),
        getPlatformConfigurationPolling: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({ platformId, dataTypeId }) =>
            ({
                url: `configurations/${platformId}?dataTypeId=${dataTypeId}`,
                method: 'GET',
                headers: { "Content-Type": "application/json" }
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    debugger;
                    const { data } = await queryFulfilled 
                    if (data.statusCode === 200 && data.response) { 
                        dispatch(setPlatformConfigurations(data.response?.platformConfigurations))
                    } else {
                        dispatch(setPlatformConfigurations(null))
                    }

                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        getPlatformByIdConfiguration: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({ platformId, dataTypeId }) =>
            ({
                url: `get/${platformId}?dataTypeId=${dataTypeId}`,
                method: 'GET',
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        savePlatformConfiguration: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({ configBody, platformType }) =>
            ({
                url: `configuration/${platformType}`,
                method: configBody.configurationId === null || configBody.configurationId == "" ? 'POST' : "PUT",
                body: configBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
        }),
        updatePlatformConfiguration: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({ configBody, type }) =>
            ({
                url: `configuration/${type}`,
                method: 'PUT',
                body: configBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
        }),
        addNewConfigration: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: ({ platformId, dataType }) =>
            ({
                url: `configuration/${platformId}/${dataType}`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response,
        }),
        deletePlatformConfiguration: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (platformBody) =>
            ({
                url: `configuration`,
                method: 'DELETE',
                body: platformBody
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,

            async onQueryStarted(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    queryFulfilled,
                    getCacheEntry,
                    updateCachedData,
                }
            ) {
                // const globalState = getState()
                // console.log('queryStart', getCacheEntry());
                try {
                    const { data } = await queryFulfilled
                    // console.log('data.response', data.response);
                    // dispatch(setAllPlatfomrs({ platforms: data.response.platforms, categories: data.response.categories }))
                } catch (err) {
                    // `onError` side-effect
                    console.log('partners error', err);
                    // dispatch(messageCreated('Error fetching posts!'))
                }
            },
            // The 2nd parameter is the destructured `QueryCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                    updateCachedData,
                }
            ) { },
        }),
        getConnectionUrl: builder.mutation({
            query: ({ connectorId, platformName, platformType }) => ({
                url: `connect/url/${connectorId}/${platformName}/${platformType}`,
                method: "GET",
                headers: { "Content-Type": "application/json" },
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response,
        }),
    })
})

export const {
    useFetchPartnerQuery,
    useFetchPlatformsQuery,
    useSelectPlatformMutation,
    useGetSelectedPlatformsQuery,
    useRequestPlatformMutation,
    useDeleteRequestPlatformMutation,
    usePlatformDetailsMutation,
    useDeleteSelectedPlatformMutation,
    useGetPlatformConfigurationQuery,
    useGetPlatformConfigurationPollingQuery,
    useGetPlatformByIdConfigurationMutation,
    useSavePlatformConfigurationMutation,
    useUpdatePlatformConfigurationMutation,
    useGetPlatformDetailsQuery,
    useUpdatePlatformDetailsMutation,
    useAddNewConfigrationMutation,
    useDeletePlatformConfigurationMutation,
    useGetConnectionUrlMutation
} = platformApi