import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { madTechState } from "../../features/madTechSlice";
import { useGetUserQuery } from "../../services/query";

const AuthLayout = ({ children,OAuthType }) => {
  const { isLoggedin } = useSelector(madTechState);
  // const navigate = useNavigate();
  var user = JSON.parse(localStorage.getItem("USER"));
  const { data: userDetails } = useGetUserQuery(
    { userid: user !== undefined && user?.userId },
    { skip: !user }
  );

  useEffect(() => {
    if(!userDetails) return;
    if (isLoggedin === "yes") {
      if (
        userDetails !== undefined &&
        userDetails.response !== null &&
        userDetails.response.stepPending !== undefined &&
        userDetails.response.stepPending !== null &&
        userDetails.response.stepPending !== 0
      ) {
        // navigate('/completeProfile?u=' + user.userId);
        window.location.href = `/completeProfile?u=${userDetails.response.userId}`;
      } else {
        // navigate('/dashboard');
        if (userDetails === undefined && user === undefined) {
          window.location.href = `/dashboard`;
        } else if (
          (userDetails !== undefined &&
            (userDetails.response.userType === "agency" ||
              userDetails.response.userType === "madconnect")) ||
          (user !== undefined &&
            (user?.userType === "agency" || user?.userType === "madconnect" || user?.userType === "platform"))
        ) {
          window.location.href = `/admin/dashboard`;
        } else {
          window.location.href = `/dashboard`;
        }
      }
    }
  }, [isLoggedin]);

  return (
    <div>
      {/* {OAuthType ? "" : <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: (function (w, d, s, l, i) {
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
              j.async = !0;
              j.src =
                "//launch.amplifyreach.com/" +
                s +
                "/" +
                l +
                "/" +
                i +
                ".js?t=" +
                new Date().getTime();
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "2005", "c97f6e41b15f6dd9"),
          }}
        ></script>
      </Helmet>
      } */}
      {children}
    </div>
  );
};

export default AuthLayout;
