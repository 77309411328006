import React from "react";
import { Controller } from "react-hook-form";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export const SDropDown = ({
  control,
  name,
  label,
  list,
  required,
  value,
  borderColor,
  borderRadius,
  marginTop,
  color,
  height
}) => {


  return (
    <Box>
      <Controller
        name={name}
        control={control}
        defaultValue={value ? value : ""} // Set the defaultValue to the initial value of the Select
        render={({ field }) => (
          <FormControl
            sx={{
              width: "100%",
              height: "2.75rem",
              fontSize: "1rem",
              color: "#666666",
              backgroundColor: "#FFFFFF",
              fontFamily: "Helvetica Neue",
              marginTop: marginTop ? marginTop : ''
            }}
            size="small"
          >
            <InputLabel
              sx={{
                fontSize: "0.875rem",
                marginTop: "0.25rem",
                opacity: 0.5,
                backgroundColor: "#FFFFFF",
                fontFamily: "Helvetica Neue",
              }}
              id={`${name}-label`}
            >
              {label}
            </InputLabel>
            <Select
              value={field.value} // Set the value to the field value
              onChange={field.onChange} // Update the field value on change
              id={`${name}-label`}
              labelId={`${name}-label`}
              label={label}
              MenuProps={MenuProps}
              sx={{
                fontSize: "1rem",
                color: "#666666",
                backgroundColor: "#FFFFFF",
                fontFamily: "Helvetica Neue",
                color: color ? color : '',
                height: height ? height : "",
                "& fieldset": {
                  borderColor: borderColor ? borderColor : "",
                },
                borderRadius: borderRadius,
              }}
            >
              {list?.map((item, index) => (
                <MenuItem key={index} value={item?.value}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};
