import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import blackLogo from '../../assets/madconnect-black.svg'
import linkDnLogo from '../../assets/LinkDnLogo.svg'
import { madTechState, setIsPlatformContact } from '../../features/madTechSlice'
import { BenifitSuperContainer, CopyRightContainer, CopyRightText, Divider, HomeFooterSuperContainer, LinkedinIconContainer, NavigationLabel } from './Home.styles'

const navLinks = [
    { label: 'Home', path: '/' },
    { label: 'Connectors', path: '/connector' },
    { label: 'Learn More', path: '/learnmore' },
    { label: 'Contact Us', path: '/contact' },
    { label: 'Privacy Policy', path: '/privacy' }
];

const HomeFooter = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isGetStarted } = useSelector(madTechState)
    
    const matchesxs = useMediaQuery('(min-width:915px)');
    const matchesxss = useMediaQuery('(min-width:600px)');
    const matchesxsss = useMediaQuery('(min-width:710px)');

    const handleNavigation = (path) => {
        if(path === '/contact'){
            if (!isGetStarted) {
                dispatch(setIsPlatformContact(false))
                navigate(path);
                window.scrollTo({ behavior: 'auto', top: 0 });
            }
        }
        if (!isGetStarted) {
            navigate(path);
            window.scrollTo({ behavior: 'auto', top: 0 });
        }
    };

    return (
        <Box width='100%' >
            <HomeFooterSuperContainer>
                {matchesxss && <Divider height={1} />}
                <BenifitSuperContainer justifyContent='space-between' width= '90%' maxWidth= {1350}>
                    <Box width='20%'>
                        <img style={{ width: '80%', height: 35 }} alt={'MADCONNECT FOOTER LOGO'} src={blackLogo}></img>
                    </Box>
                    {matchesxs &&
                        <Box  display= 'flex'  alignItems= 'center' >
                            {navLinks.map((link, index) => (
                                <NavigationLabel
                                    key={index}
                                   ml= {index === 0 ? 0 : 4 }
                                    onClick={() => handleNavigation(link.path)}
                                >
                                    {link.label}
                                </NavigationLabel>
                            ))}
                        </Box>
                    }
                    <LinkedinIconContainer onClick={() => window.open('https://www.linkedin.com/company/madtechadvisors/about/?viewAsMember=true')}>
                        <img style={{ width: 18, height: 18 }} alt={'MADCONNECT FOOTER LOGO'} src={linkDnLogo}></img>
                    </LinkedinIconContainer>
                </BenifitSuperContainer>
                {!matchesxs &&
                    <Box display='flex' mt='1rem' width='90%' justifyContent='space-between'>
                         {navLinks.map((link, index) => (index < 3 && <NavigationLabel
                                    key={index}
                                    onClick={() => handleNavigation(link.path)}
                                >
                                    {link.label}
                                </NavigationLabel>
                            ))
                          }
                        {matchesxsss && <NavigationLabel onClick={() => handleNavigation('/contact')}>
                                    Contact Us
                                </NavigationLabel>
                            }
                        {matchesxsss && <NavigationLabel onClick={() => handleNavigation('/privacy')}>
                                    Privacy Policy
                                </NavigationLabel>
                        }
                    </Box>}
                {!matchesxs && !matchesxsss &&
                    <Box display='flex' mt='1rem' width='90%' justifyContent='space-between' >
                                <NavigationLabel onClick={() => handleNavigation('/contact')}>
                                    Contact Us
                                </NavigationLabel>
                                <NavigationLabel onClick={() => handleNavigation('/privacy')}>
                                    Privacy Policy
                                </NavigationLabel>
                    </Box>
                }
            </HomeFooterSuperContainer>
            <CopyRightContainer>
                <CopyRightText>
                    @2023 MadTech Advisors, LLC.  All rights reserved.
                </CopyRightText>
            </CopyRightContainer>
        </Box>

    )
}

export default HomeFooter