import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import CommonButton from "../CommonButton/CommonButton";
import { Strings } from "../../utils/Strings";
import { color } from "../../utils/Colors";
import ModalAlertIcons from "../../assets/ModalAlertIcons.svg";
// import akamaiImg from '../../assets/Akamai.png';
// import astraImg from '../../assets/Astra.png';
// import awsImg from '../../assets/AWS.png';
// import comscoreImg from '../../assets/Comscore.png';
// import doubleVerifyImg from '../../assets/DoubleVerify.png';
// import connectorImg from '../../assets/Connector.svg';
import connectorImg1 from "../../assets/Connector.svg";
import lineImg from "../../assets/Line_18.svg";
import {
  useAddConnectionsMutation,
  useDeleteConnectionMutation,
  useGetConfigurationPlatformMutation,
  useGetConnectionsQuery,
  useUpdateConnectionsStatusMutation,
} from "../../services/connectionsQuery";
import { madTechState } from "../../features/madTechSlice";
import { useGetSelectedPlatformsQuery } from "../../services/platformQuery";
import trash from "../../assets/trash-2.svg";
import cancel from "../../assets/cancel.svg";
import ConnectionLists from "./ConnectionLists";
import { useMyContext } from "../CommanToasterContext/toast";


function Plans({ setStep, connectors, setConnectors }) {
  const navigate = useNavigate();
  const { selectedAccount, allSelectedPlatforms } = useSelector(madTechState);
  const [source, setSource] = useState(null);
  const { setOpen2, setMessage2 } = useMyContext();
  // { value: 'Astra', label: 'Astra', img: 'http://madconnect.io/Partners/Adstra.svg', id: 'ca62b6eb444ccbf5' }
  const [destination, setDestination] = useState(null);
  // { value: 'Akamai', label: 'Akamai', img: 'http://madconnect.io/Partners/Akamai.svg', id: '4b218c9cc1a3c471' }
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const [selectedSources, setSelectedSources] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");
  const [inputText, setInputText] = useState("");
  const [sourceConfiguration, setSourceConfiguration] = useState([]);
  const [destinationConfiguration, setDestinationConfiguration] = useState([]);
  const [allConnectionData, setAllConnectionData] = useState([])

  // const connectionData = {
  //   "statusCode": 200,
  //   "statusMessage": "Connection information get successfully",
  //   "response": [
  //     {
  //       "id": 222,
  //       "conId": "6e45708ccb1e0641",
  //       "name": "Yahoo Connection",
  //       "src": {
  //         "id": "65j8b5tt6rtki3a4",
  //         "dataType": "reporting",
  //         "configurationName": "",
  //         "name": "Yahoo",
  //         "logo": "platforms/Yahoo.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "Wjmb8q8q9MCnI6UM",
  //         "dataType": "",
  //         "configurationName": "",
  //         "name": "Amazon S3",
  //         "logo": "platforms/s3.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714969516,
  //       "updatedTime": 1714969516,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 221,
  //       "conId": "69f4fc6484643bfb",
  //       "name": "s3-admanager",
  //       "src": {
  //         "id": "b8f92b3360f60ea3",
  //         "dataType": "reporting",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "Wjmb8q8q9MCnI6UM",
  //         "dataType": "",
  //         "configurationName": "",
  //         "name": "Amazon S3",
  //         "logo": "platforms/s3.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714804653,
  //       "updatedTime": 1714804653,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 218,
  //       "conId": "51304e4aace4405a",
  //       "name": "Admanager connection",
  //       "src": {
  //         "id": "b8f92b3360f60ea3",
  //         "dataType": "reporting",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "17cb9338984dd58d",
  //         "dataType": "conversions",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714731086,
  //       "updatedTime": 1714731086,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 217,
  //       "conId": "d4e2db318b4f1960",
  //       "name": "marketing-yahoo-s3-789",
  //       "src": {
  //         "id": "65j8b5tt6rtki3a4",
  //         "dataType": "reporting",
  //         "configurationName": "",
  //         "name": "Yahoo",
  //         "logo": "platforms/Yahoo.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "b49afb32f7352822",
  //         "dataType": "audiences",
  //         "configurationName": "",
  //         "name": "Lotame",
  //         "logo": "platforms/Lotame.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714729961,
  //       "updatedTime": 1714729961,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 216,
  //       "conId": "8e0674e39b29b32b",
  //       "name": "marketing-yahoo-s3-134",
  //       "src": {
  //         "id": "65j8b5tt6rtki3a4",
  //         "dataType": "reporting",
  //         "configurationName": "",
  //         "name": "Yahoo",
  //         "logo": "platforms/Yahoo.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "69b7318a943118d3",
  //         "dataType": "1P audience activation",
  //         "configurationName": "",
  //         "name": "The Trade Desk",
  //         "logo": "platforms/TheTradeDesk.svg",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714726819,
  //       "updatedTime": 1714726819,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 215,
  //       "conId": "7cefe0476024e84b",
  //       "name": "marketing-yahoo-s3-1",
  //       "src": {
  //         "id": "ic1nosf03ic4st9o",
  //         "dataType": "Events",
  //         "configurationName": "",
  //         "name": "ActionIQ",
  //         "logo": "platforms/ActionIQ.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "69b7318a943118d3",
  //         "dataType": "1P audience activation",
  //         "configurationName": "",
  //         "name": "The Trade Desk",
  //         "logo": "platforms/TheTradeDesk.svg",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714726777,
  //       "updatedTime": 1714726777,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 214,
  //       "conId": "55361e4dde884018",
  //       "name": "Yahoo Default Name",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "dataType": "Insights",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "6b86e12b4f48a69b",
  //         "dataType": "audiences",
  //         "configurationName": "",
  //         "name": "Tik Tok",
  //         "logo": "platforms/TikTok.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714725926,
  //       "updatedTime": 1714725926,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 213,
  //       "conId": "e1ae66c0fad4c78a",
  //       "name": "Yahoo Default Name",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "dataType": "Insights",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "6b86e12b4f48a69b",
  //         "dataType": "audiences",
  //         "configurationName": "",
  //         "name": "Tik Tok",
  //         "logo": "platforms/TikTok.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714725647,
  //       "updatedTime": 1714725647,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 211,
  //       "conId": "494df624b4ecd886",
  //       "name": "yahoo connection",
  //       "src": {
  //         "id": "65j8b5tt6rtki3a4",
  //         "dataType": "reporting",
  //         "configurationName": "",
  //         "name": "Yahoo",
  //         "logo": "platforms/Yahoo.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "17cb9338984dd58d",
  //         "dataType": "conversions",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1714645648,
  //       "updatedTime": 1714645648,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 206,
  //       "conId": "f56ef70d7e495a9f",
  //       "name": "marketing-yahoo-s3-2",
  //       "src": {
  //         "id": "65j8b5tt6rtki3a4",
  //         "dataType": "reporting",
  //         "configurationName": "",
  //         "name": "Yahoo",
  //         "logo": "platforms/Yahoo.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "17cb9338984dd58d",
  //         "dataType": "conversions",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1713939996,
  //       "updatedTime": 1713939996,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 145,
  //       "conId": "5935af51d740e5b3",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "ic1nosf03ic4st9o",
  //         "configurationName": "",
  //         "name": "ActionIQ",
  //         "logo": "platforms/ActionIQ.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709290458,
  //       "updatedTime": 1709290458,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 144,
  //       "conId": "1148cea01fb30050",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "b8f92b3360f60ea3",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709290436,
  //       "updatedTime": 1709290436,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 143,
  //       "conId": "5e2f74b1df497f9d",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "b8f92b3360f60ea3",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709290418,
  //       "updatedTime": 1709290418,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 141,
  //       "conId": "9168de2436aaaba6",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "b8f92b3360f60ea3",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709288214,
  //       "updatedTime": 1709288214,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 140,
  //       "conId": "4521a1a49e9186e0",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "b8f92b3360f60ea3",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709288100,
  //       "updatedTime": 1709288100,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 130,
  //       "conId": "408493e8ea1de3b4",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "b8f92b3360f60ea3",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709273317,
  //       "updatedTime": 1709273317,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 129,
  //       "conId": "ddaa247068bb0215",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "ic1nosf03ic4st9o",
  //         "configurationName": "",
  //         "name": "ActionIQ",
  //         "logo": "platforms/ActionIQ.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709272555,
  //       "updatedTime": 1709272555,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 128,
  //       "conId": "3f29435f9d450266",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "ic1nosf03ic4st9o",
  //         "configurationName": "",
  //         "name": "ActionIQ",
  //         "logo": "platforms/ActionIQ.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709272383,
  //       "updatedTime": 1709272383,
  //       "enabled": true,
  //       "deleted": false
  //     },
  //     {
  //       "id": 127,
  //       "conId": "b4375a9cfc63a6d8",
  //       "src": {
  //         "id": "17cb9338984dd58d",
  //         "configurationName": "",
  //         "name": "Meta Ads",
  //         "logo": "platforms/Meta.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "dest": {
  //         "id": "b8f92b3360f60ea3",
  //         "configurationName": "",
  //         "name": "Google Ads Manager",
  //         "logo": "platforms/GoogleAdManager.png",
  //         "description": "Data input platforms",
  //         "configured": false
  //       },
  //       "status": "In-progress",
  //       "usedVolume": 0,
  //       "createdTime": 1709272285,
  //       "updatedTime": 1709272285,
  //       "enabled": true,
  //       "deleted": false
  //     }
  //   ]
  // }
  const {
    data: connectionData,
    isLoading: connectionLoading,
    error: connectionError,
    refetch,
  } = useGetConnectionsQuery();

  useEffect(() => {
    refetch()
  }, [])

  const [filteredConnectors, setFilteredConnectors] = useState(null);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isForDisable, setIsForDisable] = useState(false);
  const [connectionErrorMsg, setConnectionErrorMsg] = useState("");
  const [deleteIds, setDeleteIds] = useState([]);
  const [EditIds, setEditIds] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const filteredItems = allConnectionData?.filter(item => {
      return (
        item?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
        item?.src?.dataType?.toLowerCase().includes(inputValue?.toLowerCase()) ||
        item?.src?.name?.toLowerCase().includes(inputValue?.toLowerCase()) ||
        item?.dest?.name?.toLowerCase().includes(inputValue?.toLowerCase())
      );
    });

    if (filteredItems?.length === 0 || inputValue === "") {
      setFilteredConnectors(null);
    } else {
      setFilteredConnectors(filteredItems);
    }
  }, [inputValue]);

  useEffect(() => {
    setConnectors(filteredConnectors !== null ? filteredConnectors : allConnectionData)
  }, [filteredConnectors])

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleDeleteClose = () => {
    setSelectedItem(null);
    setDeleteIds([]);
    setIsDeleteOpen(false);
  };
  useEffect(() => {
    if (!isDeleteOpen) {
      setSelectedItem(null);
      setIsForDisable(false);
    }
  }, [isDeleteOpen]);

  const handleDeleteOpen = () => setIsDeleteOpen(true);
  

  const isSmallForTableHeader = useMediaQuery('(max-width:1390px)');


  const style = useMemo(
    () => ({
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px 8px 8px 8px",
      bgcolor: "#F8F8FE",
      width: "31.25rem",
      boxShadow: 24,
      p: 4,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&:focus": {
        outline: "none",
      },
    }),
    []
  );

  const addDuration = (id, timeDuration) => {
    let newConnectors = [...connectors];
    newConnectors[id].duration = timeDuration;
    setConnectors(newConnectors ? newConnectors : []);
  };

  useEffect(() => {
    if (inputValue === "" && allSelectedPlatforms) {
      setOptions(
        allSelectedPlatforms.map((item) => ({
          value: item?.name,
          label: item?.name,
          img: item?.logo,
          id: item?.id,
          description: item?.description,
          dataTypes: item?.dataTypes,
          dataTypeId: item?.dataTypeId
        }))
      );
    }
    if (inputValue2 === "" && allSelectedPlatforms) {
      setOptions2(
        allSelectedPlatforms.map((item) => ({
          value: item?.name,
          label: item?.name,
          img: item?.logo,
          id: item?.id,
          description: item?.description,
          dataTypes: item?.dataTypes,
          dataTypeId: item?.dataTypeId

        }))
      );
    }
  }, [inputValue, inputValue2, allSelectedPlatforms]);


  const { data: partnerData, error: partnerError } =
    useGetSelectedPlatformsQuery();
  const [
    addConnections,
    { data: addConnectionData, error: addConnectionError },
  ] = useAddConnectionsMutation();
  const [
    deleteConnection,
    {
      data: deleteConnectionData,
      isLoading: deleteConnectionLoading,
      error: deleteConnectionError,
    },
  ] = useDeleteConnectionMutation();

  const [
    updateConnectionStatus,
    {
      data: updateConnectionStatusData,
      isLoading: updateConnectionStatusLoading,
      error: updateConnectionStatusError,
    },
  ] = useUpdateConnectionsStatusMutation();


  useEffect(() => {
    refetch();
  }, [selectedAccount]);

  useEffect(() => {
    if (connectionData !== undefined && connectionData.response) {
      const allConnections = connectionData.response.map((item) => {

        return {
          id: item?.id,
          src: item.src,
          dest: item.dest,
          usedVolume: item.usedVolume,
          enabled: item.enabled,
          status: item.status,
          deleted: item.deleted,
          conId: item.conId,
          name: item.name
        };
      });
      const connectionDummyData = []
      const updatedConnections = allConnections.concat(connectionDummyData);
      setConnectors(updatedConnections);
      setAllConnectionData(updatedConnections)
    }

    if (connectionError !== undefined) {
      console.log("connectionError", connectionError);
      if (connectionError.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [connectionData, connectionError]);

 

  useEffect(() => {
    if (partnerData !== undefined) {
      if (partnerData.response) {
        setOptions(
          partnerData.response.selectedPlatforms.map((item) => ({
            value: item?.name,
            label: item?.name,
            img: item?.logo,
            id: item?.id,
            description: item?.description,
            dataTypes: item?.dataTypes,
          }))
        );
        setOptions2(
          partnerData.response.selectedPlatforms.map((item) => ({
            value: item?.name,
            label: item?.name,
            img: item?.logo,
            id: item?.id,
            description: item?.description,
            dataTypes: item?.dataTypes,
          }))
        );
      }
    }
    if (partnerError !== undefined) {
      console.log("partnerError", partnerError);
      if (partnerError.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [partnerData, partnerError]);

  useEffect(() => {
    if (addConnectionData !== undefined) {
      if (addConnectionData.statusCode === 500) {
        setConnectionErrorMsg(addConnectionData.statusMessage);
      } else if (addConnectionData.response) {
        refetch();
        handleOpen();
        setSource(null);
        setDestination(null);
      }
    }

    if (addConnectionError !== undefined) {
      console.log("add Connection Error", addConnectionError);
    }
  }, [addConnectionData, addConnectionError]);

 
  useEffect(() => {
    if (EditIds.length > 0) {
      navigate(selectedAccount !== null && selectedAccount !== undefined
        ? `/create-connections?accountId=${selectedAccount.id}&connectionId=${EditIds[0]}`
        : `/create-connections?connectionId=${EditIds[0]}`)
    }
  }, [EditIds])


  useEffect(() => {
    if (deleteIds.length > 0) {
      handleDeleteOpen();
    }
  }, [deleteIds]);

  const deleteConnector = useCallback(() => {
    deleteConnection({
      id: deleteIds[0],
    });
  }, [useDeleteConnectionMutation, connectors, deleteIds]);

  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

  useEffect(() => {
    if (deleteConnectionData !== undefined) {
      if (deleteConnectionData.statusCode === 200) {
        refetch();
        handleDeleteClose();
        let newConnectors = [...connectors];
        newConnectors.splice(deleteIds[1], 1);
        setConnectors(newConnectors ? newConnectors : []);
        setSelectedItem(null);
        if (setStep === undefined || setStep === "undefined") {
          setIsDeleteSuccess(true);
        }
      }
      // handleDeleteClose()
      // setIsDeleteSuccess(true)
    }

    if (deleteConnectionError !== undefined) {
      console.log("deleteConnectionError", deleteConnectionError);
      setMessage2(deleteConnectionError.data.error)
      setOpen2(true)
    }
  }, [deleteConnectionData, deleteConnectionError]);

  const [
    getConfigurations,
    {
      data: connectionConfigurations,
      error: configurationsError,
      refetch: configurationsFetch,
    },
  ] = useGetConfigurationPlatformMutation();

  const [fromWhere, setFromWhere] = useState("");

  useEffect(() => {
    if (source) {
      getConfigurations({
        platformId: source?.id,
        platformType: "SOURCE",
        dataType: source?.dataType,
      });
      setFromWhere("src");
    }
  }, [useGetConfigurationPlatformMutation, source]);

  useEffect(() => {
    if (connectionConfigurations?.response) {
      if (connectionConfigurations?.statusCode === 200) {
        if (fromWhere === "src") {
          const response = connectionConfigurations?.response;
          setSourceConfiguration(response);

          // Set the default selected source if the response has data
          if (response?.length > 0) {
            setSelectedSources(response[0].id.toString());
          }
        } else if (fromWhere === "dest") {
          const response = connectionConfigurations?.response;
          setDestinationConfiguration(response);

          // Set the default selected destination if the response has data
          if (response?.length > 0) {
            setSelectedDestination(response[0].id.toString());
          }
        }
      }
    }
    ;
  }, [
    connectionConfigurations,
    configurationsError,
    fromWhere,
    setSelectedSources,
  ]);

  useEffect(() => {
    if (destination) {
      getConfigurations({
        platformId: destination?.id,
        platformType: "DESTINATION",
        dataType: destination?.dataType,
      });
      setFromWhere("dest");
    }
  }, [useGetConfigurationPlatformMutation, destination]);


  useEffect(() => {
    setInputText("");
  }, [isDeleteOpen]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };
  useEffect(() => {
    if (inputText === "DELETE" || isForDisable) {
      setIsDeleteButtonEnabled(true);
    } else {
      setIsDeleteButtonEnabled(false);
    }
  }, [inputText, isForDisable]);

  useEffect(() => {
    if (updateConnectionStatusData !== undefined) {
      refetch();
      handleDeleteClose();
    }

    if (updateConnectionStatusError !== undefined) {
      console.log("updateConnectionStatusError", updateConnectionStatusError);
    }
  }, [updateConnectionStatusData, updateConnectionStatusError]);

  const _handleUpdateConnectionStatus = useCallback(() => {
    updateConnectionStatus({
      id: selectedItem.id,
      enabled: !selectedItem.enabled,
    });
  }, [useUpdateConnectionsStatusMutation, selectedItem, connectors]);

  return (
    <Box
      sx={{
        maxWidth: setStep === undefined ? "100%" : 1500,
        margin: "auto",
        backgroundColor: "#fff",
      }}
    >
      {setStep !== undefined && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
            width: "100%",
            alignItems: "center",
            // border: '1px solid black'
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "46%", mt: "1rem" }}>
              <p
                className="text-center"
                style={{
                  fontSize: "2rem",
                  fontWeight: 700,
                  fontFamily: "Helvetica Neue",
                }}
              >
                {Strings.subscriptionPlanHeading}
              </p>
              <p
                className="text-center"
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  color: "#999999",
                  fontFamily: "Helvetica Neue",
                }}
              >
                {Strings.subscriptionPlanSubtitle}
              </p>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          background: setStep !== undefined ? "#fff" : color.layoutBgColor,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
            alignItems: "center",
            width: setStep !== undefined ? "85%" : "100%",
            mx: "auto",
            justifyContent: "center",
            boxShadow:
              setStep !== undefined
                ? "none"
                : "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
            px: setStep !== undefined ? 0 : 2,
            height: "100%"
          }}
        >

        </Box>
        <ConnectionLists {...{ isLoading: connectionLoading, EditIds, refetch, setEditIds, connectors, setStep, isSmallForTableHeader, deleteConnector, addDuration, handleDeleteOpen, setDeleteIds, setIsForDisable, setSelectedItem, navigate, selectedAccount, inputValue, setInputValue }} />
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <Box sx={{ width: 68, height: 68, mb: 3 }}>
              <img
                src={ModalAlertIcons}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Box>
            <Typography
              style={{
                fontWeight: 700,
                fontFamily: "Helvetica Neue",
                fontSize: "1.25rem",
                textAlign: "center",
                color: "#25262D",
              }}
            >
              Connection created!
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontFamily: "Helvetica Neue",
                fontSize: "0.875rem",
                textAlign: "center",
                color: "#656C7B",
                width: "70%",
                mt: 1,
              }}
            >
              A customer success manager will get in touch with you to activate
              your connection.
            </Typography>
            <Box sx={{ mt: 3 }}>
              <CommonButton
                width={"4.375rem"}
                height={"2.25rem"}
                textStyle={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  fontFamily: "Helvetica Neue",
                }}
                onClick={() => handleClose()}
                title={"Okay"}
                color={color.buttonColor}
              ></CommonButton>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isDeleteOpen}
        onClose={handleDeleteClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isDeleteOpen}>
          <Box sx={{ ...style, pl: 5, pr: 5, width: 600 }}>
            <Typography
              style={{
                fontWeight: 700,
                fontFamily: "Helvetica Neue",
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              {selectedItem !== null && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      mb: 2,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          p: 1,
                          width: "35%",
                          height: 60,
                          borderRadius: 1,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "2.5rem",
                            borderRadius: 1,
                            p: 0.4,
                          }}
                        >
                          <img
                            alt=""
                            src={selectedItem?.src.logo}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          ></img>
                        </Box>
                      </Box>
                      <div
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          height: 0,
                          width: 72,
                          borderTop: "2px solid rgba(25, 50, 65, 0.5)",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: 56,
                          height: 56,
                          borderRadius: 8,
                          backgroundColor: "#EDEFF0",
                        }}
                      >
                        <img
                          alt=""
                          src={connectorImg1}
                          style={{ width: 24, height: 24, color: "#193241" }}
                        ></img>
                      </Box>
                      <img
                        src={lineImg}
                        style={{ width: 55, marginRight: 5, marginLeft: 5 }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          p: 1,
                          width: "35%",
                          height: 60,
                          borderRadius: 1,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "2.5rem",
                            borderRadius: 1,
                            p: 0.4,
                          }}
                        >
                          <img
                            alt=""
                            src={selectedItem?.dest.logo}
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          ></img>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Typography>

            <Typography
              style={{
                fontWeight: 400,
                fontFamily: "Helvetica Neue",
                fontSize: "1rem",
                textAlign: "center",
                color: "#656C7B",
                width: "70%",
                marginBottom: 16,
              }}
            >
              Are you sure you want{" "}
              {isForDisable
                ? `${!selectedItem?.enabled ? "enable" : "disable"}`
                : `delete`}{" "}
              this connection?
            </Typography>
            {!isForDisable && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <FormControl
                  sx={{
                    width: "70%",
                    height: "2.75rem",
                    fontSize: "1rem",
                    color: "#666666",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Helvetica Neue",
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                  size="small"
                >
                  <InputLabel
                    sx={{
                      fontSize: "0.875rem",
                      marginTop: "0.25rem",
                      opacity: 0.5,
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    id="delete"
                  >
                    Type DELETE to confirm
                  </InputLabel>
                  <OutlinedInput
                    placeholder={""}
                    sx={{
                      width: "100%",
                      height: "2.75rem",
                      fontSize: "1rem",
                      color: "#666666",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "Helvetica Neue",
                    }}
                    labelid="delete"
                    id="delete"
                    label="Type DELETE to confirm"
                    onChange={handleInputChange}
                    value={inputText}
                    autoComplete="off"
                  />
                </FormControl>
              </Box>
            )}
            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button
                onClick={(event) => {
                  if (isForDisable) {
                    _handleUpdateConnectionStatus(event);
                  } else {
                    deleteConnector();
                  }
                }}
                disabled={!isDeleteButtonEnabled}
                style={{
                  cursor: "pointer",
                  backgroundColor: isForDisable
                    ? color.buttonColor
                    : color.whiteColor,
                  width: "6.625rem",
                  height: "2.5rem",
                  color: isForDisable ? color.whiteColor : color.themeRed,
                  textTransform: "none",
                  border: "1.5px solid",
                  borderColor: isForDisable
                    ? color.buttonColor
                    : color.themeRed,
                  cursor: isDeleteButtonEnabled ? "pointer" : "not-allowed",
                }}
                variant="contained"
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  {!isForDisable && (
                    <img
                      alt="trash"
                      src={trash}
                      style={{
                        width: "1.3rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></img>
                  )}

                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica",
                      display: "flex",
                      alignItems: "center",
                      // marginTop: 2,
                    }}
                  >
                    {isForDisable ? "Yes" : "Delete"}
                  </Typography>
                </Box>
              </Button>
              <Button
                onClick={handleDeleteClose}
                isLoading={deleteConnectionLoading}
                disabled={deleteConnectionLoading}
                style={{
                  cursor: "pointer",
                  // border: `1px solid ${color.themeDarkGray}`,
                  backgroundColor: color.whiteColor,
                  width: "6.625rem",
                  height: "2.5rem",
                  color: color.themeBlack,
                  textTransform: "none",
                }}
                variant="contained"
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  {!isForDisable && (
                    <img
                      alt=""
                      src={cancel}
                      style={{
                        width: "0.8rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></img>
                  )}
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica",
                      display: "flex",
                      alignItems: "center",
                      // marginTop: 2,
                    }}
                  >
                    {isForDisable ? "No" : "Cancel"}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isDeleteSuccess}
        onClose={() => setIsDeleteSuccess(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isDeleteSuccess}>
          <Box sx={{ ...style, height: "12.5rem" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontFamily: "Helvetica Neue",
                fontSize: "1rem",
                textAlign: "center",
                color: "#656C7B",
                width: "70%",
              }}
            >
              Your connection is marked as deleted. It will take up to 2 working
              days to reflect the changes.
            </Typography>
            <Box sx={{ mt: 3 }}>
              <CommonButton
                width={"4.375rem"}
                height={"2.25rem"}
                textStyle={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  fontFamily: "Helvetica Neue",
                }}
                onClick={() => setIsDeleteSuccess(false)}
                title={"Okay"}
                color={color.buttonColor}
              ></CommonButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Plans;



