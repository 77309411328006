import { Box, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'


import NaLayout from '../../components/NavLayout/NaLayout'
import { setImageLoaded } from '../../features/madTechSlice'
import useDocumentTitle from '../../hook/useDocumentTitle'
import { color } from '../../utils/Colors'
import HomeFooter from '../Home/HomeFooter'
import LetsConnect from '../Home/LetsConnect'
import pdfWhiteImg from '../../assets/pdf_white.svg'
import { HOST } from '../../utils/Baseurl'
import {
    DownloadBtn,
    DownloadBtnWrapper,
    Heading,
    HeadingWrapper,
    LastUpdatedAt,
  } from "./TermsCondition.styles";

const Addendum = () => {

    useDocumentTitle('Madconnect Data Processing Addendum')
    const matcheslg = useMediaQuery('(min-width:1100px)');
    const matchesmd = useMediaQuery('(min-width:980px)');
    const matchessm = useMediaQuery('(min-width:880px)');
    const matchesxs = useMediaQuery('(min-width:760px)');
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setImageLoaded(true))
    }, [])

    return (
        <NaLayout>
            <Box sx={{ mx: 'auto' }}>
                <HeadingWrapper>
          <Box sx={{ width: "91%", maxWidth: 1350 }}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Heading
                fontSize={
                  matcheslg
                    ? "2.5rem"
                    : matchesmd
                    ? "2.3rem"
                    : matchessm
                    ? "2rem"
                    : matchesxs
                    ? "1.8rem"
                    : "1.6rem"
                }
              >
                MADCONNECT DATA PROCESSING ADDENDUM
              </Heading>
              <DownloadBtnWrapper>
              <a style={{ color: color.whiteColor, textDecoration: "none" }} download={"MadConnect_data_processing_addendum_02272024.pdf"} target="_blank" href={`${HOST}/platforms/MadConnect_data_processing_addendum_02272024.pdf`}>
                  <Box
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                    }}
                  >
                    <Typography>
                      <img
                        src={pdfWhiteImg}
                        style={{ maxWidth: "24px", maxHeight: "24px" }}
                      />
                    </Typography>
                    <DownloadBtn>DOWNLOAD</DownloadBtn>
                  </Box>
                </a>
              </DownloadBtnWrapper>
            </Box>
            <LastUpdatedAt
              fontSize={
                matcheslg
                  ? "1rem"
                  : matchesmd
                  ? "0.9rem"
                  : matchessm
                  ? "0.8rem"
                  : matchesxs
                  ? "0.7rem"
                  : "0.7rem"
              }
            >
              Last Updated: 27 February, 2024
            </LastUpdatedAt>
          </Box>
        </HeadingWrapper>
                <Box sx={{
                    py: 10, display: 'flex', justifyContent: 'center',
                    alignItems: 'center', backgroundColor: '#eeeeee'
                }}>
                    <Box sx={{ width: '91%', maxWidth: 1350, backgroundColor: '#fff', p: 3, borderRadius: 2, border: '1px solid rgba(54, 70, 172, 0.24)' }}>
                        <div>
                            <meta httpequiv="Content-Type" content="text/html; charset=utf-8" />
                            <meta name="Generator" content="Microsoft Word 15 (filtered)" />
                            <style dangerouslySetInnerHTML={{ __html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n@font-face\n\t{font-family:\"Noto Sans Symbols\";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:170%;}\nh1\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpFirst\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpMiddle\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpLast\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:;\n\tfont-family:\"Arial\",sans-serif;}\nspan.Heading1Char\n\t{mso-style-name:\"Heading 1 Char\";\n\tmso-style-link:\"Heading 1\";\n\tfont-family:\"Arial\",sans-serif;\n\tcolor:windowtext;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-family:\"Arial\",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:6.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:.5in 1.0in 1.25in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in; padding-left: 1rem;}\nul\n\t{margin-bottom:0in;}\n-->\n" }} />
                            <div className="WordSection1">
                                <p className="MsoNormal">
                                    <a name="_9kR3WTr12346FhdwfvyxwkuZUv">This Data Processing
                                        Addendum (“<b>DPA</b>”) supplements and forms a part of the MadConnect Platform
                                        License Agreement between MadTech, LLC ("<b>MadTech</b>") and the
                                        Client named therein, as updated from time to time, governing the use of the MadConnect
                                        Platform (the “<b>License Agreement</b>”). Unless otherwise defined in this DPA
                                        or in the License Agreement, all capitalized terms used in this DPA will have
                                        the meanings given to them in Section 1 of this DPA.
                                    </a>
                                </p>
                                <p className="MsoNormal">
                                    <b><span style={{ color: "black" }}>&nbsp;</span></b>
                                </p>
                                <p className="Legal2L1">
                                    <span style={{ textTransform: "uppercase", fontWeight: 600 }}>1.<span style={{ font: " 'Times New Roman'", fontWeight: 600 }}>&nbsp;&nbsp;&nbsp;</span></span>Definitions
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kR3WTr18947CNdrq">1.1<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>CCPA</b></a> means the <a name="_9kR3WTr3CB47DO1hqls55xl">California</a> Consumer <a name="_9kR3WTr27747EcVv0tb0Z8yByDMMV">Privacy Act of 2018</a>, as amended by the California Privacy Rights Act of 2020 (the “<b>CPRA</b>”), and any regulations promulgated thereunder, in each case, as amended from time to time.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    1.2<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Customer Personal Data </b>means Personal Data in any form made available by Client  to MadTech in connection with the use of the MadConnect Platform.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    1.3<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Data Protection Laws </b>means, as applicable in connection with the use of the MadConnect Platform, the laws and regulations of any relevant jurisdiction related to the confidentiality, privacy, security or Processing of Personal Data, including the CCPA, <span lang="EN">Connecticut Data Privacy Act (“<b>CTDPA</b>”), Virginia Consumer Data Protection Act (“<b>VCDPA</b>”), the Colorado Privacy Act (“<b>CPA</b>”), and the Utah Consumer Privacy Act (“<b>UCPA</b>”)</span>.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    1.4<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Data Subject Request</b> means a request or complaint from an individual in relation to the individual’s Customer Personal Data, including any request to exercise such individual’s rights under Data Protection Laws (e.g., to access, correct, delete, opt-out of the sale of Personal Data).
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kR3WTr18948CSKoq32mu3z5kWn4K9CTtd402zA">1.5<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Information Security Incident</b></a> means any actual or reasonably suspected accidental, unauthorized or unlawful destruction, encryption, loss, use, alteration, unavailability, disclosure of, or <a name="_9kMIH5YVt3AB69H94bMvA84rq">access to, or any other misuse of, Customer Personal Data</a> while in MadTech’s care, custody or control.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kR3WTr24447EfLct9y1ImNixII35">1.6<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Personal Data</b></a> means information that (i) relates to an identified or identifiable natural person or (ii) constitutes Personal Data, Personal Information, or any other analogous term under Data Protection Laws.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    1.7<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Processing</b> means any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction. “<b>Process</b>“, “<b>Processes</b>” and “<b>Processed</b>” will be interpreted accordingly.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kMHG5YVt39A6ABbNevB03KoPkzKK57">1.8<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Security Measures</b></a> has the meaning given in <a name="_9kMHG5YVt4AA6ACEFhSrrt8nVm3J8BSwXs7SSDF">Section </a><a name="_9kMHG5YVt4BB6ADFFhSrrt8nVm3J8BSwXs7SSDF">4.1</a>.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kR3WTr18949Cbbrn44qhyDAAF">1.9<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><b>Subprocessors</b></a> means vendors or other third parties that MadTech engages to Process Customer Personal Data.
                                </p>
                                <p className="Legal2L1">
                                    <span style={{ textTransform: 'uppercase' }}>2.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>Application and Duration of <a name="_9kMLK5YVt39A68GQ4egrrz9">Addendum</a>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '0in' }}>
                                    This <a name="_9kMNM5YVt39A68GQ4egrrz9">Addendum</a> shall apply if and to the extent that, and remain in effect so long as, Vendor Processes Customer Personal Data under the License Agreement.
                                </p>
                                <p className="Legal2L1">
                                    <a name="_Ref_ContractCompanion_9kb9Ur06C"></a><a name="_Ref_ContractCompanion_9kb9Ur01B"></a><a name="_Ref_ContractCompanion_9kb9Ur06E"></a><a name="_9kMML5YVt3AB69DbKt862poSCz0"><span style={{ textTransform: 'uppercase' }}>3.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>Personal Data</a> Processing
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    3.1<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>MadTech shall Process <a name="_9kMNM5YVt3AB69DbKt862poSCz0">Customer Personal Data</a> in compliance with <a name="_9kMIH5YVt3AB69JSG2ztleequNE12ZlHKBvBIEK">Data Protection Laws</a>.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    3.2<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>It is the parties’ intent that with respect to the Processing of Customer Personal Data, MadTech is a service provider. MadTech shall Process Customer Personal Data only as necessary to provide the MadConnect Platform and will provide the same level of privacy protection to Customer Personal Data as is required by Data Protection Laws.
                                </p>
                                <p className="Legal2L1">
                                    <a name="_Ref4700142"><span lang="EN" style={{ textTransform: 'uppercase' }}>4.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span><span lang="EN">Security</span></a>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_Ref_ContractCompanion_9kb9Ur017"></a><a name="_Ref4699927"></a><a name="_Ref_ContractCompanion_9kb9Ur028"></a><a name="_Ref4702888"></a><a name="_9kR3WTr28848ACDfQppr6lTk1H69QuVq5QQBD"></a><a name="_9kR3WTr29948BDDfQppr6lTk1H69QuVq5QQBD"></a><a name="_9kMJI5YVt39A6ABbNevB03KoPkzKK57">4.1<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u><span lang="EN">MadTech</span></u></a><u>’s</u><u> <span lang="EN">Security Measures</span></u><span lang="EN">.</span><span lang="EN"> </span>MadTech will implement and maintain reasonable technical, administrative and physical safeguards designed to protect <a name="_9kMON5YVt3AB69DbKt862poSCz0">Customer Personal Data</a> in MadTech’s care, custody or control against <a name="_9kMHG5YVt3AB6AEUMqs54ow517mYp6MBEVvf624">Information Security Incidents</a> (the “<a name="_9kR3WTr178489ZLct9y1ImNixII35"><b>Security Measures</b></a>”). Such <a name="_9kMKJ5YVt39A6ABbNevB03KoPkzKK57">Security Measures</a> shall (a) be as protective as the measures MadTech applies to its own similar information, (b) comply in all material respects with <a name="_9kMJI5YVt3AB69JSG2ztleequNE12ZlHKBvBIEK">Data Protection Laws</a> and (c) without limiting the generality of the foregoing, include the minimum measures described in Annex 2.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    4.2<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>No Offshore Processing</u>. MadTech shall not permit Customer Personal Data to be Processed in or from any location outside of the United States without Customer’s prior written consent.
                                </p>
                                <p className="Legal2L1">
                                    <a name="_Ref_ContractCompanion_9kb9Ur013"></a><a name="_Ref_ContractCompanion_9kb9Ur019"></a><a name="_Ref4702148"></a><a name="_9kR3WTr29948GIETMqs54ow517mYp6MBEVvf624"></a><a name="_9kMIH5YVt3AB6AEUMqs54ow517mYp6MBEVvf624"><span lang="EN" style={{ textTransform: 'uppercase' }}>5.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>Information Security Incidents</a>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_Ref_ContractCompanion_9kb9Ur06G">5.1<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>Notice of Information Security Incident</u></a>. If MadTech becomes aware <a name="_9kMHG5YVt9ID6AF1qdmVQuw98s095B">of any </a><a name="_9kMJI5YVt3AB6AEUMqs54ow517mYp6MBEVvf624">Information Security Incident</a>, MadTech shall (a) notify <a name="_9kR3WTr7GB48DzobkTOsu76qy739"></a><a name="_9kR3WTr7GB48F1ot9uiPQuw98s095B">Client of the </a><a name="_9kMKJ5YVt3AB6AEUMqs54ow517mYp6MBEVvf624">Information Security Incident</a> without undue delay and in no event later than <a name="_9kR3WTr14548E5A6DouC1xu0F1k6P">two (2) business days</a> after becoming aware of the <a name="_9kMLK5YVt3AB6AEUMqs54ow517mYp6MBEVvf624">Information Security Incident</a> and (b) take commercially reasonable steps to identify the cause <a name="_9kMHG5YVt9ID6AH3qvBwkRSwyBAu2B7D">of such </a><a name="_9kMML5YVt3AB6AEUMqs54ow517mYp6MBEVvf624">Information Security Incident</a>, minimize harm and prevent a recurrence. Notifications made pursuant to this Section 5.1 shall include, to the extent possible, a description of the <a name="_9kMNM5YVt3AB6AEUMqs54ow517mYp6MBEVvf624">Information Security Incident</a>, including the suspected cause, the nature of the information affected and the number and categories of individuals affected.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    5.2<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>Cooperation with Client</u>. MadTech will cooperate with Client and take such commercially reasonable steps as may be directed by Client to assist in the investigation of any such Information Security Incident, including providing Client with all facts about the Information Security Incident reasonably necessary to support Client’s own assessment of the associated risk to Customer Personal Data.
                                </p>
                                <p className="Legal2L1">
                                    <span style={{ textTransform: 'uppercase' }}>6.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>Data Subject Requests
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    6.1<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>Notice to Client</u>. If MadTech receives any <a name="_9kMPO5YVt3AB69DbKt862poSCz0">Data</a> Subject Request, MadTech shall notify Client in writing of such request or complaint promptly and in no event later than <a name="_9kR3WTr14548Huizw7udzI">five (5) calendar days</a> <a name="_9kMH3K6ZWu4AB7ABfRqqs7">after </a>MadTech’s receipt thereof, and MadTech shall not take any action in response to such request except in accordance with Client’s written instructions unless otherwise required by Data Protection Laws.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    6.2<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>MadTech’s Data Subject Request Assistance</u>. MadTech shall provide Client with such assistance as Client may reasonably request to timely respond to Data Subject Requests within any deadlines imposed by Data Protection Laws.
                                </p>
                                <p className="Legal2L1">
                                    <a name="_Ref_ContractCompanion_9kb9Ur024"></a><a name="_Ref_ContractCompanion_9kb9Ur02A"><span style={{ textTransform: 'uppercase' }}>7.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>Audits</a>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kMPO5YVt39A69BMErlr7"><span lang="EN">7.1<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><u><span lang="EN">Scope</span></u></a><span lang="EN">. MadTech shall make available to Client the information reasonably necessary for Client to demonstrate compliance with its obligations under Data Protection Laws. </span><span lang="EN">Client (or an auditor appointed by Client that enters into a confidentiality agreement with MadTech, in form and substance satisfactory to MadTech) may but is not obligated to audit <a name="_9kMH7O6ZWu4AB7ABfRqqs7">(including through inspections, manual reviews, and automated scans and other technical and operational testing) </a>MadTech’s compliance with its obligations under this <a name="_9kMPO5YVt39A68GQ4egrrz9">Addendum</a> and applicable Data Protection Laws, up to <a name="_9kR3WTr14548I4wldrsvG4hv">once per year</a> and more often if required by <a name="_9kMML5YVt3AB69JSG2ztleequNE12ZlHKBvBIEK">Data Protection Laws</a> or by a governmental authority, and MadTech will reasonably cooperate with such audits. Such audits may require MadTech to complete questionnaires and/or make available relevant documents and records for review and relevant </span>MadTech <span lang="EN">personnel for interviews. Any audit by or on behalf of Client must be conducted during MadTech’s regular business hours, and may not unreasonably interfere with or disrupt MadTech’s business or operations.  </span>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}><span lang="EN">7.2<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span><u><span lang="EN">Audit Reports</span></u><span lang="EN">. If the controls or measures to be assessed in the requested audit are addressed in an <a name="_9kR3WTr26645Fi5qVgkDu8"></a><a name="_9kR3WTr266478Z5qVgkDu8">SOC 2 Type 2</a>, ISO, <a name="_9kR3WTr12349AUu0C">or NIST</a> audit report performed by a qualified and independent third party auditor within <a name="_9kR3WTr14549B1Awm4y5H335B66">the 12 months</a> preceding Client’s audit request and MadTech has certified in writing that there are no known material changes in the controls audited, Client shall accept such report in lieu of performing an audit of such controls or measures, unless otherwise expressly required by Data Protection Laws or a governmental authority. MadTech will provide Client with a copy of any such audit report obtained by MadTech, upon Client’s request.</span>
                                </p>
                                <p className="Legal2L1"><a name="_9kMHG5YVt3AB6BEddtp66sj0FCCH"><span style={{ textTransform: 'uppercase' }}>8.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>Subprocessors</a></p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}><a name="_Ref12719156"></a><a name="_9kR3WTr29949HLGMHz4ws8FBlm2yFF1s9OLLmdC">8.1<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>Consent to </u></a><a name="_9kMIH5YVt3AB6BEddtp66sj0FCCH"><u>Subprocessor</u></a><u> Engagement</u>. Client consents to MadTech’s engagement of the <a name="_9kMKJ5YVt3AB6BEddtp66sj0FCCH">Subprocessors</a> (together with their locations), if any, listed in the space below:</p>

                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '0in' }}>
                                    <a name="_9kR3WTr7FA49D"><i>Amazon Web Services, Inc. 410 Terry Avenue North, Seattle, WA 98109-5210, U.S.A.</i></a>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '0in' }}>
                                    <i>Aqfer, Inc.  9100 Conroy Windermere Rd., Suite 200, Windermere, FL 34786</i>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '0in' }}>
                                    <i><span style={{ fontSize: '2.0pt' }}>     </span></i><u><span style={{ fontSize: '2.0pt' }}>                 </span></u>
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kMNM5YVt3AB6BEddtp66sj0FCCH">8.2<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>Subprocessor</u></a><u> Requirements</u>. MadTech shall enter into a written contract with <a name="_9kMPO5YVt3AB6BEddtp66sj0FCCH">each Subprocessor</a> containing privacy, confidentiality and data security obligations with respect to Customer Personal Data at least equivalent in substance to those in the <a name="_9kMML5YVt39A69CL7vuirsuA">License Agreement</a> (including this <a name="_9kMH2J6ZWu4AB79HR5fhss0A">Addendum</a>). MadTech will remain responsible for its compliance with the obligations of this DPA and for any acts or omissions of a Subprocessor that cause MadTech to breach any of MadTech’s obligations under this DPA.
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '-.5in' }}>
                                    <a name="_9kMH1I6ZWu4BC7CFeeuq77tk1GDDI">8.3<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><u>Subprocessor</u></a><u> Changes</u>. MadTech may engage additional Subprocessors not listed in Section <a name="_9kMHG5YVt4BB6BJNIOJ16yuAHDno40HH3uBQNNo">8.1</a>, <a name="_9kMJ2H6ZWu4AB7ABfRqqs7">provided that </a>MadTech shall notify Client of each such engagement (including the name and location of the relevant <a name="_9kMH3K6ZWu4BC7CFeeuq77tk1GDDI">Subprocessor</a> and the activities it will perform).
                                </p>
                                <p className="Legal2L1">
                                    <span style={{ textTransform: 'uppercase' }}>9.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>Termination
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '0in' }}>
                                    Upon termination of Client’s access to the MadConnect Platform or upon Client’s request, MadTech shall delete or cause the deletion of all <a name="_9kMH2J6ZWu4BC7AEcLu973qpTD01">Customer Personal Data</a> in MadTech’s care, custody or control as soon as reasonably practicable, except to the extent retention thereof is required by applicable law.
                                </p>
                                <p className="Legal2L1">
                                    <span style={{ textTransform: 'uppercase' }}>10.<span style={{ font: ' "Times New Roman"' }}></span>&nbsp;&nbsp;</span>Miscellaneous
                                </p>
                                <p className="Legal2L2" style={{ marginLeft: '.75in', textIndent: '0in' }}>
                                    Except as expressly modified by this DPA, the terms of the <a name="_9kMHzG6ZWu4AB7ADM8wvjstvB">License Agreement</a> remain in full force and effect. The requirements of this DPA are in addition to and not in lieu of the requirements of the <a name="_9kMH0H6ZWu4AB7ADM8wvjstvB">License Agreement</a>. To the extent of any conflict or inconsistency between this DPA and the other terms of the <a name="_9kMH1I6ZWu4AB7ADM8wvjstvB">License Agreement</a>, this DPA shall govern. References in this DPA to “including” mean “including, without limitation.”
                                </p>

                            </div>
                            <br />
                            <br />
                            <div className="WordSection2">
                                <p className="MsoBodyText" align="center" style={{ textAlign: 'center', textIndent: '0in' }}>
                                    <a name="_9kR3WTrAG846AKCwozNQ7nwryBB3rKSC4F"><b><u>Annex 1</u></b></a>
                                </p>
                                <p className="MsoBodyText" align="center" style={{ textAlign: 'center', textIndent: '0in' }}>
                                    <a name="_9kMIH5YVt5ED69FQ3jsnu77zn"></a>
                                    <a name="_3znysh7"></a>
                                    <b>California</b>
                                    <b> Annex</b>
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    <a name="_9kMK2G6ZWu4AB7ABfRqqs7"></a>
                                    <a name="_9kR3WTr2994ABHCwozNFkVuuwBG7qv7AEL"></a>
                                    <a name="_Ref_ContractCompanion_9kb9Ur015"></a>
                                    <a name="_Ref_ContractCompanion_9kb9Ur026"></a>
                                    <a name="_Ref_ContractCompanion_9kb9Ur079">1.</a>
                                    <span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    For purposes of this Annex, the terms “<b>business</b>,” “<b>consumer</b>,”
                                    “<b>commercial purpose</b>,” “<b>sell</b>,” “<b>share</b>,” “<b>business purpose</b>,” and “<b>service provider</b>” shall have the respective meanings given thereto in the CCPA, and “<b>personal information</b>” shall mean Customer Personal Data that constitutes personal information governed by the CCPA.
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    2.
                                    <span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    The parties acknowledge and agree that the details of MadTech’s Processing of
                                    personal information are as described in Exhibit A to this Annex 1 (Data
                                    Processing Details).
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    3.
                                    <span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    It is the parties’ intent that with respect to any personal information, MadTech is a service
                                    provider with respect to its Processing of such personal information. MadTech (a)
                                    acknowledges that personal information is disclosed by Client only for limited
                                    and specified purposes described in the License Agreement; (b) shall comply with
                                    applicable obligations under the CCPA and shall provide the same level of
                                    privacy protection to personal information as is required by the CCPA; (c)
                                    shall notify Client in writing of any determination made by MadTech that it can
                                    no longer meet its obligations under the CCPA; and (d) agrees that Client has
                                    the right, upon notice, including pursuant to the preceding clause, to take
                                    reasonable and appropriate steps to stop and remediate unauthorized use of
                                    personal information.
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    4.
                                    <span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    MadTech agrees that Client may conduct audits, in accordance with Section 7 of the main body
                                    of this DPA, to help ensure that MadTech’s use of personal information is
                                    consistent with MadTech’s obligations under the CCPA.
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    5.
                                    <span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    MadTech shall not (a) sell or share any personal information; (b) retain, use or disclose any
                                    personal information for any commercial purpose other than for the business
                                    purpose of providing the services specified in the License Agreement, or as
                                    otherwise permitted by the CCPA, (c) retain, use or disclose the personal
                                    information outside of the direct business relationship between MadTech and Client,
                                    or (d) combine personal information received pursuant to the Agreement with
                                    personal information (i) received from or on behalf of another person, or (ii)
                                    collected from MadTech’s own interaction with any consumer to whom such
                                    personal information pertains.  MadTech hereby certifies that it understands
                                    its obligations under this Section 5 and will comply with them.
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    6.
                                    <span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    MadTech’s notice to Client of Subprocessor engagements in accordance with Section 8 of the main
                                    body of this DPA shall satisfy MadTech’s obligation under the CCPA to give
                                    notice of such engagements.
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    7.
                                    <span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    The parties acknowledge that MadTech’s retention, use and disclosure of personal
                                    information authorized by Client’s instructions documented in the License Agreement
                                    are integral to MadTech’s provision of the services under the License Agreement
                                    and the business relationship between the parties.
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    8.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    If MadTech receives a request directly from an individual, MadTech will notify Client and
                                    advise the individual to submit its request to Client. MadTech will provide Client
                                    with any assistance reasonably necessary for Client to perform its obligations
                                    under the CCPA to fulfil or respond to requests from individuals to exercise
                                    their rights under the CCPA.
                                </p>
                                <p className="StandardL3" style={{ marginLeft: '.25in', textIndent: '-.25in' }}>
                                    9.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    MadTech agrees to cooperate in good faith with Client concerning any amendments to the DPA or
                                    this Annex as may be necessary to address compliance with the CCPA.
                                </p>
                            </div>
                            <br />
                            <br />
                            <div className="WordSection3">
                                <p className="UKScheduleL1" style={{ marginLeft: '0in', textIndent: '0in', textAlign: "center", fontWeight: 600 }}>
                                    <a name="_9kR3WTrAG846BLCwozOhRizF47OsTo3OO9B">
                                        <span lang="EN-CA" style={{ fontFamily: 'Arial, sans-serif' }}>Exhibit A to Annex 1 - </span>
                                    </a>
                                    <span lang="EN-GB" style={{ fontFamily: 'Arial, sans-serif' }}>Data Processing Details</span>
                                </p>
                                <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><b><u>DETAILS OF PROCESSING</u></b></p>
                                <p className="MsoNormal" style={{ pageBreakAfter: 'avoid' }}><b>Categories of Data Subjects: </b></p>
                                <p className="pf0" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textIndent: '-.25in' }}>
                                    <span style={{ fontSize: '', fontFamily: 'Symbol' }}>·</span>
                                    <span style={{ fontSize: '', fontFamily: 'Arial, sans-serif', marginLeft: "16px" }}>Client application users</span>
                                </p>
                                <br />
                                <p className="MsoNormal" style={{ pageBreakAfter: 'avoid' }}><b>Categories of Personal Data:</b></p>
                                <p className="pf0" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textIndent: '-.25in' }}>
                                    <span style={{ fontSize: '', fontFamily: 'Symbol' }}>·</span>
                                    <span style={{ fontSize: '', fontFamily: 'Arial, sans-serif', marginLeft: "16px" }}>
                                        Personal contact details (first name, last name, address, phone number, email address, birthday, social media handles)
                                    </span>
                                </p>
                                <p className="pf0" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textIndent: '-.25in' }}>
                                    <span style={{ fontSize: '', fontFamily: 'Symbol' }}>·</span>
                                    <span style={{ fontSize: '', fontFamily: 'Arial, sans-serif', marginLeft: "16px" }}>
                                        Demographic data (age, gender, geography, household income, ethnicity, interests)
                                    </span>
                                </p>
                                <p className="pf0" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textIndent: '-.25in' }}>
                                    <span style={{ fontSize: '', fontFamily: 'Symbol' }}>·</span>
                                    <span style={{ fontSize: '', fontFamily: 'Arial, sans-serif', marginLeft: "16px" }}>Transactional data (sales / purchase history, user account and customer service data, order data, return data)</span>
                                </p>
                                <p className="pf0" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textIndent: '-.25in' }}>
                                    <span style={{ fontSize: '', fontFamily: 'Symbol' }}>·</span>
                                    <span style={{ fontSize: '', fontFamily: 'Arial, sans-serif', marginLeft: "16px" }}>Behavioral data (product usage and engagement data, marketing campaign engagement data)</span>
                                </p>
                                <p className="pf0" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textIndent: '-.25in' }}>
                                    <span style={{ fontSize: '', fontFamily: 'Symbol' }}>·</span>
                                    <span style={{ fontSize: '', fontFamily: 'Arial, sans-serif', marginLeft: "16px" }}>Third-party data (data generated by, and ingested from third-party datasets, advertising technology, and marketing technology providers)</span>
                                </p>
                                <p className="pf0" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '.5in', textIndent: '-.25in' }}>
                                    <span style={{ fontSize: '', fontFamily: 'Symbol' }}>·</span>
                                    <span style={{ fontSize: '', fontFamily: 'Arial, sans-serif', marginLeft: "16px" }}>Other data categories as included from time-to-time</span>
                                </p>
                                <p className="MsoNormal"><b>&nbsp;</b></p>
                                <p className="MsoNormal"><b>Sensitive Categories of Data, and associated additional restrictions/safeguards: </b>Not applicable</p>
                                <p className="MsoNormal"><b>Nature and purpose of the Processing: </b>Provide the services, as more particularly described in the License Agreement, and comply with Customer instructions thereunder.</p>
                                <p className="MsoNormal"><b>Duration of Processing / Retention Period: </b>Concurrent with term of the License Agreement and then thereafter pursuant to Section 9 of this DPA.</p>
                                <br />
                                <br />
                                <p className="MsoNormal" align="center" style={{ marginBottom: '6.0pt', textAlign: 'center' }}><b><u>Annex 2</u></b></p>
                                <p className="MsoNormal" align="center" style={{ marginBottom: '12.0pt', textAlign: 'center' }}>
                                    <a name="_9kMLK5YVt39A6ABbNevB03KoPkzKK57"><b>Security Measures</b></a>
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    1.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Written Information Security Program</u>. Develop, implement, maintain, monitor, upgrade and comply with administrative, technical and physical safeguards, including, without limitation, written information security policies and procedures, designed to protect the confidentiality, availability and integrity of Personal Data and any systems that store or otherwise process it, which are: (a) aligned with an industry-standard control framework (e.g., NIST SP 800-53, ISO 27001, SOC 2 Type 2, CIS Critical Security Controls); (b) consistent with the safeguards for protection of Personal Data and information of a similar character set forth in any state, federal or foreign regulations by which MadTech is bound; (c) approved by executive management; (d) reviewed and updated at least annually or whenever there is a material change in business practices that may reasonably implicate the security or integrity of Personal Data; and (e) communicated to all personnel with access to Personal Data. Assign to an individual or a group of individuals the responsibility for developing, implementing, and managing the organization’s written information security program.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    2.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Risk Assessment</u>. Maintain risk assessment procedures for the purposes of periodic review, identification and assessment of internal and external risks to the organization and the security, confidentiality, integrity and availability of Personal Data, monitoring and maintaining compliance with the organization’s policies and procedures, and reporting the condition of the organization’s information security and compliance to MadTech’s internal executive management and board of directors or similar body.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    3.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Personnel Training</u>. Train personnel on an ongoing basis on the importance of information security, the proper use of computer security and on maintaining the confidentiality, integrity, availability and security of Personal Data and comply with MadTech’s policies and procedures, consistent with the terms of the License Agreement and Data Protection Laws.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    4.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Disciplinary Measures.</u> Impose disciplinary measures on personnel for violations of MadTech’s policies and procedures and information security program.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    5.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Subcontractor Management</u>. Conduct reasonable due diligence and monitoring to ensure subcontractors and Subprocessors are capable of maintaining the privacy, confidentiality, security, integrity and availability of Personal Data consistent with this DPA.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    6.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Access Controls</u>. Maintain logical access controls designed to limit access to Personal Data and relevant information systems only to authorized personnel and third parties (e.g. granting access on a need-to-know basis, use of unique IDs and passwords (which are not vendor-supplied default passwords) for all users, periodic review and revoking/changing access when personnel are terminated or changes in job functions occur), and storing physical records containing Personal Data in locked facilities, storage areas or containers.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    7.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Secure User Authentication</u>. Maintain password controls designed to manage and control password strength, expiration and usage including prohibiting users from sharing passwords and requiring that passwords controlling access to Personal Data must: (a) be at least eight (8) characters in length and meet minimum complexity requirements; (b) not be stored in readable format on the organization’s computer systems; (c) be changed at least every ninety (90) days; (d) have a history threshold to prevent reuse of recent passwords; (e) if newly issued, be changed after first use; and (f) blocking access to user accounts after multiple unsuccessful attempts to gain access.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    8.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Multi-factor Authentication and VPNs.</u> Use multi-factor authentication to authenticate access Personal Data by MadTech personnel, and permit remote-access to Personal Data only through a MadTech-controlled virtual private network (VPN).
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    9.<span style={{ font: ' "Times New Roman"' }}>&nbsp;&nbsp;</span>
                                    <u>Incident Detection and Response</u>. Maintain policies and procedures to detect, prevent and respond to actual or reasonably suspected Information Security Incidents, encourage the reporting of such incidents, and document responsive actions taken in connection with any such incidents (including mandatory post-incident review of events and actions taken, if any, to make changes in business practices relating to the protection of Personal Data).
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    10.<span style={{ font: ' "Times New Roman"' }}>&nbsp;</span>
                                    <u>Encryption</u>. Apply industry standard encryption to Personal Data: (a) stored on any medium (i.e., laptops, mobile devices, portable storage devices, file servers and application databases); and (b) transmitted across any public network (such as the Internet) or wirelessly.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    11.<span style={{ font: ' "Times New Roman"' }}>&nbsp;</span>
                                    <u>Network Security</u>. Implement network security controls such as up-to-date firewalls, endpoint protection and anti-malware software with up-to-date virus definitions, operating system patches, layered <a name="_9kR3WTr26647BNoBi">DMZs</a>, updated intrusion detection/prevention systems and other traffic and event correlation procedures designed to protect systems from intrusion and limit the scope of any successful attack.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    12.<span style={{ font: ' "Times New Roman"' }}>&nbsp;</span>
                                    <u>Vulnerability Management</u>. Implement vulnerability management, threat protection technologies and scheduled monitoring procedures to detect, assess, mitigate, remove and protect against unauthorized use and new and existing security vulnerabilities and threats, including viruses, bots and other malicious code.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    13.<span style={{ font: ' "Times New Roman"' }}>&nbsp;</span>
                                    <u>Change Control</u>. Follow change management procedures and implement tracking mechanisms designed to test, approve and monitor all changes to MadTech’s technology and information assets.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    14.<span style={{ font: ' "Times New Roman"' }}>&nbsp;</span>
                                    <u>Physical Security</u>. Physical and environmental security of data center, server room facilities and other areas containing <a name="_9kMI4K6ZWu4BC7AEcLu973qpTD01">Personal Data</a> designed to: (a) protect information assets from unauthorized physical access; (b) manage, monitor and log movement of persons into and out of MadTech facilities; and (c) guard against environmental hazards such as heat, fire and water damage.
                                </p>
                                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '.25in', textAlign: 'justify', textIndent: '-.25in' }}>
                                    15.<span style={{ font: ' "Times New Roman"' }}>&nbsp;</span>
                                    <u>Business Continuity and Disaster Recovery</u>. Maintain business continuity and disaster recovery policies and procedures designed to maintain service and recover from foreseeable emergency situations or disasters.
                                </p>
                            </div>


                        </div>
                    </Box>
                </Box>
                <LetsConnect />
                <Box sx={{ maxWidth: 1550, mx: 'auto' }}>
                    <HomeFooter />
                </Box>
            </Box>
        </NaLayout>
    )
}

export default Addendum