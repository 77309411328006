import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import {
  Box, Typography, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { color } from "../../utils/Colors";
import CommonButton from "../../components/CommonButton/CommonButton";
import "./SRoles.css";
import {
  PANEL, getPanelType, madTechState, setSelectedAccount,
} from "../../features/madTechSlice";

import { FormNameWrapper, FormNameLabelWrapper, FormNameInputWrapper, PermissionHeading } from "../SRoles/Srole.styles"
import { permissionAdmin, permissionPlatform } from "./permission"

const isDisabledByUserType = (section, action, panelType) => {
  const permission =
    panelType === PANEL.PLATFORM_USER ? permissionPlatform : permissionAdmin;
  if (permission[section] && permission[section][action])
    return permission[section][action] === "disabled";
  return true;
};

const CheckboxCell = ({ checked, onChange, disabled }) => (
  <TableCell align="center" sx={{ border: "none", padding: 0 }}>
    <Checkbox checked={checked} onChange={onChange} disabled={disabled} />
  </TableCell>
);

export const TableRowComponent = ({
  title,
  roleDetails,
  setRoleDetails,
  section,
  isDisabled,
  panelType,
  index,
}) => {
  return (
    <TableRow key={title}>
      <TableCell sx={{ border: "none", padding: "0 0 0 16px" }}>
        {title}
      </TableCell>
      {["view", "create", "modify", "delete"].map((action) => (
        <CheckboxCell
          key={action}
          checked={roleDetails.rolePermissionDetails[index].permissions[action]}
          onChange={(e) => {
            const rolePermissionDetails = [
              ...roleDetails.rolePermissionDetails.slice(0, index),
              {
                ...roleDetails.rolePermissionDetails[index],
                permissions: {
                  ...roleDetails.rolePermissionDetails[index].permissions,
                  [action]: e.target.checked,
                },
              },
              ...roleDetails.rolePermissionDetails.slice(index + 1),
            ];
            setRoleDetails({ ...roleDetails, rolePermissionDetails });
            // const updatedSection = {
            //   ...roleDetails.rolePermissionDetails[section],
            //   [action]: e.target.checked,
            // };
            // setRoleDetails({ ...roleDetails, rolePermissionDetails: { ...roleDetails.rolePermissionDetails, [section]: updatedSection } });
          }}
          disabled={
            isDisabled || isDisabledByUserType(title, action, panelType)
          }
        />
      ))}
    </TableRow>
  );
};
const SAddRole = ({
  roleDetails,
  setRoleDetails,
  _handleAddRole,
  addRoleLoading,
}) => {
  const panelType = useSelector(getPanelType);
  const navigate = useNavigate();
  const { selectedAccount } = useSelector(madTechState);

  const rolePermissionDetails = useMemo(() => {
    if (roleDetails) {
      return roleDetails.rolePermissionDetails.map((section, index) => {
        return (
          <TableRowComponent index={index} key={section.name} title={section.name} roleDetails={roleDetails} setRoleDetails={setRoleDetails} section={section}
            isDisabled={false} panelType={panelType} />
        );
      });
    }
    return [];
  }, [roleDetails, panelType]);

  return (
    <>
      <Box display="flex" textAlign="center" justifyContent="center"></Box>
      {/* ROLE DETAILS START */}
      <Box width="100%" mt={2} border="1px solid #E4E4E4" p={3} display="flex" flexDirection="column" backgroundColor="#fff">
        <Box display="flex" flexDirection="column">
          <Typography fontSize="1.125rem" fontFamily="Helvetica Neue" fontWeight={500} letterSpacing={0.2} mb={2}>
            Role details
          </Typography>
          <Box display="flex" mt={0} width="100%" justifyContent="space-between">
            <Box display="flex" flexDirection="column" width="70%">
              <Box display="flex" justifyContent="space-between">
                <FormNameWrapper sx={{ width: "35%", }} size="small">
                  <FormNameLabelWrapper id="firstName">  Role name*</FormNameLabelWrapper>
                  <FormNameInputWrapper placeholder={"Role name*"} labelid="roleName" id="roleName" label="Role name*" value={roleDetails.name}
                    onChange={(e) => { setRoleDetails({ ...roleDetails, name: e.target.value }); }} />
                </FormNameWrapper>
                <FormNameWrapper sx={{ width: "55%", }} size="small">
                  <FormNameLabelWrapper id="lastName">  Description</FormNameLabelWrapper>
                  <FormNameInputWrapper placeholder={"Description"} labelid="description" id="description" label="Description" value={roleDetails.description}
                    onChange={(e) => { setRoleDetails({ ...roleDetails, description: e.target.value, }); }} />
                </FormNameWrapper>
              </Box>
            </Box>
          </Box>
          <PermissionHeading>Permissions</PermissionHeading>
          <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%" mx="auto">
            <Paper sx={{ width: "70%", overflow: "hidden" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }}></TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center">  View</TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center">  Create</TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center">  Modify</TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center" >   Delete </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{rolePermissionDetails}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Box>
      </Box>
      {/* ROLE DETAILS END */}

      {/* BUTTONS START */}
      <Box display="flex" flexDirection="row" width="100%" my="1rem" gap="0.5rem">
        <CommonButton width={106} height={40} textStyle={{ fontSize: "1rem", fontWeight: 600, fontFamily: "Helvetica", letterSpacing: "normal", }}
          customStyle={{ marginRight: "20px", marginTop: "20px", }} onClick={(event) => { _handleAddRole(event); }} title={"Save"} color={color.buttonColor}
          disabled={addRoleLoading} startIcon={<BookmarkBorderOutlinedIcon startIconStyle={{ height: 24, width: 24, }}
            sx={{ marginRight: "-8px", marginLeft: "4px" }} />}></CommonButton>
        <Button onClick={() => { navigate(selectedAccount ? "/roles?accountId=" + selectedAccount.id : "/roles"); }} className="cancel_style1" variant="contained"
          startIcon={<ClearOutlinedIcon startIconStyle={{ height: 24, width: 24, }} sx={{ marginRight: "-8px", marginLeft: "4px" }} />}>

          <label style={{
            cursor: "pointer", fontSize: "1rem", fontWeight: 600, fontFamily: "Helvetica", letterSpacing: "normal", display: "flex", alignItems: "center",
            marginTop: "1px"
          }}>   Cancel </label>
        </Button>
      </Box>
      {/* BUTTONS END */}
    </>
  );
};

export default SAddRole;
