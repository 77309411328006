import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'


import Contract from '../../components/Contract/Contract'
import CreatePassword from '../../components/CreatePassword/CreatePassword'
import Plans from '../../components/Plans/Plans'
import PlatformDetails from '../../components/PlatformDetails/PlatformDetails'
import PlatformDetailsThnkYou from '../../components/PlatformDetails/PlatformDetailsThnkYou'
import Platforms from '../../components/Platforms/Platforms'
// import SelectPlThankU from '../../components/SelectPlThankU/SelectPlThankU'
import Stepper from '../../components/Stepper/Stepper'
import { useGetUserQuery } from '../../services/query'

const CompleteProfile = () => {

    const [step, setStep] = useState(1)
    const [userType, setUserType] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const userid = searchParams.get('u')
    const { data: userDetails, error: userError, isLoading: userLoading } = useGetUserQuery({ userid })
    

    useEffect(() => {
        if (userDetails !== undefined) {
            if (userDetails.statusCode === 200 && userDetails.response) {
                // console.log('userDetails', userDetails.response);
                setUserType(userDetails.response.userType)
                // if (userDetails.response.stepPending === 2) {
                //     setStep(userDetails.response.stepPending);
                // }
                setStep(userDetails.response.stepPending);
            }

            // for local testing
            // {
            //     "userId": "b481ab12428cab45",
            //     "userType": "Brand",
            //     "token": "849bfb67-9685-421f-a8fb-6a1ce509b2fe",
            //     "firstName": "Subhajit",
            //     "lastName": "Paul",
            //     "email": "subhajit.p@madtechadvisors.com",
            //     "companyName": "MadConnect",
            //     "title": "Developer",
            //     "phoneNumber": "8961912223",
            //     "stepPending": 4,
            //     "accountDetails": {
            //       "status": "WAITING_FOR_REVIEW",
            //       "description": "Account is under review",
            //       "firstLogin": true
            //     }
            //   }
            // setStep(4)
        }
        if (userError !== undefined) {
            console.log('userError', userError);
        }
    }, [userDetails, userError])

    return (
        <Stepper step={step} userType={userType}>
            {userLoading ?
                <Box sx={{
                    mt: "3rem",
                    background: '#FFFFFF', display: 'flex',
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <CircularProgress style={{}} size={35} thickness={8}></CircularProgress>
                </Box>
                :
                step === 1 ?
                    <CreatePassword
                        userDetails={userDetails}
                        userError={userError}
                        setStep={setStep}
                        searchParams={searchParams} />
                    : step === 2 ?
                        userType === "platform" ?
                            <PlatformDetails setStep={setStep} /> :
                            <Platforms setStep={setStep} />
                        :
                        // <SelectPlThankU />
                        step === 3 ?
                            <Plans setStep={setStep} /> :
                            userType === "platform" ?
                                <PlatformDetailsThnkYou />
                                :
                                <Contract setStep={setStep} />
            }
        </Stepper>
    )
}

export default CompleteProfile