import React from 'react';

const PersonalInformation = () => {
    return (
        <div>
            <h1>
                <a name="_heading=h.mt129wjaneem" />
                2.<span style={{}}>&nbsp;&nbsp;&nbsp;</span>PERSONAL INFORMATION
                WE COLLECT, SELL, SHARE, AND OTHERWISE DISCLOSE
            </h1>
            <p className="MsoNormal">
                The CCPA/CPRA defines personal information as “information that
                identifies, relates to, describes, is reasonably capable of
                being associated with, or could reasonably be linked, directly
                or indirectly, with a particular consumer or household.” The
                CCPA/CPRA defines the following categories of personal
                information:
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>Identifiers:</span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    name, alias, address, online identifier, Internet Protocol
                    address, email address, account name, Social Security number,
                    Driver’s license number, passport number.{" "}
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>
                        Customer records (defined by the California Customer Records
                        Law, Cal. Civ. Code Section 1798.80):
                    </span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    name, signature, Social Security number, physical
                    characteristics or description, address, telephone number,
                    driver’s license or state identification card number,
                    insurance policy number, education, employment, employment
                    history, bank account number, credit card number, debit card
                    number, any other financial information, medical information,
                    health insurance information.
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>
                        Characteristics of protected classifications under
                        California or federal law:
                    </span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    age (over 40), AIDS/HIV-positive status, ancestry, citizenship
                    status, disability, gender identity or expression, genetic
                    information or conditions, marital status, medical condition,
                    military or veteran status, national origin, political
                    affiliations or activities, pregnancy, race, religion, sex
                    (including pregnancy, childbirth, and related medical
                    conditions), sexual orientation.
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>
                        Commercial information:
                    </span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    records of personal property, products or services purchased,
                    obtained, or considered or other purchasing or consuming
                    histories or tendencies.
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>Biometric information:</span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    imagery of the iris, retina, fingerprint, face, hand, palm,
                    vein patterns, and voice recordings, from which an identifier
                    template, such as a faceprint, a minutiae template, or a
                    voiceprint, can be extracted, and keystroke patterns or
                    rhythms, gait patterns or rhythms, and sleep, health, or
                    exercise data that contain identifying information.
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>
                        Internet or other network activity information:
                    </span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    browsing history, search history, information regarding a
                    consumer’s interaction with an internet website application,
                    or advertisement, or other similar information.
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>Geolocation data: </span>
                </b>
                <span style={{ color: "black" }}>
                    any data that is derived from a device and that is used or
                    intended to be used to locate a Consumer within a geographic
                    area
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>
                        Audio, electronic, visual, thermal, olfactory, or similar
                        information
                    </span>
                </b>
                <span style={{ color: "black" }}>
                    (collectively referred to as “Sensory information”), as
                    described in the category name.
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>
                        Professional or employment-related information
                    </span>
                </b>
                <span style={{ color: "black" }}>
                    , as described in the category name.{" "}
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>Education information:</span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    information that is not publicly available personally
                    identifiable information as defined in the Family Educational
                    Rights and Privacy Act (20 U.S.C. Section 1232g; 34 C.F.R.
                    Part 99).
                </span>
            </p>
            <p className="MsoNormal" style={{
                "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in",
                "text-indent": "-.25in", border: "none",
            }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>
                        Sensitive personal information
                    </span>
                </b>
                <span style={{ color: "black" }}>
                    , Social Security number, driver’s license number, state
                    identification card number, passport number, account log-in,
                    financial account, debit card, or credit card number in
                    combination with any required security or access code,
                    password, or credentials allowing access to an account,
                    precise geolocation (meaning any geolocation data that can be
                    used to locate a consumer within a radius of 1,850 feet.),
                    racial or ethnic origin, religious or philosophical beliefs,
                    union membership, contents of a consumer’s mail, email, and
                    text messages unless the business collecting or using the data
                    is the intended recipient of the communication, genetic data,
                    biometric information for the purpose of uniquely identifying
                    a consumer, personal information collected and analyzed
                    concerning a consumer’s health, personal information collected
                    and analyzed concerning a consumer’s sex life or sexual
                    orientation, when collected or used for
                </span>{" "}
                the purposes of inferring characteristics about a
                consumer.&nbsp;{" "}
            </p>
            <p className="MsoNormal" style={{ "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", border: "none", }}>
                <span style={{}}>
                    ●
                    <span style={{}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </span>
                <b>
                    <span style={{ color: "black" }}>Inferences</span>
                </b>
                <span style={{ color: "black" }}>
                    {" "}
                    drawn from any of the information in any other category to
                    create a profile about a consumer reflecting the consumer’s
                    preferences, characteristics, psychological trends,
                    predispositions, behavior, attitudes, intelligence, abilities,
                    and aptitudes.
                </span>
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
                The following chart describes what categories of personal
                information we have collected over the past 12 months, how we
                collected that information, the business and commercial purposes
                for which we generally use such information, and which types of
                trusted business partners to whom we may sell, share, or
                otherwise disclose that information:
            </p>
        </div>
    );
};

export default PersonalInformation;
