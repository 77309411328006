import React, { useCallback, useEffect, useState } from "react";
import {
    useMediaQuery,
} from "@mui/material";


// import whiteLogo from '../../assets/madtechlight.svg'
// import madConnectLogo from '../../assets/MadConnectNewLogo1.svg'
import madConnectLogo from "../../assets/madconnectdark.png";
// import lineLogo from '../../assets/line_2.svg'
// import bookOpenLogo from '../../assets/book-open.svg'
import { useOAuthsignupUserMutation, useSignupUserMutation } from "../../services/query";
import "react-phone-input-2/lib/bootstrap.css";
import useDocumentTitle from "../../hook/useDocumentTitle";
import CommanSignUp from "../../components/CommanOAuth/CommanSignup";

function SignUpOAuth() {
    useDocumentTitle("Sign Up");
    const matchesxsss = useMediaQuery("(min-width:495px)");
    const [OauthsignupUser, { isLoading, data, error }] = useOAuthsignupUserMutation();
   const maxHeight = useMediaQuery('(max-height:712px)');

    return (
        <div style={{ display: 'flex', justifyContent: 'center', background: "#C4C4C4", alignItems:"center", height: maxHeight ?   "" : "100vh" }}>
            <div style={{ display: "block" }}>
            <div style={{textAlign:"center", padding:"20px" }}>
                <img
                    style={{ width: "auto", height: "4rem", justifyContent: 'center' }}
                    alt={"MADCONNECT LOGO"}
                    src={madConnectLogo}
                ></img>
                </div>
                <CommanSignUp 
                    textAlign="center"
                    classname=""
                    Boxwidth="100%"
                    TextWidth="100%"
                    OAuthType={true}
                    background="transparent"
                    AuthData={data}
                    AuthError={error}
                    OauthsignupUser={OauthsignupUser}
                    AuthLoading={isLoading}
                />
            </div>
        </div>
    );
}

export default SignUpOAuth;
