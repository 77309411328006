import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import noConfigImg from "../../../assets/NorecordSVG.svg";
import { madTechState } from "../../../features/madTechSlice";
import { CreateButton , Heading, Wrapper  } from "./Styles/CreateConnection.styles";

const CreateConnection = () => {
    const navigate = useNavigate()
  const { selectedAccount } = useSelector(madTechState);

  const handleCreate = () => {
    navigate(
        selectedAccount !== null && selectedAccount !== undefined
          ? `/create-connections?accountId=${selectedAccount.id}`
          : "/create-connections"
      );
  }


  return (
    <Wrapper>
      <Box>
        <img
          src={noConfigImg}
          alt='not found'
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </Box>
      <Heading mt={4}>Get started with connections</Heading>
      <CreateButton
        variant="outlined"
        onClick={handleCreate}
        mt={3}
      >
        Create Connection
      </CreateButton>
    </Wrapper>
  );
};

export default CreateConnection;
