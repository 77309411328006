import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid,  OutlinedInput, Typography } from '@mui/material';

import './PlatformDetails.css';
import { color } from '../../utils/Colors';
import { Strings } from '../../utils/Strings';
import CommonButton from '../CommonButton/CommonButton'
import CommonInput from '../CommonInput/CommonInput';
import { usePlatformDetailsMutation, useUpdatePlatformDetailsMutation } from '../../services/platformQuery';
import ClearIcon from '@mui/icons-material/Clear';
import { madTechState } from '../../features/madTechSlice';

const PlatformDetails = ({ setStep, requestedPlatforms }) => {
  const [dataTypes, setDataTypes] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [platformType, setPlatformType] = useState("");
  const [documentLink1, setDocumentLink1] = useState("");
  const [documentLink, setDocumentLink] = useState([]);
  const [user, setUser] = useState({});
  const { isLoggedin } = useSelector(madTechState)
  const [description, setDescription] = useState("");
  const [validatePlatformDetailsForm, setValidatePlatformDetailsForm] = useState(false);
  const [platformDetailsErrorMsg, setPlatformDetailsErrorMsg] = useState("");
  const [platformdetailsSuccessMsg, setPlatformDetailsSuccessMsg] = useState("")
  const [platformDetails, { data: platformDetailsData, isLoading: platformDetailsLoading, error: platformDetailsError }] = usePlatformDetailsMutation()

  const [updateplatformDetails, { data: updateplatformDetailsData, isLoading: updateplatformDetailsLoading, error: updateplatformDetailsError }] = useUpdatePlatformDetailsMutation()

  const _validatePlatformDetailsForm = useCallback(() => {
    if (description.length === 0 || documentLink.length === 0) {
      // setValidatePlatformDetailsForm(true)
      return true
    }
    // setValidatePlatformDetailsForm(false)
    return false
  }, [dataTypes, productCategory, documentLink])

  const _requestPlatformDetails = useCallback((event) => {
    event.preventDefault();
    // validate form
    if (_validatePlatformDetailsForm()) {
      return;
    }

    // var documentLink = [];
    // documentLink.push(documentLink1)

    var platformDetailsBody = {
      dataTypes, productCategory, platformType, documentLink, description
    };

    if (setStep !== undefined && requestedPlatforms === undefined) {
      platformDetails(platformDetailsBody)
    } else {
      updateplatformDetails(platformDetailsBody)
    }
  }, [usePlatformDetailsMutation, useUpdatePlatformDetailsMutation,
    dataTypes, productCategory, platformType, description, documentLink,
    _validatePlatformDetailsForm, setStep, requestedPlatforms])

  // request platform hook
  useEffect(() => {
    if (platformDetailsData !== undefined) {
      if (platformDetailsData.statusCode != 200) {
        setPlatformDetailsErrorMsg(platformDetailsData.statusMessage);
      } else {
        setDataTypes(["Analystics"])
        setProductCategory(["Analystics"])
        setPlatformType("Source")
        setDocumentLink1("")
        setDescription("")
        setPlatformDetailsErrorMsg("");
        if (setStep !== undefined) {
          setStep(5);
        }
      }
    }
    if (platformDetailsError !== undefined) {
      console.log('error', platformDetailsError);
    }
  }, [platformDetailsData, platformDetailsError])

  useEffect(() => {
    var userData = localStorage.getItem("USER");
    if (userData !== undefined && userData !== null) {
      var user = JSON.parse(userData);
      setUser(user);
    }
  }, [])

  useEffect(() => {
    if (requestedPlatforms !== undefined && requestedPlatforms) {
      setDescription(requestedPlatforms.description)
      setDocumentLink(requestedPlatforms.documentLink)
    }
  }, [requestedPlatforms])

  useEffect(() => {
    if (updateplatformDetailsData !== undefined) {
      // console.log('data', updateplatformDetailsData);
      if (updateplatformDetailsData.statusCode !== 200) {
        setPlatformDetailsErrorMsg(updateplatformDetailsData.statusMessage);
      } else {
        setPlatformDetailsSuccessMsg(updateplatformDetailsData.statusMessage)
        setTimeout(() => {
          setPlatformDetailsSuccessMsg("")
        }, 4000)
      }
    }
    if (updateplatformDetailsError !== undefined) {
      console.log('error', updateplatformDetailsError);
    }
  }, [updateplatformDetailsData, updateplatformDetailsError])

  return (
    <div className='mt-4'>
      <div className=''>
        <p className='text-center mb-2' style={{ fontSize: "2rem", fontWeight: 700 }}>{Strings.platform_details}</p>
        <p className='text-center mb-4 sub-text-color'>Submit documentation details to be approved for integration queue.</p>
        <Box sx={{ width: "90%", maxWidth: 1600, minHeight: 666, mx: 'auto' }}>
          <Grid container spacing={{ xs: 4, sm: 4, md: 4, lg: 8 }}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className='platform-user-details-container'>
                <Grid container sx={{
                  backgroundColor: "#F4F5F6", borderRadius: "20px 20px 0px 0px",
                  p: setStep !== undefined ? "20px 50px" : "20px 35px"
                }}>
                  <Grid item xs={12} sm={12} md={setStep !== undefined ? 6 : 12} sx={{ marginTop: "20px" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ width: 130, color: "#666666", fontFamily: 'Helvetica Neue', fontSize: "1rem", marginRight: 2 }}>First Name:</Typography>
                      <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: "1rem", fontWeight: 500 }}>
                        {user !== undefined && user.firstName !== undefined ? user.firstName : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={setStep !== undefined ? 6 : 12} sx={{ marginTop: "20px" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ width: 130, color: "#666666", fontFamily: 'Helvetica Neue', fontSize: "1rem", marginRight: 2 }}>Last Name:</Typography>
                      <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: "1rem", fontWeight: 500 }}>{user !== undefined && user.lastName !== undefined ? user.lastName : ""}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "20px" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ width: 130, color: "#666666", fontFamily: 'Helvetica Neue', fontSize: "1rem", marginRight: 2 }}>Category:</Typography>
                      <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: "1rem", fontWeight: 500 }}>
                        {user !== undefined && user.userType !== undefined ? "I am a Platform" : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "20px" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ width: 130, color: "#666666", fontFamily: 'Helvetica Neue', fontSize: "1rem", marginRight: 2 }}>Work Email:</Typography>
                      <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: "1rem", fontWeight: 500 }}>
                        {user !== undefined && user.email !== undefined ? user.email : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "20px" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ width: 130, color: "#666666", fontFamily: 'Helvetica Neue', fontSize: "1rem", marginRight: 2 }}>Company Name:</Typography>
                      <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: "1rem", fontWeight: 500 }}>
                        {user !== undefined && user.companyName !== undefined ? user.companyName : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "20px" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ width: 130, color: "#666666", fontFamily: 'Helvetica Neue', fontSize: "1rem", marginRight: 2 }}>Title:</Typography>
                      <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: "1rem", fontWeight: 500 }}>
                        {user !== undefined && user.title !== undefined ? user.title : ""}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "20px" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography sx={{ width: 130, color: "#666666", fontFamily: 'Helvetica Neue', fontSize: "1rem", marginRight: 2 }}>Phone Number:</Typography>
                      <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: "1rem", fontWeight: 500 }}>
                        {user !== undefined && user.phoneNumber !== undefined ? "+" + user.phoneNumber : ""}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container sx={{ minHeight: 325, padding: 0 }}>
                  <ul style={{ margin: '3px', paddingTop: 15 }}>
                    <li style={{ margin: 15, color: "#193241", fontSize: "1rem" }}>Please submit all API documentation and a short description of its functionality and capabilities.</li>
                    <li style={{ margin: 15, color: "#193241", fontSize: "1rem" }}>Note that we will prioritize the development of each connector based on the number of customers requesting the connector.</li>
                  </ul>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              {/* <div className=''>
                <Typography sx={{ color: "#121212", fontFamily: 'Helvetica Neue', fontSize: 20 }}>Enter Details</Typography>
              </div> */}
              <div className='platform-details-container'>
                <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                  <Typography variant='subtitle2' sx={{ color: "red", textAlign: "center", height: "1.25rem" }}>{platformDetailsErrorMsg}</Typography>
                </Box>
                {/* <div className=''>
                  <label className='platform-label'>Data Type *</label>
                  <Select
                    multiple
                    value={dataTypes}
                    onChange={(event) => {
                      setDataTypes(event.target.value);
                      setPlatformDetailsErrorMsg("");
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      width: "100%",
                      height: "2.75rem",
                      fontSize: "16px"
                    }}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Advertising"}>Advertising</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Analystics"}>Analytics</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Customer"}>Customer</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Marketing"}>Marketing</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Media"}>Media</MenuItem>
                  </Select>
                </div>
                <div className='mt-3'>
                  <label className='platform-label'>Product Category *</label>
                  <Select
                    multiple
                    value={productCategory}
                    onChange={(event) => {
                      setProductCategory(event.target.value);
                      setPlatformDetailsErrorMsg("");
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      width: "100%",
                      height: "2.75rem",
                      fontSize: "16px"
                    }}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Advertising"}>Advertising</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Analystics"}>Analytics</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Customer"}>Customer</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Marketing"}>Marketing</MenuItem>
                    <MenuItem style={{ fontSize: "14px" }} value={"Media"}>Media</MenuItem>
                  </Select>
                </div>
                <div className='mt-3'>
                  <label className='platform-label'>Product Type *</label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e, value) => {
                      setPlatformType(value)
                      setPlatformDetailsErrorMsg("");
                    }}
                    sx={{ display: "flex", flexDirection: 'row', justifyContent: 'normal' }}
                    defaultValue="Source"
                  >
                    <FormControlLabel value="Source" control={<Radio sx={{
                      color: color.themeLightGray,
                      '&.Mui-checked': {
                        color: color.themeColor,
                      }
                    }} />} label="Source" />
                    <FormControlLabel value="Destination" control={<Radio sx={{
                      color: color.themeLightGray,
                      '&.Mui-checked': {
                        color: color.themeColor,
                      }
                    }} />} label="Destination" />
                    <FormControlLabel value="Both" control={<Radio sx={{
                      color: color.themeLightGray,
                      '&.Mui-checked': {
                        color: color.themeColor,
                      }
                    }} />} label="Both" />
                  </RadioGroup>
                </div> */}
                <div className=''>
                  <label className='platform-label'>Platform Description *</label>
                  <OutlinedInput multiline rows={4} placeholder='Description'
                    style={{
                      fontSize: '1rem'
                    }}
                    sx={{ width: '100%' }}
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value)
                      setPlatformDetailsErrorMsg("");
                    }}
                  />
                </div>
                <div className='mt-3'>
                  <label className='platform-label'>Documentation Link *</label>
                  <CommonInput
                    height={"2.75rem"}
                    backgroundColor={'white'}
                    placeholder={Strings['document-link']}
                    inputType={"text"}
                    inputProps={{ fontSize: '1rem' }}
                    value={documentLink1}
                    onChange={(event) => {
                      setDocumentLink1(event.target.value);
                      setPlatformDetailsErrorMsg("");
                    }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        if (!documentLink.includes(documentLink1)) {
                          setDocumentLink1("");
                          setDocumentLink([...documentLink, documentLink1])
                        } else {
                          setDocumentLink1("");
                        }
                      }
                    }}
                    endAdornment={
                      <button className={!documentLink1 ? 'add-button' : 'add-button-active'}
                        onClick={(event) => {
                          if (!documentLink.includes(documentLink1)) {
                            setDocumentLink1("");
                            setDocumentLink([...documentLink, documentLink1])
                          } else {
                            setDocumentLink1("");
                          }
                        }}
                        disabled={!documentLink1}
                      > Add
                      </button>
                    }
                  ></CommonInput>
                  {
                    documentLink.length > 0 && documentLink.map((item, index) =>
                      <Box key={index} sx={{
                        display: "flex", flexDirection: "row", px: 1.5, py: "1rem", height: "1.5rem", mt: "0.5rem",
                        borderRadius: 20, justifyContent: "space-between", alignItems: "center",
                        backgroundColor: "rgba(25, 50, 65, 0.08)"
                      }}>
                        <Typography title={item} sx={{ color: "#193241", fontSize: '14px', fontWeight: 400, fontFamily: 'Helvetica Neue', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item}
                        </Typography>
                        <ClearIcon sx={{ color: color.themeColor, width: "1rem", height: "1rem", cursor: 'pointer' }}
                          onClick={() => {
                            setDocumentLink(documentLink.filter(el =>
                              el !== item))
                          }}
                        />
                      </Box>
                    )
                  }
                </div>
                {/* <div className='mt-3 text-center'>
                  </div> */}
                <Box sx={{
                  width: '100%', mt: "1.5rem", display: 'flex', flexDirection: 'column',
                  justifyContent: 'center', alignItems: 'center'
                }}>
                  <CommonButton
                    width={240}
                    height={"2.5rem"}
                    textStyle={{
                      fontSize: '1rem',
                      fontWeight: '500',
                      fontFamily: 'Helvetica'
                    }}
                    onClick={(event) => {
                      _requestPlatformDetails(event);
                    }}
                    title={setStep !== undefined ? Strings.submit : 'Update'}
                    color={!documentLink[0] || !description ?
                      color.themeLightGray : '#3843AC'}
                    //   color={'#3843AC'}
                    isLoading={platformDetailsLoading || updateplatformDetailsLoading}
                    disabled={!documentLink[0] || !description}
                  ></CommonButton>
                  <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                    <Typography variant='subtitle2' sx={{ mt: "0.5rem", color: "green", textAlign: "center" }}>{platformdetailsSuccessMsg}</Typography>
                  </Box>
                </Box>
              </div>
              {/* <div className='mt-3' style={{ textAlign: 'right'}}>
                  <CommonButton
                    width={`240px`}
                    height={"2.75rem"}
                    textStyle={{
                      fontSize: '1.125rem',
                      fontWeight: '500',
                    }}
                    onClick={(event) => {
                      _requestPlatformDetails(event);
                    }}
                    title={Strings.submit}
                    color={!documentLink[0] || !description ?
                      color.themeLightGray : '#3843AC'}
                    //   color={'#3843AC'}
                    isLoading={platformDetailsLoading}
                    disabled={!documentLink[0] || !description}
                  ></CommonButton>
              </div> */}
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default PlatformDetails