import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useState } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

export function TextFieldComponent({
  control,
  name,
  label,
  type,
  required,
  readonly,
  copyText,
  value,
  borderColor,
  borderRadius,
  marginTop,
  color,
  dValue
}) {
  const [copied, setCopied] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dValue ? dValue : value ? value : ""}
      rules={{ required }}
      render={({ field }) => (
        <FormControl
          variant="outlined"
          sx={{
            width: "100%",
            height: "2.75rem",
            fontSize: "1rem",
            color: "#666666",
            backgroundColor: "#FFFFFF",
            fontFamily: "Helvetica Neue",
            marginTop: marginTop ? marginTop : ''
          }}
          size="small"
        >
          <InputLabel
            sx={{
              fontSize: "0.875rem",
              marginTop: "0.25rem",
              opacity: 0.5,
              backgroundColor: "#FFFFFF",
              fontFamily: "Helvetica Neue",
            }}
          >
            {label}
            {required && "*"}
          </InputLabel>
          {
            <OutlinedInput
              {...field}
              sx={{
                width: "100%",
                height: "2.75rem",
                fontSize: "1rem",
                color: color ? color : "#666666",
                borderRadius: borderRadius,
                backgroundColor: "#FFFFFF",
                fontFamily: "Helvetica Neue",
                "& fieldset": {
                  borderColor: borderColor ? borderColor : "",
                }
              }}
              id={`outlined-adornment-${name}`}
              label={label}
              type={type === "text" ? type : (showPassword ? "text" : type)}
              required={required}
              disabled={readonly}
              placeholder={label}
              InputLabelProps={{
                style: {
                  fontSize: "0.875rem",
                  marginTop: "0.25rem",
                  opacity: 0.5,
                  width: "fit-content",
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Helvetica Neue",
                },
              }}
              InputProps={{
                readOnly: readonly,
              }}
              endAdornment={
                <>
                  {copyText && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => {
                          navigator.clipboard.writeText(value ? value : dValue);
                          setCopied(true);
                          setTimeout(() => {
                            setCopied(false);
                          }, 500);
                        }}
                      >
                        {!copied ? <ContentCopy /> : <CheckOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  {type === "password" && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              }
            />
          }
        </FormControl>
      )}
    />
  );
}
