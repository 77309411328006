import React from "react";
import { Box, Checkbox, ListItemText, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useEffect } from "react";
import { useState } from "react";

import AppLayout from "../../components/AppLayout/AppLayout";
import { PANEL, getPanelType, madTechState } from "../../features/madTechSlice";
import { Strings } from "../../utils/Strings";
import CommonButton from "../../components/CommonButton/CommonButton";
import {
  useGetSRoleMutation,
  useGetUsersForSProfileMutation,
  useUpdateSUserMutation,
} from "../../services/query";
import { useGetAllSProfileAccountsMutation } from "../../services/accountsQuery";
import { useMyContext } from "../../components/CommanToasterContext/toast";

import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import Loader from "../../components/Setting/Loader";
import {
  AccountSelectStyle,
  ChangePasswordBtn,
  FormNameInputWrapper,
  FormNameLabelWrapper,
  FormNameWrapper,
  PhoneInputWrapper,
  RightLeftContainer,
  SubWrapper,
  Wrapper,
} from "./SMyProfile.styles";
import ProfileDrawer from "./ProfileDrawer";

export const renderAccountNames = (selectedIds, accounts) => {
  // Find the corresponding names for each selected account ID
  const selectedNames = accounts
    .filter((account) => selectedIds.includes(account.id))
    .map((account) => account.name)
    .join(", ");

  return selectedNames;
};

const nameDetails = [
  {
    id: "firstName",
    label: "First name*",
  },
  {
    id: "lastName",
    label: "Last name*",
  },
];

export default function SMyProfile() {
  const { user } = useSelector(madTechState);
  const { setOpen, setMessage } = useMyContext();
  const panelType = useSelector(getPanelType);

  const [userDetails, setUserDetails] = useState(null);
  const [roles, setRoles] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [getAllAccounts, { data, isLoading, error }] =
    useGetAllSProfileAccountsMutation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (data !== undefined) {
      if (data.statusCode === 200 && data.response) setAccounts(data.response);
      else setAccounts([]);
    }

    if (error !== undefined) {
      console.log("error", error);
      setAccounts([]);
    }
  }, [data, error]);

  const _handleUpdateUser = (event) => {
    event.preventDefault();
    updateSUser(userDetails);
  };

  const [
    updateSUser,
    {
      data: updateSUserData,
      isLoading: updateSUserLoading,
      error: updateSUserError,
    },
  ] = useUpdateSUserMutation();

  const [
    getData,
    { data: userData, isLoading: getUserLoading, error: getUserError, refetch },
  ] = useGetUsersForSProfileMutation();

  const [
    getRoleData,
    { data: roleData, isLoading: roleLoading, error: roleError },
  ] = useGetSRoleMutation();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getRoleData();
  }, []);

  useEffect(() => {
    if (updateSUserData !== undefined) {
      if (updateSUserData.statusCode === 200) {
        setMessage(updateSUserData?.statusMessage);
        setOpen(true);
        // window.location.replace(selectedAccount
        //   ? "/myprofile?accountId=" + selectedAccount.id
        //   : "/myprofile"
        // );
      } else if (updateSUserData.statusCode === 500)
        alert(updateSUserData.statusMessage);
    }
    if (updateSUserError !== undefined) {
      console.log("updateSUserError", updateSUserError);
    }
  }, [updateSUserData, updateSUserError]);

  useEffect(() => {
    if (roleData !== undefined) {
      if (roleData.statusCode === 200 && roleData.response) {
        setRoles(roleData.response);
      } else {
        setRoles([]);
      }
    }

    if (roleError !== undefined) {
      setRoles([]);
    }
  }, [roleData, roleError]);

  useEffect(() => {
    if (panelType !== PANEL.PLATFORM_USER) getAllAccounts();
  }, [panelType]);

  useEffect(() => {
    if (
      userData !== undefined &&
      userData.statusCode === 200 &&
      userData.response
    ) {
      var response = {
        ...userData.response,
        accounts: userData.response.accountList
          ? userData.response.accountList
          : [],
      };
      setUserDetails(response);
    }

    if (getUserError !== undefined) {
      console.log("error", getUserError);
      setUserDetails({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        companyName: user?.companyName,
        title: "",
        roleId: "",
        accounts: [],
      });
    }
  }, [userData, getUserError]);

  useEffect(() => {
    if (roleData !== undefined) {
      if (roleData.statusCode === 200 && roleData.response) {
        setRoles(roleData.response);
      } else {
        setRoles([]);
      }
    }

    if (roleError !== undefined) {
      setRoles([]);
      console.log("roleError", roleError);
    }
  }, [roleData, roleError]);

  const showLoader = () => {
    return (
      roleLoading ||
      roles == null ||
      accounts === null ||
      isLoading ||
      getUserLoading ||
      userDetails === null
    );
  };

  const handleChange = (e, name) => {
    setUserDetails({
      ...userDetails,
      [name]: e.target.value,
    });
  };

  return (
    <AppLayout>
      <Box width="100%" px="1.5rem" pb="1.5rem">
        <SBreadCrumb path="myprofile" pathName="My Profile" />
        {showLoader() ? (
          <Loader />
        ) : (
          <Wrapper>
            <SubWrapper>
              <RightLeftContainer>
                <Box display="flex" justifyContent="space-between">
                  {nameDetails?.map((detail, index) => {
                    return (
                      <FormNameWrapper size="small" key={index}>
                        <FormNameLabelWrapper id={detail.id}>
                          {detail.label}
                        </FormNameLabelWrapper>
                        <FormNameInputWrapper
                          placeholder={detail.label}
                          labelid={detail.id}
                          id={detail.id}
                          label={detail.label}
                          value={userDetails[detail.id]}
                          onChange={(e) => handleChange(e, detail.id)}
                        />
                      </FormNameWrapper>
                    );
                  })}
                </Box>
                <FormNameWrapper
                  sx={{
                    width: "100%",
                    mt: 3,
                  }}
                  size="small"
                  disabled
                >
                  <FormNameLabelWrapper id="workEmail">
                    Work email*
                  </FormNameLabelWrapper>
                  <FormNameInputWrapper
                    placeholder={"Work email*"}
                    labelid="workEmail"
                    id="workEmail"
                    label="Work email*"
                    value={userDetails.email}
                    onChange={(e) => handleChange(e, "email")}
                  />
                </FormNameWrapper>
                <Box mt={3} height="2.75rem">
                  <PhoneInputWrapper
                    placeholder={`${Strings.phoneNumber}*`}
                    inputClass="colors"
                    country={"us"}
                    countryCodeEditable={false}
                    enableSearch={true}
                    value={userDetails.phoneNumber}
                    onChange={(phone) => {
                      setUserDetails({ ...userDetails, phoneNumber: phone });
                    }}
                  />
                </Box>
                {false && panelType !== PANEL.PLATFORM_USER && (
                  <FormNameWrapper size="small" sx={{ width: "100%", mt: 3, }}>
                    <FormNameLabelWrapper id="accounts">  Accounts*</FormNameLabelWrapper>
                    <AccountSelectStyle labelid="accounts" disabled id="accounts" label="Accounts*" multiple value={userDetails?.accounts}
                      renderValue={(selected) => renderAccountNames(selected, accounts)} onChange={(e) => {
                        const { target: { value }, } = e;
                        setUserDetails({ ...userDetails, accounts: typeof value === "string" ? value.split(",") : value, });
                      }}
                      MenuProps={{ PaperProps: { style: { maxHeight: "15.625rem", }, }, }}>
                      {accounts?.map((account) => (
                        <MenuItem
                          sx={{
                            fontSize: "0.875rem",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "Helvetica Neue",
                          }}
                          value={account?.id}
                        >
                          <Checkbox
                            checked={
                              userDetails?.accounts?.indexOf(account?.id) > -1
                            }
                          />
                          <ListItemText primary={account?.name} />
                        </MenuItem>
                      ))}
                    </AccountSelectStyle>
                  </FormNameWrapper>
                )}
              </RightLeftContainer>
              <RightLeftContainer>
                <FormNameWrapper sx={{ width: "100%", }} size="small">
                  <FormNameLabelWrapper id="companyName">  Company name*</FormNameLabelWrapper>
                  <FormNameInputWrapper placeholder={"Company name*"} labelid="companyName" id="companyName" label="Company name*" value={userDetails.companyName}
                    onChange={(e) => setUserDetails({ ...userDetails, companyName: e.target.value, })} />
                </FormNameWrapper>
                <FormNameWrapper
                  sx={{
                    width: "100%",
                    mt: 3,
                  }}
                  size="small"
                >
                  <FormNameLabelWrapper id="title">Title</FormNameLabelWrapper>
                  <FormNameInputWrapper
                    placeholder={"Title"}
                    labelid="title"
                    id="title"
                    label="Title"
                    value={userDetails.title}
                    onChange={(e) =>
                      setUserDetails({ ...userDetails, title: e.target.value })
                    }
                  />
                </FormNameWrapper>
                <FormNameWrapper
                  sx={{
                    width: "100%",
                    mt: 3,
                  }}
                  size="small"
                  disabled
                >
                  <FormNameLabelWrapper id="role">Role*</FormNameLabelWrapper>
                  <AccountSelectStyle
                    labelid="role"
                    id="roleId"
                    label="Role*"
                    value={userDetails?.roleId}
                    onChange={(e) =>
                      setUserDetails({ ...userDetails, roleId: e.target.value })
                    }
                  >
                    {roles.map((role) => {
                      return (
                        <MenuItem
                          sx={{
                            fontSize: "0.875rem",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "Helvetica Neue",
                          }}
                          value={role.id}
                        >
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </AccountSelectStyle>
                </FormNameWrapper>
                <ChangePasswordBtn onClick={toggleDrawer}>
                  Click here to change password
                </ChangePasswordBtn>
              </RightLeftContainer>
              {/* Drawer */}
              <ProfileDrawer
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                toggleDrawer={toggleDrawer}
              />
            </SubWrapper>

            {/* BUTTONS START */}
            <Box display="flex" mt={2} width="100%">
              <CommonButton
                width={106}
                height={40}
                textStyle={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  fontFamily: "Helvetica",
                  letterSpacing: "normal",
                }}
                startIcon={
                  <BookmarkBorderOutlinedIcon
                    startIconStyle={{ height: 24, width: 24 }}
                    sx={{ marginRight: "-8px", marginLeft: "4px" }}
                  />
                }
                customStyle={{
                  marginRight: "20px",
                  marginTop: "20px",
                }}
                onClick={(event) => {
                  _handleUpdateUser(event);
                }}
                title={Strings.submit}
                color={"#3843AC"}
              // isLoading={updateUserLoading || updateProfileLoading}
              // disabled={updateUserLoading || updateProfileLoading}
              ></CommonButton>
            </Box>
            {/* BUTTONS END */}
          </Wrapper>
        )}
      </Box>
    </AppLayout >
  );
}
