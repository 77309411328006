export const color = {
  buttonColor: '#3646AC',
  buttonColorOpacity: '#B57DD4',
  chevronSelectedColor: '#185BC3',
  chevronDisabledColor: '#BCBCBC',
  searchButtonColor: '#000',
  black: "#000000",
  whiteColor: '#fff',
  dashboardCardTitle: '#0C0D0F',
  themeColor: "#193241",
  themeGray: "#666666",
  themeBlack: "#122121",
  themeLightGray: "#D9D9D9",
  themeDarkGray: "#AEAEAE",
  themeRed: "#FF5A5A",
  layoutBgColor: '#FAFCFF',
  // #193241  #4E5CB6
  lightThemeColor: 'rgba(54, 70, 172, 0.05)'
}