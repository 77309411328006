import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Pagination,
  Typography,
  OutlinedInput,
  Paper,
  CircularProgress,
  Button,
  Tooltip,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";


import "./Platform.css";
import { color } from "../../utils/Colors";
import { Strings } from "../../utils/Strings";
import CommonButton from "../CommonButton/CommonButton";
import searchImg from "../../assets/search.svg";
import leftArrow from "../../assets/left_arrow.svg";
import slash from "../../assets/slash.svg";
import connectionLogo from "../../assets/Connector.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  madTechState,
} from "../../features/madTechSlice";
import {
  useFetchPartnerQuery,
  useSelectPlatformMutation,
  useRequestPlatformMutation,
  useDeleteRequestPlatformMutation,
  useGetSelectedPlatformsQuery,
} from "../../services/platformQuery";
import CommonInput from "../CommonInput/CommonInput";
// import { Item } from '../../screens/Connections/Connections';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: color.buttonColor,
    },
  },
});

export const Item = styled(Paper)(({ theme }) => ({
  border: "1px solid rgba(54, 70, 172, 0.25)",
  borderRadius: 10,
  position: "relative",
  cursor: "pointer",
  height: 120,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  // '&:hover': { boxShadow: '2px 6px 6px 6px rgba(0, 0, 0, 0.1)' },
}));

const Platforms = ({ setStep }) => {
  const dispatch = useDispatch();
  const { platfomrs, selectedAccount } = useSelector(madTechState);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(12);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [selectedPlatformIds, setSelectePlatformIds] = useState([]);
  const [allplatfomrs, setAllplatfomrs] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [validateRequestPlatformForm, setValidateRequestPlatformForm] =
    useState(false);
  const [isRequestedPlaform, setIsRequestedPlatform] = useState(false);
  const [requestError, setRequestError] = useState("");

  const { data, isLoading, error } = useFetchPartnerQuery();
  const [
    selectPlatform,
    {
      data: selectedPlatformData,
      isLoading: selectPlatformLoading,
      error: selecPlatformError,
    },
  ] = useSelectPlatformMutation();
  const [
    requestPlatform,
    {
      data: requestedPlatformData,
      isLoading: requestPlatformLoading,
      error: requestPlatformError,
    },
  ] = useRequestPlatformMutation();
  const [
    deleteRequestPlatform,
    {
      data: deleteRequestedPlatformData,
      isLoading: deleteRequestPlatformLoading,
      error: deleteRequestPlatformError,
    },
  ] = useDeleteRequestPlatformMutation();
  const {
    data: platformsData,
    error: selectedPlatformsError,
    refetch,
  } = useGetSelectedPlatformsQuery();

  useEffect(() => {
    if (searchValue === "") {
      setAllplatfomrs(platfomrs);
      setSelectedCategories([]);
      setCategoryName("");
    }
  }, [searchValue]);

  useEffect(() => {
    if (selectedPlatform.length > 0) {
      setSelectePlatformIds(selectedPlatform.map((item) => {
        return { platformId: item.id, dataTypeId: item.dataTypeId }
      })
      );
    } else {
      setSelectePlatformIds([]);
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (selectedCategories.length > 0 && platfomrs.length > 0) {
      const newAllPlatforms = [];
      platfomrs.forEach((ptItem) => {
        ptItem.categories.forEach((catEl) => {
          if (selectedCategories.includes(catEl)) {
            if (!newAllPlatforms.includes(catEl)) {
              newAllPlatforms.push(ptItem);
            }
          }
        });
      });
      const newSetArr = [...new Set(newAllPlatforms.map((data) => data))];
      // console.log('newSetArr', newSetArr.length);
      setAllplatfomrs(newSetArr);

      // let newselectedPlatform = [...selectedPlatform]
      // let newArr = []
      // newselectedPlatform.forEach(ptItem => {
      //     ptItem.categories.forEach(catEl => {
      //         if (selectedCategories.includes(catEl)) {
      //             if (!newArr.includes(catEl)) {
      //                 newArr.push(ptItem)
      //             }
      //         }
      //     });
      // })
      // const newSetArr2 = [...new Set(newArr.map((data) => data))]
      // setSelectedPlatform(newSetArr2)
    } else {
      setAllplatfomrs(platfomrs);
    }

    // if (selectedCategories.length > 0 && platfomrs.length > 0) {
    //     setAllplatfomrs(platfomrs.filter(ptItem =>
    //         selectedCategories.includes(ptItem.categories[0])))
    //     let newselectedPlatform = [...selectedPlatform]
    //     setSelectedPlatform(newselectedPlatform.filter(item =>
    //         selectedCategories.includes(item.categories[0])))
    // } else {
    //     setAllplatfomrs(platfomrs)
    // }
  }, [selectedCategories, platfomrs]);

  const handleSelectPlatform = useCallback((dataTypeId) => {
    // const filteredIds = selectedPlatformIds.filter((item) => item.length > 3);
    selectPlatform({ platformIds: selectedPlatformIds });
    // setStep(3)
  }, [useSelectPlatformMutation, selectedPlatformIds]);

  // const handleSelectPlatform = useCallback(
  //   (id, dataTypeId) => {
  //     // const filteredIds = selectedPlatformIds.filter(item => item.length > 3)
  //     selectPlatform({ platformIds: [{ platformId: id, dataTypeId: dataTypeId }] });
  //     // selectPlatform({ 'platformIds': filteredIds })
  //   },
  //   [useSelectPlatformMutation, selectedPlatformIds]
  // );

  const _validateRequestPlatformForm = useCallback(() => {
    if (name.trim().length === 0 && url.trim().length === 0) {
      setValidateRequestPlatformForm(true);
      return true;
    } else {
      setValidateRequestPlatformForm(false);
    }
    return false;
  }, [name, url]);

  const _requestNewPlatform = useCallback(
    (event) => {
      event.preventDefault();
      // validate form
      if (_validateRequestPlatformForm()) {
        return;
      }

      var requestPlatformBody = {
        name,
        url,
      };

      requestPlatform(requestPlatformBody);
    },
    [useRequestPlatformMutation, name, url, _validateRequestPlatformForm]
  );

  // request platform hook
  useEffect(() => {
    if (requestedPlatformData !== undefined) {
      // console.log('data', requestedPlatformData);
      if (requestedPlatformData.statusCode != 200) {
        setRequestError(requestedPlatformData.statusMessage);
      } else {
        setName("");
        setUrl("");
        setRequestError("");
        var item = requestedPlatformData.response;

        if (!selectedPlatformIds.map((item) => item.platformId).includes(item?.platformId)) {
          setSelectedPlatform([...selectedPlatform, item]);
        }
        setIsRequestedPlatform(false);
      }
    }
    if (requestPlatformError !== undefined) {
      console.log("error", requestPlatformError);
    }
  }, [requestedPlatformData, requestPlatformError]);

  const _deleteRequestedPlatform = useCallback(
    (id) => {
      var deleteRequestPlatformBody = {
        id,
      };

      deleteRequestPlatform(deleteRequestPlatformBody);
    },
    [useDeleteRequestPlatformMutation]
  );

  // delete request platform hook
  useEffect(() => {
    if (deleteRequestedPlatformData !== undefined) {
      // console.log('data', deleteRequestedPlatformData);
      if (deleteRequestedPlatformData.statusCode != 200) {
        // setRequestError(deleteRequestedPlatformData.statusMessage);
      } else {
        setSelectedPlatform(
          selectedPlatform.filter(
            (el) => el.id !== deleteRequestedPlatformData.response
          )
        );
      }
    }
    if (deleteRequestPlatformError !== undefined) {
      console.log("error", deleteRequestPlatformError);
    }
  }, [deleteRequestedPlatformData, deleteRequestPlatformError]);

  useEffect(() => {
    if (data !== undefined && data.response) {
      // console.log('data', data);
      setAllplatfomrs(data.response.platforms);
      setAllCategories(data.response.categories);
    }
    if (error !== undefined) {
      console.log("error", error);
    }
  }, [data, error]);

  useEffect(() => {
    if (selectedPlatformData !== undefined && selectedPlatformData.response) {
      // console.log('data', selectedPlatformData);
      refetch();
      setStep(3);
    }
    if (selecPlatformError !== undefined) {
      console.log("error", selecPlatformError);
    }
  }, [selectedPlatformData, selecPlatformError]);

  useEffect(() => {
    if (platformsData !== undefined && platformsData.response) {
      // console.log('data', data);
      // setSelectedPlatform(platformsData.response.selectedPlatforms);
      // [...data.response.selectedPlatforms, ...data.response.requestedPlatforms.map((ele)=> ({...ele, logo: undefined}))]
      setSelectedPlatform([...platformsData.response.selectedPlatforms, ...platformsData.response.requestedPlatforms.map((ele) => ({ ...ele, logo: undefined }))])
    }

    // for local testing
    // setSelectedPlatform([
    //     {
    //         "categories": [5, 7],
    //         "id": "a04fb499f1acfa2e",
    //         "name": "Gravy",
    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
    //         "description": "Data input platforms"
    //     },
    //     {
    //         "categories": [12, 18],
    //         "id": "a224e0c684fd4b3e",
    //         "name": "Google DV360",
    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
    //         "description": "Data input platforms",
    //         "status": 2
    //     },
    //     {
    //         "categories": [16, 19],
    //         "id": "3f78a3d42bfa5902",
    //         "name": "Freewheel",
    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
    //         "description": "Data input platforms"
    //     },
    //     {
    //         "categories": [8, 13],
    //         "id": "24abf7f8fc33403f",
    //         "name": "Comscore",
    //         "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/FacebookAdvertising.svg",
    //         "description": "Data input platforms"
    //     },
    //     // {
    //     //     description: "Data input platforms",
    //     //     id: 18,
    //     //     // logo: "http://madconnect.io/Partners/Connector.svg",
    //     //     name: "Google",
    //     //     url: "https://www.google.com",
    //     // }
    // ])

    if (selectedPlatformsError !== undefined) {
      console.log("error", selectedPlatformsError);
    }
  }, [platformsData, selectedPlatformsError]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          maxWidth: "93.75rem",
          display: "flex",
          flexDirection: "row",
          width: "90%",
          mx: "auto",
          my: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "24%",
            pt: "1rem",
            borderRadius: 1,
            border: `1px solid ${color.themeLightGray}`,
          }}
        >
          <Box
            sx={{
              px: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              className="heading"
              sx={{
                fontSize: "1.125rem",
                color: "#8B989F",
                fontWeight: 500,
                fontFamily: "Helvetica Neue",
              }}
            >
              Filter by
            </Typography>
            <Typography
              className="heading"
              sx={{
                fontSize: "1rem",
                color: color.themeColor,
                fontWeight: 500,
                cursor: "pointer",
                fontFamily: "Helvetica Neue",
              }}
              onClick={() => {
                setSelectedCategories([]);
                setCategoryName("");
              }}
            >
              Reset filters
            </Typography>
          </Box>
          <Box sx={{ px: 2 }}>
            <div
              style={{
                height: 1,
                border: "1px solid #eeeeee",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Box>
          {/* <Box sx={{ py: 1, px: 2, mb:"1rem", height: "2rem", backgroundColor: 'rgba(54, 70, 172, 0.05)' }}>
                        <Typography className='heading' sx={{
                            fontSize: "0.9rem", mb: 1, color: '#3646AC',
                            cursor: 'pointer', fontWeight: 700
                        }}>View All</Typography>
                    </Box> */}
          <Box
            sx={{
              overflowY: "scroll",
              height: 600,
              px: 2,
              // '::-webkit-scrollbar': {
              //     width: '5px',
              //     height: '5px'
              // }
            }}
          >
            {allCategories.map((catItem, idx) => (
              <Box
                key={idx}
                sx={{ display: "flex", flexDirection: "column", mb: "1rem" }}
              >
                <Typography
                  className="heading"
                  sx={{
                    fontSize: "1rem",
                    mb: 1,
                    cursor: "pointer",
                    fontFamily: "Helvetica Neue",
                    color: `rgba(18, 18, 18, ${categoryName === catItem.name ? "1" : "0.9"
                      })`,
                    fontWeight: categoryName === catItem.name ? 700 : 500,
                  }}
                  onClick={() => {
                    if (
                      selectedCategories.length === 0 ||
                      categoryName !== catItem.name
                    ) {
                      setCategoryName(catItem.name);
                      setSelectedCategories(
                        catItem.subCategories.map((item) => item.id)
                      );
                    } else if (selectedCategories.length > 0) {
                      setCategoryName(catItem.name);
                      setSelectedCategories(
                        catItem.subCategories.map((item) => item.id)
                      );
                    } else {
                      setSelectedCategories([]);
                      setCategoryName("");
                    }
                  }}
                >
                  {catItem.name}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {catItem.subCategories.map(
                    (subCat, index) => (
                      <Typography
                        key={index}
                        sx={{
                          fontSize: "0.875rem",
                          color: "#666666",
                          mb: 1,
                          fontWeight: selectedCategories.includes(subCat.id)
                            ? 600
                            : 400,
                          cursor: "pointer",
                          fontFamily: "Helvetica Neue",
                        }}
                        onClick={() => {
                          if (!selectedCategories.includes(subCat.id)) {
                            setSelectedCategories([subCat.id]);
                            setCategoryName(catItem.name);
                            // setSelectedCategories([...selectedCategories, subCat.id])
                          } else {
                            setSelectedCategories(
                              selectedCategories.filter(
                                (scItem) => scItem === subCat.id
                              )
                            );
                          }
                        }}
                      >
                        {subCat.name}
                      </Typography>
                    )
                    // <FormControlLabel key={index}
                    //     control={<Checkbox disabled={isRequestedPlaform} checked={selectedCategories.includes(subCat.id)}
                    //         onChange={() => {
                    //             if (!selectedCategories.includes(subCat.id)) {
                    //                 setSelectedCategories([...selectedCategories, subCat.id])
                    //             } else {
                    //                 setSelectedCategories(selectedCategories.filter(scItem => scItem !== subCat.id))
                    //             }
                    //             setStart(0)
                    //             setEnd(12)
                    //         }} sx={{
                    //             color: '#eeeeee',
                    //             '&.Mui-checked': {
                    //                 color: teal[900],
                    //             },
                    //         }} />}
                    //     label={subCat.name} />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        {isRequestedPlaform ? (
          <Box
            sx={{
              width: "48%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                height: "3.125rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                className="heading"
                sx={{ fontSize: "1.2rem", fontWeight: 700 }}
              >
                <img
                  alt="<"
                  src={leftArrow}
                  style={{
                    width: "0.5rem",
                    height: "0.75rem",
                    marginRight: "0.625rem",
                    marginBottom: 3,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setName("");
                    setUrl("");
                    setIsRequestedPlatform(false);
                    setRequestError("");
                  }}
                ></img>
                <span
                  className="heading"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 700,
                    fontFamily: "Helvetica Neue",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    setName("");
                    setUrl("");
                    setIsRequestedPlatform(false);
                    setRequestError("");
                  }}
                >
                  Back
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                height: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="request-platform-container">
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  {validateRequestPlatformForm ? (
                    <span className="form-error">{Strings.wrong_input}</span>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "red",
                        textAlign: "center",
                        height: "1.25rem",
                      }}
                    >
                      {requestError}
                    </Typography>
                  )}
                </Box>
                <p
                  className="text-center mb-2"
                  style={{ fontSize: "1.5rem", fontWeight: 700 }}
                >
                  {Strings.request_platform}
                </p>
                <p className="text-center mb-4 sub-text-color">
                  Don’t see a platform you need? Request a platform below.
                </p>
                <div className="">
                  <CommonInput
                    height={"2.75rem"}
                    backgroundColor={"white"}
                    placeholder={Strings.name}
                    inputType={"text"}
                    value={name}
                    onChange={(event) => {
                      setRequestError("");
                      setName(event.target.value);
                    }}
                  ></CommonInput>
                </div>
                <div className="mt-3">
                  <CommonInput
                    height={"2.75rem"}
                    backgroundColor={"white"}
                    placeholder={Strings.url}
                    inputType={"text"}
                    value={url}
                    onChange={(event) => {
                      setRequestError("");
                      setUrl(event.target.value);
                    }}
                  ></CommonInput>
                </div>
                <div className="mt-3 mb-4">
                  <CommonButton
                    width={`100%`}
                    height={"2.75rem"}
                    textStyle={{
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                    onClick={(event) => {
                      _requestNewPlatform(event);
                    }}
                    title={Strings.request}
                    color={color.buttonColor}
                    isLoading={requestPlatformLoading}
                    disabled={!name || !url}
                  ></CommonButton>
                </div>
              </div>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: "48%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // border: '1px solid black'
              }}
            >
              <Typography
                className="heading"
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: 500,
                  fontFamily: "Helvetica Neue",
                  color: "#121212",
                }}
              >
                All Platforms
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  // border: '1px solid black'
                }}
              >
                {/* <img alt='' src={sortDown} style={{ width: "1.5rem", height: "1.5rem", marginRight: 20 }}></img> */}
                <Button
                  onClick={() => {
                    setIsRequestedPlatform(true);
                  }}
                  style={{
                    marginRight: "1.25rem",
                    cursor: "pointer",
                    border: "1px solid #3646AC",
                    backgroundColor: color.whiteColor,
                    // width: "40%",
                    boxShadow: "none",
                    height: "2.75rem",
                    color: color.buttonColor,
                    textTransform: "none",
                  }}
                  variant="contained"
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    {Strings.requested_platform}
                  </Typography>
                  {/* <label style={{ fontSize: "0.75rem", cursor: "pointer", marginLeft: 0, fontSize: '1.125rem', fontWeight: '500' }}>
                                        {Strings.requested_platform}
                                        </label> */}
                </Button>
                <OutlinedInput
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    if (selectedCategories.length === 0) {
                      setAllplatfomrs(
                        platfomrs.filter((item) =>
                          item.name
                            .toLowerCase()
                            .startsWith(e.target.value.toLowerCase())
                        )
                      );
                    } else {
                      setAllplatfomrs(
                        allplatfomrs.filter((item) =>
                          item.name
                            .toLowerCase()
                            .startsWith(e.target.value.toLowerCase())
                        )
                      );
                    }
                  }}
                  placeholder="Search"
                  inputProps={{ style: { padding: "12px 0px 10px 10px" } }}
                  style={{
                    backgroundColor: "#eeeeee",
                    width: "40%",
                    height: "2.75rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  endAdornment={
                    <div>
                      {" "}
                      <img
                        alt=""
                        src={searchImg}
                        style={{ width: "1.25rem", height: "1.25rem" }}
                      ></img>
                    </div>
                  }
                ></OutlinedInput>
              </Box>
            </Box>
            <Box sx={{ height: 550, my: 2 }}>
              {data !== undefined ? (
                <Grid container spacing={{ xs: 2, sm: 2, md: 2 }}>
                  {searchValue === ""
                    ? allplatfomrs.slice(start, end).map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={index}
                        onClick={(e) => {
                          if (!selectedPlatformIds.map((item) => item.platformId).includes(item.platformId)) {
                            setSelectedPlatform([...selectedPlatform, item]);
                          }
                        }}
                      >
                        <Item
                          sx={{
                            backgroundColor: selectedPlatformIds.map((item) => item.platformId).includes(
                              item.platformId
                            )
                              ? "rgba(25, 50, 65, 0.02)"
                              : "rgba(54, 70, 172, 0.02)",
                            border: selectedPlatformIds.map((item) => item.platformId).includes(item.platformId)
                              ? `1px solid ${color.themeColor}`
                              : `1px solid ${color.themeLightGray}`,
                          }}
                        // onClick={() => {
                        //     if (item.docUrl) {
                        //         window.open(item.docUrl)
                        //     }
                        // }}
                        >
                          <img
                            alt=""
                            src={item.logo ? item.logo : connectionLogo}
                            style={{ maxWidth: "100%", maxHeight: "60%" }}
                          ></img>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              maxWidth: "70%",
                              top: 90,
                              right: 12,
                              flexDirection: "row",
                            }}
                          >
                            <Tooltip title={<span style={{
                              textTransform: "capitalize",
                              fontSize: "12px"
                            }}>{item.dataType ? item.dataType : ""}</span>} placeholder="top">
                              <span style={{
                                color: "#000000c2", fontWeight: 600,
                                fontFamily: "Helvetica Neue",
                                textTransform: "capitalize",
                                fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                              }}>{item.dataType ? item.dataType : ""}</span>
                            </Tooltip>
                          </Box>
                          {item.status === 2 && (
                            <Box
                              sx={{
                                pt: 0.1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                top: 8,
                                right: 8,
                                width: "4.875rem",
                                height: "1.3125rem",
                                border: "1px solid #3646AC",
                                borderRadius: 5,
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontFamily: "Helvetica Neue",
                                  fontSize: "0.625rem",
                                  color: "#3646AC",
                                }}
                              >
                                Coming Soon
                              </Typography>
                            </Box>
                          )}
                        </Item>
                      </Grid>
                    ))
                    : allplatfomrs.map((item, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={index}
                        onClick={(e) => {
                          if (!selectedPlatformIds.map((item) => item.platformId).includes(item.platformId)) {
                            setSelectedPlatform([...selectedPlatform, item]);
                          }
                        }}
                      >
                        <Item
                          sx={{
                            backgroundColor: selectedPlatformIds.map((item) => item.platformId).includes(
                              item.platformId
                            )
                              ? "rgba(25, 50, 65, 0.02)"
                              : "rgba(54, 70, 172, 0.02)",
                            border: selectedPlatformIds.map((item) => item.platformId).includes(item.platformId)
                              ? `1px solid ${color.themeColor}`
                              : `1px solid ${color.themeLightGray}`,
                          }}
                        // onClick={() => {
                        //     if (item.docUrl) {
                        //         window.open(item.docUrl)
                        //     }
                        // }}
                        >
                          <img
                            alt=""
                            src={item.logo}
                            style={{ maxWidth: "100%", maxHeight: "60%" }}
                          ></img>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "absolute",
                              maxWidth: "70%",
                              top: 90,
                              right: 12,
                              flexDirection: "row",
                            }}
                          >
                            <Tooltip title={<span style={{
                              textTransform: "capitalize",
                              fontSize: "12px"
                            }}>{item.dataType ? item.dataType : ""}</span>} placeholder="top">
                              <span style={{
                                color: "#000000c2", fontWeight: 600,
                                fontFamily: "Helvetica Neue",
                                textTransform: "capitalize",
                                fontSize: "12px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                              }}>{item.dataType ? item.dataType : ""}</span>
                            </Tooltip>
                          </Box>
                          {item.status === 2 && (
                            <Box
                              sx={{
                                pt: 0.1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                                top: 8,
                                right: 8,
                                width: "4.875rem",
                                height: "1.3125rem",
                                border: "1px solid #3646AC",
                                borderRadius: 5,
                                backgroundColor: "#FFFFFF",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontFamily: "Helvetica Neue",
                                  fontSize: "0.625rem",
                                  color: "#3646AC",
                                }}
                              >
                                Coming Soon
                              </Typography>
                            </Box>
                          )}
                        </Item>
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={45} thickness={8}></CircularProgress>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Pagination
                count={
                  selectedCategories.length === 0
                    ? platfomrs.length % 12
                      ? parseInt(platfomrs.length / 12) + 1
                      : parseInt(platfomrs.length / 12)
                    : allplatfomrs.length % 12
                      ? parseInt(allplatfomrs.length / 12) + 1
                      : parseInt(allplatfomrs.length / 12)
                }
                shape="rounded"
                onChange={(e, page) => {
                  // console.log('page', page);
                  setStart((page - 1) * 12);
                  setEnd(page * 12);
                }}
                color="primary"
                sx={{
                  color: color.buttonColor,
                  "&.Mui-checked": {
                    color: color.buttonColor,
                  },
                }}
              />
              <CommonButton
                width={160}
                height={"2.75rem"}
                textStyle={{
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                isLoading={selectPlatformLoading}
                onClick={handleSelectPlatform}
                // onClick={(event) => {
                //     dispatch(setSelectedPlatforms(selectedPlatform))
                //     setStep(3);
                // }}
                title={Strings.next}
                color={
                  selectedPlatform.length === 0
                    ? color.themeLightGray
                    : color.buttonColor
                }
                disabled={
                  selectedPlatform.length === 0 || selectPlatformLoading
                }
              ></CommonButton>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "24%",
            // border: `1px solid ${color.themeLightGray}`
          }}
        >
          <Box sx={{ mt: "0.5rem" }}>
            <Typography
              sx={{
                cursor: "pointer",
                color: color.black,
                fontSize: "1.25rem",
                fontWeight: 500,
                fontFamily: "Helvetica Neue",
              }}
            >
              Selected Platforms
            </Typography>
          </Box>
          <Box
            sx={{
              p: "0.5rem",
              overflowY: selectedPlatformIds.length > 8 ? "scroll" : "none",
              mt: "1rem",
              height: 600,
              borderRadius: 1,
              border: `1px solid ${color.themeLightGray}`,
            }}
          >
            {selectedPlatform.length > 0 ? (
              selectedPlatform.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    p: "0.5rem",
                    height: "3.75rem",
                    mb: 1,
                    borderRadius: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid rgba(54, 70, 172, 0.15)",
                    backgroundColor: color.lightThemeColor,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "2.5rem",
                        height: "2.5rem",
                        borderRadius: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      {item.logo ? (
                        <img
                          alt=""
                          src={item.logo}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></img>
                      ) : (
                        <img
                          alt=""
                          src={connectionLogo}
                          style={{ width: "2rem", height: "2rem" }}
                        ></img>
                      )}
                    </Box>
                    <Box
                      sx={{
                        ml: "1rem",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: color.black,
                          fontSize: "1rem",
                          fontWeight: 500,
                          fontFamily: "Helvetica Neue",
                        }}
                      >
                        {item.name}
                      </Typography>
                      {item.logo === undefined && (
                        // <Typography sx={{ color: color.black, fontSize: "0.75rem", fontWeight: 500, fontFamily: 'Helvetica Neue' }}>
                        //     Lorem Ipsum is fake text
                        // </Typography> :
                        <Typography
                          sx={{
                            color: color.buttonColor,
                            fontSize: "0.75rem",
                            fontWeight: 500,
                            fontFamily: "Helvetica Neue",
                          }}
                        >
                          Requested
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <ClearIcon
                    sx={{
                      color: color.themeColor,
                      width: "1.25rem",
                      height: "1.25rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (item.logo !== undefined) {
                        setSelectedPlatform(
                          selectedPlatform.filter((el) => el.id !== item.id)
                        );
                      } else {
                        // API call
                        _deleteRequestedPlatform(item.id);
                      }
                    }}
                  />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt=""
                  src={slash}
                  style={{ width: "4rem", height: "4rem", marginBottom: 10 }}
                ></img>
                <Typography
                  sx={{
                    color: color.themeGray,
                    fontSize: "1rem",
                    letterSpacing: 1,
                    fontWeight: 500,
                  }}
                >
                  No platform is selected
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Platforms;
