import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";



import AppLayout from "../../components/AppLayout/AppLayout";
import { color } from "../../utils/Colors";
import "./SRoles.css";
import { getPanelType, madTechState } from "../../features/madTechSlice";
import SEditRole from "./SEditRole";
import {
  useDeleteRoleMutation,
  useGetASRoleMutation,
  useUpdateRoleMutation,
} from "../../services/query";
import trash from '../../assets/trash-2.svg'
import cancel from '../../assets/cancel.svg'
import { useMyContext } from "../../components/CommanToasterContext/toast";
import { Strings } from "../../utils/Strings";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import Loader from "../../components/Setting/Loader";
import { CenteredBox, ConfirmationTextBox, FormNameInputWrapper, FormNameLabelWrapper, FormNameWrapper } from "../SRoles/Srole.styles"


const SRoleEditForm = () => {
  const panelType = useSelector(getPanelType);
  const { selectedAccount } = useSelector(madTechState);
  const { id: roleId } = useParams();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [updateRole, { data: updateRoleData, isLoading: updateRoleLoading, error: updateRoleError, },] = useUpdateRoleMutation();
  const [deleteRole, { data: deleteRoleData, isLoading: deleteRoleLoading, error: deleteRoleError, },] = useDeleteRoleMutation();
  const [getData, { data: getRoleData, isLoading: getRoleLoading, error: getRoleError, refetch, },] = useGetASRoleMutation();

  const navigate = useNavigate();
  const userDetail = JSON.parse(localStorage.getItem("USER"));

  const [isRoleEdit, setIsRoleEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();
  useEffect(() => {
    if (!roleId) return;
    getData({ roleId });
  }, [roleId]);

  const _handleUpdateRole = (event) => {
    event.preventDefault();
    if (selectedItem.roleName === "") {
      setOpen2(true)
      setMessage2(Strings.wrong_credentials)
      return;
    }
    updateRole(selectedItem);
  };
  const deleteRoleClose = () => {
    setIsDeleteOpen(false);
  };
  const _handleDeleteRole = (event) => {
    event.preventDefault();
    var payload = {
      id: selectedItem.id,
    };

    deleteRole(payload);
  };

  useEffect(() => {
    if (updateRoleData !== undefined) {
      if (updateRoleData.statusCode === 200) {
        setMessage(updateRoleData?.statusMessage)
        setOpen(true)
        navigate(selectedAccount ? '/roles?accountId=' + selectedAccount.id : '/roles')
        return
      } else {
        setMessage2(updateRoleData.statusMessage);
        setOpen2(true)
      }
    }
    if (updateRoleError !== undefined) {
      console.log("updateRoleError", updateRoleError);
    }
  }, [updateRoleData, updateRoleError]);

  useEffect(() => {
    if (deleteRoleData !== undefined) {
      if (deleteRoleData.statusCode === 200) {
        setIsDeleteOpen(false);
        setSelectedItem(null);
        setIsRoleEdit(false);
        navigate("/roles");
        return
      } else {
        setMessage2(updateRoleData.statusMessage);
        setOpen2(true)
      }
    }
    if (deleteRoleError !== undefined) {
      console.log("deleteRoleError", deleteRoleError);
    }
  }, [deleteRoleData, deleteRoleError]);

  useEffect(() => {
    if (
      getRoleData !== undefined &&
      getRoleData.statusCode === 200 &&
      getRoleData.response
    ) {
      var response = getRoleData.response;
      setSelectedItem(response);
    }

    if (getRoleError !== undefined) {
      console.log("error", getRoleError);
      setSelectedItem({
        id: "",
        name: "",
        description: "",
        rolePermissionDetails: []
      })
    }
  }, [getRoleData, getRoleError]);

  const [inputText, setInputText] = useState("");
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
    setInputText(inputValue);
  };

  useEffect(() => {
    if (inputText === "DELETE") {
      setIsDeleteButtonEnabled(true);
    } else {
      setIsDeleteButtonEnabled(false);
    }
  }, [inputText]);

  useEffect(() => {
    setInputText("");
  }, [isDeleteOpen]);

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <AppLayout>
      <Box px="1.5rem">
        <SBreadCrumb path="roles" pathName={selectedItem?.name} ModulepathName="Roles" modulePath="roles" state={isRoleEdit} setState={setIsRoleEdit} />

        {getRoleLoading || selectedItem === null ? (
          <Loader />
        ) : (
          // getRoleError ? <Typography color={color.themeRed}>Something went wrong. {getRoleError?.error}</Typography> :
          <SEditRole
            key={"edit"}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            _handleUpdateRole={_handleUpdateRole}
            isRoleEdit={isRoleEdit}
            setIsRoleEdit={setIsRoleEdit}
            setIsDeleteOpen={setIsDeleteOpen}
          ></SEditRole>
        )}
      </Box>
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={isDeleteOpen} onClose={deleteRoleClose} closeAfterTransition
        slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500, }, }}>
        <Fade in={isDeleteOpen}>
          <CenteredBox>
            <Typography fontWeight={700} fontFamily="Helvetica Neue" fontSize="1rem" textAlign="center">
              {selectedItem !== null && (
                <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2} alignItems="center">
                  <Box display="flex" flexDirection="row" alignItems="center" width="100%" justifyContent="space-between">
                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                      <Typography sx={{ fontSize: 22, fontFamily: "Helvetica Neue", color: color.buttonColor, }}>  {selectedItem.name}</Typography>
                      <Typography sx={{ fontSize: "1rem", fontFamily: "Helvetica Neue", color: "#656C7B", }}>  {selectedItem.id}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Typography>
            <ConfirmationTextBox>  Are you sure you want delete this role?</ConfirmationTextBox>
            <Box width="100%" display="flex" justifyContent="center" flexDirection="column">
              <FormNameWrapper sx={{ width: "70%", display: "flex", justifyContent: "center", marginRight: "auto", marginLeft: "auto", }} size="small">
                <FormNameLabelWrapper id="delete" >   Type DELETE to confirm </FormNameLabelWrapper>
                <FormNameInputWrapper placeholder={""} labelid="delete" id="delete" label="Type DELETE to confirm" onChange={handleInputChange}
                  value={inputText} autoComplete="off" />
              </FormNameWrapper>
            </Box>
            <Box mt={3} display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap='1rem'>
              <Button onClick={(event) => { _handleDeleteRole(event); }} disabled={deleteRoleLoading || !isDeleteButtonEnabled}
                style={{
                  cursor: "pointer", backgroundColor: color.whiteColor, width: "6.625rem", height: "2.5rem", color: color.themeRed, textTransform: "none",
                  border: "1.5px solid", borderColor: color.themeRed, cursor: isDeleteButtonEnabled ? 'pointer' : 'not-allowed'
                }} variant="contained">
                <Box display="flex" gap="0.5rem" alignItems='center'>
                  <img alt="" src={trash} style={{ width: "1.3rem", display: "flex", alignItems: "center", }}></img>
                  <Typography className="labelStyle">Delete</Typography>
                </Box>
              </Button>
              <Button onClick={deleteRoleClose} style={{
                cursor: "pointer", backgroundColor: color.whiteColor, width: "6.625rem", height: "2.5rem",
                color: color.themeBlack, textTransform: "none",
              }} variant="contained">
                <Box display="flex" gap="0.5rem" alignItems='center'>
                  <img alt="" src={cancel} style={{ width: "0.8rem", display: "flex", alignItems: "center", }}  ></img>
                  <Typography className="labelStyle">Cancel</Typography>
                </Box>
              </Button>
            </Box>
          </CenteredBox>
        </Fade>
      </Modal>
    </AppLayout>
  );
};

export default SRoleEditForm;
