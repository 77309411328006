export const fiveSixSevenArr = [
  {
    question: "THIRD PARTY PLATFORMS",
    start: 5,
    answer:
      " MadTech is not responsible or liable for the content or data accessible through any Third Party Platforms accessed by Client through the Services. Client understands and acknowledges that Client is solely responsible for all content and data it sends or accesses through the Services, and Client, not MadTech, has full responsibility for all such content and data, including its dissemination, transfer, accessibility, legality, reliability, accuracy, and appropriateness.",
    isAlpha: false,
  },
  {
    question: "ENFORCEMENT",
    start: 6,
    answer:
      " MadTech reserves the right and has absolute discretion to enforce the Agreement. MadTech may, in its sole discretion, immediately terminate or suspend: (i) the Service; (ii) the License Agreement; and/or (iii) Client’s or any Client User’s access to the Service, if MadTech determines that Client’s services, offerings or activities violate the Agreement. Without limitation, MadTech also reserves the right to report any activity (including the disclosure of appropriate data) that it suspects may violate any law or regulation to appropriate law enforcement officials, regulators, or other appropriate third parties. MadTech also may cooperate with appropriate law enforcement agencies to assist in the investigation and prosecution of any illegal conduct.",
    isAlpha: false,
  },
  {
    question: "MODIFICATIONS TO THE SERVICE",
    start: 7,
    answer:
      " MadTech has the right to amend the Terms and modify or discontinue all or part of the Service, temporarily or permanently, at any time and with or without notice, and is not obligated to support or update the Service. Any amendment of the Terms shall become effective immediately after such amendment is initially posted on this [site/page]. Client’s continued use of the Service after the posting of the amended Terms shall constitute Client’s affirmative acknowledgment of, and agreement to abide and be bound by, the Terms, as amended.",
    isAlpha: false,
  },
  {
    question: "CONFIDENTIAL INFORMATION",
    start: 8,
    isAlpha: true,
    alphaArr: [
      {
        key: "A.",
        value: `"Confidential Information" means all written or oral information, disclosed by either party to the other, related to the operations of either party or a third party that has been identified as confidential or that by the nature of the circumstances surrounding disclosure ought reasonably to be understood as confidential. The parties acknowledge that during the performance of this Agreement, each party will have access to certain of the other party’s Confidential Information or Confidential Information of third parties that the disclosing party is required to maintain as confidential. Both parties agree that all items of Confidential Information are proprietary to the disclosing party or such third party, as applicable, and will remain the sole property of the disclosing party or such third party.`,
      },
      {
        key: "B.",
        value:
          "Each party agrees: (i) to use Confidential Information disclosed by the other party only for the purposes described herein; (ii) not to reproduce Confidential Information disclosed by the other party, and use commercially reasonable efforts to hold in confidence and protect such Confidential Information from dissemination to, and use by, any third party; (iii) not to create any derivative work from Confidential Information disclosed to such party by the other party; (iv) to restrict access to the Confidential Information disclosed by the other party to such of its personnel, agents, and/or consultants, if any, who have a need to have access and who have been advised of and have agreed in writing to treat such information in accordance with the terms of the Agreement; and (v) to the extent practicable, to return or destroy all Confidential Information disclosed by the other party that is in its possession upon termination or expiration of the Agreement, upon request of the other party.",
      },
      {
        key: "C.",
        value: `Notwithstanding the foregoing, the provisions of this Section 8 will not apply to Confidential Information that (i) is publicly available or in the public domain at the time disclosed; (ii) is or becomes publicly available or enters the public domain through no fault of the recipient; (iii) is rightfully communicated to the recipient by persons not bound by confidentiality obligations with respect thereto; (iv) is already in the recipient’s possession free of any confidentiality obligations with respect thereto at the time of disclosure; (v) is independently developed by the recipient; or (vi) is approved for release or disclosure by the disclosing party without restriction.`,
      },
      {
        key: "D.",
        value:
          "Notwithstanding the foregoing, each party may disclose Confidential Information to the limited extent required (i) in order to comply with the order of a court or other governmental body, or as otherwise necessary to comply with applicable law, provided that, to the extent permitted by law, the party making the disclosure pursuant to the order shall first have given written notice to the other party and made a reasonable effort to obtain a protective order; or (ii) to establish a party’s rights under this Agreement, including to make such court filings as it may be required to do.",
      },
      {
        key: "E.",
        value: `If Client or any of its Client Users provides MadTech with any suggestions, recommendations, or other feedback relating to MadTech’s current or future products or services ("Feedback"), MadTech shall have the right to use the Feedback in any manner, including but not limited to future enhancements and modifications to the Services. Client hereby grants to MadTech and its assigns a perpetual, worldwide, fully transferable, sublicensable, fully paid-up, irrevocable, royalty free license to use, reproduce, modify, create derivative works from, distribute, and display the Feedback in any manner any for any purpose, in any media, software, or technology of any kind now existing or developed in the future, without any obligation to provide attribution or compensation to Client or any third party.  In addition, MadTech shall be free to reuse all general knowledge, experience, know-how, works and technologies (including ideas, concepts, processes and techniques) related to or acquired during provision of the Services.`,
      },
    ],
  },
  {
    question: "INTELLECTUAL PROPERTY RIGHTS",
    start: 9,
    answer:
      `The Service is proprietary to MadTech and/or its licensors. All of the text, images, marks, logos and other content of the Service ("Service Content") is proprietary to MadTech or its licensors. MadTech authorizes Client to view, download, and print the Service Content provided that Client: (i) may only do so for its own internal business use; (ii) may not copy, reproduce, publish, redistribute or modify any Service Content without MadTech’s prior authorization; (iii) may not remove any copyright, trademark, or other proprietary notices that have been placed on the Service Content by MadTech or any of its licensors. Client represents and warrants that its use of the Service Content will be consistent with this license and the License Agreement and will not infringe or violate the rights of any other party or breach any contract or legal duty to any other parties.`,
    isAlpha: false,
  },
];
