import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import leftArrowImg from '../../assets/leftArrow.svg'
import './signin.css'
import { Strings } from '../../utils/Strings';
import CommonButton from '../../components/CommonButton/CommonButton'
import CommonInput from '../../components/CommonInput/CommonInput';
import { useForgotPasswordMutation } from '../../services/query';
import useDocumentTitle from '../../hook/useDocumentTitle';
import { BackToLogin, ErrorMsg, Heading, Para } from './CommanForget.styles';

function CommanForgotPass(props) {

    const { background, classname, OAuthType, boxRadius, boxShadow, TitlefontSize, textforntSize } = props
    const location = useLocation();
    const navigate = useNavigate();
    
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get('clientId');

    useEffect(() => {
        if (OAuthType === true && clientId === null) {
            window.close()
        }
    }, [OAuthType])

    useDocumentTitle('Forgot Password')
    const matchesxsss = useMediaQuery('(min-width:495px)');
    const [email, setEmail] = useState("");
    const [errorMsg, setErrormsg] = useState('')
    const [emailErr, setEmailErr] = useState(false);
    const [msg, setMsg] = useState('')

    const [forgotPassword, { data, error, isLoading }] = useForgotPasswordMutation()


    const validateEmail = () => {
        if (!email.match(/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailErr(true);
            return true;
        } else {
            setEmailErr(false);
        }
        return false;
    };

    const sendEmail = useCallback(() => {
        if (validateEmail()) {
            return
        }
        forgotPassword({ email })
    }, [useForgotPasswordMutation, email])

    useEffect(() => {
        if (data !== undefined)
            if (data.response) {
                setMsg('An email has been sent to your account.')
                setTimeout(() => {
                    if (OAuthType === true) {
                        navigate(clientId ? "/oauth/signin?clientId=" + clientId : "/oauth/signin")
                    } else {
                        localStorage.clear()
                        navigate('/signin')
                    }
                }, 3000)
            } else {
                setErrormsg(data.statusMessage)
            }
        if (error !== undefined) {
            console.log('error', error);
        }
    }, [data, error])

    const handleChange = (event) => {
        setEmail(event.target.value);
        setEmailErr(false)
        setErrormsg('')
        setMsg('')
    }

    const handleBack = () => {
        setErrormsg('')
        setMsg('')
        if (OAuthType === true) {
        navigate(clientId ? '/oauth/signin?clientId=' + clientId : '/oauth/signin');
        } else {
            navigate('/signin');
            }
    }

    return (
        <Grid item md={5} lg={5} sm={12} xs={12}>
            <div className={classname} style={{ background: background }}>
                <div className='signin-container' style={{
                    width: "60%",
                    backgroundColor: "white",
                    minWidth: matchesxsss ? 400 : 320,
                    padding: matchesxsss ? 50 : 30,
                    maxWidth: OAuthType ? "250px" : "none",
                    borderRadius: boxRadius,
                    boxShadow: boxShadow
                }}
                >
                    <Box display="flex" textAlign="center" justifyContent="center">
                        <ErrorMsg variant='subtitle2'>{errorMsg}</ErrorMsg>
                    </Box>
                    <Heading className='text-center mb-2' TitlefontSize={TitlefontSize}>Forgot password</Heading>
                    <Para className='text-center mb-4 sub-text-color' textforntSize={textforntSize}>Please enter your email address. We will send you an email to reset your password.</Para>
                    <div className=''>
                        <CommonInput
                            height={"2.75rem"}
                            isError={!!emailErr}
                            backgroundColor={'white'}
                            placeholder={'Enter Email'}
                            value={email}
                            onChange={(event) => handleChange(event)}
                       
                        />
                        <div style={{ height: 10 }}>
                            {
                                emailErr &&
                                <span className='form-error'>{Strings.email_error}</span>
                            }
                            {
                                msg &&
                                <span style={{ fontSize: "0.75rem", color: "green" }}>{msg}</span>
                            }
                        </div>
                    </div>
                    <div className='mt-4 mb-4'>
                        <CommonButton
                            width={"100%"}
                            height={"2.75rem"}
                            textStyle={{
                                fontSize: '1.125rem',
                                fontWeight: '500',
                                fontFamily: 'Helvetica Neue'
                            }}
                            onClick={sendEmail}
                            isLoading={isLoading}
                            title={Strings.send_email}
                            color={'#3843AC'}
                        />
                    </div>
                    <hr className='dashed' />
                    <Box className='mt-3 text-center' display="flex" justifyContent="center" alignItems="center"
                        sx={{cursor: "pointer",}} 
                        onClick={handleBack}
                        >
                        <img style={{ height: "0.75rem", width: 15, marginRight: 10 }}
                            alt={''} src={leftArrowImg}></img>
                        <BackToLogin className='sub-text-color'>
                            Back to log in
                        </BackToLogin>
                    </Box>
                </div>
            </div>
        </Grid>

    );
}

export default CommanForgotPass;
