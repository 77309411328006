import React from "react";
import {
  Box,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useSelector } from "react-redux";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useNavigate } from "react-router-dom";

import { color } from "../../../utils/Colors";
import CommonButton from "../../../components/CommonButton/CommonButton";
import { Strings } from "../../../utils/Strings";
import { madTechState } from "../../../features/madTechSlice";
import {
  AddUserFieldWrapper,
  AddUserFromHeading,
  AddUserFromWrapper,
  InputFieldWrapper,
  InputLabelWrapper,
  InputsWrapper,
  PhoneInputWrapper,
} from "../SUers.styles";
import { leftArr , rightArr } from "../constants";

const SAddUser = ({ userDetails, setUserDetails, _handleAddUser, roles }) => {
  const navigate = useNavigate();
  const { selectedAccount } = useSelector(madTechState);

  const handleChange = (e, name) => {
    setUserDetails({
      ...userDetails,
      [name]: e.target.value,
    });
  };

  const handleCancel = () => {
    navigate(
      selectedAccount
        ? "/users?accountId=" + selectedAccount.id
        : "/users"
    );
  }

  return (
    <>
      <AddUserFromWrapper>
        <AddUserFromHeading>User details</AddUserFromHeading>
        <AddUserFieldWrapper>
          <Box display="flex" flexDirection="column" width="44%">
            <Box display="flex" justifyContent="space-between">
              {leftArr?.map((data , i) => {
                return (
                  <InputsWrapper size="small" key={i}>
                    <InputLabelWrapper id={data.id}>{data.label}</InputLabelWrapper>
                    <InputFieldWrapper
                      placeholder={data.label}
                      labelid={data.id}
                      id={data.id}
                      label={data.label}
                      value={userDetails[data.id]}
                      onChange={(e) => handleChange(e, data.id)}
                    />
                  </InputsWrapper>
                );
              })}
            </Box>
            <InputsWrapper width="100%" mt="24px" size="small">
              <InputLabelWrapper id="workEmail">Work email*</InputLabelWrapper>
              <InputFieldWrapper
                placeholder={"Work email*"}
                labelid="workEmail"
                id="workEmail"
                label="Work email*"
                value={userDetails.email}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
              />
            </InputsWrapper>
            <Box sx={{ mt: 3, height: "2.75rem", backgroundColor: "#FFFFFF" }}>
              <PhoneInputWrapper
                placeholder={`${Strings.phoneNumber}*`}
                inputClass="colors"
                country={"us"}
                countryCodeEditable={false}
                enableSearch={true}
                value={userDetails.phoneNumber}
                onChange={(phone) => {
                  setUserDetails({ ...userDetails, phoneNumber: phone });
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "44%",
            }}
          >
            {rightArr?.map((data,i) => {
              return (
                <InputsWrapper key={i} width="100%" mt={i>0 && "24px"} size="small">
                  <InputLabelWrapper id={data.id}>{data.label}</InputLabelWrapper>
                  <InputFieldWrapper
                    placeholder={data.label}
                    labelid={data.id}
                    id={data.id}
                    label={data.label}
                    value={userDetails[data.id]}
                    onChange={(e) => handleChange(e, data.id)}
                  />
                </InputsWrapper>
              );
            })}

            <InputsWrapper width="100%" mt="24px" size="small">
              <InputLabelWrapper id="role">Role*</InputLabelWrapper>
              <Select
                sx={{
                  height: "2.75rem",
                  fontSize: "1rem",
                  color: "#666666",
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Helvetica Neue",
                }}
                labelid="role"
                id="roleId"
                label="Role*"
                value={userDetails?.roleId}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, roleId: e.target.value })
                }
              >
                {roles.map((role) => {
                  return (
                    <MenuItem
                      sx={{
                        fontSize: "0.875rem",
                        backgroundColor: "#FFFFFF",
                        fontFamily: "Helvetica Neue",
                      }}
                      value={role.id}
                    >
                      {role.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </InputsWrapper>
          </Box>
        </AddUserFieldWrapper>
      </AddUserFromWrapper>
      {/* USER DETAILS END */}

      {/* BUTTONS START */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          my: "1rem",
          gap: "0.5rem",
        }}
      >
        <CommonButton
          width={106}
          height={40}
          textStyle={{
            fontSize: "1rem",
            fontWeight: 600,
            fontFamily: "Helvetica",
            letterSpacing: "normal",
          }}
          customStyle={{
            marginRight: "20px",
            marginTop: "20px",
          }}
          onClick={(event) => {
            _handleAddUser(event);
          }}
          title={"Save"}
          color={color.buttonColor}
          startIcon={
            <BookmarkBorderOutlinedIcon
              startIconStyle={{ height: 24, width: 24 }}
              sx={{ marginRight: "-8px", marginLeft: "4px" }}
            />
          }
        ></CommonButton>
        <Button
          onClick={handleCancel}
          className="cancel_style1"
          variant="contained"
          startIcon={
            <ClearOutlinedIcon
              startIconStyle={{ height: 24, width: 24 }}
              sx={{ marginRight: "-8px", marginLeft: "4px" }}
            />
          }
        >
          <label
            style={{
              cursor: "pointer",
              fontSize: "1rem",
              fontWeight: 600,
              fontFamily: "Helvetica",
              letterSpacing: "normal",
              display: "flex",
              alignItems: "center",
              marginTop: "1px",
            }}
          >
            Cancel
          </label>
        </Button>
      </Box>
      {/* BUTTONS END */}
    </>
  );
};

export default SAddUser;
