import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'


import NaLayout from '../../components/NavLayout/NaLayout'
import useDocumentTitle from '../../hook/useDocumentTitle'
import { color } from '../../utils/Colors'
import HomeFooter from '../Home/HomeFooter'
import LetsConnect from '../Home/LetsConnect'

const PrivacyPolicy = () => {

  useDocumentTitle('Privacy Policy')
  const matcheslg = useMediaQuery('(min-width:1100px)');
  const matchesmd = useMediaQuery('(min-width:980px)');
  const matchessm = useMediaQuery('(min-width:880px)');
  const matchesxs = useMediaQuery('(min-width:760px)');
  const matchesxss = useMediaQuery('(min-width:600px)');

  return (
    <NaLayout>
      <Helmet>
        <script dangerouslySetInnerHTML={{
          __html: (function (w, d, s, l, i) {
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s); j.async = !0; j.src =
              '//launch.amplifyreach.com/' + s + '/' + l + '/' + i + '.js?t=' + new Date().getTime(); f.parentNode.insertBefore(j, f)
          })(window, document, 'script', '2005', 'c97f6e41b15f6dd9')
        }}>
        </script>
      </Helmet>
      <Box sx={{ mx: 'auto' }}>
        <Box sx={{
          py: 6, display: 'flex',
          flexDirection: 'column', width: '100%',
          backgroundColor: color.themeColor,
          justifyContent: 'center', alignItems: 'center'
        }}>
          <Box sx={{ width: '91%', maxWidth: 1350 }}>
            <Typography sx={{
              color: '#fff', fontWeight: 400, fontFamily: 'Phosphate',
              // fontSize: '2.5rem',
              fontSize: matcheslg ? '2.5rem' : matchesmd ? '2.3rem' : matchessm ? '2rem' : matchesxs ? '1.8rem' : '1.6rem',
              letterSpacing: 0.2,
            }}>
              Privacy policy
            </Typography>
            <Typography sx={{
              width: '60%', textAlign: "left", mt: "2rem", color: '#fff',
              fontWeight: 500, fontFamily: 'Helvetica Neue',
              // fontSize: '1rem',
              fontSize: matcheslg ? '1rem' : matchesmd ? '0.9rem' : matchessm ? '0.8rem' : matchesxs ? '0.7rem' : '0.7rem',
              letterSpacing: 0.2,
            }}>
              Last Updated: 22 January, 2023
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          py: 10, display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center', backgroundColor: '#eeeeee'
        }}>
          <Box sx={{ width: '91%', maxWidth: 1350, backgroundColor: '#fff', p: 3, borderRadius: 2, border: '1px solid rgba(54, 70, 172, 0.24)' }}>
            <div>
              <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
              <meta name="Generator" content="Microsoft Word 15 (filtered)" />
              <style dangerouslySetInnerHTML={{ __html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n@font-face\n\t{font-family:\"Noto Sans Symbols\";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:170%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpFirst\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpMiddle\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpLast\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nspan.Heading1Char\n\t{mso-style-name:\"Heading 1 Char\";\n\tmso-style-link:\"Heading 1\";\n\tfont-family:\"Arial\",sans-serif;\n\tcolor:windowtext;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-family:\"Arial\",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:6.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:.5in 1.0in 1.25in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n" }} />
              <div className="WordSection1">
                <p className="MsoNormal"><span style={{ "font-size": "14.0pt", "line-height": "107%" }}>CCPA/CPRA
                  CONSUMER PRIVACY RIGHTS POLICY</span></p>
                <p className="MsoNormal">Dated:&nbsp; February 1, 2023</p>
                <p className="MsoNormal"><span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }}>Madtech, LLC. ("<b>MadTech</b>") respects your
                  right to privacy.&nbsp; This Privacy Notice explains who we are, how we collect,
                  store, share and use personal data about you, and how you can exercise your
                  privacy rights.&nbsp; This Privacy Notice applies to personal data that we collect,
                  including through our websites at </span><a href="http://www.madtech.io"><span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#1155CC", "background": "white" }}>www.madtech.io</span></a><span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }}> and </span><a href="http://www.madconnect.io"><span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#1155CC", "background": "white" }}>www.madconnect.io</span></a><span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }}>,
                    within our product(s) and on other websites that MadTech operates and that link
                    to this policy ("collectively <b>Websites</b>”). </span></p>
                <p className="MsoNormal"><span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }}>If you have any questions or concerns about our use of your
                  personal data, then please contact us using the contact details provided at the
                  bottom of this Privacy Notice.</span></p>
                <h1 style={{ "border": "none" }}><a name="_heading=h.48osm77m91nm" />1.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>YOUR RIGHTS AS A
                  CALIFORNIA CONSUMER</h1>
                <p className="MsoNormal">At MadTech we respect your privacy rights as a consumer
                  (“Consumer”) under the California Consumer Privacy Act of 2018, as revised by
                  the California Privacy Rights Act of 2020, and the associated regulations (the
                  “CCPA/CPRA”) and other California laws. We are committed to protecting your
                  CCPA/CPRA rights to: </p>
                <ol style={{ "margin-top": "0in" }} start={1} type={1}>
                  <li className="MsoNormal" style={{ "margin-top": "12.0pt", "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify" }}>Delete personal information collected from or about them (“Right
                    to Delete”).</li>
                  <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify" }}>Correct
                    inaccurate Personal Information collected and maintained about them
                    (“Right to Correct”).</li>
                  <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify" }}>Know what
                    personal information is being collected about them, to access that
                    personal information, and to know what personal information about them is
                    being sold or shared and to whom (together called the “Right to Know”).</li>
                  <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify" }}>Opt-out of
                    the Sale or sharing of their personal information (“Right to Opt-out”).</li>
                  <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify" }}>Limit use and
                    disclosure of sensitive personal information to what is necessary to
                    perform the services or provide the goods reasonably expected by an
                    average Consumer who requests such goods or services (“Right to Limit”).</li>
                  <li className="MsoNormal" style={{ "margin-bottom": "0in", "-webkit-text-align": "justify", "text-align": "justify" }}>Not be
                    retaliated against for exercising their rights (“Right to No
                    Discrimination”).</li>
                </ol>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">This document provides you with a description of our
                  business practices, both online and offline, regarding the collection use,
                  disclosure, and sale of personal information and of the rights of Consumers
                  regarding their own personal information. </p>
                <p className="MsoNormal">The terms used in this policy have the meanings used in the
                  CCPA/CPRA. To learn more about your California privacy rights, please
                  visit:&nbsp;<a href="https://oag.ca.gov/privacy/privacy-laws"><span style={{ "color": "windowtext" }}>https://oag.ca.gov/privacy/privacy-laws</span></a>.</p>
                <h1><a name="_heading=h.mt129wjaneem" />2.<span style={{}}>&nbsp;&nbsp;&nbsp;
                </span>PERSONAL INFORMATION WE COLLECT, SELL, SHARE, AND OTHERWISE DISCLOSE</h1>
                <p className="MsoNormal">The CCPA/CPRA defines personal information as “information
                  that identifies, relates to, describes, is reasonably capable of being
                  associated with, or could reasonably be linked, directly or indirectly, with a
                  particular consumer or household.” The CCPA/CPRA defines the following
                  categories of personal information:</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Identifiers:</span></b><span style={{ "color": "black" }}> name, alias, address, online identifier, Internet Protocol
                  address, email address, account name, Social Security number, Driver’s license
                  number, passport number. </span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Customer records (defined by the
                  California Customer Records Law, Cal. Civ. Code Section 1798.80):</span></b><span style={{ "color": "black" }}> name, signature, Social Security number, physical
                    characteristics or description, address, telephone number, driver’s license or
                    state identification card number, insurance policy number, education,
                    employment, employment history, bank account number, credit card number, debit
                    card number, any other financial information, medical information, health
                    insurance information.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Characteristics of protected
                  classifications under California or federal law:</span></b><span style={{ "color": "black" }}> age (over 40), AIDS/HIV-positive status, ancestry,
                    citizenship status, disability, gender identity or expression, genetic
                    information or conditions, marital status, medical condition, military or
                    veteran status, national origin, political affiliations or activities,
                    pregnancy, race, religion, sex (including pregnancy, childbirth, and related
                    medical conditions), sexual orientation.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Commercial information:</span></b><span style={{ "color": "black" }}> records of personal property, products or services
                  purchased, obtained, or considered or other purchasing or consuming histories
                  or tendencies.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Biometric information:</span></b><span style={{ "color": "black" }}> imagery of the iris, retina, fingerprint, face, hand,
                  palm, vein patterns, and voice recordings, from which an identifier template,
                  such as a faceprint, a minutiae template, or a voiceprint, can be extracted,
                  and keystroke patterns or rhythms, gait patterns or rhythms, and sleep, health,
                  or exercise data that contain identifying information.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Internet or other network activity
                  information:</span></b><span style={{ "color": "black" }}> browsing history, search
                    history, information regarding a consumer’s interaction with an internet
                    website application, or advertisement, or other similar information.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Geolocation data: </span></b><span style={{ "color": "black" }}>any data that is derived from a device and that is used or
                  intended to be used to locate a Consumer within a geographic area</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Audio, electronic, visual, thermal,
                  olfactory, or similar information</span></b><span style={{ "color": "black" }}>
                    (collectively referred to as “Sensory information”), as described in the
                    category name.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Professional or employment-related
                  information</span></b><span style={{ "color": "black" }}>, as described in the category
                    name. </span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Education information:</span></b><span style={{ "color": "black" }}> information that is not publicly available personally
                  identifiable information as defined in the Family Educational Rights and
                  Privacy Act (20 U.S.C. Section 1232g; 34 C.F.R. Part 99).</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Sensitive personal information</span></b><span style={{ "color": "black" }}>, Social Security number, driver’s license number, state
                  identification card number, passport number, account log-in, financial account,
                  debit card, or credit card number in combination with any required security or
                  access code, password, or credentials allowing access to an account, precise
                  geolocation (meaning any geolocation data that can be used to locate a consumer
                  within a radius of 1,850 feet.), racial or ethnic origin, religious or
                  philosophical beliefs, union membership, contents of a consumer’s mail, email,
                  and text messages unless the business collecting or using the data is the
                  intended recipient of the communication, genetic data, biometric information
                  for the purpose of uniquely identifying a consumer, personal information
                  collected and analyzed concerning a consumer’s health, personal information
                  collected and analyzed concerning a consumer’s sex life or sexual orientation,
                  when collected or used for</span> the purposes of inferring characteristics
                  about a consumer.&nbsp; </p>
                <p className="MsoNormal" style={{ "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ "color": "black" }}>Inferences</span></b><span style={{ "color": "black" }}> drawn from any of the information in any other category to
                  create a profile about a consumer reflecting the consumer’s preferences,
                  characteristics, psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.</span></p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">The following chart describes what categories of personal
                  information we have collected over the past 12 months, how we collected that
                  information, the business and commercial purposes for which we generally use
                  such information, and which types of trusted business partners to whom we may
                  sell, share, or otherwise disclose that information:</p>
                <table className={1} border={1} cellSpacing={0} cellPadding={0} width={666} style={{ "margin-left": "-.25pt", "border-collapse": "collapse", "border": "none" }}>
                  <tbody><tr>
                    <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-bottom": "solid black 1.5pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                      <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Category of personal information</span></b></p>
                    </td>
                    <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "solid black 1.0pt", "border-left": "none", "border-bottom": "solid black 1.5pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                      <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Collected?</span></b></p>
                    </td>
                    <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "solid black 1.0pt", "border-left": "none", "border-bottom": "solid black 1.5pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                      <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Categories of Sources</span></b></p>
                    </td>
                    <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "solid black 1.0pt", "border-left": "none", "border-bottom": "solid black 1.5pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                      <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Business or Commercial Purpose</span></b></p>
                    </td>
                    <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "solid black 1.0pt", "border-left": "none", "border-bottom": "solid black 1.5pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                      <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Categories of third parties to which we have sold or
                        shared </span></b></p>
                    </td>
                    <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "solid black 1.0pt", "border-left": "none", "border-bottom": "solid black 1.5pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                      <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Categories of third parties to which we have
                        otherwise disclosed </span></b></p>
                    </td>
                  </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Identifiers</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>Yes</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        {/* <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p> */}
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>provided directly by the customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>collected from customer’s browser or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>inferred from information provided by customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>obtained from one or more third parties</span></p>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        {/* <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p> */}
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-providing
                          our products and services </span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising
                          and marketing</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        {/* <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p> */}
                        {/* <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}>
                          <span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                            partners</span></p> */}
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                        {/* <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p> */}
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        {/* <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": ".5in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "background": "yellow" }}>&nbsp;</span></p> */}
                        {/* <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}><span style={{}}>&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p> */}
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        {/* <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-website analytics vendors</span></p> */}
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>
                          -website analytics vendors</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Customer records<a name="_heading=h.gjdgxs" /></span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>Yes</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>provided directly by the customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>collected from customer’s browser or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>inferred from information provided by customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>obtained from one or more third parties</span></p>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>provided directly by the customer</span></p>

                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>collected from customer’s browser
                          or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>inferred from information
                          provided by customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>obtained from one or more third
                          parties</span></p>
                      </td> */}
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-providing
                          our products and services </span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising
                          and marketing</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>
                          -website analytics vendors</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td> */}
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-website analytics vendors</span></p>
                      </td> */}
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Characteristics of legally protected classifications</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>Yes</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>provided directly by the customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>collected from customer’s browser or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>inferred from information provided by customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>obtained from one or more third parties</span></p>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>provided directly by the customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>collected from customer’s browser
                          or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>inferred from information
                          provided by customer</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>obtained from one or more third
                          parties</span></p>
                      </td> */}
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-providing
                          our products and services </span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising
                          and marketing</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>
                          -website analytics vendors</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-website analytics vendors</span></p>
                      </td> */}
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Commercial information</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>No</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Biometric information</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>No</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Internet or other network activity information</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>Yes</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>
                          collected from customer’s browser or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>
                          inferred from information provided by customer</span></p>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>collected from customer’s browser
                          or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>inferred from information
                          provided by customer</span></p>
                      </td> */}
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-providing
                          our products and services </span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising
                          and marketing</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>
                          -website analytics vendors</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-website analytics vendors</span></p>
                      </td> */}
                    </tr>
                    <tr style={{ "height": "7.3pt" }}>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt", "height": "7.3pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Geolocation data</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt", "height": "7.3pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>Yes</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>
                          collected from customer’s browser or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp; </span></span><span style={{ "font-size": "8.0pt" }}>
                          inferred from information provided by customer</span></p>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt", "color": "red" }}>&nbsp;</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt", "height": "7.3pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>collected from customer’s browser
                          or device</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>inferred from information
                          provided by customer</span></p>
                      </td> */}
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt", "height": "7.3pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-providing
                          our products and services </span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising
                          and marketing</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>
                          -website analytics vendors</span></p>
                      </td>
                      {/* <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt", "height": "7.3pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-advertising platform providers</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt", "height": "7.3pt" }}>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-marketing and advertising
                          partners</span></p>
                        <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><span style={{ "font-size": "8.0pt" }}>-website analytics vendors</span></p>
                      </td> */}
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Sensory information</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>No</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Professional or employment-related information</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>No</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Education information</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>No</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Sensitive personal information</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>No</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                    </tr>
                    <tr>
                      <td width={116} valign="top" style={{ "width": "87.0pt", "border": "solid black 1.0pt", "border-top": "none", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><b><span style={{ "font-size": "9.0pt" }}>Inferences from any of the above</span></b></p>
                      </td>
                      <td width={106} valign="top" style={{ "width": "79.5pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>No</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                      <td width={111} valign="top" style={{ "width": "83.25pt", "border-top": "none", "border-left": "none", "border-bottom": "solid black 1.0pt", "border-right": "solid black 1.0pt", "padding": "0in 5.4pt 0in 5.4pt" }}>
                        <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal" }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                      </td>
                    </tr>
                  </tbody></table>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">Personal information does not include deidentified or
                  aggregate consumer information or information that is lawfully made available
                  from federal, state, or local government records.&nbsp; </p>
                <p className="MsoNormal">Personal information is not considered to have been
                  disclosed by a business when a consumer instructs a business to transfer the
                  consumer’s personal information from one business to another in the context of
                  switching services. </p>
                <p className="MsoNormal">MadTech, LLC. retains c<a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(i)"><span style={{ "color": "windowtext", "-webkit-text-decoration": "none", "text-decoration": "none" }}>onsumer</span></a>s’ p<a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(v)"><span style={{ "color": "windowtext", "-webkit-text-decoration": "none", "text-decoration": "none" }}>ersonal information</span></a> as
                  reasonably necessary and proportionate to achieve the purposes for which the p<a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(v)"><span style={{ "color": "windowtext", "-webkit-text-decoration": "none", "text-decoration": "none" }}>ersonal information</span></a>
                  was <a href="https://www.caprivacy.org/annotated-cpra-text-with-ccpa-changes/#1798.140(f)"><span style={{ "color": "windowtext", "-webkit-text-decoration": "none", "text-decoration": "none" }}>collected</span></a> or
                  processed.</p>
                <p className="MsoNormal">MadTech does not sell or share consumers’ personal
                  information to any third parties, including in the preceding twelve months.</p>
                <p className="MsoNormal">We may also disclose personal information in the following
                  situations:</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".25in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;
                </span><b><span style={{ "color": "black" }}>Business transfers:</span></b><span style={{ "color": "black" }}> The Company may transfer to a third party the personal
                  information of a consumer as an asset that is part of a merger, acquisition,
                  bankruptcy, or other transaction in which the third party assumes control of
                  all or part of the Company. It will only do so where that information is used
                  or shared consistent with the CCPA/CPRA, that transfer will not constitute a
                  “sharing” of personal information and it is not required </span>to be reported<span style={{ "color": "black" }}> in our policy and disclosures as such.</span></p>
                <p className="MsoNormal" style={{ "margin-left": ".25in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp; </span><b><span style={{ "color": "black" }}>Legal compliance and law enforcement: </span></b><span style={{ "color": "black" }}>We may be required to disclose your personal information in
                  limited circumstances</span>, f<span style={{ "color": "black" }}>or example, to respond
                    to a subpoena or similar judicial process, to comply with state, federal or
                    local laws, </span>to exercise or defnd legal claims <span style={{ "color": "black" }}>or,
                      to the extent required by law, to provide information to law enforcement
                      agencies. </span></p>
                <h1 style={{ "border": "none" }}><a name="_heading=h.a1j59e51vpoq" />3.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>RIGHT TO DELETE
                  PERSONAL INFORMATION</h1>
                <p className="MsoNormal">You have the right to request that we delete any of your
                  personal information, subject to certain exceptions.</p>
                <h1 style={{ "border": "none" }}><a name="_heading=h.dza65vd9bso2" />4.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>RIGHT TO CORRECT
                  INACCURATE PERSONAL INFORMATION </h1>
                <p className="MsoNormal">You have the right to request that we correct any inaccurate
                  personal information about you.</p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">&nbsp;</p>
                <h1 style={{ "border": "none" }}><a name="_heading=h.2bk8q0420jv8" />5.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>RIGHT TO KNOW </h1>
                <p className="MsoNormal">You have the right to request, up to two times per year,
                  that we disclose the following information to you about our collection and use
                  of your personal information over the past 12 months:</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%", "font-family": "\"Noto Sans Symbols\"", "color": "black" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>the categories of personal information
                  we collected about you.&nbsp; </span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%", "font-family": "\"Noto Sans Symbols\"", "color": "black" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>the categories of sources for the
                  personal information we collected about you.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%", "font-family": "\"Noto Sans Symbols\"", "color": "black" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>our business or commercial purpose for
                  collecting your personal information.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%", "font-family": "\"Noto Sans Symbols\"", "color": "black" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>the categories of third parties with
                  whom we sell, share, or otherwise disclose your personal information.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>the specific pieces of personal
                  information we have collected about you. You may also request a copy of
                  specific pieces of personal information that we have collected about you.
                  Please note that “specific pieces of information” do not include data generated
                  to help ensure security and integrity or as prescribed by regulation. </span></p>
                <h1>6.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>HOW
                  TO EXERCISE YOUR RIGHTS TO DELETE, CORRECT, AND KNOW</h1>
                <p className="MsoNormal">Your Right to Delete, Right to Correct, and Right to Know
                  can be exercised by submitting a verifiable request to us by sending an email
                  to the following address or via US Mail. </p>
                <p className="MsoNormal" style={{ "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp; </span>E<span style={{ "color": "black" }}>mail</span>: <a href="mailto:partnerships@madtech.io"><span style={{ "font-size": "11.5pt", "line-height": "107%", "color": "#1155CC" }}>partnerships@madtech.io</span></a><span style={{ "font-size": "11.5pt", "line-height": "107%", "color": "#D1D2D3" }}> </span></p>
                <p className="MsoNormal" style={{ "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp; </span>Address: 7
                  Toffee Ln, Madison, CT 06443</p>
                <p className="MsoNormal">We cannot respond to your verifiable request if we cannot
                  verify your identity or authority to make the request and confirm that the
                  personal information relates to you. For this reason, your request must both:</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>provide sufficient information that
                  allows us to reasonably verify you are the person about whom we collected
                  personal information or an authorized representative; and</span></p>
                <p className="MsoNormal" style={{ "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>describe your request with sufficient
                  detail that allows us to properly understand, evaluate, and respond to it.</span></p>
                <p className="MsoNormal">If you have a password-protected account with our Company,
                  we may require you to verify your identity through our existing authentication
                  practices for the account. We will only use personal information provided in a
                  verifiable request to verify the requestor’s identity or authority to make the
                  request.</p>
                <p className="MsoNormal">Only you, or your properly authorized agent, may make a
                  request related to your personal information. <span style={{ "color": "red" }}>&nbsp;</span>To
                  be certain that anyone claiming to make a&nbsp; verifiable request on your behalf
                  has been properly authorized by you, we may require additional information,
                  including:</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>submitting the signed authorization
                  that </span>you<span style={{ "color": "black" }}> provided to </span>your<span style={{ "color": "black" }}> agent. </span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-family": "\"Noto Sans Symbols\"", "color": "black" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>verifying their own identity directly
                  with </span>us<span style={{ "color": "black" }}>.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-family": "\"Noto Sans Symbols\"", "color": "black" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>otherwise directly confirming with </span>us<span style={{ "color": "black" }}> that </span>you have<span style={{ "color": "black" }}> provided </span>your<span style={{ "color": "black" }}> authorized agent permission to submit the verifiable
                  request. </span></p>
                <p className="MsoNormal" style={{ "margin-top": "10.0pt" }}>You may submit a Right to Delete
                  or a Right to Know request for specific pieces of information on behalf of all
                  members of your household. The the following requirements must be met before we
                  can comply with a Right to Delete request or a Right to Know request for
                  specific pieces of information made on a household basis: </p>
                <ol style={{ "margin-top": "0in" }} start={1} type={1}>
                  <li className="MsoNormal" style={{ "margin-bottom": "0in" }}>all Consumers of the household
                    jointly request to know specific pieces of information for the household
                    or to delete household personal information;</li>
                  <li className="MsoNormal" style={{ "margin-bottom": "0in" }}>we individually verify each of
                    the members of the household; and </li>
                  <li className="MsoNormal">we verify that each member making the request is currently
                    a member of the household.</li>
                </ol>
                <p className="MsoNormal">If a member of the household is under the age of 13, we are
                  also required to obtain verifiable parental consent, as described in the next
                  section, below, before complying with a Consumer Rights request.</p>
                <h1><span style={{ "font-variant": "small-caps", "color": "black" }}>7.<span style={{}}>&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "font-variant": "small-caps", "color": "black" }}>RIGHT TO LIMIT
                  USE AND DISCLOSURE OF SENSITIVE PERSONAL INFORMATION</span></h1>
                <p className="MsoNormal">MadTech, LLC uses or discloses your sensitive personal
                  information beyond that which is necessary to perform the services, disclose
                  this information to certain third parties, or to provide the goods reasonably
                  expected by an average consumer who requests such goods or services, to perform
                  the following services:</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": "1.0in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><a name="_heading=h.1fob9te" /><span style={{ "color": "black" }}>a.<span style={{}}>&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>ensuring the security and integrity of
                  our website and system.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": "1.0in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "color": "black" }}>b.<span style={{}}>&nbsp;&nbsp;&nbsp; </span></span><span style={{ "color": "black" }}>short-term, transient use, such as non-personalized
                  advertising.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": "1.0in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "color": "black" }}>c.<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span style={{ "color": "black" }}>performing services on behalf of the business.</span></p>
                <p className="MsoNormal" style={{ "margin-left": "1.0in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "color": "black" }}>d.<span style={{}}>&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>maintaining or improving the quality or
                  safety of a service or device we own, manufacture, or control.</span></p>
                <p className="MsoNormal"><span style={{ "font-size": "10.5pt", "line-height": "107%", "font-family": "Roboto" }}>You may exercise your right to opt out by using an opt-out preference
                  signal </span>&nbsp;sent by a platform, technology, or mechanism such as a browser
                  plug-in or privacy setting, device setting, or other mechanism, that
                  communicate or signal the consumer’s choice to limit the use of their sensitive
                  personal information. </p>
                <p className="MsoNormal">Only you, or your properly authorized agent, may make a
                  Right to Limit request using the method(s) provided above. If you change your
                  mind after making a Right to Limit request, you may contact us at any time to
                  opt-in to the use or disclosure of your sensitive personal information as
                  described above.</p>
                <h1>8.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>ADDITIONAL
                  INFORMATION REGARDING CHILDREN UNDER 16 YEARS OF AGE</h1>
                <p className="MsoNormal"><b>Exercising Consumer Rights: </b>A parent or guardian may
                  make a Consumer Rights request on behalf of their child under the age of 16. In
                  the case of a request to exercise the Right to Delete or the Right to Know on
                  behalf of a child under the age of 13, we provide additional safeguards to
                  confirm that you are the parent or guardian of that child. If you are making a
                  request for a child under 13, please indicate this in the submission of your
                  request or contact us using our contact information provided below so that we
                  can provide you with further instructions on how to comply with CCPA/CPRA
                  requirements.</p>
                <p className="MsoNormal"><b>Sale or sharing of the children’s personal information:</b>
                  MadTech<span style={{ "color": "red" }}> </span>does not knowingly sell or share the
                  personal information of children under 16 years of age. </p>
                <h1>9.<span style={{}}>&nbsp;&nbsp;&nbsp; </span>NON-DISCRIMINATION</h1>
                <p className="MsoNormal">We will not discriminate against you for exercising any of
                  your CCPA/CPRA rights. We will not unlawfully:</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".75in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>Deny you goods or services.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".75in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>Charge you different prices or rates
                  for goods or services, including through granting discounts or other benefits,
                  or imposing penalties.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".75in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>Provide you a different level or
                  quality of goods or services.</span></p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".75in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>Suggest that you may receive a
                  different price or rate for goods or services or a different level or quality
                  of goods or services.</span></p>
                <p className="MsoNormal" style={{ "margin-left": ".75in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in", "border": "none" }}><span style={{ "font-size": "10.0pt", "line-height": "107%" }}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ "color": "black" }}>Retaliate against an employee,
                  applicant for employment, or independent contractor for exercising their rights
                  under this title.</span></p>
                <h1 style={{ "border": "none" }}>10.<span style={{}}>&nbsp; </span>CALIFORNIA
                  “SHINE THE LIGHT” LAW</h1>
                <p className="MsoNormal"><a name="_heading=h.vl6je13ivr4y" />California’s “Shine
                  the Light” law (Civil Code Section § 1798.83) permits users of our website that
                  are California residents to request certain information regarding our
                  disclosure of personal information to third parties for their direct marketing
                  purposes. To make such a request, please follow the instructions found <a href="https://privacyrights.org/resources/californias-shine-light-law-goes-effect-jan-1-2005"><span style={{ "color": "#1155CC" }}>here</span></a>.</p>
                <h1>11.<span style={{}}>&nbsp; </span>CHANGES TO OUR
                  PRIVACY NOTICE</h1>
                <p className="MsoNormal">We reserve the right to amend this privacy notice at our
                  discretion and at any time. When we make changes to this privacy notice, we
                  will post the updated notice on our website and update the notice’s effective
                  date. Your continued use of our website following the posting of changes
                  constitutes your acceptance of such changes.</p>
                <h1>12.<span style={{}}>&nbsp; </span>CONTACT
                  INFORMATION</h1>
                <p className="MsoNormal"><a name="_heading=h.2et92p0" />If you have any questions
                  or concerns about our privacy policies and practices, please do not hesitate to
                  contact us as follows: </p>
                <p className="MsoNormal" style={{ "margin-bottom": "0in" }}>&nbsp;</p>
                <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": "0in", "margin-bottom": "0in", "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span>Email: <a href="mailto:partnerships@madtech.io"><span style={{ "font-size": "11.5pt", "line-height": "107%", "color": "#1155CC" }}>partnerships@madtech.io</span></a><span style={{ "font-size": "11.5pt", "line-height": "107%" }}> <span style={{ "color": "#D1D2D3" }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ "margin-left": ".5in", "-webkit-text-indent": "-.25in", "text-indent": "-.25in" }}><span style={{}}>●<span style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span>Address: 7 Toffee Ln., Madison, CT 06443</p>
                {/* <p className="MsoNormal"><span style={{ "background": "yellow" }}>&nbsp;</span></p> */}
                <p className="MsoNormal" style={{ "margin-left": ".5in", "border": "none" }}><span style={{ "color": "red" }}>&nbsp;</span></p>
              </div>
            </div>
          </Box>
        </Box>
        <LetsConnect />
        <Box sx={{ maxWidth: 1550, mx: 'auto' }}>
          <HomeFooter />
        </Box>
      </Box>
    </NaLayout>
  )
}

export default PrivacyPolicy