import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

import noConfigImg from "../../assets/Illustrations.svg";

export default function DestinationConfigurationTab({ 
  formsSaveConfig,
  setformsSaveConfig,
  handleChangeDest,
  isConfigure,
  PlatformsIds,
  value,
  platformConfigurations,
  setExpandedDest,
  expandedDest,
  destConfigRefs,
  ConfigurationAccordion,
  isLoading,
  isDestinationVisible,
  platformByIdDatas
}){  
  return (
    <>
      {isConfigure === "DESTINATION" &&
        (isLoading ? (
          <Box
            sx={{
              height: "12.5rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              style={{}}
              size={30}
              thickness={8}
            ></CircularProgress>
          </Box>
        ) : isDestinationVisible ? (
          <Box>
            <Box sx={{ display: "flex", height: "80%" }}>
              {/* <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={verticalTabChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  width: "170px",
                  backgroundColor: "#fff",
                  color: color.black,
                  "& .Mui-selected": {
                    backgroundColor: "rgba(54, 70, 172, 0.05)",
                    color: color.black,
                    fontWeight: "bold",
                    padding: "5px",
                  },
                }}
              >
                {platformConfigurations?.dataTypes?.map((dataType, index) => (
                  <Tab
                    key={index}
                    label={dataType}
                    sx={{
                      padding: "5px 15px",
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  />
                ))}
              </Tabs> */}
              <Box
                sx={{
                  width: "100%",
                  padding: "0rem 0rem 1rem 1rem",
                  marginTop: "10px"
                }}
              >
                {platformConfigurations?.dataTypes.map((dataType, index) => {
                  if (value === index) {
                    const dataTypeConfig = platformConfigurations[dataType];
                    if (!dataTypeConfig.showDest) {
                      return (
                        <Box mt={4} textAlign="center" fontWeight="bold">
                          Not supported
                        </Box>
                      );
                    }
                    const configured =
                      platformConfigurations[dataType]?.destConfigured; 
                    const configurations =
                      platformConfigurations[dataType]
                        ?.destPlatformConfigurations.map(item=>{
                          return {
                            ...item,
                            configuration: item.configuration.map(config => {
                              if(config.type === "button"){
                                return {
                                  ...config,
                                  configured
                                }
                              }
                              return config
                            })
                          }
                        }); 
                    return configurations?.length ? (
                      configurations.map(
                        ({ name, lastUpdated, configuration, id }, index) => (
                          <ConfigurationAccordion 
                            suffix={"panelDest"}
                            ref={(instance) => {
                              destConfigRefs.current[`panelDest${index}`] =
                                instance;
                            }}
                            expanded={expandedDest}
                            setExpanded={setExpandedDest}
                            dataType={platformConfigurations?.dataTypes[value]}
                            platformId={PlatformsIds ? PlatformsIds : ""}
                            platformByIdDatas={platformByIdDatas}
                            key={name}
                            configuration={configuration}
                            index={index}
                            name={name}
                            lastUpdated={lastUpdated}
                            activeConfiguration={isConfigure}
                            id={id}
                            handleChange={handleChangeDest}
                            setformsSaveConfig={setformsSaveConfig}
                            formsSaveConfig={formsSaveConfig}
                            type={"dest"}
                          />
                        )
                      )
                    ) : (
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={400}
                        mt={2}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "11.875rem",
                              height: "11.875rem",
                            }}
                          >
                            <img
                              src={noConfigImg}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: "#25262D",
                              textAlign: "center",
                              fontSize: "1.125rem",
                              fontWeight: 700,
                              fontFamily: "Helvetica Neue",
                            }}
                          >
                            No Configuration Details Available!
                          </Typography>
                          <Typography
                            sx={{
                              width: "60%",
                              mt: "1rem",
                              color: "#bdbdbd",
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: 400,
                              fontFamily: "Helvetica Neue",
                            }}
                          >
                            Our team will assist you to setup API integration
                            once you create the connection.
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                  return null;
                })}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={400}
            mt={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "11.875rem",
                  height: "11.875rem",
                }}
              >
                <img
                  src={noConfigImg}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  color: "#25262D",
                  textAlign: "center",
                  fontSize: "1.125rem",
                  fontWeight: 700,
                  fontFamily: "Helvetica Neue",
                }}
              >
                No Configuration Details Available!
              </Typography>
              <Typography
                sx={{
                  width: "60%",
                  mt: "1rem",
                  color: "#bdbdbd",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: 400,
                  fontFamily: "Helvetica Neue",
                }}
              >
                Our team will assist you to setup API integration once you
                create the connection.
              </Typography>
            </Box>
          </Box>
        ))}
    </>
  );
}
