import { Box, Button, Checkbox, CircularProgress, FormControlLabel, InputAdornment, OutlinedInput, Skeleton, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { madTechState } from '../../features/madTechSlice';
import edit2 from '../../assets/edit-2.svg';
import trash from "../../assets/transhBlack.svg";
import Search from "../../assets/myConnectionSearch.svg";
import resetClose from "../../assets/cancel.svg";
import { favIconMapping1 } from '../FavIconMaping/favicon';
import { useMyContext } from '../CommanToasterContext/toast';
import noConfigImg from "../../assets/NorecordSVG.svg";
import { useGetConditionConfirmationMutation, usePostConditionConfirmationMutation, usePostTestConnectionMutation, usePutActivateConnectionMutation } from '../../services/query';



const MyConnection = ({
    data,
    id,
    setDeleteIds,
    setSelectedItem,
    setIsForDisable,
     setEditIds,
    inputValue, setInputValue, refetch, isLoading
}) => {
    
    const boxRef = useRef()
    const { user } = useSelector(madTechState);
    const navigate = useNavigate();
    const { selectedAccount } = useSelector(madTechState);
    const [groupedData, setGroupedData] = useState({});
    const [rowSelect, setRowSelect] = useState(false)
    const [rowSelectIndex, setRowSelectIndex] = useState(0)

    const { setOpen2, setMessage2, setOpen, setMessage } = useMyContext();

    const [isDisabled, setIsDisabled] = useState(true);
    const [isDisabledOffline, setIsDisabledOffline] = useState(true);

    const [checkboxesStatus, setCheckboxesStatus] = useState({})
    const [destinationCheckboxes, setDestinationCheckboxes] = useState({});
    const [sourceCheckboxes, setSourceCheckboxes] = useState({});
    const [selectedConnection, setSelectedConnection] = useState({});
    // const conditionData = {
    //     "statusCode": 200,
    //     "statusMessage": "Connection setup get successfully",
    //     "response": {
    //         "destination": [
    //             {
    //                 "value": true,
    //                 "key": "Invite frame link image un"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Device group opacity scrolli"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Distribute flatten"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Figma ipsum component"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Variant main layer"
    //             }
    //         ],
    //         "source": [
    //             {
    //                 "value": true,
    //                 "key": "Figma ipsum component"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Distribute flatten asdsdf"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Variant main layer"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Distribute flatten sada"
    //             },
    //             {
    //                 "value": true,
    //                 "key": "Device group opacity scrolling"
    //             }
    //         ]
    //     }
    // }
    const [conditionCheck, {
        data: conditionData,
        isLoading: conditionLoading,
        error: conditionError }] =
        useGetConditionConfirmationMutation();

    const [addConditionCheck, {
        data: addconditionData,
        error: addconditionError }] =
        usePostConditionConfirmationMutation();

    const [testNowfunction, {
        data: testNowData,
        isLoading: testLoading,
        error: testNowError }] =
        usePostTestConnectionMutation();

    const [activatefunction, {
        data: activateData,
        isLoading: activateLoading,
        error: activateError }] =
        usePutActivateConnectionMutation();



    useEffect(() => {
        if (conditionData !== undefined) {
            if (conditionData.statusCode === 200) {
                setCheckboxesStatus(conditionData.response)
            } else {
                setMessage2(conditionData.statusMessage)
                setOpen2(true)
            }
        }

        if (conditionError !== undefined) {
            console.log("add Connection Error", conditionError);
            // setMessage2(conditionError.data.error)
            // setOpen2(true)

        }
    }, [conditionData, conditionError]);

    useEffect(() => {
        if (testNowData !== undefined) {
            if (testNowData.statusCode === 200) {
                setMessage(testNowData.statusMessage);
                setOpen(true)
            } else {
                setMessage2(testNowData.statusMessage)
                setOpen2(true)
            }
        }
        if (testNowError !== undefined) {
            console.log("Test Connection Error", testNowError);
        }
    }, [testNowData, testNowError]);

    useEffect(() => {
        if (addconditionData !== undefined) {
            if (addconditionData.statusCode === 200) {
                setMessage(addconditionData.statusMessage);
                setOpen(true)
            } else {
                setMessage2(addconditionData.statusMessage)
                setOpen2(true)
            }
        }

        if (addconditionError !== undefined) {
            console.log("Add Connection Error", addconditionError);
        }
    }, [addconditionData, addconditionError]);

    useEffect(() => {
        if (activateData !== undefined) {
            if (activateData.statusCode === 200) {
                setMessage(activateData.statusMessage);
                setOpen(true)
                refetch()
                setRowSelectIndex(0)
                setIsDisabled(true)
            } else {
                setMessage2(activateData.statusMessage)
                setOpen2(true)
            }
        }
        if (activateError !== undefined) {
            console.log("Activate Connection Error", activateError);
        }
    }, [activateData, activateError]);


    useEffect(() => {
        if (checkboxesStatus?.destination || checkboxesStatus?.source) {
            const initialDestinationState = checkboxesStatus.destination?.reduce((acc, item) => {
                acc[item.key] = item.value;
                return acc;
            }, {});
            setDestinationCheckboxes(initialDestinationState || {});

            const initialSourceState = checkboxesStatus.source?.reduce((acc, item) => {
                acc[item.key] = item.value;
                return acc;
            }, {});
            setSourceCheckboxes(initialSourceState || {});
        }
    }, [checkboxesStatus]);

    const handleDestinationCheckboxChange = (event) => {
        setDestinationCheckboxes({
            ...destinationCheckboxes,
            [event.target.name]: event.target.checked,
        });
        const data = {
            destination: {
                ...destinationCheckboxes,
                [event.target.name]: event.target.checked,
            },
            source: sourceCheckboxes
        }
        addConditionCheck({ connectionId: selectedConnection.conId, connectionPayload: data })
    };

    const handleSourceCheckboxChange = (event) => {
        setSourceCheckboxes({
            ...sourceCheckboxes,
            [event.target.name]: event.target.checked,
        });
        const data = {
            destination: destinationCheckboxes
            ,
            source: {
                ...sourceCheckboxes,
                [event.target.name]: event.target.checked,
            }
        }
        addConditionCheck({ connectionId: selectedConnection.conId, connectionPayload: data })
    };

    const handleCheckboxChange = (event) => {
        setIsDisabled(!event.target.checked);
    };

    const HandleActivate = () => {
        const data = {
            id: selectedConnection.conId
        }
        activatefunction({ data: data })
    }
    const HandleTestNow = () => {
        testNowfunction({ connectionId: selectedConnection.conId })
        // setMessage("Successfully Tested.");
        // setOpen(true)
    }
    function getFavIconImagePath(key) {
        if (favIconMapping1.hasOwnProperty(key)) {
            return favIconMapping1[key];
        } else {

            return "platforms2/favicon.png";
        }
    }
    const handleCheckboxChangeOffline = (event) => {
        setIsDisabledOffline(!event.target.checked);
    };

    function getFavIconImagePath(key) {
        if (favIconMapping1.hasOwnProperty(key)) {
            return favIconMapping1[key];
        } else {

            return "platforms2/favicon.png";
        }
    }


    const findPermissionByName = (name) => {
        // const foundPermission = permission.find((p) => p.name === name);
        const foundPermission = user?.permissions.find((p) => p.name === name);
        return foundPermission ? foundPermission.permissions : null;
    };

    const connectionsPermissions = useMemo(
        () => findPermissionByName("Connections"),
        [user?.permissions]
    );
    useEffect(() => {
        // Function to group data by status
        const groupDataByStatus = () => {
            const grouped = {};
            data?.forEach(item => {
                if (!grouped[item.status]) {
                    grouped[item.status] = [item];
                } else {
                    grouped[item.status].push(item);
                }
            });
            return grouped;
        };

        setGroupedData(groupDataByStatus());
    }, [data]);


    const getStatusCount = (status) => {
        const statusData = groupedData[status];
        return statusData ? statusData.length : 0;
    };
    const handleRowClick = (item, index) => {
        setRowSelect(true);
        setRowSelectIndex(index)
        setSelectedConnection(item)
        boxRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const statusOrder = ['In-progress', 'Active', 'Offline', 'Disabled', 'Deleted'];
    const sortedStatuses = Object.keys(groupedData).sort((a, b) => {
        return statusOrder.indexOf(a) - statusOrder.indexOf(b);
    });
    const computeActiveTabDefault = () => {
        return sortedStatuses.includes("In-progress") ? "In-progress" :
            sortedStatuses.includes("Active") ? "Active" :
                sortedStatuses.includes("Offline") ? "Offline" :
                    sortedStatuses.includes("Disabled") ? "Disabled" : "In-progress";
    };

    const [activeTab, setActiveTab] = useState(computeActiveTabDefault());

    useEffect(() => {
        const itemInProgress = data.find(item => item.status === "In-progress");
        const itemOffline = data.find(item => item.status === "Offline");
        if (itemInProgress && activeTab === "In-progress") {
            const conIdInProgress = itemInProgress.conId;
            conditionCheck({ connectionId: conIdInProgress })
            setSelectedConnection(itemInProgress)
        } else if (itemOffline && activeTab === "Offline") {
            const conIdOffline = itemOffline.conId;
            conditionCheck({ connectionId: conIdOffline })
            setSelectedConnection(itemOffline)
        } else {

            console.log("No item found with status 'ANY TAB'");
        }
    }, [activeTab])

    // useEffect(() => {
    //     setActiveTab(computeActiveTabDefault());
    // }, [groupedData]);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
        setRowSelect(false);
        setRowSelectIndex()
    };

    useEffect(() => {
        if ((activeTab === "In-progress" || activeTab === "Offline")) {
            setRowSelect(true)
            setRowSelectIndex(0)
        }
    }, [activeTab])
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mb: "1rem",
                alignItems: "center",
            }}
            ref={boxRef}
        >
            <Box sx={{ width: '100%', bgcolor: '' }}>
                <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", mt: 1 }}>
                    <Box sx={{ display: "flex" }} >
                        <Typography
                            sx={{
                                // m: "auto",
                                ml: 0,
                                fontSize: "1.25rem",
                                fontWeight: 700,
                                fontFamily: "Helvetica Neue",
                            }}
                        >My Connections
                        </Typography>
                    </Box>
                    <Box sx={{ display: data.length !== 0 ? "flex" : "none", gap: "10px", alignItems: "center" }}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate(selectedAccount !== null && selectedAccount !== undefined
                                    ? `/create-connections?accountId=${selectedAccount.id}`
                                    : "/create-connections")
                            }}
                            sx={{
                                border: "1px solid black", color: "#fff", textTransform: "capitalize", background: "#25262D", borderRadius: "10px",
                                '&:hover': {
                                    background: "#000",
                                    border: "1px solid black",
                                    cursor: "pointer",
                                },
                                padding: "7px 22px"

                            }}
                        >
                            Create Connection
                        </Button>
                        <OutlinedInput
                            value={inputValue}
                            onChange={(e) => {
                                setInputValue(e.target.value);

                            }}
                            sx={{
                                "& fieldset": {
                                    borderColor: "#EAEDF6",
                                }

                            }}
                            placeholder='Search'
                            style={{
                                margin: "auto",
                                height: "2.7rem",
                                borderRadius: "10px",
                                background: "#fff",
                                display: "flex",
                                justifyContent: "center",


                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <img src={Search} alt="Search icon" style={{ width: 22, height: 22 }} />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="start">
                                    <img src={resetClose} alt="Search icon" style={{ width: 14, height: 14, cursor: "pointer", display: inputValue === "" ? "none" : "" }} onClick={() => setInputValue("")} />
                                </InputAdornment>
                            }
                        />
                    </Box>
                </Box>
                {data && data.length !== 0 ? <>
                    <Box sx={{ width: '100%', display: "flex", mt: 2 }}>
                        <Box>
                            <Tabs
                                value={activeTab}
                                onChange={handleChangeTab}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                sx={{ border: "1px solid rgba(234, 237, 246, 1)", borderRadius: "8px" }}
                            >
                                {/* Render tabs based on sorted statuses */}
                                {sortedStatuses.map((status, index) => (
                                    <Tab key={status} value={status}
                                        label={
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <span style={{ fontSize: "16px", fontFamily: "Helvetica Neue", padding: "1px", marginTop: "2px" }}>{status}</span>
                                                <span style={{ fontSize: "16px", fontFamily: "Helvetica Neue", border: activeTab === status ? "1px solid rgba(83, 139, 244, 1)" : "1px solid rgba(97, 97, 97, 1)", background: activeTab === status ? "rgba(83, 139, 244, 0.05)" : "rgba(97, 97, 97, 0.05)", borderRadius: "20px", padding: "1px 7px", paddingTop: "2.5px" }}>{getStatusCount(status)}</span>
                                            </div>
                                        }
                                        sx={{ fontSize: "16px", fontFamily: "Helvetica Neue", padding: "14px 25px", borderRight: index < sortedStatuses.length - 1 ? "1px solid rgba(234, 237, 246, 1)" : "none", textTransform: "capitalize" }} />
                                ))}
                            </Tabs>
                        </Box>
                        {/* <Box sx={{ width: '39%', display: "none" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                ml: "auto",
                                gap: "20px"
                            }}
                        >
                            <Box className="action_Connection"
                                onClick={() => {
                                    // setSelectedItem(data);
                                }}
                                sx={{ cursor: "pointer", border: "1px solid rgba(234, 237, 246, 1)", borderRadius: "20px", padding: "6px" }}
                            >
                                <img
                                    alt=""
                                    src={edit2}
                                    style={{ width: "1.5rem", height: "1.5rem", }}
                                ></img>
                            </Box>
                            <Box className="action_Connection"
                                onClick={() => {
                                    setDeleteIds([data.id, id]);
                                    setSelectedItem(data);
                                    // deleteConnector(data.id, id)
                                }}
                                sx={{ cursor: "pointer", border: "1px solid rgba(234, 237, 246, 1)", borderRadius: "20px", padding: "6px" }}
                            >
                                <img
                                    alt=""
                                    src={trash}
                                    style={{ width: "1.5rem", height: "1.5rem" }}
                                ></img>
                            </Box>

                        </Box>
                    </Box> */}
                    </Box>
                    {sortedStatuses.map((status, index) => (
                        <Box key={status} hidden={activeTab !== status} sx={{ width: '100%', display: "flex", mt: 2, }}>
                            <Box sx={{ width: (activeTab !== "In-progress" && activeTab !== "Offline") ? "100%" : '57.8%', height: "730px" }} className="mainContent_scroll">
                                <TableContainer id="scrolling" sx={{ height: "100%", overflowY: "auto", }}>
                                    <Table style={{ border: 'none', height: (activeTab === "In-progress") && "" }} >
                                        <TableHead sx={{ position: "sticky", top: 0, background: "#fff", zIndex: 99 }}>
                                            <TableRow style={{ border: "none" }}>
                                                <TableCell style={{ border: "none", color: "#454545", fontSize: "1rem", padding: "16px 16px 16px 10px" }}>Connection Name</TableCell>
                                                <TableCell style={{ border: "none", color: "#454545", fontSize: "1rem", padding: "16px 16px 16px 10px" }}>Source</TableCell>
                                                <TableCell style={{ border: "none", color: "#454545", fontSize: "1rem", padding: "16px 16px 16px 10px" }}>Destination</TableCell>
                                                <TableCell style={{ border: "none", color: "#454545", fontSize: "1rem", padding: "16px 16px 16px 10px", display: status !== "In-progress" ? "" : "none" }}>Data Type</TableCell>
                                                <TableCell style={{ border: "none", color: "#454545", fontSize: "1rem", padding: "16px 16px 16px 10px", justifyContent: "center", display: "flex" }}>Actions</TableCell>
                                                {/* Add more TableHeaderCells for additional properties */}
                                            </TableRow>
                                        </TableHead>
                                        {/* Render table rows for each status */}
                                        <TableBody >
                                            {groupedData[status].map((item, index) => (
                                                <TableRow key={item.id} onClick={() => {
                                                    if (groupedData["In-progress"] || groupedData["Offline"]) {
                                                        handleRowClick(item, index)
                                                        conditionCheck({ connectionId: item.conId })
                                                    }
                                                }}
                                                    style={{ backgroundColor: rowSelectIndex === index && (activeTab === "In-progress" || activeTab === "Offline") && (groupedData["In-progress"] || groupedData["Offline"]) !== undefined ? 'rgba(83, 139, 244, 0.1)' : 'inherit', width: "100%", cursor: (activeTab === "In-progress" || activeTab === "Offline") && (groupedData["In-progress"] || groupedData["Offline"]) !== undefined ? "pointer" : "" }}>
                                                    <TableCell style={{ width: "25%", border: 'none', padding: "10px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", color: "#25262D", fontFamily: "Helvetica" }}>{item.name}</TableCell>
                                                    <TableCell style={{ width: "22%", border: 'none', padding: "10px" }}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                                width: "90%",
                                                                height: "3rem",
                                                                gap: "5px"
                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                src={getFavIconImagePath(item?.src?.logo)}
                                                                // src='http://ec2-44-213-15-199.compute-1.amazonaws.com/logos/favicons/tik%20tok.svg'
                                                                style={{
                                                                    maxWidth: "100%", maxHeight: "100%", minWidth: "2.5rem",
                                                                    maxWidth: "2.5rem",
                                                                    height: "2.5rem",
                                                                    borderRadius: 2,
                                                                    p: 1
                                                                }}
                                                            ></img>
                                                            <Tooltip title={<span style={{
                                                                textTransform: "capitalize",
                                                                fontSize: "1rem"
                                                            }}>{item.src.name ? item.src.name : ""}</span>} placeholder="top">
                                                                <span style={{
                                                                    color: "#000000c2", fontWeight: 500,
                                                                    fontFamily: "Helvetica Neue",
                                                                    textTransform: "capitalize",
                                                                    cursor: "default",
                                                                    // width: "80%",
                                                                    fontSize: "0.875rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                                                                }}>{item.src.name ? item.src.name : ""}</span>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell style={{ width: "22%", border: 'none', padding: "10px" }}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                                width: "90%",
                                                                height: "3rem",
                                                                gap: "5px"

                                                            }}
                                                        >
                                                            <img
                                                                alt=""
                                                                src={getFavIconImagePath(item?.dest?.logo)}
                                                                // src='http://ec2-44-213-15-199.compute-1.amazonaws.com/logos/favicons/meta%20logo.svg'

                                                                style={{
                                                                    maxWidth: "100%", maxHeight: "100%", minWidth: "2.5rem",
                                                                    maxWidth: "2.5rem",
                                                                    height: "2.5rem",
                                                                    borderRadius: 2,
                                                                    p: 1
                                                                }}
                                                            ></img>
                                                            <Tooltip title={<span style={{
                                                                textTransform: "capitalize",
                                                                fontSize: "1rem"
                                                            }}>{item.dest.name ? item.dest.name : ""}</span>} placeholder="top">
                                                                <span style={{
                                                                    color: "#000000c2", fontWeight: 500,
                                                                    fontFamily: "Helvetica Neue",
                                                                    textTransform: "capitalize",
                                                                    cursor: "default",
                                                                    // width: "80%",
                                                                    fontSize: "0.875rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                                                                }}>{item.dest.name ? item.dest.name : ""}</span>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell style={{ width: "21%", border: 'none', padding: "10px", color: "rgba(97, 97, 97, 1)", display: status !== "In-progress" ? "" : "none" }}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "start",
                                                                alignItems: "center",
                                                                flexDirection: "row",
                                                                height: "100%",
                                                                width: "80%"
                                                            }}
                                                        >
                                                            <Tooltip title={<span style={{
                                                                textTransform: "capitalize",
                                                                fontSize: "12px"
                                                            }}>{item?.src?.dataType ? item?.src?.dataType : ""}</span>} placeholder="top">
                                                                <span style={{
                                                                    color: "#616161", fontWeight: 500,
                                                                    fontFamily: "Helvetica Neue",
                                                                    textTransform: "capitalize",
                                                                    cursor: "default",
                                                                    fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                                                                }}>{item?.src?.dataType ? item?.src?.dataType : (item?.dest?.dataType) ? item?.dest?.dataType : ""}</span>
                                                            </Tooltip>
                                                        </Box></TableCell>
                                                    <TableCell style={{ width: "10%", border: 'none', padding: "10px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px", }}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                ml: "auto",
                                                                gap: "15px",
                                                                justifyContent: "space-around"
                                                            }}
                                                        >
                                                            {status !== "In-progress" && status !== "Deleted" && (<Tooltip title={!data.enabled ? "Enable" : "Disable"} placement="top">
                                                                <Box className="action_Connection"
                                                                    sx={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        // setIsDeleteButtonEnabled(true);
                                                                        setIsForDisable(true);
                                                                        setSelectedItem(item);
                                                                        setDeleteIds([item.id, id]);
                                                                    }}
                                                                >
                                                                    <Switch size="small" checked={item.enabled} />
                                                                </Box>
                                                            </Tooltip>)}
                                                            {/* {status !== "In-progress" && status !== "Deleted" && (<Box className="action_Connection"
                                                                onClick={() => {
                                                                    // setDeleteIds([data.id, id]);
                                                                    // setSelectedItem(data);
                                                                    // deleteConnector(data.id, id)
                                                                }}
                                                                sx={{ cursor: "pointer" }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={rfresh}
                                                                    style={{ width: "1.5rem", height: "1.5rem", marginBottom: 5 }}
                                                                ></img>
                                                            </Box>)} */}

                                                            {/* {status === "Deleted" && (<Box className="action_Connection"
                                                                onClick={() => {
                                                                    // setDeleteIds([data.id, id]);
                                                                    // setSelectedItem(data);
                                                                    // deleteConnector(data.id, id)
                                                                }}
                                                                sx={{ cursor: "pointer" }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={RefreshIcon}
                                                                    style={{ width: "1.5rem", height: "1.5rem", marginBottom: 5 }}
                                                                ></img>
                                                            </Box>)} */}
                                                            {status !== "Deleted" && (<Box className="action_Connection2"
                                                                onClick={() => {
                                                                    setSelectedItem(item);
                                                                    setEditIds([item.conId])
                                                                }}
                                                                sx={{ cursor: "pointer", }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={edit2}
                                                                    style={{ width: "1.5rem", height: "1.5rem", }}
                                                                ></img>
                                                            </Box>)}
                                                            {status !== "Deleted" && (<Box className="action_Connection2"
                                                                onClick={() => {
                                                                    setDeleteIds([item.conId, index]);
                                                                    setSelectedItem(item);
                                                                    // deleteConnector(data.id, id)
                                                                }}
                                                                sx={{ cursor: "pointer", }}
                                                            >
                                                                <img
                                                                    alt=""
                                                                    src={trash}
                                                                    style={{ width: "1.5rem", height: "1.5rem" }}
                                                                ></img>
                                                            </Box>)}

                                                        </Box>
                                                    </TableCell>

                                                    {/* Add more TableCell components for additional properties */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            {activeTab === "In-progress" && (<Box sx={{ width: '42.2%', display: groupedData["In-progress"] !== undefined ? "flex" : "none", borderLeft: "1px solid rgba(234, 237, 246, 1)" }}>
                                {rowSelect && activeTab === "In-progress" && (
                                    <Box sx={{ padding: "1rem", width: '100%', }}>
                                        <Box sx={{
                                            color: "#454545",
                                            fontSize: "1rem",
                                            fontWeight: 500,
                                            fontFamily: "Helvetica Neue",
                                        }}>
                                            {!conditionLoading ? "Configuration" :
                                                <Skeleton variant="rounded" width={150} height={35} sx={{ mb: -1 }} />}
                                        </Box>
                                        <Box sx={{ display: "flex", }}>
                                            <Box sx={{ width: '50%', display: "block", }}>

                                                {!conditionLoading ? <Typography
                                                    sx={{
                                                        color: "#25262D",
                                                        fontSize: "0.875rem",
                                                        fontWeight: 500,
                                                        mt: 3,
                                                        fontFamily: "Helvetica Neue",
                                                        display: checkboxesStatus?.destination?.length === 0 ? "none" : ""
                                                        // display: "none"
                                                    }}
                                                > {groupedData[status][rowSelectIndex]?.dest?.name}  </Typography> :
                                                    <Skeleton variant="rounded" width={"50%"} height={25} />}


                                                {!conditionLoading ? <Box sx={{ display: "block" }}>
                                                    {checkboxesStatus?.destination?.map((item) => (
                                                        <FormControlLabel
                                                            key={item.key}
                                                            control={
                                                                <Checkbox
                                                                    checked={destinationCheckboxes[item.key] || false}
                                                                    onChange={handleDestinationCheckboxChange}
                                                                    name={item.key}
                                                                />
                                                            }
                                                            className='CheckboxStype'
                                                            label={item.key}
                                                            sx={{ width: "100%" }}
                                                        />
                                                    ))}
                                                </Box> :
                                                    <Skeleton variant="rounded" sx={{ mt: 1 }} width={"85%"} height={190} />}

                                            </Box>
                                            <Box sx={{ width: '50%', display: "block", }}>
                                                {!conditionLoading ? <Typography
                                                    sx={{
                                                        color: "#25262D",
                                                        fontSize: "0.875rem",
                                                        fontWeight: 500,
                                                        fontFamily: "Helvetica Neue",
                                                        mt: 3,
                                                        display: checkboxesStatus?.source?.length === 0 ? "none" : ""
                                                        // display: "none"
                                                    }}
                                                > {groupedData[status][rowSelectIndex]?.src?.name}  </Typography> :
                                                    <Skeleton variant="rounded" width={"50%"} height={25} />}

                                                {!conditionLoading ? <Box sx={{ display: "block" }}>
                                                    {checkboxesStatus?.source?.map((item) => (
                                                        <FormControlLabel
                                                            key={item.key}
                                                            control={
                                                                <Checkbox
                                                                    checked={sourceCheckboxes[item.key] || false}
                                                                    onChange={handleSourceCheckboxChange}
                                                                    name={item.key}
                                                                />
                                                            }
                                                            className='CheckboxStype'
                                                            label={item.key}
                                                            sx={{ width: "100%" }}
                                                        />
                                                    ))}
                                                </Box> :
                                                    <Skeleton variant="rounded" sx={{ mt: 1 }} width={"85%"} height={190} />}

                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", mt: 2 }}>
                                            <Box sx={{ width: '90%', display: "block", }}>

                                                {!conditionLoading ? <Typography
                                                    sx={{
                                                        color: "#25262D",
                                                        fontSize: "0.875rem",
                                                        fontWeight: 500,
                                                        fontFamily: "Helvetica Neue",
                                                        mt: 2,
                                                        width: "90%"
                                                    }}
                                                >Testing </Typography> :
                                                    <Skeleton variant="rounded" width={"30%"} height={25} sx={{ mt: 1 }} />}

                                                {!conditionLoading ?
                                                    <Typography
                                                        className='TestText'
                                                        sx={{ width: "90%" }}
                                                    >Test the connection before activation</Typography> :
                                                    <Skeleton variant="rounded" width="100%" height={45} sx={{ mt: 1 }} />}


                                                {!conditionLoading ? <Button variant="contained" size="large" disabled sx={{ textTransform: "capitalize", borderRadius: "10px", mt: 2, background: "#538BF4", minWidth: "110px", minHeight: "42px" }} onClick={HandleTestNow}
                                                >
                                                    {!testLoading ?
                                                        "Test Now" :
                                                        <CircularProgress style={{ color: "white" }} size={25} thickness={8}></CircularProgress>
                                                    }
                                                </Button> :
                                                    <Skeleton variant="rounded" width={112} height={40} sx={{ mt: 2 }} />}
                                            </Box>
                                            {/* <Box sx={{ width: '50%', display: "block", ml: "1rem" }}>
                                            <Typography
                                                sx={{
                                                    color: "#25262D",
                                                    fontSize: "0.875rem",
                                                    fontWeight: 500,
                                                    fontFamily: "Helvetica Neue",
                                                    mt: 2,
                                                }}
                                            >

                                                Approval
                                            </Typography>
                                            <Typography
                                                className='TestText'
                                            >
                                                Figma ipsum component variant main layer. Hand variant component object boolean image device font. Frame draft rotate flows flatten list.
                                            </Typography>
                                        </Box> */}
                                        </Box>
                                        <Box>
                                            <Box sx={{ width: '100%', display: "block", }}>
                                                <Typography
                                                    sx={{
                                                        color: "#25262D",
                                                        fontSize: "0.875rem",
                                                        fontWeight: 500,
                                                        fontFamily: "Helvetica Neue",
                                                        mt: 2,
                                                        marginLeft: 0
                                                    }}
                                                >


                                                    {!conditionLoading ? "Test Result" :
                                                        <Skeleton variant="rounded" width={100} height={25} />}
                                                </Typography>


                                                {!conditionLoading ? <Typography
                                                    className='TestText'
                                                    sx={{ width: "90%" }}
                                                >No testing result found</Typography> :
                                                    <Skeleton variant="rounded" width={"90%"} height={35} sx={{ mt: 1 }} />}

                                            </Box>
                                            <Box sx={{ width: '100%', display: "block", }}>

                                                <Box sx={{}}>

                                                    {!conditionLoading ? <FormControlLabel
                                                        control={<Checkbox onChange={handleCheckboxChange} />}
                                                        className='CheckboxStype'
                                                        label="I validated all content"

                                                        sx={{ color: "rgba(102, 105, 106, 1)s" }}
                                                    /> :
                                                        <Skeleton variant="rounded" width={160} height={25} sx={{ mt: 1 }} />}
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: '100%', display: "block", mt: 1 }}>


                                                {!conditionLoading ?
                                                    <Button variant="outlined" size="large" sx={{ textTransform: "capitalize", borderRadius: "10px", minWidth: "110px", minHeight: "42px" }} disabled={isDisabled} onClick={HandleActivate}>
                                                        {!activateLoading && !isLoading ?
                                                            "Activate" :
                                                            <CircularProgress style={{ color: "#538BF4" }} size={25} thickness={8}></CircularProgress>
                                                        }
                                                    </Button> :
                                                    <Skeleton variant="rounded" width={112} height={40} sx={{ mt: 1 }} />}
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </Box>)}
                            {activeTab === "Offline" && (<Box sx={{ width: '42.2%', display: groupedData["Offline"] !== undefined ? "flex" : "none", borderLeft: "1px solid rgba(234, 237, 246, 1)" }}>
                                {rowSelect && activeTab === "Offline" && (
                                    <Box sx={{ padding: "1rem", width: '100%', }}>
                                        <Box sx={{
                                            color: "#25262D",
                                            fontSize: "1rem",
                                            fontWeight: 500,
                                            fontFamily: "Helvetica Neue",
                                        }}>
                                            Error Description
                                        </Box>
                                        <Box sx={{ width: '100%', display: "block", marginLeft: 0 }} className="TestText">
                                            Figma ipsum component variant main layer. Layer outline move style content. Polygon style flatten bold fill pen library. Duplicate plugin vertical comment auto italic create. Inspect follower component link.

                                        </Box>
                                        <Box sx={{
                                            color: "#25262D",
                                            fontSize: "1rem",
                                            fontWeight: 500,
                                            fontFamily: "Helvetica Neue",
                                            mt: 2
                                        }}>
                                            How To Resolve
                                        </Box>
                                        <Box sx={{ width: '100%', display: "block", marginLeft: 0 }} className="TestText">
                                            Figma ipsum component variant main layer. Layer outline move style content. Polygon style flatten bold fill pen library. Duplicate plugin vertical comment auto italic create. Inspect follower component link.

                                        </Box>

                                        <Box sx={{ width: '100%', display: "block", mt: 2 }}>

                                            <Button variant="contained" size="large" disabled sx={{ textTransform: "capitalize", borderRadius: "10px", background: "#538BF4", minWidth: "110px", minHeight: "42px" }} onClick={HandleTestNow}>
                                                {!testLoading ?
                                                    "Test Now" :
                                                    <CircularProgress style={{ color: "white" }} size={25} thickness={8}></CircularProgress>
                                                }
                                            </Button>
                                        </Box>
                                        <Box sx={{ width: '100%', display: "block", }}>
                                            <Typography
                                                sx={{
                                                    color: "#25262D",
                                                    fontSize: "0.875rem",
                                                    fontWeight: 500,
                                                    fontFamily: "Helvetica Neue",
                                                    mt: 2,
                                                    marginLeft: 0
                                                }}
                                            >

                                                Test Result
                                            </Typography>
                                            <Typography
                                                className='TestText'
                                            >
                                                No testing result found
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: '100%', display: "block", }}>

                                            <Box sx={{}}>
                                                <FormControlLabel
                                                    control={<Checkbox onChange={handleCheckboxChangeOffline} />}
                                                    className='CheckboxStype'
                                                    label="I validated all content"
                                                    sx={{ color: "rgba(102, 105, 106, 1)s" }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '100%', display: "block", mt: 1 }}>

                                            <Button variant="outlined" size="large" sx={{ textTransform: "capitalize", borderRadius: "10px", minWidth: "110px", minHeight: "42px" }} disabled={isDisabledOffline} onClick={HandleActivate}>
                                                {!activateLoading && !isLoading ?
                                                    "Reactivate" :
                                                    <CircularProgress style={{ color: "#538BF4" }} size={25} thickness={8}></CircularProgress>
                                                }
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>)}
                        </Box>
                    ))}
                </> : <>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "800px",
                        }}
                    >
                        <Box >
                            <img
                                src={noConfigImg}
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                        </Box>
                        <Typography
                            sx={{
                                mt: 4,
                                color: "#999999",
                                textAlign: "center",
                                fontSize: "1.125rem",
                                fontWeight: 400,
                                fontFamily: "Helvetica Neue",
                            }}
                        >
                            Get started with connections
                        </Typography>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate(selectedAccount !== null && selectedAccount !== undefined
                                    ? `/create-connections?accountId=${selectedAccount.id}`
                                    : "/create-connections")
                            }}
                            sx={{
                                border: "1px solid black", color: "#fff", textTransform: "capitalize", background: "#000", borderRadius: "8px", mt: 3,
                                '&:hover': {
                                    background: "#000",
                                    border: "1px solid black",
                                    cursor: "pointer"
                                }
                            }}
                        >
                            Create Connection
                        </Button>
                    </Box>
                </>}
            </Box>

        </Box>
    )
}

export default MyConnection