import React from 'react'
import "./PrivacyPolicy.css"

const PrivacyTable = () => {
    return (
        <div>
            <table className={1} border={1} cellSpacing={0} cellPadding={0} width={666} style={{ "margin-left": "-.25pt", "border-collapse": "collapse", border: "none", }} >
                <tbody>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-bottom": "solid black 1.5pt", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>    <span style={{ "font-size": "9.0pt" }}> Category of personal information</span></b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style' style={{ width: "79.5pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }} >
                                <b>    <span style={{ "font-size": "9.0pt" }}>Collected? </span></b>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>    <span style={{ "font-size": "9.0pt" }}> Categories of Sources</span></b>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>    <span style={{ "font-size": "9.0pt" }}>Business or Commercial Purpose</span></b>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>    <span style={{ "font-size": "9.0pt" }}> Categories of third parties to which we have sold or shared{" "}</span></b>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b> <span style={{ "font-size": "9.0pt" }}> Categories of third parties to which we have otherwise disclosed{" "}</span> </b>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b> <span style={{ "font-size": "9.0pt" }}> Identifiers </span></b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{
                            width: "79.5pt",
                        }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>Yes</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{
                                "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt",
                                "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal",
                            }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    provided directly by the customer
                                </span>
                            </p>
                            <p className="MsoNormal" style={{
                                "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt",
                                "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal",
                            }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    collected from customer’s browser or device
                                </span>
                            </p>
                            <p className="MsoNormal" style={{
                                "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt",
                                "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal",
                            }} >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    inferred from information provided by customer
                                </span>
                            </p>
                            <p className="MsoNormal" style={{
                                "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "9.0pt",
                                "-webkit-text-indent": "-9.0pt", "text-indent": "-9.0pt", "line-height": "normal",
                            }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    obtained from one or more third parties
                                </span>
                            </p>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }} >
                                <span style={{ "font-size": "8.0pt", color: "red" }}>
                                    &nbsp;
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{
                                "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in",
                                "line-height": "normal",
                            }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    -providing our products and services{" "}
                                </span>
                            </p>
                            <p className="MsoNormal" style={{
                                "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in",
                                "line-height": "normal",
                            }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising and marketing
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>

                            <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising platform providers
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p className="MsoNormal" style={{ "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in", "margin-left": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>
                                    -website analytics vendors
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>    <span style={{ "font-size": "9.0pt" }}>        Customer records        <a name="_heading=h.gjdgxs" />    </span></b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }} >
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>Yes</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    provided directly by the customer
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    collected from customer’s browser or device
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    inferred from information provided by customer
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    obtained from one or more third parties
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt", color: "red" }}>
                                    &nbsp;
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -providing our products and services{" "}
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising and marketing
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising platform providers
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -website analytics vendors
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>
                                    <span style={{ "font-size": "9.0pt" }}>
                                        Characteristics of legally protected classifications
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>Yes</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    provided directly by the customer
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    collected from customer’s browser or device
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    inferred from information provided by customer
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    obtained from one or more third parties
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt", color: "red" }}>
                                    &nbsp;
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -providing our products and services{" "}
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising and marketing
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising platform providers
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -website analytics vendors
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            width={116}
                            valign="top"
                            style={{
                                width: "87.0pt",
                                border: "solid black 1.0pt",
                                "border-top": "none",
                                padding: "0in 5.4pt 0in 5.4pt",
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <b>
                                    <span style={{ "font-size": "9.0pt" }}>
                                        Commercial information
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>No</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>    <span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>
                                    <span style={{ "font-size": "9.0pt" }}>
                                        Biometric information
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }} ><span style={{ "font-size": "8.0pt" }}>No</span> </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }} ><span style={{ "font-size": "8.0pt" }}>N/A</span> </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b><span style={{ "font-size": "9.0pt" }}>Internet or other network activity information </span></b></p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>    <span style={{ "font-size": "8.0pt" }}>Yes</span></p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt" }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    collected from customer’s browser or device
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    inferred from information provided by customer
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt", color: "red" }}>
                                    &nbsp;
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{
                                "margin-top": "0in", "margin-right": ".75pt", "margin-bottom": "0in",
                                "margin-left": "0in", "line-height": "normal",
                            }}>
                                <span style={{ "font-size": "8.0pt" }}>-providing our products and services{" "}   </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising and marketing
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising platform providers
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -website analytics vendors
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "7.3pt" }}>
                        <td
                            width={116}
                            valign="top"
                            style={{
                                width: "87.0pt",
                                border: "solid black 1.0pt",
                                "border-top": "none",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "7.3pt",
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <b>
                                    <span style={{ "font-size": "9.0pt" }}>
                                        Geolocation data
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", height: "7.3pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>    <span style={{ "font-size": "8.0pt" }}>Yes</span></p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    collected from customer’s browser or device
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "9.0pt",
                                    "-webkit-text-indent": "-9.0pt",
                                    "text-indent": "-9.0pt",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    ●<span style={{}}>&nbsp; </span>
                                </span>
                                <span style={{ "font-size": "8.0pt" }}>
                                    inferred from information provided by customer
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt", color: "red" }}>
                                    &nbsp;
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", height: "7.3pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -providing our products and services{" "}
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising and marketing
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -advertising platform providers
                                </span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -marketing and advertising partners
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-top": "0in",
                                    "margin-right": ".75pt",
                                    "margin-bottom": "0in",
                                    "margin-left": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>
                                    -website analytics vendors
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b><span style={{ "font-size": "9.0pt" }}>Sensory information</span></b>
                            </p>
                        </td>
                        <td width={106} className='border-style-1' valign="top" style={{ width: "79.5pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>    <span style={{ "font-size": "8.0pt" }}>No</span></p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>    <span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}><span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b><span style={{ "font-size": "9.0pt" }}>Professional or employment-related information</span></b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>No</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b><span style={{ "font-size": "9.0pt" }}>Education information</span></b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }} >
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>No</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>    <span style={{ "font-size": "8.0pt" }}>N/A</span></p>
                        </td>
                    </tr>
                    <tr>
                        <td width={116} valign="top" style={{ width: "87.0pt", border: "solid black 1.0pt", "border-top": "none", padding: "0in 5.4pt 0in 5.4pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <b>
                                    <span style={{ "font-size": "9.0pt" }}>
                                        Sensitive personal information
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>No</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p className="MsoNormal" style={{ "margin-bottom": "0in", "line-height": "normal", }}>
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td
                            width={116}
                            valign="top"
                            style={{
                                width: "87.0pt",
                                border: "solid black 1.0pt",
                                "border-top": "none",
                                padding: "0in 5.4pt 0in 5.4pt",
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <b>
                                    <span style={{ "font-size": "9.0pt" }}>
                                        Inferences from any of the above
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td width={106} valign="top" className='border-style-1' style={{ width: "79.5pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>No</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                        <td width={111} valign="top" className='border-style-1' style={{ width: "83.25pt", }}>
                            <p
                                className="MsoNormal"
                                style={{
                                    "margin-bottom": "0in",
                                    "line-height": "normal",
                                }}
                            >
                                <span style={{ "font-size": "8.0pt" }}>N/A</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PrivacyTable