import { Box, CircularProgress } from "@mui/material";

import CommonButton from "../CommonButton/CommonButton";
import { Strings } from "../../utils/Strings";
import { color } from "../../utils/Colors";
import MyConnection from "./MyConnection";


const ConnectionLists = ({ isLoading, connectors, setStep, refetch, isSmallForTableHeader, deleteConnector, EditIds, setEditIds, addDuration, handleDeleteOpen, setDeleteIds, setIsForDisable, setSelectedItem, navigate, selectedAccount, inputValue, setInputValue }) => {
    if (isLoading || !connectors) return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <CircularProgress style={{ color: "#3646ac" }} size={55} thickness={8}></CircularProgress>
        </Box>
    )

    if (connectors) return (
        <Box
            sx={{
                backgroundColor: "#fff",
                mt: setStep !== undefined ? 0 : 2,
                px: setStep !== undefined ? 0 : 2,
                pt: setStep !== undefined ? 0 : 1,
                borderRadius: 2,
                mb: "1rem",
                boxShadow:
                    setStep === undefined
                        ? "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)"
                        : "none",
            }}
        >
            {connectors && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        // border: "1px solid #DEDEDE", borderRadius: 2,
                        width: setStep !== undefined ? "85%" : "100%",
                        mb: "2.5rem",
                        mx: "auto",
                        // border: '1px solid black'
                    }}
                >
                    <MyConnection
                        data={connectors}
                        deleteConnector={deleteConnector}
                        setIsForDisable={setIsForDisable}
                        handleDeleteOpen={handleDeleteOpen}
                        setDeleteIds={setDeleteIds}
                        setSelectedItem={setSelectedItem}
                        EditIds={EditIds}
                        setEditIds={setEditIds}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        refetch={refetch}
                        isLoading={isLoading}

                    />

                    {/* <DashboardSingleConnector
                                    key={idx}
                                    data={item}
                                    id={idx}
                                    deleteConnector={deleteConnector}
                                    setIsForDisable={setIsForDisable}
                                    handleDeleteOpen={handleDeleteOpen}
                                    setDeleteIds={setDeleteIds}
                                    setSelectedItem={setSelectedItem}
                                /> */}
                    {/* </>

                        )
                    )} */}
                </Box>)}


            {setStep !== undefined && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            mb: 4,
                            flexDirection: "row",
                            alignItems: "center",
                            mx: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                mb: 4,
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                width: setStep !== undefined ? "85%" : "100%",
                                alignItems: "center",
                                ml: "110px",
                                mr: "auto",
                            }}
                        >
                            <CommonButton
                                width={160}
                                height={"2.75rem"}
                                textStyle={{
                                    fontSize: "1.125rem",
                                    fontWeight: "500",
                                    fontFamily: "Helvetica Neue",
                                }}
                                onClick={(event) => {
                                    if (setStep !== undefined) {
                                        setStep(2);
                                    }
                                }}
                                title={Strings.back}
                                color={color.buttonColor}
                            ></CommonButton>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                mb: 4,
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                width: setStep !== undefined ? "85%" : "100%",
                                alignItems: "center",
                                mr: "110px",
                                ml: "auto",
                                // border: '1px solid black'
                            }}
                        >
                            <CommonButton
                                width={160}
                                height={"2.75rem"}
                                textStyle={{
                                    fontSize: "1.125rem",
                                    fontWeight: "500",
                                    fontFamily: "Helvetica Neue",
                                }}
                                onClick={(event) => {
                                    if (setStep !== undefined) {
                                        setStep(4);
                                    }
                                }}
                                title={Strings.next}
                                color={
                                    connectors?.length === 0
                                        ? color.themeLightGray
                                        : color.buttonColor
                                }
                                // color={color.buttonColor}
                                disabled={connectors?.length === 0}
                            ></CommonButton>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    )


}

export default ConnectionLists