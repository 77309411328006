import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import {
  Alert,
  Box,
  Input,
  Snackbar,
  styled,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import ConfigurationForm from "./ConfigurationForm";
import { convertUnixTimestampToAgo } from "../../utils/utils";
import { color } from "../../utils/Colors";
import {
  useDeletePlatformConfigurationMutation,
  useSavePlatformConfigurationMutation,
} from "../../services/platformQuery";
import {
  deleteConfigurationAction,
  updateConfiguration,
} from "../../features/madTechSlice";
import { useMyContext } from "../CommanToasterContext/toast";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    marginBottom: "0.5rem",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "&:focus, &:focus-within": {
    backgroundColor: "transparent", // Replace 'your-color-here' with the desired background color
    outline: "none", // Remove the focus outline
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
  // padding: "25px 16px"
}));

const ConfigurationAccordion = forwardRef(
  (
    {
      suffix,
      expanded,
      setExpanded,
      dataType,
      platformId,
      configuration,
      index,
      lastUpdated,
      srcPlateform,
      name,
      activeConfiguration,
      handleChange,
      id,
      setformsSaveConfig,
      formsSaveConfig,
      platformByIdDatas,
      type
    },
    ref
  ) => {
    const [editMode, setEditMode] = useState(false);
    const panel = `${suffix}${index}`;
    const [submittedData, setSubmittedData] = useState(null);
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const { setOpen: setOpenSuccess, setMessage: setSuccessMessage } = useMyContext();

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const { control, handleSubmit, formState, reset, watch } = useForm();

    const [
      saveConfig,
      {
        data: saveConfigData,
        isLoading: saveConfigLoading,
        error: saveConfigError,
      },
    ] = useSavePlatformConfigurationMutation();

    const { isDirty, isValid } = formState;
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(onSubmit),
      discard: () => {
        if (id === null || id === "") {
          dispatch(
            deleteConfigurationAction({
              dataType,
              platformType: activeConfiguration,
              index,
            })
          );
        } else {
          reset();
        }
      },
    }));

    const saveEnable = useMemo(() => {
      if (isDirty && isValid && !saveConfigLoading) {
        return true;
      }
      return false;
    }, [isDirty, isValid, saveConfigLoading]);

    useEffect(() => {
      if (watch && watch("configrationName") && setformsSaveConfig) {
        setformsSaveConfig((prev) => ({
          ...prev,
          [panel]: { saveEnable, configName: watch("configrationName") },
        }));
      }
    }, [setformsSaveConfig, saveEnable, panel, watch("configrationName")]);

    useEffect(() => {
      if (!id && panel) {
        setExpanded(panel);
      }
    }, [id, panel]);

    const [
      deleteRequestPlatform,
      {
        data: deleteRequestedPlatformData,
        isLoading: deleteRequestPlatformLoading,
        error: deleteRequestPlatformError,
      },
    ] = useDeletePlatformConfigurationMutation();

    const _deleteRequestedPlatform = useCallback(() => {
      var deleteRequestPlatformBody = {
        id,
      };

      deleteRequestPlatform(deleteRequestPlatformBody);
      // setIsDeleteOpen(false)
    }, [useDeletePlatformConfigurationMutation]);

    useEffect(() => {
      if (deleteRequestedPlatformData) {
        if (deleteRequestedPlatformData.statusCode === 200) {
          dispatch(
            deleteConfigurationAction({
              dataType,
              platformType: activeConfiguration,
              index,
            })
          );
          setIsDeleteOpen(false)
          setInputText("")
        }
      }
    }, [deleteRequestedPlatformData, deleteRequestPlatformError]);

    const onSubmit = (data) => {
      setSubmittedData(data);
      const mapping = {};
      for (const key in data) {
        mapping[key] = data[key];
      }

      // Map the values from the configuration using the mapping
      const mergedConfiguration = configuration.map((item) => {
        const name = item.name;
        const value = mapping[name] || item.value; // Use the mapped value if available, otherwise use the original value
        return { ...item, value, name, lastUpdated }; // Include name and lastUpdated in each object
      });

      // Create the configToSave object
      const configToSave = {
        configBody: {
          name: watch("configrationName"),
          configurationId: id,
          nameChanged: watch("configrationName") !== name,
          dataType,
          platformId,
          platformConfigurations: mergedConfiguration,
        },
        platformType: activeConfiguration,
      };

      // Save the configToSave object
      saveConfig(configToSave);
      //
    };

    useEffect(() => {
      if (saveConfigData) {
        if (saveConfigData.statusCode === 200) {
          setSuccessMessage(saveConfigData?.statusMessage)
          setOpenSuccess(true)
          reset(submittedData);
          const data = submittedData;
          const mapping = {};
          for (const key in data) {
            mapping[key] = data[key];
          }

          // Map the values from the configuration using the mapping
          const mergedConfiguration = configuration.map((item) => {
            const name = item.name;
            const value = mapping[name] || item.value; // Use the mapped value if available, otherwise use the original value
            return { ...item, value, name, lastUpdated }; // Include name and lastUpdated in each object
          });
          dispatch(
            updateConfiguration({
              dataType,
              platformType: activeConfiguration,
              index,
              configuration: {
                id: id && id != null ? id : saveConfigData.response,
                name: watch("configrationName"),
                lastUpdated: Math.ceil(Date.now() / 1000) - 5,
                configuration: mergedConfiguration,
              },
            })
          );
        }
      }

      if (saveConfigError) {
        console.log("saveConfigError", saveConfigError);
      }
    }, [saveConfigData, saveConfigError, submittedData]);

    const handleAccordionClick = () => {
      if (editMode) {
        setEditMode(false);
      }
    };

    const handleChangeCollapse = useMemo(() => {
      return handleChange(panel, formsSaveConfig);
    }, [panel, formsSaveConfig, expanded]);

    const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
    const [inputText, setInputText] = useState("");

    const deleteConfigurationClose = () => {
      // resetForm();
      setIsDeleteOpen(false);
      setInputText("");
    };

    const handleInputChange = (event) => {
      const inputValue = event.target.value.toUpperCase();
      setInputText(inputValue);
    };

    useEffect(() => {
      if (inputText === "DELETE") {
        setIsDeleteButtonEnabled(true);
      } else {
        setIsDeleteButtonEnabled(false);
      }
    }, [inputText]);

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <>
        {/* start......Use With Accordian menu but now its Hide if need remove Display none  */}
        <Accordion
          key={panel}
          expanded={expanded === panel}
          onChange={handleChangeCollapse}
          // onChange={()=>console.log("saveEnable",panel,saveEnable, watch("configrationName"))}
          sx={{ display: "none", border: expanded === panel && "1px solid rgba(0, 0, 0, .5)" }}
        >
          <AccordionSummary
            aria-controls={`${panel}-content`}
            id={`${panel}-header`}
            onClick={handleAccordionClick}
          >
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                icon={<CheckCircleOutlineIcon style={{ color: "white" }} />}
                onClose={handleClose}
                severity="success"
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "green",
                  color: "#fff",
                  fontWeight: 600,
                  ".MuiAlert-action": {
                    padding: "3px",
                  },
                }}
              >
                {message}
              </Alert>
            </Snackbar>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    name="configrationName"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={name}
                    render={({ field }) => (
                      <Input
                        onClick={(e) => {
                          if (expanded == panel) {
                            e.stopPropagation();
                          }
                          setEditMode(true);
                        }}
                        readOnly={expanded != panel}
                        type="text"
                        placeholder={name}
                        {...field}
                        inputProps={{
                          "aria-label": "description",
                          autoComplete: "off",
                          autoFocus: false,
                          fontSize: "1rem !important",
                        }}
                        sx={{
                          "&::before": {
                            borderBottom: "none !important", // Remove the underline
                          },
                        }}
                      />
                    )}
                  />
                  {isDirty && editMode && (
                    <>
                      {/* <DoneIcon
                      color={"success"}
                      sx={{ height: "20px", width: "20px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubmit(onSubmit)();
                      }}
                    /> */}
                      {/* <ClearIcon
                      color={"error"}
                      sx={{ height: "20px", width: "20px" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        reset();
                        setEditMode(false);
                      }}
                    /> */}
                    </>
                  )}
                </div>
                {/* )} */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                  color: color.themeDarkGray,
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                {lastUpdated > 0 && (
                  <Box>
                    Last updated:
                    <span style={{ marginLeft: "0.25rem", fontSize: "14px" }}>
                      {convertUnixTimestampToAgo(lastUpdated)}
                    </span>
                  </Box>
                )}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <ConfigurationForm
              nameChanged={
                formState.defaultValues?.configrationName !=
                watch("configrationName")
              }
              dataType={dataType}
              platformId={platformId}
              name={watch("configrationName")}
              lastUpdated={lastUpdated}
              srcPlateform={srcPlateform}
              index={index}
              configuration={configuration}
              activeConfiguration={activeConfiguration}
              platformByIdDatas={platformByIdDatas}
              id={id}
              reset={reset}
              isDirty={isDirty}
              handleSubmit={handleSubmit}
              isValid={isValid}
              control={control}
              saveEnable={saveEnable}
              saveConfigData={saveConfigData}
              saveConfigLoading={saveConfigLoading}
              saveConfigError={saveConfigError}
              saveConfig={saveConfig}
              onSubmit={onSubmit}
              _deleteRequestedPlatform={_deleteRequestedPlatform}
              deleteRequestPlatformLoading={deleteRequestPlatformLoading}
              deleteConfigurationClose={deleteConfigurationClose}
              isDeleteButtonEnabled={isDeleteButtonEnabled}
              inputText={inputText}
              handleInputChange={handleInputChange}
              isDeleteOpen={isDeleteOpen}
              setIsDeleteOpen={setIsDeleteOpen}
              types={type}
            ></ConfigurationForm>
          </AccordionDetails>
        </Accordion>
        {/* End......Use with Accordian menu but now its Hide if need remove Display none  */}
        <AccordionDetails>
          <ConfigurationForm
            nameChanged={
              formState.defaultValues?.configrationName !=
              watch("configrationName")
            }
            dataType={dataType}
            platformId={platformId}
            name={watch("configrationName")}
            lastUpdated={lastUpdated}
            srcPlateform={srcPlateform}
            index={index}
            configuration={configuration}
            activeConfiguration={activeConfiguration}
            platformByIdDatas={platformByIdDatas}
            id={id}
            reset={reset}
            isDirty={isDirty}
            handleSubmit={handleSubmit}
            isValid={isValid}
            control={control}
            saveEnable={saveEnable}
            saveConfigData={saveConfigData}
            saveConfigLoading={saveConfigLoading}
            saveConfigError={saveConfigError}
            saveConfig={saveConfig}
            onSubmit={onSubmit}
            _deleteRequestedPlatform={_deleteRequestedPlatform}
            deleteRequestPlatformLoading={deleteRequestPlatformLoading}
            deleteConfigurationClose={deleteConfigurationClose}
            isDeleteButtonEnabled={isDeleteButtonEnabled}
            inputText={inputText}
            handleInputChange={handleInputChange}
            isDeleteOpen={isDeleteOpen}
            setIsDeleteOpen={setIsDeleteOpen}
            types={type}
          ></ConfigurationForm>
        </AccordionDetails>
      </>
    );
  }
);

export default ConfigurationAccordion;
