import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  Conatiner,
  ConnectionContainer,
  LoaderWrapper,
  Wrapper,
} from "./Styles/MyConnection.styles";
import { useGetConnectionsQuery } from "../../../services/connectionsQuery";
import Header from "./Header";
import CreateConnection from "./CreateConnection";
import ConnectList from "./ConnectionList/ConnectList";
import { filteredData } from "./Constant";
import useDebounceValue from "../../../hook/useDebounceValue";
// import {connectionData} from "./dumyData"

const MyConnections = ({ connectors, setConnectors }) => {
  const navigate = useNavigate();

  const {
    data: connectionData,
    isLoading: connectionLoading,
    error: connectionError,
    isFetching,
    refetch,
  } = useGetConnectionsQuery();
  const [activeTab, setActiveTab] = useState("In-progress");
  const [inputValue, setInputValue] = useState();
  const [finalData, setFinalData] = useState([]);
  const [isStatusChange, setIsStatusChange] = useState(false);

  const debounceSearchValue = useDebounceValue(inputValue, 1000);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (connectionData?.response?.length) {
      const allConnections = connectionData?.response?.map((item) => ({
        id: item?.id,
        src: item.src,
        dest: item.dest,
        usedVolume: item.usedVolume,
        enabled: item.enabled,
        status: item.status,
        deleted: item.deleted,
        conId: item.conId,
        name: item.name,
      }));
      const connectionDummyData = [];
      const updatedConnections = [...allConnections, ...connectionDummyData];
      setConnectors(updatedConnections);
      setFinalData(updatedConnections);
      setIsStatusChange(true);
    } else {
      console.log("No connection data available to process");
    }

    if (connectionError && connectionError.statusCode === 403) {
      navigate("/signin");
    }
  }, [connectionData, connectionError]);

  useEffect(() => {
    const filteredItems = filteredData(finalData, inputValue);
    if (inputValue === "") {
      setConnectors(finalData);
    } else {
      setConnectors(filteredItems);
    }
  }, [debounceSearchValue]);

  useEffect(() => {
    setConnectors(finalData);
  }, [finalData]);

  const showLoader = () => {
    return (
      <LoaderWrapper>
        <CircularProgress
          style={{ color: "#3646ac" }}
          size={55}
          thickness={8}
        ></CircularProgress>
      </LoaderWrapper>
    );
  };

  return (
    <Wrapper>
      {(connectionLoading || isFetching) && !isStatusChange ? (
        showLoader()
      ) : (
        <ConnectionContainer>
          <Conatiner>
            <Box
              display="flex"
              justifyContent="space-between"
              mb="1rem"
              alignItems="center"
            >
              <Box width="100%">
                <Header
                  data={connectionData?.response}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                />
                {connectors?.length ? (
                  <ConnectList
                    connectors={connectors}
                    connectionLoading={connectionLoading}
                    refetch={refetch}
                    setConnectors={setConnectors}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    connectionLoader={connectionLoading || isFetching}
                    setIsStatusChange={setIsStatusChange}
                  />
                ) : (
                  <CreateConnection />
                )}
              </Box>
            </Box>
          </Conatiner>
        </ConnectionContainer>
      )}
    </Wrapper>
  );
};

export default MyConnections;
