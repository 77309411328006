import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "800px",
})

export const Heading = styled(Typography)({
    color: "#999999 !important",
    textAlign: "center",
    fontSize: "1.125rem !important",
    fontWeight: '400 !important',
    fontFamily: "Helvetica Neue !important",
})

export const CreateButton = styled(Button)({
    border: "1px solid black !important",
    color: "#fff !important",
    textTransform: "capitalize !important",
    background: "#000 !important",
    borderRadius: "8px !important",
    "&:hover": {
      background: "#000 !important",
      border: "1px solid black !important",
      cursor: "pointer !important",
    },
})