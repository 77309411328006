export const tableColumn = [
  "User",
  "Role",
  "Email",
  "Accounts",
  "Created date",
  "Last login",
];

export const renderAccountNames = (selectedIds, accounts) => {
    // Find the corresponding names for each selected account ID
    const selectedNames = accounts
      .filter((account) => selectedIds.includes(account.id))
      .map((account) => account.name)
      .join(", ");
  
    return selectedNames;
  };


 export  const leftArr = [
    {
      id: "firstName",
      label: "First name*",
    },
    {
      id: "lastName",
      label: "Last name*",
    },
  ];
  
export  const rightArr = [
    {
      id: "companyName",
      label: "Company name*",
    },
    {
      id: "title",
      label: "Title",
    },
  ];