import { Box, Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import NaLayout from '../../components/NavLayout/NaLayout'
import useDocumentTitle from '../../hook/useDocumentTitle'
import { color } from '../../utils/Colors'
import HomeFooter from '../Home/HomeFooter'
import LetsConnect from '../Home/LetsConnect'
import ConnectorsList from './ConnectorsList'
import { NoDataGetStartedBtn, NoDataHeading, NoDataMainContainer, NoDataSubHeading } from './Connectors.styles'


const HomeConnectors = () => {
    const navigate = useNavigate()
    useDocumentTitle('Connectors')

    const handleNavigate = () => {
        navigate('/contact')
    }

    return (
        <NaLayout>
            <Helmet>
                <script dangerouslySetInnerHTML={{
                    __html: (function (w, d, s, l, i) {
                        var f = d.getElementsByTagName(s)[0], j = d.createElement(s); j.async = !0; j.src =
                            '//launch.amplifyreach.com/' + s + '/' + l + '/' + i + '.js?t=' + new Date().getTime(); f.parentNode.insertBefore(j, f)
                    })(window, document, 'script', '2005', 'c97f6e41b15f6dd9')
                }}>
                </script>
            </Helmet>
            <NoDataMainContainer >
                <NoDataHeading>
                    Connectors
                </NoDataHeading>
                <Box  my={3}>
                    <NoDataSubHeading>
                        Don't see a connector you need? MadTech is constantly updating our integrations based on priority.
                    </NoDataSubHeading>
                    <NoDataSubHeading>
                        Sign up and request your connector today.
                    </NoDataSubHeading>
                </Box>
                <NoDataGetStartedBtn onClick={handleNavigate}>
                    <Typography  fontFamily= {'Helvetica Neue'} color={ color.whiteColor} >
                        Get Started
                    </Typography>
                </NoDataGetStartedBtn>
            </NoDataMainContainer>
            <Box maxWidth="93.75rem"mx= 'auto'>
                <ConnectorsList />
            </Box>
            <LetsConnect />
            <HomeFooter />
        </NaLayout>
    )
}

export default HomeConnectors