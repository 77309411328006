import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';

import blackLogo from '../../assets/madconnect-black.svg'
// import blackLogo from '../../assets/madtechdark.svg'
import { color } from '../../utils/Colors';
import { madTechState, setIsGetStarted } from '../../features/madTechSlice';
import { AppBarStyled, IconButtonStyled, ImageBox, ImageStyled, MenuBox, MenuInnerBox, MenuItemBox, MenuItemname, MenuStyled, MenuUnderLineBox, ResposiveMenuBox, Toolbarstyle } from './Navbar.styles';


const ResponsiveAppBar = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const matchesxs = useMediaQuery('(min-width:1024px)');
    const matchesxss = useMediaQuery('(min-width:700px)');
    const matchesxsss = useMediaQuery('(min-width:420px)');
    const highResolution = useMediaQuery('(min-width:1921px')

    const { isGetStarted, imageLoaded, isAdminAgency } = useSelector(madTechState)
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBarStyled matchesxs={matchesxs} highResolution={highResolution}>
            {imageLoaded &&
                <Container maxWidth="xl" sx={{ my: 'auto' }}>
                    <Toolbarstyle disableGutters>
                        <ImageBox
                            matchesxs={matchesxs} matchesxss={matchesxss} matchesxsss={matchesxsss}
                            onClick={() => navigate('/')}>
                            <ImageStyled matchesxs={matchesxs} alt={'MADCONNECT'}
                                src={blackLogo}></ImageStyled>
                        </ImageBox>
                        <MenuBox matchesxs={matchesxs}>
                            <MenuInnerBox>
                                <MenuItemBox>
                                    <MenuItemname
                                        isGetStarted={isGetStarted}
                                        pathname={location.pathname}
                                        onClick={() => {
                                            navigate('/')
                                            window.scrollTo({ behavior: 'auto', top: 0 })
                                            if (isGetStarted) {
                                                dispatch(setIsGetStarted())
                                            }
                                        }}>Home</MenuItemname>
                                    {!isGetStarted && location.pathname === '/' &&
                                        <MenuUnderLineBox />
                                    }
                                </MenuItemBox>
                                <MenuItemBox >
                                    <MenuItemname
                                        onClick={() => {
                                            navigate('/connector')
                                            window.scrollTo({ behavior: 'auto', top: 0 })
                                            if (isGetStarted) {
                                                dispatch(setIsGetStarted())
                                            }
                                        }}>Connectors</MenuItemname>
                                    {!isGetStarted && location.pathname.includes('/connector') &&
                                        <MenuUnderLineBox sx={{
                                            position: "absolute", bottom: -39,
                                            left: 8,
                                            width: 90, height: 2, backgroundColor: "#3843AC"
                                        }} />
                                    }
                                </MenuItemBox>
                                <MenuItemBox>
                                    <MenuItemname
                                        onClick={() => {
                                            navigate('/learnmore')
                                            window.scrollTo({ behavior: 'auto', top: 0 })
                                            if (isGetStarted) {
                                                dispatch(setIsGetStarted())
                                            }
                                        }}>Learn More</MenuItemname>
                                    {!isGetStarted && location.pathname === '/learnmore' &&
                                        <MenuUnderLineBox sx={{
                                            position: "absolute", bottom: -39,
                                            left: 8,
                                            width: 90, height: 2, backgroundColor: "#3843AC"
                                        }} />
                                    }
                                </MenuItemBox>
                                <MenuItemBox>
                                    <MenuItemname
                                        onClick={() => {
                                            navigate('/contact')
                                            window.scrollTo({ behavior: 'auto', top: 0 })
                                            if (isGetStarted) {
                                                dispatch(setIsGetStarted())
                                            }
                                        }}>Contact Us</MenuItemname>
                                    {!isGetStarted && location.pathname === '/contact' &&
                                        <MenuUnderLineBox />
                                    }
                                </MenuItemBox>
                            </MenuInnerBox>
                            <Box sx={{
                                px: 2, display: "flex", flexDirection: "row", alignItems: 'center'
                            }}>
                                {!isGetStarted &&
                                    <Box sx={{
                                        mr: "1rem", width: 144, height: "3rem", border: "1px solid #3843AC",
                                        display: 'flex', justifyContent: "center", alignItems: "center",
                                        borderRadius: 1, cursor: "pointer", textAlign: 'center'
                                    }} onClick={() => {
                                        if (!isGetStarted) {
                                            navigate("/contact")
                                            // dispatch(setIsGetStarted())
                                        }
                                    }}>
                                        <Typography sx={{ color: '#3843AC', fontFamily: 'Helvetica Neue', }}>
                                            Get Started
                                        </Typography>
                                    </Box>
                                }
                                <Box sx={{
                                    backgroundColor: "#3843AC", cursor: "pointer",
                                    width: "6.25rem", height: "3rem", borderRadius: 1, textAlign: 'center',
                                    display: 'flex', justifyContent: "center", alignItems: "center"
                                }}
                                    onClick={() => {
                                        if (isAdminAgency) {
                                            window.location.replace("/admin/dashboard");
                                        } else {
                                            navigate('/signin')
                                        }
                                        // window.location.href = '/signin'
                                        if (isGetStarted) {
                                            dispatch(setIsGetStarted())
                                        }
                                    }}
                                >
                                    <Typography sx={{ color: color.whiteColor, fontFamily: 'Helvetica', }}>
                                        Log in
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuBox>

                        <ResposiveMenuBox matchesxs={matchesxs}>
                            <IconButtonStyled onClick={handleOpenUserMenu}>
                                <MenuIcon />
                            </IconButtonStyled>
                            <MenuStyled
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate('/')
                                        handleCloseUserMenu()
                                        window.scrollTo({ behavior: 'auto', top: 0 })
                                        if (isGetStarted) {
                                            dispatch(setIsGetStarted())
                                        }
                                    }}>Home</MenuItem>
                                <MenuItem onClick={() => {
                                    navigate('/connector')
                                    handleCloseUserMenu()
                                    window.scrollTo({ behavior: 'auto', top: 0 })
                                    if (isGetStarted) {
                                        dispatch(setIsGetStarted())
                                    }
                                }}
                                >Connectors</MenuItem>
                                <MenuItem onClick={() => {
                                    navigate('/learnmore')
                                    handleCloseUserMenu()
                                    window.scrollTo({ behavior: 'auto', top: 0 })
                                    if (isGetStarted) {
                                        dispatch(setIsGetStarted())
                                    }
                                }}>Learn More</MenuItem>
                                <MenuItem onClick={() => {
                                    navigate('/contact')
                                    handleCloseUserMenu()
                                    window.scrollTo({ behavior: 'auto', top: 0 })
                                    if (isGetStarted) {
                                        dispatch(setIsGetStarted())
                                    }
                                }}>Contact Us</MenuItem>
                                <MenuItem onClick={() => {
                                    if (!isGetStarted) {
                                        // dispatch(setIsGetStarted())
                                        navigate('/contact')
                                        handleCloseUserMenu()
                                    }
                                }}>Get Started</MenuItem>
                                <MenuItem onClick={() => {
                                    navigate('/signin')
                                    handleCloseUserMenu()
                                    if (isGetStarted) {
                                        dispatch(setIsGetStarted())
                                    }
                                }}>Log in</MenuItem>
                            </MenuStyled>
                        </ResposiveMenuBox>

                    </Toolbarstyle>
                </Container>
            }
        </AppBarStyled>
    );
}
export default ResponsiveAppBar;