import React, { useMemo, useState } from "react";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Button,
} from "@mui/material";

import { FormNameWrapper, FormNameLabelWrapper, FormNameInputWrapper, PermissionHeading } from "../SRoles/Srole.styles"

import { color } from "../../utils/Colors";
import CommonButton from "../../components/CommonButton/CommonButton";
import "./SRoles.css";
import { getPanelType, madTechState } from "../../features/madTechSlice";
import { TableRowComponent } from "./SAddRole";
import trash from "../../assets/trash-2.svg";



const SEditRole = ({
  selectedItem,
  setSelectedItem,
  _handleUpdateRole,
  isRoleEdit,
  setIsDeleteOpen,
}) => {
  const panelType = useSelector(getPanelType);
  const navigate = useNavigate();
  const { selectedAccount } = useSelector(madTechState);

  const _handlePermissionsChange = (id, newPermissions) => { };

  const rolePermissionDetails = useMemo(() => {
    if (selectedItem) {
      return selectedItem.rolePermissionDetails.map((section, index) => {
        return (
          <TableRowComponent
            index={index}
            key={section.name}
            title={section.name}
            roleDetails={selectedItem}
            setRoleDetails={setSelectedItem}
            section={section}
            isDisabled={!isRoleEdit}
            panelType={panelType}
          />
        );
      });
    }
    return [];
  }, [selectedItem, isRoleEdit, panelType]);

  return (
    <>
      {/* ROLE DETAILS START */}
      <Box width="100%" mt={2} border="1px solid #E4E4E4" p={3} display="flex" flexDirection="column" backgroundColor="#fff">
        <Box display="flex" flexDirection="column">
          <Typography sx={{ fontSize: "1.125rem", fontFamily: "Helvetica Neue", fontWeight: 500, letterSpacing: 0.2, mb: 2, }}>  Role details</Typography>
          <Box display="flex" mt={0} width="100%" justifyContent="space-between">
            <Box display="flex" flexDirection="column" width="70%">
              <Box display="flex" justifyContent="space-between">
                <FormNameWrapper sx={{ width: "35%", }} size="small">
                  <FormNameLabelWrapper id="firstName">  Role name*</FormNameLabelWrapper>
                  <FormNameInputWrapper placeholder={"Role name*"} labelid="roleName" id="roleName" label="Role name*" value={selectedItem.name}
                    onChange={(e) => setSelectedItem({ ...selectedItem, name: e.target.value })} disabled={!isRoleEdit} />
                </FormNameWrapper>
                <FormNameWrapper sx={{ width: "55%", }} size="small">
                  <FormNameLabelWrapper id="lastName">  Description</FormNameLabelWrapper>
                  <FormNameInputWrapper placeholder={"Description"} labelid="description" id="description" label="Description" value={selectedItem.description}
                    onChange={(e) => setSelectedItem({ ...selectedItem, description: e.target.value, })} disabled={!isRoleEdit} />
                </FormNameWrapper>
              </Box>
            </Box>
          </Box>
          <PermissionHeading>Permissions</PermissionHeading>
          <Box display="flex" flexDirection="column" justifyContent="space-between" width="100%" mx="auto">
            <Paper sx={{ width: "70%", overflow: "hidden" }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} ></TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center">  View</TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center">  Create</TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center">  Modify</TableCell>
                      <TableCell sx={{ fontWeight: 500, padding: 0, border: "none" }} align="center">  Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{rolePermissionDetails}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Box>
      </Box>
      {/* ROLE DETAILS END */}

      {/* BUTTONS START */}
      <Box display="flex" flexDirection="row" width="100%" my="1rem" gap="0.5rem">
        <CommonButton width={106} height={40} textStyle={{ fontSize: "1rem", fontWeight: 600, fontFamily: "Helvetica", letterSpacing: "normal", }}
          customStyle={{ marginRight: "20px", cursor: !isRoleEdit ? "not-allowed" : "", marginTop: "20px", }} onClick={(event) => { _handleUpdateRole(event); }} title={"Save"}
          color={color.buttonColor} disabled={!isRoleEdit} startIcon={<BookmarkBorderOutlinedIcon startIconStyle={{ height: 24, width: 24, }}
            sx={{ marginRight: "-8px", marginLeft: "4px" }} />}></CommonButton>

        <Button onClick={() => { navigate(selectedAccount ? "/roles?accountId=" + selectedAccount.id : "/roles"); }} className="cancel_style1" variant="contained"
          startIcon={<ClearOutlinedIcon startIconStyle={{ height: 24, width: 24, }} sx={{ marginRight: "-8px", marginLeft: "4px" }} />}>
          {/* // setAccountState('Display');   // setSelectedItem(null);   // setIsAccountEdit(false); */}
          <label className="labelStyle">Cancel</label>
        </Button>

        <Button onClick={() => { setIsDeleteOpen(true); }} style={{
          cursor: "pointer", backgroundColor: color.whiteColor, width: "7rem", height: "2.5rem", color: color.themeRed,
          textTransform: "none", border: "1.5px solid", borderColor: color.themeRed,
        }} variant="contained">
          <Box display="flex" gap="0.5rem" alignItems="center">
            <img alt="" src={trash} style={{ width: "1.3rem", display: "flex", alignItems: "center", }}></img>
            <Typography className="labelStyle" style={{ marginTop: "1px" }}>  Delete</Typography>
          </Box>
        </Button>
      </Box>
      {/* BUTTONS END */}
    </>
  );
};

export default SEditRole;
