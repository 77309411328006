import { Strings } from "../../utils/Strings";

export const nameDetails = [
  {
    id: "firstName",
    label: "First name*",
  },
  {
    id: "lastName",
    label: "Last name*",
  },
];

export const billingDetailsArr = [
  {
    id: "companyName",
    label: "Company name",
  },
  {
    id: "address",
    label: "Address",
  },
];

export const BillContactArr = [
  {
    id: "name",
    label: "Full name",
  },
  {
    id: "title",
    label: "Title",
  },
];

export const validateAllFields = (
  userDetails,
  billingDetails,
  setOpen2,
  setMessage2
) => {
  const { firstName, lastName, email, phoneNumber } = userDetails;
  const { postalCode } = billingDetails;

  // Validate required fields
  if (firstName.trim() === "" || lastName.trim() === "") {
    setOpen2(true);
    setMessage2(Strings.wrong_credentials);
    return true;
  }

  // Validate postal code
  //   if (!postalCode) {
  //     setOpen2(true);
  //     setMessage2("Please enter valid postal code");
  //     return true;
  //   }

  // Validate email
  if (
    !email.match(
      /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    setOpen2(true);
    setMessage2(Strings.email_error);
    return true;
  }

  // Validate phone number
  if (
    phoneNumber.trim() === "" ||
    phoneNumber.length < 6 ||
    phoneNumber.length > 15 ||
    !/^[0-9]+$/.test(phoneNumber)
  ) {
    setOpen2(true);
    setMessage2(Strings.phone_error);
    return true;
  }

  return false;
};
