import React from 'react'
import {  useMediaQuery } from '@mui/material'

import datanorm from '../../assets/ben1.png'
import privacy from '../../assets/ben2.png'
import reduced from '../../assets/ben3.png'
import scal from '../../assets/ben4.png'
import efficient from '../../assets/ben5.png'
import { BenefitsBoxConatiner, BenefitsBoxConatinerHeading, BenefitsBoxConatinerSubHeading, BenifitLowerContainer, BenifitSuperContainer, BenifitUpperContainer, BenifitsHeading } from './Home.styles'
import { getFontSize } from '../../utils/utils'

const upperObj = [
    {
    heading: "Data Normalization",
    description: "Our mapping capabilities ensure data is translatable from one platform to another",
    logo: datanorm,
    logoAlt:"datanorm"
    },
    {
    heading: "Scalability",
    description: "As MadConnect is a universal hub, connecting once will turn on multiple connections to other platforms",
    logo: privacy,
    logoAlt:"privacy"
    },
    {
    heading: "Reduced Resource Cost",
    description: "As platforms evolve APIs, MadTech will keep connections up to date, lessening the burden on your engineering resources",
    logo: reduced,
    logoAlt:"reduced"
    }
]


const lowerObj = [
    {
    heading: "Privacy Safe",
    description: "End-to-end encryption and SOC 2 compliant",
    logo: scal,
    logoAlt:"scal"
    },
    {
    heading: "Efficient Economics",
    description: "Unlimited data transfer through our self-service platform",
    logo: efficient,
    logoAlt:"efficient"
    },
]

const Benifits = () => {

    const matches = {
        lg: useMediaQuery('(min-width:1100px)'),
        md: useMediaQuery('(min-width:980px)'),
        sm: useMediaQuery('(min-width:880px)'),
        xs: useMediaQuery('(min-width:760px)'),
        xss: useMediaQuery('(min-width:650px)'),
      };

    return (
        <BenifitSuperContainer width='100%' mb={10} >
            <BenifitSuperContainer flexDirection='column' width='91%' maxWidth={1350}>
                <BenifitsHeading fontSize={getFontSize(matches, {lg: '2.5rem', md: '2.2rem', sm: '1.9rem', xs: '1.7rem', xss: '1.7rem'})}>
                    Benefits
                </BenifitsHeading>
                <BenifitUpperContainer matchesxss={matches.xss} >
                    {
                        upperObj?.map((details, index)=>{
                           return <BenefitsBoxConatiner matchesxss={matches.xss} width={matches.xss ? '25%' : '100%'} key={index} >
                            <img style={{ width: "3rem", height: 48 }} alt={details.logoAlt} src={details.logo} />
                            <BenefitsBoxConatinerHeading fontSize={getFontSize(matches, {lg: '1.5rem', md: '1.3rem', sm: '1rem', xs: '1rem', xss: "1rem"})}>
                                {details.heading}
                            </BenefitsBoxConatinerHeading>
                            <BenefitsBoxConatinerSubHeading width={index >= 1 ?  "95%" : "85%"} >
                                {details.description}
                            </BenefitsBoxConatinerSubHeading>
                        </BenefitsBoxConatiner>
                        })
                    }
                </BenifitUpperContainer>
                <BenifitLowerContainer matchesxss={matches.xss} >
                    {
                        lowerObj?.map((details, index)=>{
                            return <BenefitsBoxConatiner width={matches.xss ? '30%' : '100%'} matchesxss={matches.xss} key={index}  >
                            <img style={{ width: "3rem", height: 48 }} alt={details.logoAlt} src={details.logo} />
                            <BenefitsBoxConatinerHeading fontSize={getFontSize(matches, {lg: '1.5rem', md: '1.3rem', sm: '1rem', xs: '1rem', xss: "1rem"})}>
                                {details.heading}
                            </BenefitsBoxConatinerHeading>
                            <BenefitsBoxConatinerSubHeading width={index >= 1 ?  "95%" : "85%"} >
                                {details.description}
                            </BenefitsBoxConatinerSubHeading>
                        </BenefitsBoxConatiner>
                        })
                    }
                </BenifitLowerContainer>
            </BenifitSuperContainer>
        </BenifitSuperContainer>
    )
}

export default Benifits